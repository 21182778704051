import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { first, Observable } from 'rxjs';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';
import { colorObj } from 'src/app/shared/color-object';
import { UploadService } from 'src/app/services/upload.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-room-type',
  templateUrl: './room-type.component.html',
  styleUrls: ['./room-type.component.scss']
})
export class RoomTypeComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  roomTypes = [];
  roomClasses = [];
  bedOccupancy = [];
  extraBedOccupancy = [];
  updateType: boolean = false;
  roomTypeForm: FormGroup;
  amenitiesList = [];
  choosenAmenities = [];
  quantities = [];
  occupants = [];
  pricingTypes = [];
  roomAmenities = [];
  smokingAllowed = [];
  responses = [];
  roomSpaces = [];
  amenitiesSelected = [];
  extraBedNeeded: boolean = false;
  overBookingSet: boolean = false;
  extraCotDetails: boolean = false;
  extraBedDetails: boolean = false;
  cotCost: boolean = false;
  bedCost: boolean = false;
  source_url;
  imageName: any;
  public bucketUrl = `${environment.BUCKET_URL}`;
  categoryId;
  typeId;
  typeById: any = [];
  bedOccupancyInfo: any = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private uploadService: UploadService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.roomTypeForm = this.fb.group({
      roomType: ['', Validators.required],
      roomClass: ['', Validators.required],
      bathrooms: ['', Validators.required],
      isSmoking: ['', Validators.required],
      isWheelchair: ['', Validators.required],
      roomSpace: ['', Validators.required],
      roomName: ['', Validators.required],
      roomCode: ['', Validators.required],
      roomDescription: ['', Validators.required],
      roomImage: [''],
      roomAmenities: [[], Validators.required],
      occupancyArray: fb.array([]),
      adultOccupancy: ['', Validators.required],
      childOccupancy: ['', Validators.required],
      isExtraBed: ['', Validators.required],
      extraCot: [''],
      extraCotNo: [''],
      extraCotCost: [''],
      extraCotAmount: [''],
      extraBed: [''],
      extraBedType: [''],
      extraBedNo: [''],
      extraBedCost: [''],
      extraBedAmount: [''],
      overBookingLimit: ['', Validators.required],
      overBookingCapacity: ['']
      // derivedRoom: ['', Validators.required],
      // derivedRoomType: [''],
      // derivedRoomDiscount: [''],
      // derivedRoomDiscountValue: ['']
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.roomTypes = res['ROOM_TYPE'];
        this.roomClasses = res['ROOM_CLASS'];
        this.pricingTypes = res['PRICE'];
        this.bedOccupancy = res['BED_OCCUPANCY'];
        this.extraBedOccupancy = res['EXTRA_BED_OCCUPANCY'];
        this.roomAmenities = res['ROOM_AMENITIES'];
        this.smokingAllowed = res['SMOKING_ALLOWED'];
        this.responses = res['RESPONSE'];
        this.quantities = res['QUANTITY'];
        this.occupants = res['OCCUPANT'];
        this.roomSpaces = res['ROOM_SPACE'];
      }
    })
    this.hotelService.actionType
      .pipe(first()).subscribe(step => {
        if (!!step) {
          if (step === 'add_type') {
            this.hotelService.getId().subscribe((val) => {
              this.categoryId = val;
            });
            this.addOccupancy();
          }
          else if (step === 'edit_type') {
            this.hotelService.getId().subscribe((val) => {
              this.typeId = val;
            });
            this.updateType = true;
            this.loading = true;
            this.hotelService.getRoomTypeById(this.typeId).subscribe({
              next: (res) => {
                this.loading = false;
                this.typeById = res.object;
                let image = res.object.ATTACHMENT_SOURCE;
                let selectedImage = image.split("/");
                this.imageName = selectedImage[selectedImage.length - 1];
                this.source_url = res.object.ATTACHMENT_SOURCE;
                this.roomTypeForm.get("roomType").setValue(res.object.ROOM_TYPE_ID?.ID);
                this.roomTypeForm.get("roomClass").setValue(res.object.ROOM_CLASS_ID?.ID);
                this.roomTypeForm.get("bathrooms").setValue(res.object.BATHROOM_ID?.ID);
                this.roomTypeForm.get("isSmoking").setValue(res.object.SMOKING_ALLOWED_ID?.ID);
                this.roomTypeForm.get("isWheelchair").setValue(res.object.WHEELCHAIR_ACCESSIBLE_ID?.ID);
                this.roomTypeForm.get("roomSpace").setValue(res.object.ROOM_SPACE_ID?.ID);
                this.roomTypeForm.get("roomName").setValue(res.object.NAME);
                this.roomTypeForm.get("roomCode").setValue(res.object.CODE);
                this.roomTypeForm.get("roomDescription").setValue(res.object.DESCRIPTION);
                if (!!res.object.ROOM_AMENITIES_ID) {
                  for (let amenity of res.object.ROOM_AMENITIES_ID) {
                    this.choosenAmenities.push(amenity.AMENITIES_ID?.NAME);
                  }
                }
                this.roomTypeForm.get("roomAmenities").setValue(this.choosenAmenities);
                this.bedOccupancyInfo = res.object.BED_OCCUPANCY;
                if (Array.isArray(this.bedOccupancyInfo) && this.bedOccupancyInfo.length > 0) {
                  for (let i = 0; i < this.bedOccupancyInfo.length; i++) {
                    this.addOccupancy();
                    let occupArray = this.roomTypeForm.get('occupancyArray') as FormArray;
                    let occupArrayObj = occupArray.controls[i] as FormGroup;
                    occupArrayObj.get('bedType').patchValue(this.bedOccupancyInfo[i]['OCCUPANCY_ID']['ID']);
                    occupArrayObj.get('occupancyNo').patchValue(this.bedOccupancyInfo[i]['OCCUPANCY_QUANTITY']['ID']);
                  };
                }
                else {
                  this.addOccupancy();
                }
                this.roomTypeForm.get("adultOccupancy").setValue(res.object.ADULT_OCCUPANCY);
                this.roomTypeForm.get("childOccupancy").setValue(res.object.CHILD_OCCUPANCY);
                this.roomTypeForm.get("isExtraBed").setValue(res.object.EXTRA_BED_ID?.ID);
                if (res.object.EXTRA_BED_ID?.ID == 1) {
                  this.extraBedNeeded = true;
                }
                this.roomTypeForm.get("extraCotNo").setValue(res.object.INFANT_BED_ID?.ID);
                if (res.object.INFANT_BED_ID?.ID != 0) {
                  this.roomTypeForm.get("extraCot").setValue(true);
                  this.extraCotDetails = true;
                }
                this.roomTypeForm.get("extraCotCost").setValue(res.object.INFANT_BED_COST_TYPE_ID?.ID);
                if (res.object.INFANT_BED_COST_TYPE_ID?.ID == 2) {
                  this.cotCost = true;
                  this.roomTypeForm.get("extraCotAmount").setValue(res.object.INFANT_BED_COST);
                }
                this.roomTypeForm.get("extraBedType").setValue(res.object.ADULT_BED_TYPE_ID?.ID);
                this.roomTypeForm.get("extraBedNo").setValue(res.object.ADULT_BED_ID?.ID);
                if (res.object.ADULT_BED_ID?.ID != 0) {
                  this.roomTypeForm.get("extraBed").setValue(true);
                  this.extraBedDetails = true;
                }
                this.roomTypeForm.get("extraBedCost").setValue(res.object.ADULT_BED_COST_TYPE_ID?.ID);
                if (res.object.ADULT_BED_COST_TYPE_ID?.ID == 2) {
                  this.bedCost = true;
                  this.roomTypeForm.get("extraBedAmount").setValue(res.object.ADULT_BED_COST);
                }
                this.roomTypeForm.get("overBookingLimit").setValue(res.object.OVERBOOKING_ID?.ID);
                if (res.object.OVERBOOKING_ID?.ID == 1) {
                  this.overBookingSet = true;
                  this.roomTypeForm.get("overBookingCapacity").setValue(res.object.OVERBOOKING_CAPACITY);
                }
              },
              error: (e) => {
                this.loading = false;
                this.notify.showNotification(
                  e.error.message,
                  "top",
                  (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
                  e.error.status
                )
              }
            });
          }
        }
        else {
          this.router.navigateByUrl('rooms');
        }
      })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  backToRoomTypes() {
    this.roomTypeForm.reset();
    this.router.navigateByUrl('rooms');
  }

  getOccupancy() {
    return (this.roomTypeForm.get('occupancyArray') as FormArray).controls;
  }

  removeOccupancy(index: number) {
    (<FormArray>this.roomTypeForm.get('occupancyArray')).removeAt(index);
  }

  addOccupancy() {
    let codeIndex = (<FormArray>this.roomTypeForm.get('occupancyArray')).length;
    if (codeIndex < 10) {
      let fg = this.fb.group({
        bedType: this.fb.control('', Validators.required),
        occupancyNo: this.fb.control('', Validators.required)
      });
      (<FormArray>this.roomTypeForm.get('occupancyArray')).push(fg);
    }
  }

  removeAmenities(roomName: string) {
    this.amenitiesList = this.roomTypeForm.value.roomAmenities as string[];
    const index = this.amenitiesList.indexOf(roomName);
    if (index !== -1) {
      this.amenitiesList.splice(index, 1);
    }
    this.roomTypeForm.get('roomAmenities').setValue(this.amenitiesList);
  }

  toggle(event, fieldName) {
    if (fieldName == 'isExtraBed') {
      if (event.value == 1) {
        this.extraBedNeeded = true;
        this.roomTypeForm.controls["extraCot"].setValidators(Validators.required);
        this.roomTypeForm.controls["extraBed"].setValidators(Validators.required);
      }
      else if (event.value == 2) {
        this.extraBedNeeded = false;
        this.extraCotDetails = false;
        this.extraBedDetails = false;
        this.roomTypeForm.get('extraCot').clearValidators();
        this.roomTypeForm.get('extraCot').updateValueAndValidity();
        this.roomTypeForm.get('extraBed').clearValidators();
        this.roomTypeForm.get('extraBed').updateValueAndValidity();
        this.roomTypeForm.get('extraCotNo').clearValidators();
        this.roomTypeForm.get('extraCotNo').updateValueAndValidity();
        this.roomTypeForm.get('extraCotCost').clearValidators();
        this.roomTypeForm.get('extraCotCost').updateValueAndValidity();
        this.roomTypeForm.get('extraCotAmount').clearValidators();
        this.roomTypeForm.get('extraCotAmount').updateValueAndValidity();
        this.roomTypeForm.get('extraBedType').clearValidators();
        this.roomTypeForm.get('extraBedType').updateValueAndValidity();
        this.roomTypeForm.get('extraBedNo').clearValidators();
        this.roomTypeForm.get('extraBedNo').updateValueAndValidity();
        this.roomTypeForm.get('extraBedCost').clearValidators();
        this.roomTypeForm.get('extraBedCost').updateValueAndValidity();
        this.roomTypeForm.get('extraBedAmount').clearValidators();
        this.roomTypeForm.get('extraBedAmount').updateValueAndValidity();
        this.roomTypeForm.controls["extraCot"].reset();
        this.roomTypeForm.controls["extraCotNo"].reset();
        this.roomTypeForm.controls["extraCotCost"].reset();
        this.roomTypeForm.controls["extraCotAmount"].reset();
        this.roomTypeForm.controls["extraBed"].reset();
        this.roomTypeForm.controls["extraBedType"].reset();
        this.roomTypeForm.controls["extraBedNo"].reset();
        this.roomTypeForm.controls["extraBedCost"].reset();
        this.roomTypeForm.controls["extraBedAmount"].reset();
      }
    }
    else if (fieldName == 'extraCot') {
      if (event.checked == true) {
        this.extraCotDetails = true;
        this.roomTypeForm.controls["extraCotNo"].setValidators(Validators.required);
        this.roomTypeForm.controls["extraCotCost"].setValidators(Validators.required);
        this.roomTypeForm.get('extraBed').clearValidators();
        this.roomTypeForm.get('extraBed').updateValueAndValidity();
      }
      else {
        this.extraCotDetails = false;
        this.roomTypeForm.controls["extraBed"].setValidators(Validators.required);
        this.roomTypeForm.get('extraCotNo').clearValidators();
        this.roomTypeForm.get('extraCotNo').updateValueAndValidity();
        this.roomTypeForm.get('extraCotCost').clearValidators();
        this.roomTypeForm.get('extraCotCost').updateValueAndValidity();
        this.roomTypeForm.controls["extraCotNo"].reset();
        this.roomTypeForm.controls["extraCotCost"].reset();
        this.roomTypeForm.controls["extraCotAmount"].reset();
      }
    }
    else if (fieldName == 'extraCotCost') {
      if (event.value == 1) {
        this.cotCost = false;
        this.roomTypeForm.get('extraCotAmount').clearValidators();
        this.roomTypeForm.get('extraCotAmount').updateValueAndValidity();
        this.roomTypeForm.controls["extraCotAmount"].reset();
      }
      else if (event.value == 2) {
        this.cotCost = true;
        this.roomTypeForm.controls["extraCotAmount"].setValidators(Validators.required);
      }
    }
    else if (fieldName == 'extraBed') {
      if (event.checked == true) {
        this.extraBedDetails = true;
        this.roomTypeForm.controls["extraBedType"].setValidators(Validators.required);
        this.roomTypeForm.controls["extraBedNo"].setValidators(Validators.required);
        this.roomTypeForm.controls["extraBedCost"].setValidators(Validators.required);
        this.roomTypeForm.get('extraCot').clearValidators();
        this.roomTypeForm.get('extraCot').updateValueAndValidity();
      }
      else {
        this.extraBedDetails = false;
        this.roomTypeForm.controls["extraCot"].setValidators(Validators.required);
        this.roomTypeForm.get('extraBedType').clearValidators();
        this.roomTypeForm.get('extraBedType').updateValueAndValidity();
        this.roomTypeForm.get('extraBedNo').clearValidators();
        this.roomTypeForm.get('extraBedNo').updateValueAndValidity();
        this.roomTypeForm.get('extraBedCost').clearValidators();
        this.roomTypeForm.get('extraBedCost').updateValueAndValidity();
        this.roomTypeForm.controls["extraBedType"].reset();
        this.roomTypeForm.controls["extraBedNo"].reset();
        this.roomTypeForm.controls["extraBedCost"].reset();
        this.roomTypeForm.controls["extraBedAmount"].reset();
      }
    }
    else if (fieldName == 'extraBedCost') {
      if (event.value == 1) {
        this.bedCost = false;
        this.roomTypeForm.get('extraBedAmount').clearValidators();
        this.roomTypeForm.get('extraBedAmount').updateValueAndValidity();
        this.roomTypeForm.controls["extraBedAmount"].reset();
      }
      else if (event.value == 2) {
        this.bedCost = true;
        this.roomTypeForm.controls["extraBedAmount"].setValidators(Validators.required);
      }
    }
    else if (fieldName == 'overBookingLimit') {
      if (event.value == 1) {
        this.overBookingSet = true;
        this.roomTypeForm.controls["overBookingCapacity"].setValidators(Validators.required);
      }
      else if (event.value == 2) {
        this.overBookingSet = false;
        this.roomTypeForm.get('overBookingCapacity').clearValidators();
        this.roomTypeForm.get('overBookingCapacity').updateValueAndValidity();
        this.roomTypeForm.controls["overBookingCapacity"].reset();
      }
    }
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: "7",
      SECTION: 'type',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
      }
      let tempObjForUpload = {
        url: res['SIGNED_URL'],
        file: selectedFile
      }
      await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
        if (data) {
          this.loading = false;
          this.notify.showNotification(
            'File uploaded successfully',
            "top",
            (!!colorObj[200] ? colorObj[200] : "success"),
            200
          );
          this.imageName = files[0].name;
        }
      }).catch((err) => {
        this.handleError(err);
      });
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  saveRoomType() {
    this.loading = true;
    this.roomAmenities.forEach(val => {
      if (this.roomTypeForm.value.roomAmenities.indexOf(val.NAME) != -1) {
        this.amenitiesSelected.push(val.ID)
      }
    })
    let formData: any;
    formData = this.roomTypeForm.value;
    let array = formData['occupancyArray'];
    let bedOccupancyArray = [];
    for (let i = 0; i < array.length; i++) {
      bedOccupancyArray.push({
        "OCCUPANCY_ID": array[i].bedType,
        "OCCUPANCY_QUANTITY": array[i].occupancyNo
      })
    }
    let savedTypeFiles = {
      "CATEGORY_ID": this.categoryId,
      "DESCRIPTION": this.roomTypeForm.value.roomDescription,
      "ATTACHMENT_SOURCE": this.source_url,
      "ROOM_TYPE_ID": this.roomTypeForm.value.roomType,
      "ROOM_CLASS_ID": this.roomTypeForm.value.roomClass,
      "BATHROOM_ID": this.roomTypeForm.value.bathrooms,
      "SMOKING_ALLOWED_ID": this.roomTypeForm.value.isSmoking,
      "WHEELCHAIR_ACCESSIBLE_ID": this.roomTypeForm.value.isWheelchair,
      "ROOM_SPACE_ID": this.roomTypeForm.value.roomSpace,
      "NAME": this.roomTypeForm.value.roomName,
      "CODE": this.roomTypeForm.value.roomCode,
      "ROOM_AMENITIES_ID": this.amenitiesSelected,
      "BED_OCCUPANCY": bedOccupancyArray,
      "ADULT_OCCUPANCY": this.roomTypeForm.value.adultOccupancy,
      "CHILD_OCCUPANCY": this.roomTypeForm.value.childOccupancy,
      "EXTRA_BED_ID": this.roomTypeForm.value.isExtraBed,
      "EXTRA_BED": {
        "INFANT_BED_ID": !!this.roomTypeForm.value.extraCotNo ? this.roomTypeForm.value.extraCotNo : 0,
        "INFANT_BED_COST_TYPE_ID": !!this.roomTypeForm.value.extraCotCost ? this.roomTypeForm.value.extraCotCost : 0,
        "INFANT_BED_COST": !!this.roomTypeForm.value.extraCotAmount ? this.roomTypeForm.value.extraCotAmount : 0,
        "ADULT_BED_TYPE_ID": !!this.roomTypeForm.value.extraBedType ? this.roomTypeForm.value.extraBedType : 0,
        "ADULT_BED_ID": !!this.roomTypeForm.value.extraBedNo ? this.roomTypeForm.value.extraBedNo : 0,
        "ADULT_BED_COST_TYPE_ID": !!this.roomTypeForm.value.extraBedCost ? this.roomTypeForm.value.extraBedCost : 0,
        "ADULT_BED_COST": !!this.roomTypeForm.value.extraBedAmount ? this.roomTypeForm.value.extraBedAmount : 0
      },
      "OVERBOOKING_ID": this.roomTypeForm.value.overBookingLimit,
      "OVERBOOKING_CAPACITY": this.roomTypeForm.value.overBookingCapacity
    }
    this.hotelService.addRoomType(savedTypeFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.backToRoomTypes();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.backToRoomTypes();
      }
    })
  }

  updateRoomType() {
    this.loading = true;
    this.roomAmenities.forEach(val => {
      if (this.roomTypeForm.value.roomAmenities.indexOf(val.NAME) != -1) {
        this.amenitiesSelected.push(val.ID)
      }
    })
    let formData: any;
    formData = this.roomTypeForm.value;
    let array = formData['occupancyArray'];
    let bedOccupancyArray = [];
    for (let i = 0; i < array.length; i++) {
      bedOccupancyArray.push({
        "OCCUPANCY_ID": array[i].bedType,
        "OCCUPANCY_QUANTITY": array[i].occupancyNo
      })
    }
    let savedTypeFiles = {
      "ID": this.typeId,
      "DESCRIPTION": this.roomTypeForm.value.roomDescription,
      "ATTACHMENT_SOURCE": this.source_url,
      "ROOM_TYPE_ID": this.roomTypeForm.value.roomType,
      "ROOM_CLASS_ID": this.roomTypeForm.value.roomClass,
      "BATHROOM_ID": this.roomTypeForm.value.bathrooms,
      "SMOKING_ALLOWED_ID": this.roomTypeForm.value.isSmoking,
      "WHEELCHAIR_ACCESSIBLE_ID": this.roomTypeForm.value.isWheelchair,
      "ROOM_SPACE_ID": this.roomTypeForm.value.roomSpace,
      "NAME": this.roomTypeForm.value.roomName,
      "CODE": this.roomTypeForm.value.roomCode,
      "ROOM_AMENITIES_ID": this.amenitiesSelected,
      "BED_OCCUPANCY": bedOccupancyArray,
      "ADULT_OCCUPANCY": this.roomTypeForm.value.adultOccupancy,
      "CHILD_OCCUPANCY": this.roomTypeForm.value.childOccupancy,
      "EXTRA_BED_ID": this.roomTypeForm.value.isExtraBed,
      "EXTRA_BED": {
        "INFANT_BED_ID": !!this.roomTypeForm.value.extraCotNo ? this.roomTypeForm.value.extraCotNo : 0,
        "INFANT_BED_COST_TYPE_ID": !!this.roomTypeForm.value.extraCotCost ? this.roomTypeForm.value.extraCotCost : 0,
        "INFANT_BED_COST": !!this.roomTypeForm.value.extraCotAmount ? this.roomTypeForm.value.extraCotAmount : 0,
        "ADULT_BED_TYPE_ID": !!this.roomTypeForm.value.extraBedType ? this.roomTypeForm.value.extraBedType : 0,
        "ADULT_BED_ID": !!this.roomTypeForm.value.extraBedNo ? this.roomTypeForm.value.extraBedNo : 0,
        "ADULT_BED_COST_TYPE_ID": !!this.roomTypeForm.value.extraBedCost ? this.roomTypeForm.value.extraBedCost : 0,
        "ADULT_BED_COST": !!this.roomTypeForm.value.extraBedAmount ? this.roomTypeForm.value.extraBedAmount : 0
      },
      "OVERBOOKING_ID": this.roomTypeForm.value.overBookingLimit,
      "OVERBOOKING_CAPACITY": this.roomTypeForm.value.overBookingCapacity,
      "DELETED": false
    }
    this.hotelService.updateRoomType(savedTypeFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.backToRoomTypes();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.backToRoomTypes();
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}