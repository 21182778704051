<div class="dashboard-container" *ngIf="mobile && roleId != 4" fxLayout="column" fxLayoutAlign="center center"
  fxLayoutGap="20px">
  <div>
    <img src="../../../../assets/images/warning.png">
  </div>
  <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
    <p>Dear</p>
    <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
  </span>
  <div class="dasboard-subheading">
    <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
      functionalities of the system</p>
  </div>
  <div>
    <button class="back-button" (click)="logout()">Go Back</button>
  </div>
</div>
<div class="dashboard-container" *ngIf="mobile && roleId == 4">
  <div *ngIf="profileOptionsView" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="10px">
      <div class="back-arrow" (click)="backToRequests()">
        <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
      </div>
      <div class="profile-head">
        Profile
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <div class="profile-name">
        {{this.userInfo.firstName}} {{this.userInfo.lastName}}
      </div>
      <div fxLayout="row" fxLayoutGap="15px" class="profile-details">
        <div>{{userPhoneNo}}</div>
        <div style="align-self: center;">|</div>
        <div>{{userEmailId}}</div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <div class="profile-options" (click)="editStaffProfile()">
        Edit Profile
      </div>
      <mat-divider></mat-divider>
      <div class="profile-options" (click)="changeStaffPassword()">
        Change Password
      </div>
      <mat-divider></mat-divider>
      <div class="profile-options" (click)="logout()">
        Log Out
      </div>
    </div>
  </div>
  <div *ngIf="editProfileView" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="10px">
      <div class="back-arrow" (click)="backToProfileOptions()">
        <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
      </div>
      <div class="profile-head">
        Edit Profile
      </div>
    </div>
    <form [formGroup]="profileform">
      <div fxLayout="column">
        <div class="control-container">
          <div class="label"> First Name</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="fname" autocomplete="off">
              <mat-error style="font-size: 9px;" *ngIf="profileform.controls['fname'].errors?.required">
                First name is required
              </mat-error>
              <mat-error style="font-size: 9px;" *ngIf="profileform.controls['fname'].errors?.pattern">
                Only text is allowed
              </mat-error>
              <mat-error style="font-size: 9px; line-height: 12px;"
                *ngIf="profileform.controls['fname'].errors?.minlength || profileform.controls['fname'].errors?.maxlength">
                First name should be of min length 3 and max length 25
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Last Name</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="lname" autocomplete="off">
              <mat-error style="font-size: 9px;" *ngIf="profileform.controls['lname'].errors?.required">
                Last name is required
              </mat-error>
              <mat-error style="font-size: 9px;" *ngIf="profileform.controls['lname'].errors?.pattern">
                Only text is allowed
              </mat-error>
              <mat-error style="font-size: 9px; line-height: 12px;"
                *ngIf="profileform.controls['lname'].errors?.maxlength">
                Last name should be of max length 25
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Email</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="emailId" readonly="true" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Phone No</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="phoneNo" readonly="true" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Role</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="role" readonly="true" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Services</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="service" readonly="true" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Allocated Rooms</div>
          <div>
            <mat-form-field appearance="outline" class="input-field_fname">
              <input matInput formControlName="allocatedRoom" readonly="true" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="update-div">
          <button mat-flat-button class="update-btn" (click)="savePolicy()"
            [disabled]="profileform.pristine || profileform.invalid">
            <span class="bolder">Update</span>
          </button>
          <button mat-flat-button class="cancel-btn" (click)="backToProfileOptions()">
            <span class="bolder">Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="changePasswordView" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="10px">
      <div class="back-arrow" (click)="backToProfileOptions()">
        <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
      </div>
      <div class="profile-head">
        Change Password
      </div>
    </div>
    <form [formGroup]="passwordform">
      <div fxLayout="column">
        <div class="control-container">
          <div class="label">Old password*</div>
          <div class="phone_number">
            <mat-form-field appearance="outline" class="input-field_phno">
              <input matInput formControlName="old_pswd" autocomplete="off">
            </mat-form-field>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.get('old_pswd').touched && passwordform.controls['old_pswd'].errors?.required">
              Old Password is required
            </mat-error>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.controls['old_pswd'].errors?.pattern">
              Password should contain at least one uppercase letter, one lowercase
              letter, one number and one special character (#?!@$%^&*-) and should
              be
              of length 8-15 characters
            </mat-error>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.get('old_pswd').hasError('maxlength')">
              Password should not exceed 15 characters
            </mat-error>
          </div>
        </div>
        <div class="control-container">
          <div class="label">New password*</div>
          <div class="email_add">
            <mat-form-field appearance="outline" class="input-field_phno">
              <input matInput formControlName="new_pswd" style="font-style: normal;
                  font-weight: normal; height: 20px;" autocomplete="off">
            </mat-form-field>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.get('new_pswd').touched && passwordform.controls['new_pswd'].errors?.required">
              New Password is required
            </mat-error>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.controls['new_pswd'].errors?.pattern">
              Password should contain at least one uppercase letter, one lowercase
              letter, one number and one special character (#?!@$%^&*-) and should
              be
              of length 8-15 characters
            </mat-error>
            <mat-error style="margin-top: -25px; font-size: 10px;"
              *ngIf="passwordform.get('new_pswd').hasError('maxlength')">
              Password should not exceed 15 characters
            </mat-error>
            <mat-error style="margin-top: -25px; font-size: 10px;" *ngIf="passwordform.hasError('oldNewMismatch') &&
                passwordform.controls['old_pswd'].value && passwordform.controls['new_pswd'].value">
              Your new password cannot be your old password.
            </mat-error>
          </div>
        </div>
        <div class="control-container">
          <div class="label">Confirm new password*</div>
          <div class="departmentname">
            <mat-form-field appearance="outline" class="input-field_phno">
              <input matInput formControlName="confirm_pswd" autocomplete="off">
            </mat-form-field>
            <mat-error style="margin-top: -25px; font-size: 10px;" *ngIf="passwordform.hasError('newConfirmMismatch') &&
                (passwordform.controls['confirm_pswd'].dirty || passwordform.controls['confirm_pswd'].touched)">
              New password and confirm password don't match.
            </mat-error>
          </div>
        </div>
        <div class="update-div">
          <button mat-flat-button class="update-btn" (click)="changePassword()" [disabled]="passwordform.invalid">
            <span class="bolder">Update</span>
          </button>
          <button mat-flat-button class="cancel-btn" (click)="backToProfileOptions()">
            <span class="bolder">Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
  <div class="overlay" *ngIf="loading">
    <div class="overlay__inner">
      <div class="overlay__content"><span class="spinner"></span></div>
    </div>
  </div>
  <div class="right-panel">
    <div fxLayout="column" class="content">
      <div class="profile">
        Profile
      </div>
      <div class="input-container">
        <mat-card class="card">
          <div class="myprofile-body" fxLayout="row">
            <div class="menu-panel">
              <div class="buttons" fxLayout="column">
                <div class="menu-item" (click)="userProfile()">
                  Edit profile
                </div>
                <mat-divider style="margin-top: 15px;"></mat-divider>
                <div class="menu-item" (click)="password()">
                  Password
                </div>
                <mat-divider style="margin-top: 15px;"></mat-divider>
              </div>
            </div>
            <mat-divider [vertical]="true" style="min-height: 55vh;"></mat-divider>
            <div class="form-panel">
              <div *ngIf="isMyProfile">
                <div class="userprofile-content">
                  Edit Profile
                </div>
                <form [formGroup]="profileform">
                  <div class="contents" style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutGap="9%">
                      <div class="control-container">
                        <div class="label"> First Name</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="fname" autocomplete="off">
                            <mat-error style="font-size: 9px;" *ngIf="profileform.controls['fname'].errors?.required">
                              First name is required
                            </mat-error>
                            <mat-error style="font-size: 9px;" *ngIf="profileform.controls['fname'].errors?.pattern">
                              Only text is allowed
                            </mat-error>
                            <mat-error style="font-size: 9px; line-height: 12px;"
                              *ngIf="profileform.controls['fname'].errors?.minlength || profileform.controls['fname'].errors?.maxlength">
                              First name should be of min length 3 and max length 25
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="control-container">
                        <div class="label">Last Name</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="lname" autocomplete="off">
                            <mat-error style="font-size: 9px;" *ngIf="profileform.controls['lname'].errors?.required">
                              Last name is required
                            </mat-error>
                            <mat-error style="font-size: 9px;" *ngIf="profileform.controls['lname'].errors?.pattern">
                              Only text is allowed
                            </mat-error>
                            <mat-error style="font-size: 9px; line-height: 12px;"
                              *ngIf="profileform.controls['lname'].errors?.maxlength">
                              Last name should be of max length 25
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="9%">
                      <div class="control-container">
                        <div class="label">Email</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="emailId" readonly="true" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="control-container">
                        <div class="label">Phone No</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="phoneNo" readonly="true" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="9%">
                      <div class="control-container">
                        <div class="label">Role</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="role" readonly="true" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="control-container" *ngIf="roleId == 3 || roleId == 4">
                        <div class="label">Services</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="service" readonly="true" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="9%">
                      <div class="control-container" *ngIf="roleId == 4">
                        <div class="label">Allocated Rooms</div>
                        <div>
                          <mat-form-field appearance="outline" class="input-field_fname">
                            <input matInput formControlName="allocatedRoom" readonly="true" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="update-div">
                      <button mat-flat-button class="update-btn" (click)="savePolicy()"
                        [disabled]="profileform.pristine || profileform.invalid">
                        <span class="bolder">Update</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="isChangePassword">
                <div class="userprofile-content">
                  Change Password
                </div>
                <form [formGroup]="passwordform">
                  <div class="contents" style="margin-top: 25px;">
                    <div class="control-container">
                      <div class="label">Old password*</div>
                      <div class="phone_number">
                        <mat-form-field appearance="outline" class="input-field_phno">
                          <input matInput formControlName="old_pswd" autocomplete="off">
                        </mat-form-field>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.get('old_pswd').touched && passwordform.controls['old_pswd'].errors?.required">
                          Old Password is required
                        </mat-error>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.controls['old_pswd'].errors?.pattern">
                          Password should contain at least one uppercase letter, one lowercase
                          letter, one number and one special character (#?!@$%^&*-) and should
                          be
                          of length 8-15 characters
                        </mat-error>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.get('old_pswd').hasError('maxlength')">
                          Password should not exceed 15 characters
                        </mat-error>
                      </div>
                    </div>
                    <div class="control-container">
                      <div class="label">New password*</div>
                      <div class="email_add">
                        <mat-form-field appearance="outline" class="input-field_phno">
                          <input matInput formControlName="new_pswd" style="font-style: normal;
                              font-weight: normal; height: 20px;" autocomplete="off">
                        </mat-form-field>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.get('new_pswd').touched && passwordform.controls['new_pswd'].errors?.required">
                          New Password is required
                        </mat-error>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.controls['new_pswd'].errors?.pattern">
                          Password should contain at least one uppercase letter, one lowercase
                          letter, one number and one special character (#?!@$%^&*-) and should
                          be
                          of length 8-15 characters
                        </mat-error>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.get('new_pswd').hasError('maxlength')">
                          Password should not exceed 15 characters
                        </mat-error>
                        <mat-error style="margin-top: -25px; font-size: 10px;" *ngIf="passwordform.hasError('oldNewMismatch') &&
                            passwordform.controls['old_pswd'].value && passwordform.controls['new_pswd'].value">
                          Your new password cannot be your old password.
                        </mat-error>
                      </div>
                    </div>
                    <div class="control-container">
                      <div class="label">Confirm new password*</div>
                      <div class="departmentname">
                        <mat-form-field appearance="outline" class="input-field_phno">
                          <input matInput formControlName="confirm_pswd" autocomplete="off">
                        </mat-form-field>
                        <mat-error style="margin-top: -25px; font-size: 10px;"
                          *ngIf="passwordform.hasError('newConfirmMismatch') &&
                            (passwordform.controls['confirm_pswd'].dirty || passwordform.controls['confirm_pswd'].touched)">
                          New password and confirm password don't match.
                        </mat-error>
                      </div>
                    </div>
                    <div class="update-div">
                      <button mat-flat-button class="update-btn" (click)="changePassword()"
                        [disabled]="passwordform.invalid">
                        <span class="bolder">Update</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>