import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetMasterData } from 'src/app/session/shared/store/actions/user-info.actions';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent {

  userInfo: any;
  categories = [];
  loading: boolean = false;
  serviceId: any;
  updateData = [];
  isEnabled: any;
  hotelServices: any = [];
  permittedServices: any = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddFaqComponent>,
    public adminService: AdminService,
    private notify: NotificationService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  addForm: FormGroup = this.fb.group({
    category: ['', Validators.required],
    question: ['', Validators.required],
    answer: ['', Validators.required]
  })

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (this.data) {
      this.loading = true;
      this.adminService.getFaqById(this.data.id).subscribe(res => {
        this.loading = false;
        this.isEnabled = res.object.ENABLED;
        this.addForm.get("category").setValue(res.object.SERVICE.ID);
        this.addForm.get("question").setValue(res.object.QUESTION);
        this.addForm.get("answer").setValue(res.object.ANSWER);
      });
    }
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.categories = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      if (this.userInfo.roleId == 2) {
        for (let service of this.categories) {
          if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
      else if (this.userInfo.roleId != 2) {
        for (let service of this.categories) {
          if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  save() {
    this.loading = true;
    //To update faq
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "QUESTION": this.addForm.value.question,
        "SERVICE_ID": this.addForm.value.category,
        "ANSWER": this.addForm.value.answer,
        "ENABLED": this.isEnabled
      }
      this.adminService.updateFaq(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    //To add faq
    else {
      let obj = {
        "QUESTION": this.addForm.value.question,
        "SERVICE_ID": this.addForm.value.category,
        "ANSWER": this.addForm.value.answer,
        "ENABLED": true
      }
      this.adminService.addFaq(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}