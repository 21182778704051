<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="bulk-head">
                    Bulk Update Rate Plan
                </div>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="bulkRateForm" fxLayout="column" fxLayoutGap="3%">
                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 20px;">
                        <div class="bulk-subhead">Choose a Date range</div>
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    From
                                </div>
                                <div class="text-box-date">
                                    <input matInput [matDatepicker]="picker1" (dateChange)="selectDate($event)" formControlName="bulkUpdateFrom"
                                        class="date-box" placeholder="Select" [min]="currentDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    To
                                </div>
                                <div class="text-box-date">
                                    <input matInput [matDatepicker]="picker2" formControlName="bulkUpdateTo"
                                        class="date-box" placeholder="Select" [min]="minToDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div class="bulk-subhead">Select Rate Plans</div>
                        <div fxLayout="row" fxLayoutGap="40px">
                            <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Room Category
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="roomCategory"
                                        (selectionChange)="selectRoomCategory($event.value)" placeholder="Select">
                                        <mat-option *ngFor="let category of roomCategories" [value]="category.ID">
                                            {{category.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Room Type
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="roomType"
                                        (selectionChange)="selectRoomTypes()" placeholder="Select" multiple>
                                        <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                            {{room.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let plan of ratePlans">
                            <div fxLayout="column" fxLayoutGap="15px" style="padding-bottom: 10px;">
                                <div class="bulk-subhead">
                                    Select Rates Plans of {{plan.TYPE_NAME}}
                                </div>
                                <div fxLayout="column" *ngIf="plan.RATE_PLANS.length != 0">
                                    <mat-checkbox *ngFor="let rate of plan.RATE_PLANS" (change)="selectPlans($event, plan.TYPE_ID, rate.ID, rate.NAME)">
                                        <span class="box-name">
                                            {{rate.NAME}}
                                        </span>
                                    </mat-checkbox>
                                </div>
                                <ng-container *ngIf="plan.RATE_PLANS.length == 0">
                                    <div fxLayout="column" class="box-name">
                                        No rate plans available
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px" style="padding-top: 20px;">
                        <div formArrayName="roomRateArray">
                            <div fxLayout="column" *ngFor="let inventory of bulkRateForm.controls.roomRateArray.controls; let i = index;"
                                formGroupName="{{i}}">
                                <div fxLayout="column" fxLayoutGap="25px" style="padding-bottom: 10px;">
                                    <div class="bulk-subhead">
                                        {{inventory.value.roomTypeName}}
                                    </div>
                                    <div class="box-name">
                                        Updates for {{inventory.value.ratePlanName}}
                                    </div>
                                    <div formArrayName="occupantRateArray">
                                        <div fxLayout="column"
                                            *ngFor="let occupant of inventory.controls.occupantRateArray.controls; let j = index;"
                                            formGroupName="{{j}}">
                                            <div fxLayout="column" fxLayoutGap="15px" style="padding-bottom: 20px;">
                                                <div fxLayout="row" fxFlex="100%" fxLayoutGap="45px">
                                                    <div fxLayout="column" fxFlex="15%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Occupant
                                                        </div>
                                                        <div>
                                                            <input type="number" class="text-box" formControlName="occupantNo" readonly>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Base Rate
                                                        </div>
                                                        <div>
                                                            <input type="number" class="text-box" formControlName="occupantAmount"
                                                                placeholder="Enter">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="25px">
                        <div class="bulk-subhead">Update Restrictions</div>
                        <div fxLayout="column">
                            <div fxLayout="row">
                                <div formArrayName="blackOutArray" fxFlex="65%">
                                    <div fxLayout="column" fxLayoutGap="10px" *ngFor="let guestControl of getBlackOut(); let k = index;"
                                        formGroupName="{{k}}" class="array-box">
                                        <div fxLayout="row" fxLayoutGap="35px">
                                            <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Blackout Name
                                                </div>
                                                <div>
                                                    <input type="text" class="text-box" formControlName="blackOutName" placeholder="Enter Blackout Name">
                                                </div>
                                            </div>
                                            <div fxFlex="49%" fxLayoutAlign="end ">
                                                <button mat-icon-button class="remove-btn" (click)="removeBlackOut(k)"
                                                    [disabled]="bulkRateForm.controls.blackOutArray.controls.length == 1">
                                                    <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="30px">
                                            <div fxLayout="column" fxFlex="48%" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Blackout Date From
                                                </div>
                                                <div class="text-box-date">
                                                    <input matInput [matDatepicker]="picker3" (dateChange)="selectBlackoutDate(k)" formControlName="blackOutFrom"
                                                        class="date-box" placeholder="Select" [min]="currentDate" readonly>
                                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker3></mat-datepicker>
                                                </div>
                                            </div>
                                            <div fxLayout="column" fxFlex="47%" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Blackout Date To
                                                </div>
                                                <div class="text-box-date">
                                                    <input matInput [matDatepicker]="picker4" formControlName="blackOutTo"
                                                        class="date-box" placeholder="Select" [min]="minBlackoutToDate" readonly>
                                                    <mat-datepicker-toggle matSuffix [for]="picker4" (click)="resetBlackoutDate(k)"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker4></mat-datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button mat-button class="add-occup-btn"
                                    [disabled]="getBlackOut().length == 20" (click)="addBlackOut()">
                                    + Add More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <button mat-button class="save-btn" (click)="updateBulkRate()"
                        [disabled]="bulkRateForm.invalid">Update</button>
                    <button mat-button class="cancel-btn" (click)="cancelUpdate()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>