<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div *ngIf="isRatingMngmt">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 0% 0.5% 2%;">
                <div fxFlex="25%" class="offers">
                    Rating Management
                </div>
                <div fxFlex="45%" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5%">
                    <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="3px">
                        <div class="option-text">
                            Show or Hide Ratings
                        </div>
                        <div>
                            <mat-card class="option-card">
                                <div fxLayout="row" fxLayoutAlign="space-around center">
                                    <div class="options">Hide</div>
                                    <div>
                                        <label class="switch1">
                                            <input #checkbox type="checkbox" [checked]="true">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div class="options">Show</div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                    <div>
                        <button mat-stroked-button class="header-btn" (click)="listRating()">
                            Add Rating Questions
                        </button>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2%">
                <div fxFlex="70%" fxLayout="column" fxLayoutGap="2%" class="rating-content">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="rating-display">
                        <div fxLayout="column" fxFlex="45%" fxLayoutAlign="center start" fxLayoutGap="10px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%" style="width: 100%;" *ngFor="let item of ratingStats">
                                <div fxLayout="row" fxLayoutGap="10px" fxFlex="15%">
                                    <div>{{item.rate}}</div>
                                    <div><img src="../../../../assets/images/rating_star.png"></div>
                                </div>
                                <div fxLayout="row" fxFlex="85%">
                                    <div fxFlex="{{item.width}}%" *ngIf="item.width != 0" class="rating-scale"></div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                            <div class="rating-digit">{{overAllrating}}</div>
                            <div>
                                <iconify-icon icon="material-symbols:star-rounded"
                                    *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                    [ngClass]="star <= overAllrating ? 'star' : 'star-outline'"></iconify-icon>
                            </div>
                            <div class="rating-num">{{totalRatings}} Ratings</div>
                        </div>
                    </div>
                    <!-- <div>
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="3%">
                            <mat-select class="select-ques" placeholder="Filter by rating question"
                                [formControl]="ratingQueFilter" multiple>
                                <mat-option>How would you rate us?</mat-option>
                                <mat-option>Lorem Ipsum</mat-option>
                                <mat-option>Lorem Ipsum</mat-option>
                                <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 5%;">
                                    <button mat-stroked-button class="clear-btn">
                                        Clear All
                                    </button>
                                    <button mat-stroked-button class="apply-btn">
                                        Apply
                                    </button>
                                </div>
                            </mat-select>
                            <button class="filter-btn" fxLayout="row" fxLayoutAlign="space-between center"
                                (click)="openFilterOptions()">
                                <img src="../../../../assets/images/filter-img.png">
                                Filters
                            </button>
                            <div fxLayout="row">
                                <input matInput [formControl]="reviewSearch" class="search-box" placeholder="Search">
                                <div class="search-icon-div">
                                    <mat-icon matSuffix class="search_icon">search</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div>
                        <mat-tab-group class="rating-tabs" (selectedTabChange)="onTabChanged($event)" [(selectedIndex)]="selectedTab">
                            <!-- <mat-tab label="During Stay">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <div fxLayout="column" *ngFor="let rate of duringStayReview; let i = index" fxLayoutGap="20px" style="padding: 10px 0;">
                                        <div (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxLayout="row" fxLayoutAlign=" center">
                                                <div fxLayout="column" fxFlex="25%" fxLayoutGap="8px"
                                                    fxLayoutAlign="center center">
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                    <div class="user-name" style="text-align: center; width: 80%;">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}
                                                    </div>
                                                </div>
                                                <div fxFlex="75%" class="user-review" *ngIf="!!rate.COMMENT">
                                                    {{rate.COMMENT}}
                                                </div>
                                                <div fxFlex="75%" class="user-review" *ngIf="!rate.COMMENT" style="text-align: center;">
                                                    No comments added
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!!rate.RATING && rate.RATING.length != 0">
                                                <div fxLayout="row wrap" fxLayoutGap="3%" style="padding: 30px 0 0 20px;">
                                                    <ng-container *ngFor="let que of rate.RATING; let i=index;">
                                                        <div fxFlex="47%" *ngIf="que.RATING_SERVICES != null">
                                                            <div fxLayout="row" fxLayoutAlign="start center"
                                                                style="margin-bottom: 20px;">
                                                                <div fxFlex="65%" class="user-que">
                                                                    {{que.RATING_SERVICES?.SERVICE_INFO}}</div>
                                                                <iconify-icon icon="material-symbols:star-rounded"
                                                                    *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                                    [ngClass]="star <= que.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </div>
                                </mat-card>
                            </mat-tab> -->
                            <mat-tab label="Post Stay">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <div fxLayout="column" *ngFor="let rate of postStayReview; let i = index" fxLayoutGap="20px" style="padding: 10px 0;">
                                        <div (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxLayout="row" fxLayoutAlign=" center">
                                                <div fxLayout="column" fxFlex="25%" fxLayoutGap="8px"
                                                    fxLayoutAlign="center center">
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                    <div class="user-name" style="text-align: center; width: 80%;">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}
                                                    </div>
                                                </div>
                                                <div fxFlex="75%" class="user-review" *ngIf="!!rate.COMMENT">
                                                    {{rate.COMMENT}}
                                                </div>
                                                <div fxFlex="75%" class="user-review" *ngIf="!rate.COMMENT" style="text-align: center;">
                                                    No comments added
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!!rate.RATING && rate.RATING.length != 0">
                                                <div fxLayout="row wrap" fxLayoutGap="3%" style="padding: 30px 0 0 20px;">
                                                    <ng-container *ngFor="let que of rate.RATING; let i=index;">
                                                        <div fxFlex="47%" *ngIf="que.RATING_SERVICES != null">
                                                            <div fxLayout="row" fxLayoutAlign="start center"
                                                                style="margin-bottom: 20px;">
                                                                <div fxFlex="65%" class="user-que">
                                                                    {{que.RATING_SERVICES?.SERVICE_INFO}}</div>
                                                                <iconify-icon icon="material-symbols:star-rounded"
                                                                    *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                                    [ngClass]="star <= que.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>     
                                        <mat-divider></mat-divider>
                                    </div>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="Transport">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of transportReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Category</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_ID.NAME}}</div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Type</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_TYPE_ID.NAME}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="Conference Room">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of conferenceReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Category</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_ID.NAME}}</div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Type</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_TYPE_ID.NAME}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="Packages">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of packageReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Category</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_ID.NAME}}</div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Type</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_TYPE_ID.NAME}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="In-Room Dining">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of diningReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column">
                                                <ul>
                                                    <li *ngFor="let dsVal of rate.ITEMS" class="dining-content">
                                                        {{dsVal.CATEGORY_ID?.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="Spa">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of spaReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Category</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_ID.NAME}}</div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="service-type" fxFlex="25%">Type</div>
                                                    <div class="service-value" fxFlex="75%">{{rate.RESERVATION?.CATEGORY_TYPE_ID.NAME}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="House Keeping">
                                <mat-card class="table-card" *ngIf="!loading && zeroReviews">
                                    <div class="user-que" style="text-align: center;">No Records Found</div>
                                </mat-card>
                                <mat-card class="table-card" *ngIf="!loading && !zeroReviews" ngxInfiniteScroller
                                    strategy="scrollingToBottom" (onScrollDown)="onScrollDown()">
                                    <ng-container *ngFor="let rate of housekeepingReview; let i = index">
                                        <div fxLayout="row" fxLayoutAlign=" center" (click)="getRatingDetails(i)" style="cursor: pointer">
                                            <div fxFlex="50%" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign=" center">
                                                <div>
                                                    <img src="../../../../assets/images/review-user.png" class="user-photo">
                                                </div>
                                                <div fxLayout="column" fxLayoutGap="7%">
                                                    <div class="user-name">{{rate.USER?.FIRST_NAME}} {{rate.USER?.LAST_NAME}}</div>
                                                    <div>
                                                        <iconify-icon icon="material-symbols:star-rounded"
                                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                                            [ngClass]="star <= rate.RATE ? 'star' : 'star-outline'"></iconify-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
                                                <ul>
                                                    <li *ngFor="let dsVal of rate.ITEMS" class="dining-content">
                                                        {{dsVal.ITEM_ID?.NAME}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <mat-divider style="margin: 2% 0;"></mat-divider>
                                    </ng-container>
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <div fxFlex="28%">
                    <mat-card class="detail-card" fxLayout="column" fxLayoutGap="5%">
                        <div class="review-num" style="padding: 5% 0 0 5%;">
                            Details
                        </div>
                        <mat-divider class="divider"></mat-divider>
                        <div fxLayout="column" fxLayoutGap="10%" style="padding: 5%;" *ngIf="ratingDetails.length != 0">
                            <div fxLayout="row" fxLayoutGap="2%" *ngIf="selectedTab != 0"> <!-- *ngIf="selectedTab != 0 && selectedTab != 1" -->
                                <div fxFlex="48%" fxLayout="column" fxLayoutGap="5%">
                                    <div class="detail-head">Service</div>
                                    <div class="detail-value" *ngIf="selectedTab != 4 && selectedTab != 6">{{ratingDetails[0].RESERVATION?.SERVICE_ID.NAME}} - {{ratingDetails[0].RESERVATION?.CATEGORY_ID?.NAME}} - {{ratingDetails[0].RESERVATION?.CATEGORY_TYPE_ID?.NAME}}</div> <!-- ngIf="selectedTab != 5 && selectedTab != 7" -->
                                    <div class="detail-value" *ngIf="selectedTab == 4 || selectedTab == 6">{{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.SERVICE_ID.NAME}}</div> <!-- ngIf="selectedTab == 5 || selectedTab == 7" -->
                                </div>
                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="5%">
                                    <div class="detail-head">Given To</div>
                                    <div class="detail-value" *ngIf="selectedTab != 4 && selectedTab != 6">{{ratingDetails[0].RESERVATION?.ASIGNEE?.FIRST_NAME}} {{ratingDetails[0].RESERVATION?.ASIGNEE?.LAST_NAME}}</div> <!-- ngIf="selectedTab != 5 && selectedTab != 7" -->
                                    <div class="detail-value" *ngIf="selectedTab == 4 || selectedTab == 6">{{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.ASIGNEE?.FIRST_NAME}} {{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.ASIGNEE?.LAST_NAME}}</div> <!-- ngIf="selectedTab == 5 || selectedTab == 7" -->
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="5%">
                                    <div class="detail-head">Booking ID</div>
                                    <div class="detail-value" *ngIf="selectedTab != 0 && selectedTab != 4 && selectedTab != 6">{{ratingDetails[0].RESERVATION?.BOOKING_ID.RESERVATION}}</div> <!-- *ngIf="selectedTab != 0 && selectedTab != 1" -->
                                    <div class="detail-value" *ngIf="selectedTab == 4 || selectedTab == 6">{{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.BOOKING_ID.RESERVATION}}</div> <!-- ngIf="selectedTab == 5 || selectedTab == 7" -->
                                    <div class="detail-value" *ngIf="selectedTab == 0">{{ratingDetails[0].USER?.BOOKING_ID.RESERVATION}}</div> <!-- *ngIf="selectedTab == 0 || selectedTab == 1" -->
                                </div>
                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="5%" *ngIf="selectedTab != 0"> <!-- *ngIf="selectedTab != 0 && selectedTab != 1" -->
                                    <div class="detail-head">Date</div>
                                    <div class="detail-value" *ngIf="selectedTab != 4 && selectedTab != 6">{{ratingDetails[0].RESERVATION?.DATE}}</div>
                                    <div class="detail-value" *ngIf="selectedTab == 4 || selectedTab == 6">{{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.DATE}}</div>
                                </div>
                            </div>
                            <div fxLayout="column" fxLayoutGap="3%" *ngIf="selectedTab != 0"> <!-- *ngIf="selectedTab != 0 && selectedTab != 1" -->
                                <div class="detail-head">Room Number</div>
                                <div class="detail-value" *ngIf="selectedTab != 4 && selectedTab != 6">{{ratingDetails[0].RESERVATION?.ROOM_ID?.NAME}}</div>
                                <div class="detail-value" *ngIf="selectedTab == 4 || selectedTab == 6">{{ratingDetails[0].ITEMS[0]?.RESERVATION_ID?.ROOM_ID?.NAME}}</div>
                            </div>
                            <div fxLayout="column" fxLayoutGap="3%" *ngIf="selectedTab == 0"> <!-- *ngIf="selectedTab == 0 || selectedTab == 1" -->
                                <div class="detail-head">Review</div>
                                <div class="detail-value" style="line-height: 24px;" *ngIf="!!ratingDetails[0].COMMENT">
                                    {{ratingDetails[0].COMMENT}}
                                </div>
                                <div class="detail-value" style="line-height: 24px;" *ngIf="!ratingDetails[0].COMMENT">
                                    No comments added
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div *ngIf="!isRatingMngmt">
            <div fxLayout="column" fxLayoutGap="25px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
                        <div class="back-arrow" (click)="backToRatingDetails()">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="review-num" *ngIf="ratingQuestions.length != 0">
                            List
                        </div>
                    </div>
                    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5%">
                        <div fxLayout="row" *ngIf="ratingQuestions.length != 0">
                            <input matInput [formControl]="ratingSearch" class="search-box1" placeholder="Search">
                            <div class="search-icon-div">
                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                            </div>
                        </div>
                        <button mat-stroked-button class="add-rate-btn" (click)="addRatingQuestion()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutGap="20px" class="questions-row" *ngIf="ratingQuestions.length != 0">
                    <div *ngFor="let que of activeQuestions; let i=index;">
                        <mat-card class="ques-card" fxLayout="column" fxLayoutAlign="space-between">
                            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                <div class="que-head">Rating Question</div>
                                <div class="que-value">{{que.SERVICE_INFO}}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <button mat-stroked-button class="disable-btn" (click)="enableRatingQuestion(que)" [disabled]="accessType == 2">
                                    Disable
                                </button>
                                <button mat-stroked-button class="edit-btn" (click)="editRatingQuestion(que.ID)" [disabled]="accessType == 2">
                                    Edit
                                </button>
                                <button mat-icon-button (click)="deleteRatingQuestion(que.ID)" [disabled]="accessType == 2">
                                    <span class="material-icons-outlined" style="color: rgb(255, 0, 0);">delete_outline</span>
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div fxFlex="25%" class="review-num" style="margin-top: 30px;" *ngIf="ratingQuestions.length != 0">
                    Inactive</div>
                <div fxLayout="row wrap" fxLayoutGap="20px" class="questions-row" *ngIf="ratingQuestions.length != 0">
                    <div *ngFor="let que of inactiveQuestions; let i=index;">
                        <mat-card class="ques-card" fxLayout="column" fxLayoutAlign="space-between"
                            *ngIf="que.ENABLED == false">
                            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                <div class="que-head">Rating Question</div>
                                <div class="que-value">{{que.SERVICE_INFO}}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <button mat-stroked-button class="disable-btn" (click)="editRatingQuestion(que.ID)" [disabled]="accessType == 2">
                                    Edit
                                </button>
                                <button mat-stroked-button class="edit-btn" (click)="enableRatingQuestion(que)" [disabled]="accessType == 2">
                                    Enable
                                </button>
                                <button mat-icon-button (click)="deleteRatingQuestion(que.ID)" [disabled]="accessType == 2">
                                    <span class="material-icons-outlined" style="color: rgb(255, 0, 0);">delete_outline</span>
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div fxLayout="column" class="rating-ques" *ngIf="ratingQuestions.length == 0">
                    <img src="../../../../assets/images/no-data.png" class="nodata-img">
                    <div class="no-data">No Records Found</div>
                </div>
            </div>
        </div>
    </div>
</div>