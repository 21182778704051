import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { FormControl } from '@angular/forms';
import { CheckInCardComponent } from '../check-in/check-in-card/check-in-card.component';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-arrivals',
  templateUrl: './arrivals.component.html',
  styleUrls: ['./arrivals.component.scss']
})
export class ArrivalsComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  currencyInfo: any;
  currency: any;
  searchVal = new FormControl('');
  limit = 10;
  pageNumber = 0;
  arrivalsList: any = [];
  zeroReservations: boolean = false;
  reservationDetails = [];
  arrivalData: number;
  currentActive = 0;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getArrivalList(this.pageNumber);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getArrivalList(pageNo) {
    this.loading = true;
    this.reservationDetails = [];
    this.hotelService.getReservationList('1', pageNo, this.limit).subscribe({
      next: (data) => {
        this.loading = false;
        this.arrivalsList = data.object;
        if (data.object.length == 0) {
          this.zeroReservations = true;
        }
        else {
          for (let i = 0; i < this.arrivalsList.length; i++) {
            let securityDeposit: number = 0;
            if (!!this.arrivalsList[i].ROOMRESERVATION) {
              this.arrivalsList[i].ROOMRESERVATION.forEach(room => {
                securityDeposit += room.RATE_PLAN_ID?.CHECKIN_DEPOSIT_AMOUNT;
              })
            }
            this.arrivalsList[i].SECURITY_DEPOSITE = securityDeposit;
          }
          this.currentActive = 0;
          this.reservationDetails.push(this.arrivalsList[0]);
        }
        this.arrivalData = data.object.length;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  onScrollDown() {
    this.zeroReservations = false;
    if (this.arrivalData == 10) {
      this.pageNumber++;
      this.hotelService.getReservationList('1', this.pageNumber, this.limit).subscribe({
        next: (data) => {
          this.arrivalData = data.object.length;
          this.arrivalsList = this.arrivalsList.concat(data.object);
          for (let i = 0; i < this.arrivalsList.length; i++) {
            let securityDeposit: number = 0;
            if (!!this.arrivalsList[i].ROOMRESERVATION) {
              this.arrivalsList[i].ROOMRESERVATION.forEach(room => {
                securityDeposit += room.RATE_PLAN_ID?.CHECKIN_DEPOSIT_AMOUNT;
              })
            }
            this.arrivalsList[i].SECURITY_DEPOSITE = securityDeposit;
          }
          this.cd.detectChanges();
        },
        error: (e) => {
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  getReservationDetails(index) {
    this.currentActive = index;
    this.reservationDetails = [];
    this.reservationDetails.push(this.arrivalsList[index]);
  }

  editReservation(bookingId) {
    this.hotelService.setId(bookingId);
    this.router.navigateByUrl('edit-reservation');
  }

  updateReservation(id) {
    this.confirmationMsg.title = 'Are you sure you want to check-in ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        let obj = {
          "ID": id,
          "RESERVATION_STATUS": 2
        }
        this.hotelService.updateReservationStatus(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status] : "success"),
              data.status
            )
            this.openCheckIn(id);
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  openCheckIn(id) {
    const dialogRef = this.dialog.open(CheckInCardComponent, {
      width: '700px',
      height: '600px',
      data: { bookingId: id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pageNumber = 0;
        this.getArrivalList(this.pageNumber);
      }
    });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}