<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div>
    <img src="../../../../assets/images/warning.png">
  </div>
  <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
    <p>Dear</p>
    <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
  </span>
  <div class="dasboard-subheading">
    <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
      functionalities of the system</p>
  </div>
  <div>
    <button class="back-button" (click)="logout()">Go Back</button>
  </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
  <div class="right-panel">
    <div class="overlay" *ngIf="loading">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 1% 0;">
      <div fxFlex="25%" class="offers">
        Offers
      </div>
      <div fxFlex="20%" fxLayout="row" fxLayoutAlign="space-around center">
        <div>
          <button mat-stroked-button class="header-btn" [matMenuTriggerFor]="menu">
            <mat-icon fontSet="material-icons-outlined">filter_alt</mat-icon>
            Filter
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="$event.stopPropagation()">
              <input matInput class="menu-search" placeholder="Category by">
              <mat-icon matSuffix class="search_icon">search</mat-icon>
            </button>
            <mat-checkbox fxLayout="column" style="padding-left:2%" #menuItems (click)="$event.stopPropagation()"
              *ngFor="let category of permittedServices; let i = index;"
              (change)="selectCategory(menuItems.checked, category.ID)">{{ category.NAME }}</mat-checkbox>
          </mat-menu>
        </div>
        <div>
          <button mat-stroked-button class="add-btn" (click)="add()" [disabled]="accessType == 2">
            <mat-icon>add</mat-icon>
            Add
          </button>
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="sr.no">
        <th mat-header-cell *matHeaderCellDef style="width:8%" class="table-header"> S No. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i
          + 1) }} </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef class="table-header"> Category </th>
        <td mat-cell *matCellDef="let element">
          {{element.SERVICE.NAME}}
        </td>
      </ng-container>
      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef class="table-header"> Discount% </th>
        <td mat-cell *matCellDef="let element">
          {{element.DISCOUNT}}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef style="width:35%" class="table-header"> Applicable </th>
        <td mat-cell *matCellDef="let element">
          {{element.START_DATE}} - {{element.END_DATE}}
        </td>
      </ng-container>
      <ng-container matColumnDef="special-deal">
        <th mat-header-cell *matHeaderCellDef style="width:25%" class="table-header"> Special Deal </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.NAME}}"> {{element.NAME}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef style="width:40%" class="table-header"> Description </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.DESCRIPTION}}"> {{element.DESCRIPTION}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="center">
            <button mat-icon-button (click)="editOffer(row.ID)" [disabled]="accessType == 2">
              <span class="material-icons-outlined">edit</span>
            </button>
            <button mat-icon-button (click)="deleteOffer(row.ID)" [disabled]="accessType == 2">
              <span class="material-icons-outlined" style="color: rgb(255, 0, 0);"> delete_outline </span>
            </button>
            <label class="switch">
              <input #checkbox type="checkbox" [checked]="row.ENABLED" (change)="enable(checkbox.checked, row)"
                [disabled]="accessType == 2">
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <span *ngIf="dataSource.data.length == 0" class="display-msg">No data</span>
    <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" #paginatorRef [hidePageSize]="true"
      (page)="changePage($event)"></mat-paginator>
  </div>
</div>