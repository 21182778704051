<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="30px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="room-head">
                    Departures
                </div>
            </div>
            <mat-card class="reserve-card" *ngIf="!loading && zeroReservations">
                <div class="reserve-detail" style="text-align: center;">No Records Found</div>
            </mat-card>
            <div fxLayout="row" fxLayoutGap="25px" *ngIf="!loading && !zeroReservations">
                <div fxFlex="55%" class="content" fxLayout="column" fxLayoutGap="15px" ngxInfiniteScroller strategy="scrollingToBottom"
                    (onScrollDown)="onScrollDown()">
                    <mat-card class="reserve-card" fxLayout="row" fxLayoutAlign="space-between center"
                        [ngStyle]="{'border': currentActive === i ? '1px solid #BDBDBD' : none }"
                        *ngFor="let checkin of checkedInList; let i = index" (click)="getReservationDetails(i, checkin.BOOKING.ID, checkin.BOOKING.RESERVATION)">
                        <div fxFlex="75%" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">
                            <div class="reserve-detail">Reservation ID:<span>{{checkin.BOOKING.RESERVATION}}</span></div>
                            <ng-container *ngFor="let book of checkin.USER">
                                <div class="reserve-detail" *ngIf="book.USER_TYPE.ID == 1">Guest Name:<span>{{book.FIRST_NAME}} {{book.LAST_NAME}}</span></div>
                            </ng-container>
                            <div class="reserve-detail">Room Type:<span>{{checkin.ROOMRESERVATION[0]?.ROOM_CATEGORY_TYPE_ID?.NAME}}</span></div>
                            <div class="reserve-detail">Date:<span>{{checkin.BOOKING.FROM_DATE}} - {{checkin.BOOKING.TO_DATE}}</span></div>
                            <div class="reserve-detail">Room Number:
                                <span *ngFor="let room of checkin.ROOMRESERVATION; let isLast=last">{{room.ROOM_ID?.NAME}}{{!isLast? ',' : ' '}}</span>
                            </div>
                        </div>
                        <div>
                            <button mat-button class="save-btn" (click)="viewPaymentDetails(checkin.BOOKING.ID, checkin.BOOKING.RESERVATION)">Check-Out</button>
                        </div>
                    </mat-card>
                </div>
                <div fxFlex="45%">
                    <mat-card class="info-card" fxLayout="column">
                        <div class="info-header" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="reserve-head">Order Details</div>
                        </div>
                        <div class="info-body" fxLayout="column" fxLayoutGap="10px" *ngIf="dataSource.data.length != 0">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="14%"> Date </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="15%">{{row.DATE}}</td>
                                </ng-container>
                                <ng-container matColumnDef="service">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="23%"> Service</th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="25%">
                                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                            {{row.SERVICE_ID.NAME}} - {{row.CATEGORY_ID.NAME}} - {{row.CATEGORY_TYPE_ID.NAME}}
                                        </ng-container>
                                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                            <ng-container *ngFor="let dsVal of row.ITEMS">
                                                <li>{{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_ID?.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}</li>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="row.SERVICE_ID.ID === 2 && row.SERVICE_ID.ID !== 6">
                                            <li *ngFor="let dsVal of row.ITEMS">
                                                {{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}
                                            </li>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="quantity">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="11%"> Quantity </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="8%">
                                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                            {{row.QUANTITY}}
                                        </ng-container>
                                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                            <li *ngFor="let dsVal of row.ITEMS">
                                                {{dsVal.QUANTITY}}
                                            </li>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="13%"> Amount </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="13%">
                                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                            {{currency}} {{row.CATEGORY_TYPE_ID?.PRICE}}
                                        </ng-container>
                                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                            <li *ngFor="let dsVal of row.ITEMS">
                                                {{currency}} {{dsVal.QUANTITY * dsVal.CATEGORY_TYPE_ID?.PRICE}}
                                            </li>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="discount">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="13%"> Discount </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="13%">
                                        <ng-container *ngIf="row.SERVICE_ID.ID !== 2">
                                            {{currency}} {{row.OFFER_PRICE || 0}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tax">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="13%"> Tax </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="13%">
                                        <ng-container *ngIf="row.SERVICE_ID.ID !== 2">
                                            <ng-container *ngIf="row.CATEGORY_TYPE_ID?.SERVICETAX.length != 0">
                                                {{currency}} {{row.TAXAMOUNT || 0}}
                                            </ng-container>
                                            <ng-container *ngIf="row.CATEGORY_TYPE_ID?.SERVICETAX.length == 0">
                                                {{currency}} 0
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef class="price-head" fxFlex="13%"> Total </th>
                                    <td mat-cell *matCellDef="let row" class="price-detail" fxFlex="13%">{{currency}} {{row.TOTAL_AMOUNT + (row.TAXAMOUNT || 0)}}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayoutAlign="end center">
                                <div fxFlex="60%" fxLayout="column" fxLayoutGap="10px">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="price-category">Total</div>
                                        <div class="price-amount">-</div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="price-category">Amount</div>
                                        <div class="price-amount">-</div>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="price-category">Total</div>
                                        <div class="price-amount">-</div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="price-category">Paid</div>
                                        <div class="price-amount">-</div>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="price-category">Balance</div>
                                        <div class="price-amount">-</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="price-detail" style="text-align: center;" *ngIf="dataSource.data.length == 0">
                            <span>
                                <br>No orders...<br>
                            </span>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>