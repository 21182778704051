import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-add-edit-tax',
  templateUrl: './add-edit-tax.component.html',
  styleUrls: ['./add-edit-tax.component.scss']
})
export class AddEditTaxComponent {

  loading: boolean = false;
  userInfo: any;
  services = [];
  hotelServices: any = [];
  permittedServices: any = [];
  percentageValues = [];
  addTaxForm: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditTaxComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addTaxForm = this.fb.group({
      name: ['', Validators.required],
      percentage: ['', Validators.required],
      service: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      if (this.userInfo.roleId == 2) {
        for (let service of this.services) {
          if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
      else if (this.userInfo.roleId != 2) {
        for (let service of this.services) {
          if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
    })
    this.percentageValues = Array(99).fill(0).map((x, i) => i + 1);
    if (this.data) {
      this.loading = true;
      this.cs.getTaxById(this.data.id).subscribe({
        next: (res) => {
          this.loading = false;
          this.addTaxForm.get("name").setValue(res.object.NAME);
          this.addTaxForm.get("percentage").setValue(res.object.PERCENTAGE);
          this.addTaxForm.get("service").setValue(res.object.SERVICE_ID.ID);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addRoom() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.addTaxForm.value.name,
        "PERCENTAGE": this.addTaxForm.value.percentage,
        "SERVICE_ID": this.addTaxForm.value.service
      }
      this.cs.updateTax(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.addTaxForm.value.name,
        "PERCENTAGE": this.addTaxForm.value.percentage,
        "SERVICE_ID": this.addTaxForm.value.service
      }
      this.cs.addTax(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}