import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelServicesService {

  public resourceUrl = `${environment.API_BASE_URL}`;
  url: string;

  constructor(private http: HttpClient) { }

  private roomTypeAndRate = new BehaviorSubject<string>('');
  actionType = this.roomTypeAndRate.asObservable();
  changeAction(action) {
    this.roomTypeAndRate.next(action);
  }

  private selectedId = new BehaviorSubject<string>('');
  setId(value) {
    this.selectedId.next(value);
  }
  getId() {
    return this.selectedId.asObservable();
  }

  private bookingId = new BehaviorSubject<string>('');
  setBookingId(value) {
    this.bookingId.next(value);
  }
  getBookingId() {
    return this.bookingId.asObservable();
  }

  getSpaInventory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/inventory`);
  }

  saveSpaInventory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/inventory/update`, obj);
  }

  getSpaBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/banners`);
  }

  getSpaBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/banners?ID=${id}`);
  }

  addSpaBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/spa/banners/create`, obj);
  }

  updateSpaBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/banners/update`, obj);
  }

  getSpaCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/category`);
  }

  getSpaCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/category?ID=${id}`);
  }

  addSpaCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/spa/category/create`, obj);
  }

  updateSpaCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/category/update`, obj);
  }

  getSpaType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/type?CATEGORY_ID=${category_id}`);
  }

  getSpaTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/type?ID=${id}`);
  }

  addSpaType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/spa/type/create`, obj);
  }

  updateSpaType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/type/update`, obj);
  }

  getDiningBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/banners`);
  }

  getDiningBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/banners?ID=${id}`);
  }

  addDiningBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/dining/banners/create`, obj);
  }

  updateDiningBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/dining/banners/update`, obj);
  }

  getDiningCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/category`);
  }

  getDiningCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/category?ID=${id}`);
  }

  addDiningCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/dining/category/create`, obj);
  }

  updateDiningCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/dining/category/update`, obj);
  }

  getDiningType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/type?CATEGORY_ID=${category_id}`);
  }

  getDiningTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/dining/type?ID=${id}`);
  }

  addDiningType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/dining/type/create`, obj);
  }

  updateDiningType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/dining/type/update`, obj);
  }

  getTransportBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/banners`);
  }

  getTransportBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/banners?ID=${id}`);
  }

  addTransportBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/transport/banners/create`, obj);
  }

  updateTransportBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/transport/banners/update`, obj);
  }

  getTransportCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/category`);
  }

  getTransportCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/category?ID=${id}`);
  }

  addTransportCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/transport/category/create`, obj);
  }

  updateTransportCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/transport/category/update`, obj);
  }

  getTransportType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/type?CATEGORY_ID=${category_id}`);
  }

  getTransportTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/type?ID=${id}`);
  }

  addTransportType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/transport/type/create`, obj);
  }

  updateTransportType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/transport/type/update`, obj);
  }

  getHousekeepingBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/housekeeping/banners`);
  }

  getHousekeepingBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/housekeeping/banners?ID=${id}`);
  }

  addHousekeepingBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/housekeeping/banners/create`, obj);
  }

  updateHousekeepingBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/housekeeping/banners/update`, obj);
  }

  getHousekeepingServices(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/housekeeping/service`);
  }

  getHousekeepingServiceById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/housekeeping/service?ID=${id}`);
  }

  addHousekeepingService(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/housekeeping/service/create`, obj);
  }

  updateHousekeepingService(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/housekeeping/service/update`, obj);
  }

  getConferenceBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/banners`);
  }

  getConferenceBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/banners?ID=${id}`);
  }

  addConferenceBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/conference/banners/create`, obj);
  }

  updateConferenceBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/conference/banners/update`, obj);
  }

  getConferenceCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/category`);
  }

  getConferenceCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/category?ID=${id}`);
  }

  addConferenceCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/conference/category/create`, obj);
  }

  updateConferenceCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/conference/category/update`, obj);
  }

  getConferenceType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/type?CATEGORY_ID=${category_id}`);
  }

  getConferenceTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/type?ID=${id}`);
  }

  addConferenceType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/conference/type/create`, obj);
  }

  updateConferenceType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/conference/type/update`, obj);
  }

  getPackageBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/banners`);
  }

  getPackageBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/banners?ID=${id}`);
  }

  addPackageBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/package/banners/create`, obj);
  }

  updatePackageBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/package/banners/update`, obj);
  }

  getPackageCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/category`);
  }

  getPackageCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/category?ID=${id}`);
  }

  addPackageCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/package/category/create`, obj);
  }

  updatePackageCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/package/category/update`, obj);
  }

  getPackageType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/type?CATEGORY_ID=${category_id}`);
  }

  getPackageTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/type?ID=${id}`);
  }

  addPackageType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/package/type/create`, obj);
  }

  updatePackageType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/package/type/update`, obj);
  }

  getRoomBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/banners`);
  }

  getRoomBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/banners?ID=${id}`);
  }

  addRoomBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/room/banners/create`, obj);
  }

  updateRoomBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/banners/update`, obj);
  }

  getRoomCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/category`);
  }

  getRoomCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/category?ID=${id}`);
  }

  addRoomCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/room/category/create`, obj);
  }

  updateRoomCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/category/update`, obj);
  }

  getRoomType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/type?CATEGORY_ID=${category_id}`);
  }

  getRoomTypeList(category_id): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/type?CATEGORY_ID=${category_id}&TRIM=true`));
  }

  getRoomTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/type?ID=${id}`);
  }

  addRoomType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/room/type/create`, obj);
  }

  updateRoomType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/type/update`, obj);
  }

  getRatePlanByTypeId(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/rate?ROOM_TYPE_ID=${id}`);
  }

  getRatePlanById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/rate?ID=${id}`);
  }

  addRatePlan(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/room/rate/create`, obj);
  }

  updateRatePlan(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/rate/update`, obj);
  }

  bulkUpdateRoomType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/type/update/bulk`, obj);
  }

  bulkUpdateRatePlan(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/rate/update/bulk`, obj);
  }

  getAboutBanner(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/banners`);
  }

  getAboutBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/banners?ID=${id}`);
  }

  addAboutBanner(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/about/banners/create`, obj);
  }

  updateAboutBanner(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/about/banners/update`, obj);
  }

  getAboutCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/category`);
  }

  getAboutCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/category?ID=${id}`);
  }

  addAboutCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/about/category/create`, obj);
  }

  updateAboutCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/about/category/update`, obj);
  }

  getAboutType(category_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/type?CATEGORY_ID=${category_id}`);
  }

  getAboutTypeById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/about/type?ID=${id}`);
  }

  addAboutType(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/about/type/create`, obj);
  }

  updateAboutType(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/about/type/update`, obj);
  }

  getGuestEscalatedBookings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=1,2,3,4,7&ESCALATED=true`);
  }

  getGuestEscalatedBookingsByKey(key, fromDate, toDate, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=1,2,3,4,7&SEARCH_KEY=${key}&FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}&ESCALATED=true`);
  }

  getGuestLiveBookings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=1,2,3,4,7`);
  }

  getGuestLiveBookingsByKey(key, fromDate, toDate, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=1,2,3,4,7&SEARCH_KEY=${key}&FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}`);
  }

  getGuestCompletedBookings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=5,6`);
  }

  getGuestCompletedBookingsByKey(key, fromDate, toDate, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/booking?STATUS=5,6&SEARCH_KEY=${key}&FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}`);
  }

  updateGuestBooking(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/services/booking/update`, obj);
  }

  getSpaSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  getTransportSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  getConferenceRoomSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  getPackageSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  checkBookedSlots(serviceId, date, categoryId, typeId, bookingId): Observable<any> {
    if (serviceId == 1) {
      this.url = this.resourceUrl + `/web/gofryday/spa/type/slot/check?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}&BOOKING_ID=${bookingId}`;
    } else if (serviceId == 3) {
      this.url = this.resourceUrl + `/web/gofryday/transport/type/slot/check?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}&BOOKING_ID=${bookingId}`;
    } else if (serviceId == 4) {
      this.url = this.resourceUrl + `/web/gofryday/conference/type/slot/check?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}&BOOKING_ID=${bookingId}`;
    } else if (serviceId == 5) {
      this.url = this.resourceUrl + `/web/gofryday/package/type/slot/check?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}&BOOKING_ID=${bookingId}`;
    }
    return this.http.get<any>(this.url);
  }

  bookService(serviceId, obj): Observable<any> {
    if (serviceId == 1) {
      this.url = this.resourceUrl + '/web/gofryday/spa/type/slot/book';
    } else if (serviceId == 3) {
      this.url = this.resourceUrl + '/web/gofryday/transport/type/slot/book';
    } else if (serviceId == 4) {
      this.url = this.resourceUrl + '/web/gofryday/conference/type/slot/book';
    } else if (serviceId == 5) {
      this.url = this.resourceUrl + '/web/gofryday/package/type/slot/book';
    }
    return this.http.post<any>(this.url, obj);
  }

  bookDiningService(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/dining/type/slot/book`, obj);
  }

  bookHouseKeepingService(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/housekeeping/service/book`, obj);
  }

  getBookingIdList(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/user/reservation?TRIM=true`);
  }

  getRoomWithBookingId(bookingId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/user/room?BOOKING_ID=${bookingId}`);
  }

  getOffersByServiceId(serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/offers?SERVICE_ID=${serviceId}`);
  }

  getInternalLiveBookings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/internal/request?STATUS=1,2,3,4,7`);
  }

  getInternalLiveBookingsByKey(key, fromDate, toDate, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/internal/request?STATUS=1,2,3,4,7&SEARCH_KEY=${key}&FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}`);
  }

  getInternalCompletedBookings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/internal/request?STATUS=5,6`);
  }

  getInternalCompletedBookingsByKey(key, fromDate, toDate, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/internal/request?STATUS=5,6&SEARCH_KEY=${key}&FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}`);
  }

  createInternalBooking(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/internal/request/create`, obj);
  }

  updateInternalBooking(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/internal/request/update`, obj);
  }

  getSLA(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla`);
  }

  getSLAById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla?ID=${id}`);
  }

  getSLAByServiceId(serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla?SERVICE_ID=${serviceId}`);
  }

  createSLA(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/sla/create`, obj);
  }

  updateSLA(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/sla/update`, obj);
  }

  getDashboardSLAData(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla/dashboard/guest/stats/total?`);
  }

  getSLAGraphData(monthly): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla/dashboard/guest/stats/calendar-wise?TYPE=${monthly}`);
  }

  getSLAData(serviceId, fromDate, toDate): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/sla/dashboard/guest/stats/total?FROM_DATE=${fromDate}&TO_DATE=${toDate}&SERVICE_ID=${serviceId}`);
  }

  getSearchedRoomTypes(checkInDate, checkOutDate, noOfRooms, adults, child, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/reservation/type?CHECK_IN_DATE=${checkInDate}&CHECK_OUT_DATE=${checkOutDate}&NO_OF_ROOMS=${noOfRooms}&ADULT_OCCUPANT=${adults}&CHILD_OCCUPANT=${child}&CATEGORY_ID=${categoryId}`);
  }

  createReservation(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/reservation/create`, obj);
  }

  getReservationList(status, pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/reservation/room?RESERVATION_STATUS=${status}&PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getReservationById(bookingId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/reservation/room?ID=${bookingId}`);
  }

  getReservationPaymentInfo(id, bookingId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/reservation/room/billing?ID=${id}&BOOKING_ID=${bookingId}`);
  }

  updateReservationStatus(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/reservation/room/update/status`, obj);
  }

  updateReservation(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/reservation/update`, obj);
  }

  getReservationData(checkInDate, checkOutDate): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/reservation/room?CHECK_IN_DATE=${checkInDate}&CHECK_OUT_DATE=${checkOutDate}`);
  }

  getRoomCategoryValue(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/category/type`);
  }

  // Fetch room types by category ID
  getRoomCategoryTypeValue(categoryId: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/category/type?CATEGORY_ID=${categoryId}`);
  }

}