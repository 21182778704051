import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conference-room-types',
  templateUrl: './conference-room-types.component.html',
  styleUrls: ['./conference-room-types.component.scss']
})
export class ConferenceRoomTypesComponent {

  conferenceDetailForm: FormGroup;
  imageName: any
  savedTypeFiles = {};
  source_url;
  typeById: any = [];
  loading: boolean = false;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  notCreate: boolean = false;
  currencyInfo: any;
  currency: any;
  conferenceTaxes: any = [];
  taxIds = [];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<ConferenceRoomTypesComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService,
    public hotelService: HotelServicesService,
    private cs: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.conferenceDetailForm = this.fb.group({
      image: [''],
      conferenceName: ['', Validators.required],
      tag: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      privacyPolicy: ['', Validators.required],
      inventory: ['', Validators.required],
      tax: [[]]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getTaxes();
    if (this.data.name == "edit_type") {
      this.notCreate = true;
      this.loading = true;
      this.hotelService.getConferenceTypeById(this.data.typeID).subscribe({
        next: (res) => {
          this.loading = false;
          this.typeById = res.object;
          let image = res.object.ATTACHMENT_SOURCE;
          let selectedImage = image.split("/");
          this.imageName = selectedImage[selectedImage.length - 1];
          this.source_url = res.object.ATTACHMENT_SOURCE;
          this.conferenceDetailForm.get("conferenceName").setValue(res.object.NAME);
          this.conferenceDetailForm.get("tag").setValue(res.object.TAG);
          this.conferenceDetailForm.get("price").setValue(res.object.PRICE);
          this.conferenceDetailForm.get("description").setValue(res.object.DESCRIPTION);
          this.conferenceDetailForm.get("privacyPolicy").setValue(res.object.TERMS_CONDITIONS);
          this.conferenceDetailForm.get("inventory").setValue(res.object.INVENTORY);
          for (let id of res.object.SERVICETAX) {
            this.taxIds.push(id.TAX_ID?.ID);
          }
          this.conferenceDetailForm.get("tax").setValue(this.taxIds);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getTaxes() {
    this.loading = true;
    this.cs.getTaxBySearchKey('', 4).subscribe({
      next: (res) => {
        this.conferenceTaxes = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: "4",
      SECTION: 'type',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
      }
      let tempObjForUpload = {
        url: res['SIGNED_URL'],
        file: selectedFile
      }
      await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
        if (data) {
          this.loading = false;
          this.notify.showNotification(
            'File uploaded successfully',
            "top",
            (!!colorObj[200] ? colorObj[200] : "success"),
            200
          );
          this.imageName = files[0].name;
        }
      }).catch((err) => {
        this.handleError(err);
      });
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  saveTypes() {
    let name = this.conferenceDetailForm.value.conferenceName.trim();
    if (name.length == 0) {
      this.notify.showMessage(
        "Name should not be empty",
        "top",
        "warning"
      )
      this.conferenceDetailForm.get("conferenceName").setValue('');
    }
    else {
      this.loading = true;
      if (this.data.name == "add_type") {
        this.savedTypeFiles = {
          "NAME": this.conferenceDetailForm.value.conferenceName.trim(),
          "TAG": this.conferenceDetailForm.value.tag,
          "PRICE": this.conferenceDetailForm.value.price,
          "DESCRIPTION": this.conferenceDetailForm.value.description,
          "TERMS_CONDITIONS": this.conferenceDetailForm.value.privacyPolicy,
          "INVENTORY": this.conferenceDetailForm.value.inventory,
          "TAX_ID": this.conferenceDetailForm.value.tax,
          "CATEGORY_ID": this.data.categoryID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111 //ToDo: temp value
        }
        this.hotelService.addConferenceType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
      else if (this.data.name == "edit_type") {
        this.savedTypeFiles = {
          "ID": this.typeById.ID,
          "NAME": this.conferenceDetailForm.value.conferenceName.trim(),
          "TAG": this.conferenceDetailForm.value.tag,
          "PRICE": this.conferenceDetailForm.value.price,
          "DESCRIPTION": this.conferenceDetailForm.value.description,
          "TERMS_CONDITIONS": this.conferenceDetailForm.value.privacyPolicy,
          "INVENTORY": this.conferenceDetailForm.value.inventory,
          "TAX_ID": this.conferenceDetailForm.value.tax,
          "CATEGORY_ID": this.typeById.CATEGORY.ID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111, //ToDo: this.typeById.POSITION
        }
        this.hotelService.updateConferenceType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
    }
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

}