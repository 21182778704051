<mat-card class="matCard">
  <div class="selector">
    <button mat-raised-button class="custom-button">
      <mat-select placeholder="Monthly">
        <mat-option value="Monthly">Monthly</mat-option>
      </mat-select>
    </button>
  </div>
  <mat-card-actions>
    <div *ngIf="loading" class="spinner-container">
      <mat-progress-spinner diameter="70" mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
    <div *ngIf="!loading" class="spinner-container">
      <div class="error-message">{{ errorMessage }}</div>
    </div>
    <div #chart></div>
  </mat-card-actions>
</mat-card>
