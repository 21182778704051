import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { NotificationService } from 'src/app/services/notification.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';
import { colorObj } from 'src/app/shared/color-object';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-reservation',
  templateUrl: './edit-reservation.component.html',
  styleUrls: ['./edit-reservation.component.scss']
})
export class EditReservationComponent {

  loading: boolean = true;
  userInfo: any;
  userName: any;
  accessType: any;
  reserveForm: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  timings = [];
  marketSegments = [];
  percentages: number[] = Array(100).fill(0).map((x, i) => i + 1);
  bookingId;
  reservationDetails: any = {};
  guestsInfo: any = [];
  roomsInfo: any = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.reserveForm = this.fb.group({
      hostId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      guestsArray: fb.array([]),
      bookingId: ['', Validators.required],
      checkInDate: ['', Validators.required],
      checkOutDate: ['', Validators.required],
      arrivalTime: ['', Validators.required],
      thermalAnalysis: ['', Validators.required],
      noOfRooms: ['', Validators.required],
      roomArray: fb.array([]),
      price: ['', Validators.required],
      comments: [''],
      sourceId: ['', Validators.required],
      marketSegment: ['', Validators.required],
      sourceName: ['', Validators.required],
      commission: ['', Validators.required],
      bookerName: ['', Validators.required],
      companyName: ['', Validators.required],
      travelAgent: ['', Validators.required],
      paymentMode: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    // this.masterData$.subscribe(res => {
    //   if (Object.keys(res).length != 0) {
    //     this.timings = res['SLOTS'];
    //     this.marketSegments = res['MARKET_SEGMENT'];
    //   }
    // })
    this.hotelService.getId().subscribe((val) => {
      this.bookingId = val;
    });
    this.getReservationDetails();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getReservationDetails() {
    this.hotelService.getReservationById(this.bookingId).subscribe({
      next: (data) => {
        this.loading = false;
        this.reservationDetails = data.object;
        for (let user of data.object.USER) {
          if (user.USER_TYPE.ID == 1) {
            this.reserveForm.get("hostId").setValue(user.ID);
            this.reserveForm.get("firstName").setValue(user.FIRST_NAME);
            this.reserveForm.get("lastName").setValue(user.LAST_NAME);
            this.reserveForm.get("emailId").setValue(user.EMAIL_ID);
            this.reserveForm.get("phoneNumber").setValue(user.MOBILE);
          }
          else if (user.USER_TYPE.ID == 2) {
            this.guestsInfo.push(user);
          }
        }
        if (Array.isArray(this.guestsInfo) && this.guestsInfo.length > 0) {
          for (let i = 0; i < this.guestsInfo.length; i++) {
            this.addGuests();
            let guestArray = this.reserveForm.get('guestsArray') as FormArray;
            let guestArrayObj = guestArray.controls[i] as FormGroup;
            guestArrayObj.get('guestId').patchValue(this.guestsInfo[i]['ID']);
            guestArrayObj.get('guestFirstName').patchValue(this.guestsInfo[i]['FIRST_NAME']);
            guestArrayObj.get('guestLastName').patchValue(this.guestsInfo[i]['LAST_NAME']);
            guestArrayObj.get('guestEmail').patchValue(this.guestsInfo[i]['EMAIL_ID']);
            guestArrayObj.get('guestMobileNumber').patchValue(this.guestsInfo[i]['MOBILE']);
          };
        }
        this.reserveForm.get("bookingId").setValue(data.object.BOOKING.RESERVATION);
        const startDate = moment(data.object.BOOKING.FROM_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        const endDate = moment(data.object.BOOKING.TO_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        this.reserveForm.get("checkInDate").setValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
        this.reserveForm.get("checkOutDate").setValue(this.datePipe.transform(endDate, 'yyyy-MM-dd'));
        this.reserveForm.get("noOfRooms").setValue(data.object.BOOKING.ROOMS);
        this.roomsInfo = data.object.ROOMRESERVATION;
        if (Array.isArray(this.roomsInfo) && this.roomsInfo.length > 0) {
          for (let i = 0; i < this.roomsInfo.length; i++) {
            this.addRooms();
            let roomArray = this.reserveForm.get('roomArray') as FormArray;
            let roomArrayObj = roomArray.controls[i] as FormGroup;
            roomArrayObj.get('roomNumber').patchValue(this.roomsInfo[i]['ROOM_ID']['NAME']);
            roomArrayObj.get('roomType').patchValue(this.roomsInfo[i]['ROOM_CATEGORY_TYPE_ID']['NAME']);
            roomArrayObj.get('ratePlan').patchValue(this.roomsInfo[i]['RATE_PLAN_ID']['NAME']);
          };
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getGuests() {
    return (this.reserveForm.get('guestsArray') as FormArray).controls;
  }

  addGuests() {
    let codeIndex = (<FormArray>this.reserveForm.get('guestsArray')).length;
    let fg = this.fb.group({
      guestId: this.fb.control(''),
      guestFirstName: this.fb.control(''),
      guestLastName: this.fb.control(''),
      guestEmail: this.fb.control(''),
      guestMobileNumber: this.fb.control('')
    });
    (<FormArray>this.reserveForm.get('guestsArray')).push(fg);
  }

  getRooms() {
    return (this.reserveForm.get('roomArray') as FormArray).controls;
  }

  addRooms() {
    let codeIndex = (<FormArray>this.reserveForm.get('roomArray')).length;
    let fg = this.fb.group({
      roomNumber: this.fb.control(''),
      roomType: this.fb.control(''),
      ratePlan: this.fb.control('')
    });
    (<FormArray>this.reserveForm.get('roomArray')).push(fg);
  }

  updateReservation() {
    this.loading = true;
    let formData: any;
    formData = this.reserveForm.value;
    let secondaryGuest = formData['guestsArray'];
    let secondaryGuestsArray = [];
    for (let i = 0; i < secondaryGuest.length; i++) {
      if (!!secondaryGuest[i].guestFirstName) {
        secondaryGuestsArray.push({
          "ID": secondaryGuest[i].guestId,
          "USER_TYPE": "2",
          "FIRST_NAME": secondaryGuest[i].guestFirstName,
          "LAST_NAME": secondaryGuest[i].guestLastName,
          "MOBILE": secondaryGuest[i].guestMobileNumber,
          "EMAIL_ID": secondaryGuest[i].guestEmail,
          "VERIFICATION_DOCUMENT_TYPE_ID": null,
          "VERIFICATION_DOCUMENT_ATTACHMENT_SOURCE": ""
        })
      }
    }
    let obj = {
      "BOOKING_ID": this.bookingId,
      "HOST": {
        "ID": this.reserveForm.value.hostId,
        "USER_TYPE": "1",
        "FIRST_NAME": this.reserveForm.value.firstName,
        "LAST_NAME": this.reserveForm.value.lastName,
        "MOBILE": this.reserveForm.value.phoneNumber,
        "EMAIL_ID": this.reserveForm.value.emailId,
        "VERIFICATION_DOCUMENT_TYPE_ID": null,
        "VERIFICATION_DOCUMENT_ATTACHMENT_SOURCE": ""
      },
      "GUEST": secondaryGuestsArray
    }
    this.hotelService.updateReservation(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.router.navigateByUrl('arrivals');
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.router.navigateByUrl('arrivals');
      }
    })
  }

  backToReservations() {
    this.reserveForm.reset();
    this.router.navigateByUrl('arrivals');
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}