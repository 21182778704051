<div class="contents" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="printMe">
        <div class="header">
            <div class="logo" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <span class="hotellogo"><img class="logo-img" alt="logo" src="{{imageURL}}"></span>
                <span class="hotelName">{{hotelName}}</span>
            </div>
            <div class="hotelAddress">87 Queen street,
                <br />Ontario,
                <br />canada.
                <br /> 9766436778
            </div>
        </div>
        <div class="title">
            <div class="title-name">Check-Out Card</div>
            <div class="titleVal">{{reservationDetails.BOOKING?.RESERVATION}}</div>
        </div>
        <ng-container *ngFor="let user of reservationDetails.USER">
            <div class="info" *ngIf="user.USER_TYPE.ID == 1 && reservationDetails.USER?.length != 0">
                <div>
                    <div class="key">First Name: <span class="value">{{user?.FIRST_NAME}}</span></div>
                    <div class="key">Number of guests: <span class="value">{{reservationDetails.USER?.length}}</span></div>
                </div>
                <div>
                    <div class="key">Last Name: <span class="value">{{user?.LAST_NAME}}</span></div>
                    <div class="key">Duration: <span class="value">{{reservationDetails.BOOKING?.FROM_DATE}} -
                        {{reservationDetails.BOOKING?.TO_DATE}}</span></div>
                </div>
                <div>
                    <div class="key">Room No: <span class="value" *ngFor="let room of reservationDetails.ROOMRESERVATION; let isLast=last">{{room.ROOM_ID.NAME}}{{!isLast? ',' : ' '}}</span></div>
                </div>
            </div>
        </ng-container>
        <div fxLayout="column" class="content">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="tabheader">
                    Order Details
                </div>
            </div>
            <table mat-table [dataSource]="serviceDataSource">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="12%"> Date </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{row.DATE}}</td>
                </ng-container>
                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Service </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">
                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                            {{row.SERVICE_ID.NAME}} - {{row.CATEGORY_ID.NAME}} - {{row.CATEGORY_TYPE_ID.NAME}}
                        </ng-container>
                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                            <ng-container *ngFor="let dsVal of row.ITEMS">
                                <li>{{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_ID?.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}</li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="row.SERVICE_ID.ID === 2 && row.SERVICE_ID.ID !== 6">
                            <li *ngFor="let dsVal of row.ITEMS">
                                {{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}
                            </li>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Quantity </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">
                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                            {{row.QUANTITY}}
                        </ng-container>
                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                            <li *ngFor="let dsVal of row.ITEMS">
                                {{dsVal.QUANTITY}}
                            </li>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="12%"> Amount </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">
                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                            {{currency}} {{row.CATEGORY_TYPE_ID?.PRICE}}
                        </ng-container>
                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                            <li *ngFor="let dsVal of row.ITEMS">
                                {{currency}} {{dsVal.QUANTITY * dsVal.CATEGORY_TYPE_ID?.PRICE}}
                            </li>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Discount </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">
                        <ng-container *ngIf="row.SERVICE_ID.ID !== 2">
                            {{row.OFFER_ID?.DISCOUNT || 0}}% - {{currency}} {{row.OFFER_PRICE || 0}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="18%"> Tax </th>
                    <td mat-cell *matCellDef="let row" class="table-detail" style="text-align: start !important;">
                        <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                            <ng-container *ngIf="row.CATEGORY_TYPE_ID?.SERVICETAX.length != 0"> 
                                <li *ngFor="let tax of row.CATEGORY_TYPE_ID?.SERVICETAX">
                                    {{tax.TAX_ID?.PERCENTAGE}}% - 
                                    <span *ngIf="row.OFFER_ID !== null">{{currency}} {{((row.CATEGORY_TYPE_ID?.PRICE - row.OFFER_PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                    <span *ngIf="row.OFFER_ID === null">{{currency}} {{((row.CATEGORY_TYPE_ID?.PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                </li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                            <ng-container *ngFor="let dsVal of row.ITEMS">
                                <ng-container *ngIf="dsVal.CATEGORY_TYPE_ID?.SERVICETAX.length != 0">
                                    <li *ngFor="let tax of dsVal.CATEGORY_TYPE_ID?.SERVICETAX">
                                        {{tax.TAX_ID?.PERCENTAGE}}% - 
                                        <span *ngIf="row.OFFER_ID !== null">{{currency}} {{(((dsVal.QUANTITY *
                                            dsVal.CATEGORY_TYPE_ID?.PRICE) - ((row.OFFER_ID?.DISCOUNT / 100) * (dsVal?.CATEGORY_TYPE_ID?.PRICE *
                                            dsVal?.QUANTITY))) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                        <span *ngIf="row.OFFER_ID === null">{{currency}} {{((dsVal.QUANTITY * dsVal.CATEGORY_TYPE_ID?.PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="13%"> Total </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{currency}} {{row.TOTAL_AMOUNT + (row.TAXAMOUNT || 0)}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedServiceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedServiceColumns;"></tr>
            </table>
            <div class="box-name" style="text-align: center;" *ngIf="serviceDataSource.data.length == 0">
                <span>
                    <br>No orders...<br>
                </span>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="tabheader">
                    Room Details
                </div>
            </div>
            <table mat-table [dataSource]="roomDataSource">
                <ng-container matColumnDef="roomType">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="30%"> Room Type </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">
                        {{row.ROOM_CATEGORY_TYPE_ID?.CATEGORY_ID.NAME}} - {{row.ROOM_CATEGORY_TYPE_ID?.NAME}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="checkInDate">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-In Date </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{reservationDetails.ROOM_PAYMENT?.BOOKING?.FROM_DATE}}</td>
                </ng-container>
                <ng-container matColumnDef="checkOutDate">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-Out Date </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{reservationDetails.ROOM_PAYMENT?.BOOKING?.TO_DATE}}</td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Amount </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{currency}}</td>
                </ng-container>
                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Discount </th>
                    <td mat-cell *matCellDef="let row" class="table-detail"></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Total </th>
                    <td mat-cell *matCellDef="let row" class="table-detail">{{currency}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedRoomColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedRoomColumns;"></tr>
            </table>
            <div class="box-name" style="text-align: center;" *ngIf="roomDataSource.data.length == 0">
                <span>
                    <br>No rooms...<br>
                </span>
            </div>
        </div>
        <!-- <div class="total">
            <div>Total </div>
            <div>Amount Paid</div>
            <mat-divider></mat-divider>
            <div>Balance</div>
        </div> -->
        <div class="payment">
            <div class="paymentVal"></div>
            <div class="amount">
                <div class="row">
                    <div class="payment-value">{{currency}} -</div>
                    <div class="payment-key">Total</div>
                </div>
                <div class="row">
                    <div class="payment-value">{{currency}} -</div>
                    <div class="payment-key">Amount Paid</div>
                </div>
                <mat-divider></mat-divider>
                <div class="row">
                    <div class="payment-value">{{currency}} -</div>
                    <div class="payment-key">Balance</div>
                </div>
            </div>
        </div>
        <div class="end">
            <div style="padding-bottom: 8px;">Signature</div>
            <div>Date: <span>{{currentDate | date:'dd/MM/YYYY'}}</span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2%" class="buttons">
        <button mat-button class="save-btn" (click)="printReceipt()">Print</button>
        <button mat-button class="cancel-btn" (click)="closeDialog()">Cancel</button>
    </div>
</div>