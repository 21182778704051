<mat-card class="matCard">
  <div class="selector">
      <button mat-raised-button class="custom-button">
        <mat-form-field>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date">
            <input matEndDate placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </button>
    <span style="padding: 0px 0px 0px 8px;">
      <button mat-raised-button class="custom-button1">
        <mat-select [(value)]="selectedService" (selectionChange)="onServiceChange($event)"  placeholder="Service">
          <mat-option *ngFor="let service of permittedServices" [value]="service.ID">{{ service.NAME }}</mat-option>
        </mat-select>
      </button>
    </span>
  </div>
  <div class="chart-container" #chart></div>
</mat-card>
