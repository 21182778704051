<div class="spa-banners" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="banner-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span class="header">Upload users sheet</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button class="close-btn" mat-dialog-close>
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="uploadForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="22px">
            <div class="upload-banners">
                <span class="upload-header">Upload</span>
                <div class="upload-box" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="13px"
                    appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                    <div>
                        <input type="file" formControlName="image" class="doc_file" id="doc_file" style="display: none"
                            #fileInput hidden [multiple]="false" accept=".xlsx"
                            (change)="onFileSelected($event.target.files)" />
                        <mat-icon (click)="fileInput.click()" style="cursor:pointer; color: #2A4B5B;">upload</mat-icon>
                    </div>
                    <div fxFlex="45%" (click)="fileInput.click()">
                        <span class="upload-caption1">Click to upload </span>
                        <span class="upload-caption2">or drag and drop xlsx</span>
                    </div>
                </div>
            </div>
            <div class="upload-images" fxLayout="column" fxLayoutGap="20px">
                <div class="file-name">
                    {{imageName}}
                </div>
            </div>
        </div>
    </form>
</div>