import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-dialog-delete-policy',
  templateUrl: './dialog-delete-policy.component.html',
  styleUrls: ['./dialog-delete-policy.component.scss']
})
export class DialogDeletePolicyComponent {

  addForm: any;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<DialogDeletePolicyComponent>,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close(true);
    } else {
      this.mobile = false;
    }
  }

  deletePrivacyPolicy() {
    let body = {
      "ID": this.data.id,
      "DELETED": true
    }
    this.adminservice.updatePrivacyPolicy(body)
      .subscribe({
        next: (data) => {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
          this.dialogRef.close(true);
        },
        error: (e) => {
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
  }

}