<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p><p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div fxLayout="row" fxLayoutAlign="space-between" style="margin: 10px 0;">
                <div class="header">
                    Members List
                </div>
                <div>
                    <button class="add-btn" (click)="addUser()">
                        + Add Member
                    </button>
                </div>
            </div>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Brand </th>
                    <td mat-cell *matCellDef="let row" (click)="preview(row.TENANT.ATTACHMENT_SOURCE)"><img src="{{ row.TENANT.ATTACHMENT_SOURCE != null ? bucketUrl + row.TENANT.ATTACHMENT_SOURCE : '../../../../../../assets/images/question_mark.svg' }}" class="img_preview"></td>
                </ng-container>
                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Client </th>
                    <td mat-cell *matCellDef="let row">{{row.TENANT.NAME}}</td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Name </th>
                    <td mat-cell *matCellDef="let row">{{row.FIRST_NAME}} {{row.LAST_NAME}}</td>
                </ng-container>
                <ng-container matColumnDef="user-name">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> User Name </th>
                    <td mat-cell *matCellDef="let row">{{row.USERNAME}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Email </th>
                    <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile-no">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Mobile Number</th>
                    <td mat-cell *matCellDef="let row">{{row.MOBILE}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <button mat-icon-button (click)="editUser(row.ID)">
                                <span class="material-icons-outlined">edit</span>
                            </button>
                            <button mat-icon-button (click)="deleteUser(row.ID)">
                                <span class="material-icons-outlined" style="color: rgb(255, 0, 0);"> delete_outline </span>
                            </button>
                            <label class="switch">
                                <input #checkbox type="checkbox" [checked]="!row.USER_DISABLED" (change)="enable(checkbox.checked, row.ID)">
                                <span class="slider round"></span>
                              </label>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
        </div>
    </div>
</div>