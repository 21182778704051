<div class="spa-banners" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="banner-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span class="header">Upload house keeping banners</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button class="close-btn" mat-dialog-close>
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="housekeepingBannerForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="22px">
            <div class="upload-banners">
                <span class="upload-header">Upload Image</span>
                <div class="upload-box" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="13px"
                    appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                    <div>
                        <input type="file" formControlName="image" class="doc_file" id="doc_file" style="display: none"
                            #fileInput hidden [multiple]="multiple" accept=".jpg,.jpeg,.png"
                            (change)="onFileSelected($event.target.files)" />
                        <mat-icon (click)="fileInput.click()" style="cursor:pointer">upload</mat-icon>
                    </div>
                    <div fxFlex="45%" (click)="fileInput.click()">
                        <span class="upload-caption1">Click to upload </span>
                        <span class="upload-caption2">or drag and drop jpeg,jpg,png</span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 62px;" *ngIf="savedBannerFiles.length != 0">
                <div style="align-self: center;">
                    <mat-button (click)="bannerScrollLeft()" class="scroll-btn">
                        <mat-icon>chevron_left</mat-icon>
                    </mat-button>
                </div>
                <div #bannerContent fxLayout="row" fxLayoutGap="20px" class="upload-images">
                    <div id="banner-img" *ngFor="let img of savedBannerFiles; let i = index" fxLayout="row">
                        <img src="{{bucketUrl + img.SOURCE}}" class="img_preview" (click)="preview(img.SOURCE)">
                        <div id="delete" style="color: #000000; font-weight: 600; cursor: pointer;"
                            [matMenuTriggerFor]="bannerOptions" *ngIf="data.name == 'add_banner'">
                            <mat-icon>more_vert</mat-icon>
                        </div>
                        <mat-menu #bannerOptions="matMenu">
                            <div class="option-container">
                                <button mat-menu-item (click)="deleteBanner(i)">
                                    <span class="option-text">
                                        <mat-icon style="margin-bottom: -7px;">delete_outline</mat-icon></span>
                                    <span class="option-text">Delete</span>
                                </button>
                            </div>
                        </mat-menu>
                    </div>
                </div>
                <div style="align-self: center;">
                    <mat-button (click)="bannerScrollRight()" class="scroll-btn">
                        <mat-icon>chevron_right</mat-icon>
                    </mat-button>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button
                    [ngClass]="this.savedBannerFiles.length == 0 ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.savedBannerFiles.length == 0" (click)="saveBanners()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>