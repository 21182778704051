<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">Update {{data.NAME}} Timing</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="timingForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="41px">
                <div fxLayout="row" fxLayoutGap="24px">
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Timing From
                        </div>
                        <div>
                            <mat-select formControlName="from_time" class="select"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let slot of slots" [value]="slot.ID">{{slot.TIME}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Timing To
                        </div>
                        <div>
                            <mat-select formControlName="to_time" class="select"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let slot of slots" [value]="slot.ID">{{slot.TIME}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button mat-stroked-button class="button" (click)="cancel()">
                    Cancel
                </button>
                <button mat-stroked-button class="save-button" (click)="updateTiming()">
                    Save
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</div>