import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { AuthGaurd } from '../session/shared/service/auth-gaurd.service';
import { NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { Observable, first } from 'rxjs';
import { MasterDataState } from '../session/shared/store/state/master-data.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  nestedServices: boolean = true;
  nestedOperations: boolean = true;
  nestedHotelOperations: boolean = true;
  nestedReservations: boolean = true;
  userInfo: any;
  services = [];
  hotelServices: any = [];
  permittedServices = [];
  hasService: boolean = false;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  hotelName: string;
  imageURL: string;
  public bucket_url = `${environment.BUCKET_URL}`;
  selectedIndex;
  mobile: boolean = false;
  currentUrl: string;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private auth: AuthGaurd,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        if (this.currentUrl == '/internal-request') {
          this.selectedIndex = 1;
        } else if (this.currentUrl == '/guest-request') {
          this.selectedIndex = 0;
        }
      }
    });
  }

  ngOnInit() {
    this.hotelName = window.localStorage.getItem('hotel_name');
    this.imageURL = this.bucket_url + window.localStorage.getItem('hotel_icon');
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.auth.currentService
      .pipe(first()).subscribe(step => {
        if (step === true) {
          this.nestedServices = true;
        }
      })
    this.auth.currentOperation
      .pipe(first()).subscribe(step => {
        if (step === true) {
          this.nestedOperations = true;
        }
      })
    this.auth.currentHotelOperation
      .pipe(first()).subscribe(step => {
        if (step === true) {
          this.nestedHotelOperations = true;
        }
      })
    this.auth.currentReservation
      .pipe(first()).subscribe(step => {
        if (step === true) {
          this.nestedReservations = true;
        }
      })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        //To get the permitted hotel services by admin
        this.hotelServices = window.localStorage.getItem('hotel_services');
        if (this.userInfo.roleId == 2) {
          for (let service of this.services) {
            if (this.hotelServices.indexOf(service.ID) != -1) {
              if (service.CONFIGURABLE == true) {
                this.permittedServices.push(service);
              }
            }
          }
        }
        else if (this.userInfo.roleId != 2) {
          for (let service of this.services) {
            if (this.userInfo.serviceId.indexOf(service.ID) != -1) {
              this.hasService = true;
              this.permittedServices.push(service);
            }
          }
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
    this.cdr.detectChanges();
  }

  showServices() {
    this.nestedServices = this.nestedServices === false ? true : false;
    this.auth.changeService(this.nestedServices);
  }

  showOperations() {
    this.nestedOperations = this.nestedOperations === false ? true : false;
    this.auth.manageOperations(this.nestedOperations);
  }

  showHotelOperations() {
    this.nestedHotelOperations = this.nestedHotelOperations === false ? true : false;
    this.auth.manageHotelOperations(this.nestedHotelOperations);
  }

  showReservations() {
    this.nestedReservations = this.nestedReservations === false ? true : false;
    this.auth.manageReservations(this.nestedReservations);
  }

  changeComponent(v: any) {
    this.router.navigateByUrl('profile');
  }

  tabChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('guest-request');
    } else {
      this.router.navigateByUrl('internal-request');
    }
  }

}