import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-slot-check-popup',
  templateUrl: './slot-check-popup.component.html',
  styleUrls: ['./slot-check-popup.component.scss']
})
export class SlotCheckPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<SlotCheckPopupComponent>
  ) { }

  ngOnInit(): void { }

  onSubmit(): void {
    this.dialogRef.close({ 'state': true });
  }

  onClose(): void {
    this.dialogRef.close({ 'state': false });
  }

}