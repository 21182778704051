<div class="contents" fxLayout="column">
  <div class="overlay" *ngIf="loading">
    <div class="overlay__inner">
      <div class="overlay__content"><span class="spinner"></span></div>
    </div>
  </div>
  <div class="printMe">
    <div class="header">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <span class="hotellogo"><img class="logo-img" alt="logo" src="{{imageURL}}"></span>
        <span class="hotelName">{{hotelName}}</span>
      </div>
      <div class="hotelAddress">87 Queen street,
        <br />Ontario,
        <br />canada.
        <br /> 9766436778
      </div>
    </div>
    <div class="title">
      <div class="title-name">Check-In Card</div>
      <div class="titleVal">{{reservationDetails.BOOKING?.RESERVATION}}</div>
    </div>
    <ng-container *ngFor="let user of reservationDetails.USER">
      <div class="info" *ngIf="user.USER_TYPE.ID == 1 && reservationDetails.USER?.length != 0">
        <div class="key">First Name <span class="value">{{user?.FIRST_NAME}}</span></div>
        <div class="key">Last Name <span class="value">{{user?.LAST_NAME}}</span></div>
        <div class="key">Number of guests <span class="value">{{reservationDetails.USER?.length}}</span></div>
        <div class="key">Duration <span class="value">{{reservationDetails.BOOKING?.FROM_DATE}} -
            {{reservationDetails.BOOKING?.TO_DATE}}</span></div>
      </div>
    </ng-container>
    <div class="payment">
      <div class="paymentVal">
        <div style="color:#355F73; font-weight: 400; font-size: 16px; padding: 10px; margin-left: 20px;">Payment</div>
        <div class="row">
          <div class="payment-value">xxxxxx</div>
          <div class="payment-key">Payment Mode</div>
        </div>
      </div>
      <div class="amount">
        <div class="row">
          <div class="payment-value">-</div>
          <div class="payment-key">Room Type</div>
        </div>
        <div class="row">
          <div class="payment-value">-</div>
          <div class="payment-key">Amount</div>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <div class="payment-value">-</div>
        </div>
      </div>
    </div>
    <div class="remark">
      <div>Remarks</div>
      <textarea matInput class="text"></textarea>
    </div>
    <div class="end">
      <div class="date">
        Date: <span>{{currentDate | date:'dd/MM/YYYY'}}</span></div>
      <div class="sign">Signature</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2%" class="buttons">
    <button mat-button class="save-btn" (click)="printReceipt()">Print</button>
    <button mat-button class="cancel-btn" (click)="closeDialog()">Cancel</button>
  </div>
</div>