<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span mat-dialog-title class="header">Logout</span>
    </div>
    <div>
      <button mat-button mat-dialog-close class="close-btn">
        <mat-icon class="close-icon">
          close
        </mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="content">
    Do you want to Log out or Stay Logged In?
  </div>
  <mat-dialog-actions class="actions">
    <button mat-stroked-button class="save-button" mat-dialog-close>
      Stay Logged In
    </button>
    <button mat-stroked-button class="button" mat-dialog-close (click)="closeDialog('yes')">
      Log out
    </button>
  </mat-dialog-actions>
</div>