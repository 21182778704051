import { Component, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-dashboard-sla',
  templateUrl: './dashboard-sla.component.html',
  styleUrls: ['./dashboard-sla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardSlaComponent implements AfterViewInit {

  selected: string;
  periods = [];
  data = [
    { label: "Total Guest Requests", apples: 56 },
    { label: "SLA Violated", apples: 20 },
    { label: "Success Rate", apples: 10 },
    { label: "CSAT", apples: 40 }
  ];

  private svg: any;
  private arc: any;
  private pie: any;
  private path: any;
  private color = d3.scaleOrdinal<string, string>()
  .domain(["Total Guest Requests", "SLA Violated", "Success Rate", "CSAT"])
  .range(["#47D6FF", "#D40004", "#01869E", "#FFD662"]);

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.initializePeriods();
    this.selected = this.periods[0].value; // Default selection
  }

  initializePeriods() {
    this.periods = [
      { label: 'Today', value: 'today' },
      { label: 'One Month', value: '1_month' },
      { label: 'Six Months', value: '6_months' },
      { label: 'One Year', value: '1_year' }
    ];
  }

  ngAfterViewInit() {
    this.renderChart();
  }

  onPeriodChange(value: string) {
    this.updateChart(value); // Handle chart update based on selected period
  }

  renderChart() {
    const width = 320;
    const height = 200;
    const outerRadius = height / 2 - 8;
    const innerRadius = outerRadius * 0.60;
  
    // Create the SVG element
    const svg = d3.select(this.elementRef.nativeElement).select("#chartContainer")
      .append("svg")
      .attr("viewBox", [-width / 1.5, -height / 2, width, height])
      .attr("width", width)
      .attr("height", height);
  
    // Define the arc generator
    const arc = d3.arc<d3.PieArcDatum<any>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
  
    // Define the pie layout
    const pie = d3.pie<any>()
      .sort(null)
      .value((d: any) => d.apples);
  
    // Add a text element in the center of the chart
    const text = svg.append("text")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("font-size", "16px")
      .style("fill", "#000")
      .text("SLA"); // Initial text (can be something like SLA or empty)
  
    // Create the chart slices and set fill colors
    const path = svg.selectAll("path")
      .data(pie(this.data))
      .enter()
      .append("path")
      .attr("fill", (d) => this.color(d.data.label)) 
      .attr("d", (d) => arc(d as any))
      .each(function(d) { (this as any)._current = d; });
  
      path.on("mouseover", (event, d) => {
        text.text(`${d.data.apples}`)
          .style("font-size", "24px")    
          .style("font-weight", "bold")  
          .style("fill", "#333");       
      })
      .on("mouseout", () => {
        text.text("SLA")
          .style("font-size", "16px")    
          .style("font-weight", "bold") 
          .style("fill", "#000");        
      });
  
    this.svg = svg;
    this.arc = arc;
    this.pie = pie;
    this.path = path;
  }
  
  

  updateChart(value: string) {
    this.pie.value((d: any) => d.apples);
    this.path.data(this.pie(this.data));

    this.path.transition().duration(750).attrTween("d", (a: any) => {
      const i = d3.interpolate((this as any)._current, a);
      (this as any)._current = i(0);
      return (t) => this.arc(i(t));
    });
  }
  
}
