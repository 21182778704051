<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                    <!-- <div class="option-text">Today</div>
                    <div class="vertical-line"></div> -->
                    <div class="week-text" (click)="thisWeekdata()">This Week</div>
                    <div class="vertical-line"></div>
                    <div class="calendar" fxLayout="row" fxLayoutAlign="center center">
                        <div>
                            <mat-form-field appearance="outline" class="date-picker-range custom-picker">
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="hidden-inputs">
                                    <input matStartDate formControlName="start" readonly />
                                    <input matEndDate formControlName="end" readonly />
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <!-- <button (click)="navigateBackward()" mat-button style="place-content: end;">
                            <mat-icon>arrow_back_ios</mat-icon>
                        </button>
                        <div class="date-picker" fxLayoutAlign="center center">
                            <span class="date">
                                {{minDate   | date:'dd MMMM yyyy'}}
                            </span>
                        </div>
                        <button (click)="navigateForward()" mat-button style="place-content: center;">
                            <mat-icon>arrow_forward_ios</mat-icon>
                        </button> -->
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="25px">
                    <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="center center">
                        <div class="hint" style="background-color: #EF9E00;"></div>
                        <div class="hint-text" style="color: #EF9E00;">Reserved</div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="center center">
                        <div class="hint" style="background-color: #185C00;"></div>
                        <div class="hint-text" style="color: #185C00;">Checked In</div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="center center">
                        <div class="hint" style="background-color: rgb(103 103 103);"></div>
                        <div class="hint-text" style="color: rgb(103 103 103);">Checked Out</div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="center center">
                        <div class="hint" style="background-color: #D50000;"></div>
                        <div class="hint-text" style="color: #D50000;">Cancelled</div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="80%" fxLayout="row" fxLayoutGap="15px">
                    <div fxFlex="50%" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                        <div class="option-text">Room Category</div>
                        <mat-select class="text-box" appearance="fill" [formControl]="roomType" placeholder="Select">
                            <!-- <mat-option [value]="null">All Categories</mat-option> -->
                            <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                                {{ room.NAME }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="vertical-line"></div>
                    <!-- Room Type Dropdown -->
                    <div fxFlex="50%" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                        <div class="option-text">Room Type</div>
                        <mat-select class="text-box" appearance="fill" [formControl]="roomClass" placeholder="Select">
                            <mat-option *ngFor="let roomClass of roomClasses" [value]="roomClass.ID">
                                {{ roomClass.NAME }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="reserve-plan">
                <!-- Header with the selected week -->
                <div class="row header-row">
                    <div class="cell room-header"></div>
                    <div *ngFor="let day of selectedDays; let i = index" class="cell date-header" [ngClass]="{
                      'highlight-day': isToday(day),
                      'selected-start': isSameDay(day, selectedStartDate),
                      'selected-end': isSameDay(day, selectedEndDate),
                      'in-range': isInRange(day, selectedStartDate, selectedEndDate)}">
                        <div class="date-number">{{ day | date: 'dd' }}</div>
                        <div class="day-name">{{ day | date: 'EEEE' }}</div>
                    </div>
                </div>
                <!-- Room Type and Room Numbers -->
                <div *ngFor="let roomClass of filteredRoomClasses" class="room-type-section">
                    <div class="row room-type-row">
                        <div class="cell room-type-header" [attr.colspan]="selectedDays">
                            {{ roomClass.NAME }}
                        </div>
                    </div>
                    <!-- Handle case where no rooms are available -->
                    <div *ngIf="roomClass.ROOMS && roomClass.ROOMS.length === 0" class="row no-rooms-row">
                        <div class="cell" style="background-color: rgb(255, 255, 255);" [attr.colspan]="selectedDays">
                            No rooms associated
                        </div>
                    </div>
                    <!-- Rooms available for each room class -->
                    <div *ngFor="let roomNumber of roomClass.ROOMS" class="row room-row">
                        <div class="cell room-number">{{ roomNumber.NAME }}</div>
                        <div *ngFor="let day of selectedDays; let i = index" class="cell room-data" [ngStyle]="{
                            'background-color': getStatusColor(roomNumber.ID, roomNumber.NAME, day),
                            'color': getColorBasedOnStatus(getRoomStatus(roomNumber.ID, roomNumber.NAME, day))}">
                            <div class="cell-content" [matMenuTriggerFor]="menu"
                                [matMenuTriggerDisabled]="!hasMenuActions(roomNumber.ID, roomNumber.NAME, day)">
                                <div class="status">{{ getReservationID(roomNumber.ID, roomNumber.NAME, day) }}</div>
                                <div class="user">{{ getUser(roomNumber.ID, roomNumber.NAME, day) }}</div>
                            </div>
                            <mat-menu #menu="matMenu" class="menuView">
                                <ng-container *ngFor="let btn of roomNumber.menuActions[i]">
                                    <button mat-menu-item (click)="bookingActions(btn, getBookingId(roomNumber.ID, roomNumber.NAME, day))">{{btn}}</button>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>