import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-qrcode',
  templateUrl: './app-qrcode.component.html',
  styleUrls: ['./app-qrcode.component.scss']
})
export class AppQRcodeComponent {

  public appUrl = `${environment.APP_URL}`;
  clientAppUrl: SafeUrl;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AppQRcodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.clientAppUrl = this.appUrl + '/?C_ID=' + this.data.ID;
  }

  onChangeURL(url: SafeUrl) {
    this.clientAppUrl = url;
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

}