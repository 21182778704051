<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        
        <div>
            <app-revenue></app-revenue>
        </div>

        <div class="flex-container">
            <div class="flex-item1">
                <div>
                    <app-dashboard-sla></app-dashboard-sla>
                </div>

            </div>
            <div class="flex-item2">
                <div>
                    <app-rating></app-rating>
                </div>
            </div>
        </div>
    </div>
</div>