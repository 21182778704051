import { Component, HostListener, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';

@Component({
  selector: 'app-rating-filter',
  templateUrl: './rating-filter.component.html',
  styleUrls: ['./rating-filter.component.scss']
})
export class RatingFilterComponent {

  categories = [];
  hotelServices: any = [];
  permittedServices: any = [];
  serviceIds = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  fromDate = new FormControl('');
  toDate = new FormControl('');
  roomNo = new FormControl('');
  bookingId = new FormControl('');
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<RatingFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.categories = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      for (let service of this.categories) {
        if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
          this.permittedServices.push(service);
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  changeDate() {
    this.toDate.reset()
  }

  selectServiceFilter(isChecked, id) {
    if (isChecked == true) {
      this.serviceIds.push(id);
    }
    if (isChecked == false) {
      const index = this.serviceIds.findIndex(el => el === id);
      this.serviceIds.splice(index, 1);
    }
  }

}