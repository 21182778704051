import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { GetUserInfo } from "../actions/user-info.actions";
import { AdminService } from "src/app/services/admin.service";

// User State Model
export class UserStateModel {
    userInfo: any[];
    userInfoLoaded: boolean;
}

//User state
@State<UserStateModel>({
    name: 'user',
    defaults: {
        userInfo: [],
        userInfoLoaded: false
    }
})

@Injectable()
export class UserState {
    constructor(private adminService: AdminService) { }

    @Selector()
    static getUserInfo(state: UserStateModel) {
        return state.userInfo
    }

    @Selector()
    static isUserInfoLoaded(state: UserStateModel) {
        return state.userInfoLoaded
    }

    @Action(GetUserInfo)
    getHotelInfo({ getState, setState }: StateContext<UserStateModel>) {
        return this.adminService.login('i').pipe(tap(res => {
            const state = getState();
            setState({
                ...state,
                userInfo: res,
                userInfoLoaded: true
            })
        }))
    }
}