import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conference-room-banners',
  templateUrl: './conference-room-banners.component.html',
  styleUrls: ['./conference-room-banners.component.scss']
})
export class ConferenceRoomBannersComponent {

  conferenceBannerForm: FormGroup;
  url = [];
  savedBannerFiles = [];
  updateBannerBody = {};
  multiple: boolean = false;
  bannerById: any = [];
  source_url;
  loading: boolean = false;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('bannerContent', {}) public bannerContent;

  constructor(
    public dialogRef: MatDialogRef<ConferenceRoomBannersComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService,
    public hotelService: HotelServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.conferenceBannerForm = this.fb.group({
      image: [[]],
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "add_banner") {
      this.multiple = true;
    }
    else if (this.data.name == "update_banner") {
      this.loading = true;
      this.multiple = false;
      this.hotelService.getConferenceBannerById(this.data.ID).subscribe({
        next: (res) => {
          this.loading = false;
          this.bannerById = res.object;
          this.source_url = res.object.ATTACHMENT_SOURCE;
          this.savedBannerFiles.push({
            "ID": this.data.ID,
            "POSITION": this.bannerById.POSITION,
            "SOURCE": this.bannerById.ATTACHMENT_SOURCE
          });
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  async onFileSelected(files) {
    for (let i = 0; i <= files.length - 1; i++) {
      this.loading = true;
      var selectedFile = files[i];
      let tempObjforGetsigned = {
        SERVICE_ID: "4",
        SECTION: 'banner',
        FILE_NAME: selectedFile['name']
      }
      await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
        if (res && res['SIGNED_URL'] && this.data.name == "add_banner") {
          let saveBody = {
            "SOURCE": res['PATH'],
            "POSITION": 1111 //ToDo: temp value
          }
          this.source_url = res['PATH'];
          let tempObjForUpload = {
            url: res['SIGNED_URL'],
            file: selectedFile
          }
          await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
            if (data) {
              this.loading = false;
              this.notify.showNotification(
                'File uploaded successfully',
                "top",
                (!!colorObj[200] ? colorObj[200] : "success"),
                200
              );
              this.savedBannerFiles.push(saveBody);
            }
          }).catch((err) => {
            this.handleError(err);
          });
        }
        else if (res && res['SIGNED_URL'] && this.data.name == "update_banner") {
          this.updateBannerBody = {
            "ID": this.data.ID,
            "POSITION": 1111, //ToDo: this.bannerById.POSITION,
            "SOURCE": res['PATH']
          }
          let tempObjForUpload = {
            url: res['SIGNED_URL'],
            file: selectedFile
          }
          await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
            if (data) {
              this.loading = false;
              this.notify.showNotification(
                'File uploaded successfully',
                "top",
                (!!colorObj[200] ? colorObj[200] : "success"),
                200
              );
              this.savedBannerFiles = [];
              this.savedBannerFiles.push(this.updateBannerBody);
            }
          }).catch((err) => {
            this.handleError(err);
          });
        }
      })
        .catch((err) => {
          this.savedBannerFiles.splice(i, 1);
          this.handleError(err);
        });
    }
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  deleteBanner(i) {
    this.savedBannerFiles.splice(i, 1);
    for (let ind = i; ind <= this.savedBannerFiles.length - i; ind++) {
      this.savedBannerFiles[ind].POSITION = this.savedBannerFiles[ind].POSITION - 1;
    }
  }

  saveBanners() {
    this.loading = true;
    if (this.data.name == "add_banner") {
      this.hotelService.addConferenceBanner(this.savedBannerFiles).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close('refresh');
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
          this.dialogRef.close();
        }
      })
    }
    else if (this.data.name == "update_banner") {
      this.hotelService.updateConferenceBanner(this.updateBannerBody).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close('refresh');
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
          this.dialogRef.close();
        }
      })
    }
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  public bannerScrollRight(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft + 300), behavior: 'smooth' });
  }

  public bannerScrollLeft(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft - 300), behavior: 'smooth' });
  }

}