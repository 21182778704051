import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Select, Store } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-shift',
  templateUrl: './add-edit-shift.component.html',
  styleUrls: ['./add-edit-shift.component.scss']
})
export class AddEditShiftComponent {
  loading: boolean = false;
  areas = [];
  slots = [];
  addShiftForm: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditShiftComponent>,
    private fb: FormBuilder,
    private store: Store,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addShiftForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      from_time: ['', Validators.required],
      to_time: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.slots = res['SLOTS'];
      }
    })
    if (this.data) {
      this.cs.getShiftById(this.data.id).subscribe({
        next: (res) => {
          let shiftFromIndex = this.slots.findIndex(x => x.TIME == res.object.SHIFT_FROM.TIME);
          let shiftToIndex = this.slots.findIndex(x => x.TIME == res.object.SHIFT_TO.TIME);
          this.loading = false;
          this.addShiftForm.get("name").setValue(res.object.NAME);
          this.addShiftForm.get("description").setValue(res.object.DESCRIPTION);
          this.addShiftForm.get("from_time").setValue(this.slots[shiftFromIndex].ID);
          this.addShiftForm.get("to_time").setValue(this.slots[shiftToIndex].ID);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addShift() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.addShiftForm.value.name,
        "DESCRIPTION": this.addShiftForm.value.description,
        "FROM": this.addShiftForm.value.from_time,
        "TO": this.addShiftForm.value.to_time
      }
      this.cs.updateShift(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.addShiftForm.value.name,
        "DESCRIPTION": this.addShiftForm.value.description,
        "FROM": this.addShiftForm.value.from_time,
        "TO": this.addShiftForm.value.to_time,
      }
      this.cs.addShift(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}