import { Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/services/notification.service';
import { AddComponent } from './add/add.component';
import { MatPaginator } from '@angular/material/paginator';
import { AdminService } from 'src/app/services/admin.service';
import { colorObj } from 'src/app/shared/color-object';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent {

  displayedColumns: string[] = ['sr.no', 'category', 'discount', 'date', 'special-deal', 'description', 'action'];
  dataSource = new MatTableDataSource<any>();
  loading: boolean = true;
  resArray: Array<any> = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('paginatorRef') paginatorRef: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  categories = [];
  limit = 10;
  pageSize = 5;
  pageIndex = 0;
  pageNumber = 0;
  isFilter = false;
  visitedIndex: Array<any> = [0];
  private _offersData = new BehaviorSubject<any[]>([]);
  private offersDataStore: { $offersData: any[] } = { $offersData: [] };
  readonly $offersData = this._offersData.asObservable();
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  currentPageIndex: any;
  previousPageIndex: any;
  serviceIds = [];
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  hotelServices: any = [];
  permittedServices: any = [];

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    public adminService: AdminService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    if (this.userInfo.roleId == 2) {
      this.getOffers(this.pageNumber);
    }
    else if (this.userInfo.roleId != 2) {
      this.isFilter = true;
      this.getOffersByServiceId(this.pageNumber, this.userInfo.serviceId);
    }
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.categories = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      if (this.userInfo.roleId == 2) {
        for (let service of this.categories) {
          if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
      else if (this.userInfo.roleId != 2) {
        for (let service of this.categories) {
          if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getOffers(pageNo) {
    this.adminService.getOffers(pageNo, this.limit).subscribe({
      next: (data) => {
        this.loading = false;
        this.resArray = this.resArray.concat(data.object);
        this.offersDataStore.$offersData = this.resArray;
        this.dataSource.data = this.offersDataStore.$offersData;
        this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
        this.dataSource.paginator = this.paginator.toArray()[0];
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changePage(event) {
    if (this.visitedIndex.indexOf(event.pageIndex) == -1) {
      this.visitedIndex.push(event.pageIndex);
    }
    this.currentPageIndex = event.pageIndex;
    this.previousPageIndex = event.previousPageIndex;
    if (this.currentPageIndex > this.previousPageIndex) {
      if (this.visitedIndex.indexOf(this.currentPageIndex + 1)) {
        this.pageNumber++;
        this.loading = true;
        if (this.isFilter == false) {
          if (this.userInfo.roleId == 2) {
            this.getOffers(this.pageNumber);
          }
          else {
            this.getOffersByServiceId(this.pageNumber, this.userInfo.serviceId);
          }
        }
        else {
          this.getOffersByServiceId(this.pageNumber, this.serviceIds);
        }
      }
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddComponent, {
      width: '40%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.pageNumber = 0;
        this.resArray = [];
        this.dataSource.data = [];
        if (this.userInfo.roleId == 2) {
          this.getOffers(this.pageNumber);
        }
        else {
          this.getOffersByServiceId(this.pageNumber, this.userInfo.serviceId);
        }
      }
    })
  }

  editOffer(id) {
    const dialogRef = this.dialog.open(AddComponent, {
      width: '40%',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateOffer(result);
      this.loading = false;
    })
  }

  updateOffer(obj) {
    this.offersDataStore.$offersData.forEach((t: any, i) => {
      if (t.ID === obj.ID) {
        this.offersDataStore.$offersData[i] = obj;
      }
    });
    this._offersData.next(Object.assign({}, this.offersDataStore).$offersData);
    this.$offersData.subscribe((res) => {
      this.resArray = res;
      this.dataSource.data = res;
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator.toArray()[0];
    })
  }

  enable(isChecked, element) {
    this.loading = true;
    let obj = {
      "ID": element.ID,
      "NAME": element.NAME,
      "SERVICE_ID": element.SERVICE.ID,
      "DESCRIPTION": element.DESCRIPTION,
      "ENABLED": isChecked
    }
    this.adminService.updateOffer(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.updateOffer(data.object);
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectCategory(isChecked, id) {
    if (id != -0 && isChecked == true) {
      this.serviceIds.push(id);
    }
    if (isChecked == false) {
      const index = this.serviceIds.findIndex(el => el === id);
      this.serviceIds.splice(index, 1);
    }
    this.pageNumber = 0;
    this.dataSource.data = [];
    this.resArray = [];
    if (this.serviceIds.length != 0) {
      this.isFilter = true;
      this.getOffersByServiceId(this.pageNumber, this.serviceIds);
    }
    else if (this.serviceIds.length == 0) {
      this.isFilter = false;
      if (this.userInfo.roleId == 2) {
        this.getOffers(this.pageNumber);
      }
      else {
        this.getOffersByServiceId(this.pageNumber, this.userInfo.serviceId);
      }
    }
  }

  getOffersByServiceId(pageNo, filterId) {
    this.loading = true;
    this.adminService.getOffersByServiceId(pageNo, this.limit, filterId).subscribe({
      next: (data) => {
        this.loading = false;
        this.resArray = this.resArray.concat(data.object);
        this.offersDataStore.$offersData = this.resArray;
        this.dataSource.data = this.offersDataStore.$offersData;
        this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
        this.dataSource.paginator = this.paginator.toArray()[0];
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  deleteOffer(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this offer ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true,
        }
        this.adminService.updateOffer(obj).subscribe({
          next: (data) => {
            this.loading = false;
            this.offersDataStore.$offersData.forEach((t: any, i) => {
              if (t.ID === id) {
                this.offersDataStore.$offersData.splice(i, 1);
              }
            });
            this._offersData.next(Object.assign({}, this.offersDataStore).$offersData);
            this.$offersData.subscribe((res) => {
              this.resArray = res;
              this.dataSource.data = res;
              this.dataSource = new MatTableDataSource(res);
              this.dataSource.paginator = this.paginator.toArray()[0];
              if (this.offersDataStore.$offersData.length % 5 == 0) {
                this.paginatorRef.previousPage();
              }
            })
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}