<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{ data?.id ? 'Edit Cancellation Policy' :
                'Add Cancellation Policy' }}
            </span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="cancellationForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="25px">
                <div>
                    <div class="box-name">Enter Cancellation Name</div>
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Enter">
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="25px">
                    <div fxLayout="column" fxFlex="50%" fxLayoutGap="5px">
                        <div class="box-name">
                            Description
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="description" placeholder="Enter Description"
                                maxlength="500"></textarea>
                            <mat-error class="error"
                                *ngIf="cancellationForm.controls['description'].touched && cancellationForm.get('description').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="box-name">Cancellation Window</div>
                    <mat-radio-group formControlName="cancellationWindow" aria-label="Select an option"
                        class="radio-group">
                        <mat-radio-button class="radio-label"
                            [ngClass]="{'selected-radio': cancellationForm.get('cancellationWindow').value === '1'}"
                            value="1">
                            Create Window
                        </mat-radio-button>
                        <mat-radio-button class="radio-label"
                            [ngClass]="{'selected-radio': cancellationForm.get('cancellationWindow').value === '2'}"
                            value="2">
                            Any time before check-in including no shows
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="error"
                        *ngIf="cancellationForm.controls['cancellationWindow'].touched && cancellationForm.get('cancellationWindow').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <!-- Conditionally Render Fields based on Radio Button Selection -->
                <div *ngIf="cancellationForm.get('cancellationWindow').value === '1'">
                    <div formArrayName="cancellationWindowConditions">
                        <div *ngFor="let condition of cancellationWindowConditions.controls; let i = index"
                            [formGroupName]="i" class="array-box">
                            <div class="delete-button">
                                <button mat-icon-button class="remove-icon" (click)="removeWindowCondition(i)"
                                    [disabled]="cancellationForm.controls.cancellationWindowConditions.controls.length == 1">
                                    <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                                </button>
                            </div>
                            <div fxLayout="row" fxLayoutGap="24px">
                                <div fxLayout="column" fxFlex="50%">
                                    <div class="box-name">Amount of Time Before Cut-off</div>
                                    <div>
                                        <input type="text" formControlName="cutoffTime" class="text-box-calcel"
                                            placeholder="Enter">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="50%">
                                    <div class="box-name">Cancellation Fees</div>
                                    <div>
                                        <mat-select formControlName="percentage" class="text-box-calcel"
                                            placeholder="Select">
                                            <mat-option *ngFor="let fee of cancellationFees" [value]="fee.ID">
                                                {{ fee.NAME }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <!-- Cloneable Buttons for 'Hours' and 'Days' -->
                            <div class="buttons">
                                <div class="hour-button">
                                    <button class="example-button-row" mat-flat-button
                                        [ngClass]="{'active-button': condition.get('selectedButton').value === 'Hours'}"
                                        (click)="selectButton(i, 'Hours')">
                                        Hours
                                    </button>
                                    <button class="example-button-row" mat-flat-button
                                        [ngClass]="{'active-button': condition.get('selectedButton').value === 'Days'}"
                                        (click)="selectButton(i, 'Days')">
                                        Days
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Button to add a new window -->
                    <button type="button" mat-button class="add-occup-btn" (click)="addWindowCondition()">+Add Another
                        Window</button>
                </div>
                <div fxLayout="column" fxFlex="49%" fxLayoutGap="5px">
                    <div class="box-name">Select Rate Plan</div>
                    <div>
                        <mat-select class="text-box" formControlName="roomRatePlan" multiple
                            (selectionChange)="onRatePlanSelectionChange($event)">
                            <mat-select-trigger>
                                <mat-chip-listbox>
                                    <mat-chip *ngFor="let name of cancellationForm.controls['roomRatePlan'].value"
                                        [removable]="true" (removed)="removeRatePlan(name)" class="chip-label">
                                        {{ getRatePlanNameById(name) }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </mat-select-trigger>
                            <mat-option *ngFor="let ratePlan of roomRatePlan" [value]="ratePlan.ID">
                                {{ ratePlan.NAME }}
                            </mat-option>
                        </mat-select>

                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button *ngIf="cancellationForm.get('cancellationWindow').value === '1'" mat-stroked-button
                    class="save-btn" [disabled]="this.cancellationForm.invalid"
                    [ngClass]="this.cancellationForm.invalid ? 'button' : 'save-button'" (click)="addCancellation()">
                    Save
                </button>
                <button *ngIf="cancellationForm.get('cancellationWindow').value === '2'" mat-stroked-button
                    class="save-btn"
                    [disabled]="cancellationForm.controls['roomRatePlan'].invalid || cancellationForm.controls['name'].invalid || cancellationForm.controls['description'].invalid"
                    [ngClass]="this.cancellationForm.invalid ? 'button' : 'save-button'" (click)="addCancellation()">
                    Save
                </button>
                <button
                    *ngIf="cancellationForm.get('cancellationWindow').value != '1' && cancellationForm.get('cancellationWindow').value != '2' "
                    mat-stroked-button class="save-btn" disabled=true>
                    Save
                </button>
                <button mat-button class="cancel-btn" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>