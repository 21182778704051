<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <mat-dialog-actions dir="rtl">
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </mat-dialog-actions>
    </div>
    <div mat-dialog-content fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px" class="content">
        <div fxLayout="column" fxFlex="35%" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="10px">
                <div class="box-name">
                    Date of Service
                </div>
                <div>
                    <div class="text-box-date">
                        <input matInput [matDatepicker]="picker" [formControl]="serviceDate" class="text-box"
                            [min]="from_date" [max]="to_date" placeholder="Select date"
                            (dateChange)="getServiceTimingsByDate($event.value)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <div class="box-name">
                    Time of Service
                </div>
                <div class="slots-container">
                    <div class="slots-list">
                        <div *ngFor="let slot of slots; let i = index" (click)="getSlot(slot, i)">
                            <button *ngIf="slot['ENABLED'] == true" class="slots"
                                [ngClass]="{'selected-slot': i == currentSlotIndex}"
                                [disabled]="todaysDate == selectedDate && disableIdRange >= slot['SLOT_FROM']['ID']">
                                {{slot['SLOT_FROM']['TIME']}} - {{slot['SLOT_TO']['TIME']}}
                            </button>
                        </div>
                        <div *ngIf="slots.length == 0" class="summary-desc">
                            No slots available on the selected date
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div fxLayout="column" fxFlex="60%" *ngIf="currentSlotInfo.length != 0">
            <div fxLayout="column" fxLayoutGap="15px" class="summary-content">
                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex="70%" fxLayoutAlign="start start" fxLayoutGap="15px">
                        <div class="summary-head">{{currentSlotInfo.CATEGORY_ID.NAME}} -
                            {{currentSlotInfo.TYPE_ID.NAME}}</div>
                        <div class="summary-desc">{{currentSlotInfo.TYPE_ID.DESCRIPTION}}</div>
                        <div fxLayout="row" fxLayoutGap="25px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
                                <iconify-icon icon="material-symbols:calendar-today-outline-rounded"></iconify-icon>
                                <div class="summary-date">{{currentSlotInfo.DATE}}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
                                <iconify-icon
                                    icon="material-symbols:nest-clock-farsight-analog-outline-rounded"></iconify-icon>
                                <div class="summary-date">{{currentSlotInfo.SLOT_FROM.TIME}} -
                                    {{currentSlotInfo.SLOT_TO.TIME}}</div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30%">
                        <img src="{{bucketUrl + currentSlotInfo.TYPE_ID.ATTACHMENT_SOURCE}}"
                            onerror="this.src='assets/images/question_mark.svg';" class="summary-img">
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutGap="15px">
                    <div class="summary-head" style="padding-bottom: 8px;">Order Summary</div>
                    <div fxLayout="column" *ngFor="let offers of availableOffers; let i = index;">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutGap="10px">
                                <img src="assets/images/offer.png" />
                                <div class="summary-desc">{{offers.NAME}}</div>
                            </div>
                            <button [ngClass]="selectedIndex == i? 'applied-btn' : 'apply-btn'"
                                (click)="applyOffer(offers.DISCOUNT, i, offers.ID, currentSlotInfo.TYPE_ID.PRICE)">
                                <span>{{selectedIndex == i? 'Applied' : 'Apply'}}</span>
                            </button>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="item-rates">Item Total</div>
                        <div class="summary-rate">{{currencySymbol}} {{currentSlotInfo.TYPE_ID.PRICE}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="item-rates">Taxes $ Fees</div>
                        <div class="summary-rate">{{currencySymbol}} {{totalTaxAmount}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let tax of taxArray">
                        <div class="summary-desc">{{tax.NAME}} - {{tax.PERCENTAGE}}%</div>
                        <div class="summary-desc">{{currencySymbol}} {{tax.AMOUNT}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="item-rates">Offers</div>
                        <div class="summary-rate">{{currencySymbol}} {{offerPrice}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="item-rates">Total</div>
                        <div class="summary-rate" *ngIf="priceAfterOffers != 0">{{currencySymbol}} {{priceAfterOffers}}
                        </div>
                        <div class="summary-rate" *ngIf="priceAfterOffers == 0">{{currencySymbol}}
                            {{totalAmountPayable}}</div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" style="padding-top: 15px;">
                <button mat-button class="cancel-btn" (click)="cancel()">Cancel</button>
                <button mat-button class="book-btn" (click)="proceed(currentSlotInfo.ID)">Book</button>
            </div>
        </div>
    </div>
</div>