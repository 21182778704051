<mat-card>
  <mat-card-header class="card-header">
    <mat-card-title class="header">Ratings</mat-card-title>
    <div class="selector">
      <button mat-raised-button class="custom-button">
        <mat-select [value]="selected" class="selectionlist">
          <mat-option *ngFor="let period of periods" [value]="period.value" class="optionlist" >
            {{ period.label }}
          </mat-option>
        </mat-select>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    
    
    <div >
    <div class="rating-title">
      Overall Rating
    </div>
    <div class="rating-no">
      4.0
    </div>
    <div>
      <button mat-icon-button class="star" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" >
          <mat-icon>
            {{showIcon(i)}}
          </mat-icon>
        </button>
    </div>
    <div class="no-rating">
      Based on 23 Ratings
    </div>
    </div>
  </mat-card-content>
</mat-card>