import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-booking-notifications',
  templateUrl: './booking-notifications.component.html',
  styleUrls: ['./booking-notifications.component.scss']
})
export class BookingNotificationsComponent {

  mobile: boolean;
  loading: boolean = true;
  notificationList = [];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<BookingNotificationsComponent>,
    private adminService: AdminService,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.getNotifications();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getNotifications() {
    this.adminService.getNotification().subscribe({
      next: (data) => {
        this.loading = false;
        this.notificationList = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

}