import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-types',
  templateUrl: './about-types.component.html',
  styleUrls: ['./about-types.component.scss']
})
export class AboutTypesComponent {

  aboutDetailForm: FormGroup;
  imageName: any
  savedTypeFiles = {};
  source_url;
  typeById: any = [];
  loading: boolean = false;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  notCreate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AboutTypesComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService,
    public hotelService: HotelServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.aboutDetailForm = this.fb.group({
      image: [''],
      aboutName: ['', Validators.required],
      tag: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "edit_type") {
      this.notCreate = true;
      this.loading = true;
      this.hotelService.getAboutTypeById(this.data.typeID).subscribe({
        next: (res) => {
          this.loading = false;
          this.typeById = res.object;
          let image = res.object.ATTACHMENT_SOURCE;
          let selectedImage = image.split("/");
          this.imageName = selectedImage[selectedImage.length - 1];
          this.source_url = res.object.ATTACHMENT_SOURCE;
          this.aboutDetailForm.get("aboutName").setValue(res.object.NAME);
          this.aboutDetailForm.get("tag").setValue(res.object.TAG);
          this.aboutDetailForm.get("description").setValue(res.object.DESCRIPTION);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: "8",
      SECTION: 'type',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
      }
      let tempObjForUpload = {
        url: res['SIGNED_URL'],
        file: selectedFile
      }
      await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
        if (data) {
          this.loading = false;
          this.notify.showNotification(
            'File uploaded successfully',
            "top",
            (!!colorObj[200] ? colorObj[200] : "success"),
            200
          );
          this.imageName = files[0].name;
        }
      }).catch((err) => {
        this.handleError(err);
      });
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  saveTypes() {
    let name = this.aboutDetailForm.value.aboutName.trim();
    if (name.length == 0) {
      this.notify.showMessage(
        "Name should not be empty",
        "top",
        "warning"
      )
      this.aboutDetailForm.get("aboutName").setValue('');
    }
    else {
      this.loading = true;
      if (this.data.name == "add_type") {
        this.savedTypeFiles = {
          "NAME": this.aboutDetailForm.value.aboutName.trim(),
          "TAG": this.aboutDetailForm.value.tag,
          "DESCRIPTION": this.aboutDetailForm.value.description,
          "CATEGORY_ID": this.data.categoryID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111 //ToDo: temp value
        }
        this.hotelService.addAboutType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
      else if (this.data.name == "edit_type") {
        this.savedTypeFiles = {
          "ID": this.typeById.ID,
          "NAME": this.aboutDetailForm.value.aboutName.trim(),
          "TAG": this.aboutDetailForm.value.tag,
          "DESCRIPTION": this.aboutDetailForm.value.description,
          "CATEGORY_ID": this.typeById.CATEGORY.ID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111, //ToDo: this.typeById.POSITION
        }
        this.hotelService.updateAboutType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
    }
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

}