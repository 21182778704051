<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Edit Allocation':'Allocate Room'}}</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addAllocationForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="41px">
                <div>
                    <div class="box-name">User</div>
                    <div>
                        <mat-select formControlName="user" class="select" placeholder="Select User"
                            style="font-size: 13px;width: 97% !important;" appearance="fill"
                            (selectionChange)="getAllocatedRoomsByUserId($event.value)">
                            <ng-container *ngFor="let user of users">
                                <mat-option [value]="user.ID" *ngIf="user.USER_DISABLED != true">{{user.FIRST_NAME}}{{user.LAST_NAME}} | {{user.ROLE.NAME}}
                                    |
                                    <span *ngFor="let service of user.SERVICE;let last = last">
                                        {{service.NAME && last ? service.NAME: service.NAME + ','}}
                                    </span>
                                </mat-option>
                            </ng-container>

                        </mat-select>
                    </div>
                    <mat-error class="error"
                        *ngIf="addAllocationForm.controls['user'].touched && addAllocationForm.get('user').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div>
                    <div class="box-name">
                        Area
                    </div>
                    <div>
                        <mat-select formControlName="area" class="select" placeholder="Select Area"
                            style="font-size: 13px;width: 97% !important;" appearance="fill"
                            (selectionChange)="getRoomsByAreaId()">
                            <mat-option *ngFor="let area of areas" [value]="area.ID">
                                {{area.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <mat-error class="error"
                        *ngIf="addAllocationForm.controls['area'].touched && addAllocationForm.get('area').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div>
                    <div class="box-name">
                        Rooms
                    </div>
                    <div>
                        <mat-select class="select" formControlName="rooms" placeholder="Select Rooms" multiple
                            style="font-size: 13px;">
                            <mat-select-trigger>
                                <mat-chip-listbox>
                                    <mat-chip #chip *ngFor="let room of addAllocationForm.controls['rooms'].value"
                                        [removable]="true" (removed)="removeRooms(room)">
                                        {{ room}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </mat-select-trigger>
                            <mat-option *ngFor="let room of rooms" value="{{room.NAME}}">
                                {{room.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <mat-error class="error"
                        *ngIf="addAllocationForm.controls['rooms'].touched && addAllocationForm.get('rooms').hasError('required')">
                        Required
                    </mat-error>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button mat-stroked-button class="button" (click)="cancel()">
                    Cancel
                </button>
                <button mat-stroked-button [ngClass]="this.addAllocationForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addAllocationForm.invalid" (click)="addAllocation()">
                    Save
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</div>