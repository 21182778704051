import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-add-edit-diningfilter',
  templateUrl: './add-edit-diningfilter.component.html',
  styleUrls: ['./add-edit-diningfilter.component.scss']
})
export class AddEditDiningfilterComponent {

  diningTypeForm: FormGroup;
  savedFilters = {};
  filterById: any = [];
  loading: boolean = false;
  mobile: boolean;
  notCreate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditDiningfilterComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    public adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.diningTypeForm = this.fb.group({
      diningType: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "update_filter") {
      this.notCreate = true;
      this.loading = true;
      this.adminService.getDiningFilterById(this.data.ID).subscribe({
        next: (res) => {
          this.loading = false;
          this.filterById = res.object;
          this.diningTypeForm.get("diningType").setValue(res.object.NAME);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  saveCategories() {
    this.loading = true;
    if (this.data.name == "add_filter") {
      this.savedFilters = {
        "NAME": this.diningTypeForm.value.diningType
      }
      this.adminService.createDiningFilter(this.savedFilters).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close('refresh');
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
          this.dialogRef.close();
        }
      })
    }
    else if (this.data.name == "update_filter") {
      this.savedFilters = {
        "ID": this.data.ID,
        "NAME": this.diningTypeForm.value.diningType
      }
      this.adminService.updateDiningFilter(this.savedFilters).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close('refresh');
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
          this.dialogRef.close();
        }
      })
    }
  }

}