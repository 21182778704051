<div class="dashboard-container" *ngIf="mobile && userInfo.roleId != 4" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div *ngIf="!isNewRequest">
            <div fxLayout="row">
                <mat-tab-group class="rating-tabs" (selectedTabChange)="onTabChanged($event)" [(selectedIndex)]="selectedTab">
                    <mat-tab label="Escalated Requests">
                        <mat-card class="table-card">
                            <div fxLayout="column" class="content">
                                <div fxLayout="row" fxLayoutAlign="center" style="padding: 10px;">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20%" fxLayoutGap="9px">
                                        <div class="header">Escalated Request</div>
                                        <div>
                                            <button class="refresh-btn"><span class="material-symbols-outlined"
                                                (click)="refresh()" style="font-size: 19px;">refresh</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="11px" fxLayoutAlign="end center">
                                        <mat-select class="select-service" placeholder="Select Service"
                                            [formControl]="service">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-form-field class="from-date" appearance="outline">
                                            <input matInput [formControl]="reqFromDate" placeholder="From"
                                                [matDatepicker]="picker1" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="to-date" appearance="outline">
                                            <input matInput [formControl]="reqToDate" placeholder="To"
                                                [matDatepicker]="picker2" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                        <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end center">
                                            <div style="margin-right: -20px;">
                                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                                                <input matInput [formControl]="searchVal" class="menu-search"
                                                    placeholder="Search">
                                            </div>
                                            <div>
                                                <button class="search-btn" (click)="search()">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-scroll">
                                    <table mat-table [dataSource]="dataSource">
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:15%"> Date </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div fxLayout="column">
                                                    <div>{{element.DATE | date:'dd-MM-YYYY'}}</div>
                                                    <div *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                        ({{element.SLOT_ID?.SLOT_FROM?.TIME}} - {{element.SLOT_ID?.SLOT_TO?.TIME}})
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="room">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Room No </th>
                                            <td mat-cell *matCellDef="let element">{{element.ROOM_ID?.NAME}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:25%"> Service </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{element.CATEGORY_ID?.NAME}} - {{element.CATEGORY_TYPE_ID?.NAME}}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS != null">
                                                    <ng-container *ngFor="let item of element.ITEMS; let last = last;">
                                                        <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}}
                                                        {{last ? '': ','}}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS.length == 0">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}</i></u>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 2">
                                                    <span fxLayout="row" class="service-info">
                                                        <span>
                                                            <u><i>{{element.SERVICE_ID?.NAME}}:</i></u>
                                                            <ng-container *ngFor="let item of element?.ITEMS; let last = last;">
                                                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                                                            </ng-container>
                                                        </span>
                                                        <mat-icon class="icon" matTooltip="{{element.INSTRUCTION}}" *ngIf="element.INSTRUCTION">info</mat-icon>
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Priority </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.PRIORITY?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assignee">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"
                                                [hidden]="userInfo.roleId == 4"> Assignee </th>
                                            <td mat-cell *matCellDef="let element" [hidden]="userInfo.roleId == 4">
                                                {{element.ASIGNEE?.FIRST_NAME}} {{element.ASIGNEE?.LAST_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%">
                                                Status </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.REQUEST_STATUS?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="button">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%">
                                                Action </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button class="search-btn" (click)="updateBooking(element, 'escalated')" [disabled]="accessType == 2">Update</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <!-- <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator> -->
                            </div>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="Live Requests">
                        <mat-card class="table-card">
                            <div fxLayout="column" class="content">
                                <div fxLayout="row" fxLayoutAlign="center" style="padding: 10px;">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20%" fxLayoutGap="9px">
                                        <div class="header">Live Request</div>
                                        <div>
                                            <button class="refresh-btn"><span class="material-symbols-outlined"
                                                (click)="refresh()" style="font-size: 19px;">refresh</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="11px" fxLayoutAlign="end center">
                                        <mat-select class="select-service" placeholder="Select Service"
                                            [formControl]="service">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-form-field class="from-date" appearance="outline">
                                            <input matInput [formControl]="reqFromDate" placeholder="From"
                                                [matDatepicker]="picker3" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="to-date" appearance="outline">
                                            <input matInput [formControl]="reqToDate" placeholder="To"
                                                [matDatepicker]="picker4" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker4"></mat-datepicker-toggle>
                                            <mat-datepicker #picker4></mat-datepicker>
                                        </mat-form-field>
                                        <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end center">
                                            <div style="margin-right: -20px;">
                                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                                                <input matInput [formControl]="searchVal" class="menu-search"
                                                    placeholder="Search">
                                            </div>
                                            <div>
                                                <button class="search-btn" (click)="search()">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-scroll">
                                    <table mat-table [dataSource]="dataSource">
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:15%"> Date </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div fxLayout="column">
                                                    <div>{{element.DATE | date:'dd-MM-YYYY'}}</div>
                                                    <div *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                        ({{element.SLOT_ID?.SLOT_FROM?.TIME}} - {{element.SLOT_ID?.SLOT_TO?.TIME}})
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="room">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Room No </th>
                                            <td mat-cell *matCellDef="let element">{{element.ROOM_ID?.NAME}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:25%"> Service </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{element.CATEGORY_ID?.NAME}} - {{element.CATEGORY_TYPE_ID?.NAME}}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS != null">
                                                    <ng-container *ngFor="let item of element.ITEMS; let last = last;">
                                                        <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}}
                                                        {{last ? '': ','}}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS.length == 0">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}</i></u>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 2">
                                                    <span fxLayout="row" class="service-info">
                                                        <span>
                                                            <u><i>{{element.SERVICE_ID?.NAME}}:</i></u>
                                                            <ng-container *ngFor="let item of element?.ITEMS; let last = last;">
                                                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                                                            </ng-container>
                                                        </span>
                                                        <mat-icon class="icon" matTooltip="{{element.INSTRUCTION}}" *ngIf="element.INSTRUCTION">info</mat-icon>
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Priority </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.PRIORITY?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assignee">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"
                                                [hidden]="userInfo.roleId == 4"> Assignee </th>
                                            <td mat-cell *matCellDef="let element" [hidden]="userInfo.roleId == 4">
                                                {{element.ASIGNEE?.FIRST_NAME}} {{element.ASIGNEE?.LAST_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%">
                                                Status </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.REQUEST_STATUS?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="button">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%">
                                                Action </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button class="search-btn" (click)="updateBooking(element, 'live')" [disabled]="accessType == 2">Update</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <!-- <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator> -->
                            </div>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="Completed Requests">
                        <mat-card class="table-card">
                            <div fxLayout="column" class="content">
                                <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2%" style="padding: 10px;">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="9px">
                                        <div class="header">
                                            Completed Request
                                        </div>
                                        <div>
                                            <button class="refresh-btn"><span class="material-symbols-outlined"
                                                (click)="refresh()" style="font-size: 19px;">refresh</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                                        <mat-select class="select-service" placeholder="Select Service"
                                            [formControl]="service">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-form-field class="from-date" appearance="outline">
                                            <input matInput [formControl]="reqFromDate" placeholder="From"
                                                [matDatepicker]="picker5" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker5"></mat-datepicker-toggle>
                                            <mat-datepicker #picker5></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="to-date" appearance="outline">
                                            <input matInput [formControl]="reqToDate" placeholder="To"
                                                [matDatepicker]="picker6" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker6"></mat-datepicker-toggle>
                                            <mat-datepicker #picker6></mat-datepicker>
                                        </mat-form-field>
                                        <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end center">
                                            <div style="margin-right: -20px;">
                                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                                                <input matInput [formControl]="searchVal" class="menu-search"
                                                    placeholder="Search">
                                            </div>
                                            <div>
                                                <button class="search-btn" (click)="search()">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-scroll">
                                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:15%"> Date </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div fxLayout="column">
                                                    <div>{{element.DATE | date:'dd-MM-YYYY'}}</div>
                                                    <div *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                        ({{element.SLOT_ID?.SLOT_FROM?.TIME}} - {{element.SLOT_ID?.SLOT_TO?.TIME}})
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="room">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Room No </th>
                                            <td mat-cell *matCellDef="let element">{{element.ROOM_ID?.NAME}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:25%"> Service </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID != 6 && element.SERVICE_ID.ID != 2">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{element.CATEGORY_ID?.NAME}} - {{element.CATEGORY_TYPE_ID?.NAME}}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS != null">
                                                    <span *ngFor="let item of element.ITEMS; let last = last;">
                                                        <u><i>{{element.SERVICE_ID?.NAME}}:</i></u> {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}}
                                                        {{last ? '': ','}}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 6 && element.ITEMS.length == 0">
                                                    <u><i>{{element.SERVICE_ID?.NAME}}</i></u>
                                                </ng-container>
                                                <ng-container *ngIf="element.SERVICE_ID.ID == 2">
                                                    <span fxLayout="row" class="service-info">
                                                        <span>
                                                            <u><i>{{element.SERVICE_ID?.NAME}}:</i></u>
                                                            <ng-container *ngFor="let item of element?.ITEMS; let last = last;">
                                                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                                                            </ng-container>
                                                        </span>
                                                        <mat-icon class="icon" matTooltip="{{element.INSTRUCTION}}" *ngIf="element.INSTRUCTION">info</mat-icon>
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%;"> Priority </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.PRIORITY?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assignee">
                                            <th mat-header-cell *matHeaderCellDef [hidden]="userInfo.roleId ==  4"
                                                class="table-header" style="width:15%"> Assignee </th>
                                            <td mat-cell *matCellDef="let element" [hidden]="userInfo.roleId ==  4">
                                                {{element.ASIGNEE?.FIRST_NAME}} {{element.ASIGNEE?.LAST_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> Status </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.REQUEST_STATUS?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="button">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%">
                                                Action </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button class="search-btn" (click)="updateBooking(element, 'completed')">View</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <!-- <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator> -->
                            </div>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>
                <div class="btn-toolbar" fxLayout="row" *ngIf="userInfo.roleId != 4">
                    <button mat-stroked-button class="add-request-btn" (click)="createRequest()" [disabled]="accessType == 2">
                        New Request
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isNewRequest">
            <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                        <div class="back-arrow" (click)="backToRequests()">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="request-head">
                            Create New Request
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="request-subhead">Guest Details</div>
                    <form [formGroup]="addRequestForm">
                        <div mat-dialog-content fxLayout="column" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutGap="30px">
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Booking ID
                                    </div>
                                    <div>
                                        <mat-select panelClass="codeClass" class="text-box"
                                            formControlName="bookingId" name="booking" placeholder="Select Booking ID"
                                            (selectionChange)="changeBookingId($event.value)">
                                            <mat-select-trigger>{{reservation}}</mat-select-trigger>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search" [formControl]="booking" (input)="filterBookingId()"
                                                    noEntriesFoundLabel="No Matching Found">
                                                    <mat-icon ngxMatSelectSearchClear
                                                        class="close">close</mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let id of filterBookingIds" [value]="id.ID">
                                                {{id.RESERVATION}} | {{id.HOST.FIRST_NAME}} {{id.HOST.LAST_NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Room No.
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="roomNo"
                                            placeholder="Select Room">
                                            <mat-option *ngFor="let room of roomsList"
                                                [value]="room.ROOM?.ID">{{room.ROOM?.NAME}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Service
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="service"
                                            placeholder="Select Service"
                                            (selectionChange)="selectRequestService($event.value)">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="30px">
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Guest Name
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="guestName"
                                            placeholder="Enter Guest Name">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Created By
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="createdBy"
                                            placeholder="Created By" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="request-subhead">What are you looking for?</div>
                    <mat-card class="category-card" *ngIf="selectedService != 2">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <div style="align-self: center;" *ngIf="serviceCategories.length != 0">
                                <mat-button (click)="categoryScrollLeft()" class="scroll-btn">
                                    <img src="assets/images/arrow_back.svg">
                                </mat-button>
                            </div>
                            <div #categoryContent fxLayout="row" fxLayoutGap="30px" class="banners"
                                *ngIf="serviceCategories.length != 0">
                                <div *ngFor="let category of serviceCategories; let i=index;"
                                    [ngStyle]="{'border-bottom': currentActive === i ? '5px solid #000;' : '1px solid #E8E8E8' }"
                                    fxLayout="column" fxLayoutAlign="center center"
                                    (click)="displayDetails(i, category.ID)">
                                    <div fxLayout="row" id="category-images">
                                        <img src="{{bucketUrl + category.ATTACHMENT_SOURCE}}"
                                            onerror="this.src='assets/images/question_mark.svg';" class="img_preview">
                                    </div>
                                    <div style="padding:4px" class="category-name"
                                        [ngStyle]="{'font-weight': currentActive === i ? '600' : '400' }">
                                        {{category.NAME}}</div>
                                </div>
                            </div>
                            <div style="align-self: center;" *ngIf="serviceCategories.length != 0">
                                <mat-button (click)="categoryScrollRight()" class="scroll-btn">
                                    <img src="assets/images/arrow_forward.svg">
                                </mat-button>
                            </div>
                            <div class="text-message" *ngIf="serviceCategories.length == 0">
                                <span>
                                    <br>
                                    No categories available ....
                                    <br>
                                </span>
                            </div>
                        </div>
                    </mat-card>
                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="selectedService == 2">
                        <div style="align-self: center;" *ngIf="housekeepingServices.length != 0">
                            <mat-button (click)="categoryScrollLeft()" class="scroll-btn">
                                <img src="assets/images/arrow_back.svg">
                            </mat-button>
                        </div>
                        <div #categoryContent fxLayout="row" class="banners" *ngIf="housekeepingServices.length != 0">
                            <div fxLayout="row" fxLayoutGap="20px">
                                <mat-card *ngFor="let service of housekeepingServices; let i=index;"
                                    class="service-card">
                                    <div class="service-name">{{service.NAME}}</div>
                                    <mat-checkbox class="checkbox-area" (change)="selectHouseKeepingServices($event, service.ID)"></mat-checkbox>
                                </mat-card>
                            </div>
                        </div>
                        <div style="align-self: center;" *ngIf="housekeepingServices.length != 0">
                            <mat-button (click)="categoryScrollRight()" class="scroll-btn">
                                <img src="assets/images/arrow_forward.svg">
                            </mat-button>
                        </div>
                        <div class="text-message" *ngIf="housekeepingServices.length == 0">
                            <span>
                                <br>
                                No services available ....
                                <br>
                            </span>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px"
                        *ngIf="selectedService == 2 && housekeepingServices.length != 0">
                        <div class="box-name">
                            Add Instruction
                        </div>
                        <div>
                            <input type="text" class="text-box" [formControl]="serviceInstruction"
                                placeholder="Enter Instruction" maxlength="200">
                        </div>
                    </div>
                    <div *ngIf="selectedService == 2 && housekeepingServices.length != 0">
                        <button mat-stroked-button class="submit-request" (click)="submitHousekeepingRequest()" [disabled]="requiredServices.length == 0 || this.addRequestForm.invalid">
                            Submit the Request
                        </button>
                    </div>
                    <div *ngIf="selectedService == 6 && serviceItems.length != 0 && canOrder" fxLayoutAlign="end center">
                        <button class="book-dining-btn" (click)="bookDining()">
                            Book
                        </button>
                    </div>
                    <mat-card class="items-card" *ngIf="serviceCategories.length != 0 && selectedService != 2">
                        <ng-container *ngIf="serviceItems.length != 0">
                            <div *ngFor="let item of serviceItems; let i = index" style="padding-bottom: 2%;">
                                <div fxLayout="column" fxLayoutGap="10px">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="column" fxFlex="75%" fxLayoutGap="7px">
                                            <div class="item-detail" style="font-weight: 500;">{{item.NAME}}</div>
                                            <div class="item-detail" style="font-weight: 400;">{{currency}}
                                                {{item.PRICE}}</div>
                                            <div class="item-desc">{{item.DESCRIPTION}}</div>
                                        </div>
                                        <div fxLayout="column" fxFlex="25%" fxLayoutAlign="center center">
                                            <img src="{{bucketUrl + item.ATTACHMENT_SOURCE}}"
                                                onerror="this.src='assets/images/question_mark.svg';"
                                                class="img_preview">
                                            <button class="book-btn" *ngIf="selectedService != 6" [disabled]="this.addRequestForm.invalid" (click)="bookService(item.ID)">Book</button>
                                            <ng-container *ngIf="selectedService == 6">
                                                <button class="book-btn" *ngIf="isIdPresent(item.ID); else printVal" [disabled]="this.addRequestForm.invalid"
                                                    (click)="addItem(i, item)">Add</button>
                                                <ng-template #printVal>
                                                    <div class="inventory" fxLayout="row">
                                                        <button class="value-button" (click)="reduceQuantity(item.ID)">-</button>
                                                        <div class="item-value">{{getQuantity(item.ID)}}</div>
                                                        <button class="value-button" (click)="addQuantity(item.ID)">+</button>
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                        </ng-container>
                        <div class="text-message" *ngIf="serviceItems.length == 0">
                            <span>
                                <br>
                                No types available ....
                                <br>
                            </span>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dashboard-container" fxLayout="column" fxLayoutGap="20px" *ngIf="mobile && userInfo.roleId == 4">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <mat-tab-group class="rating-tabs" (selectedTabChange)="onTabChanged($event)" [(selectedIndex)]="selectedTab">
        <mat-tab label="Escalated Requests">
            <div fxLayout="column" class="tab-body">
                <input matInput [formControl]="searchVal" class="menu-search-staff" placeholder="Search">
                <mat-icon matSuffix class="search_icon-staff">search</mat-icon>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutGap="1%" style="width:100%">
                        <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center start">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="22%" style="font-weight: 500; font-size: 15px;">From : </div>
                                <mat-form-field class="from-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqFromDate" placeholder="From" [matDatepicker]="pickerMob1" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob1"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob1></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="24%" style="font-weight: 500; font-size: 15px;">To : </div>
                                <mat-form-field class="to-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqToDate" placeholder="To" [matDatepicker]="pickerMob2" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob2"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="start end">
                            <div>
                                <button class="clear-btn-staff" (click)="refresh()">
                                    Clear
                                </button>
                            </div>
                            <div>
                                <button class="search-btn-staff" (click)="search()">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="card" fxLayout="column" fxLayoutGap="20px" *ngFor="let booking of escalatedBookings">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                        <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="1%">
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Room no:</div>
                                <div class="room">{{booking.ROOM_ID?.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Status:</div>
                                <div class="room">{{booking.REQUEST_STATUS.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Date:</div>
                                <div class="room">{{booking.DATE | date:'d MMMM y'}}</div>
                                <div class="room" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                                    ({{booking.SLOT_ID?.SLOT_FROM?.TIME}} - {{booking.SLOT_ID?.SLOT_TO?.TIME}})
                                </div>
                            </div>
                        </div>
                        <div fxFlex="6%" fxLayoutAlign="start center">
                            <button mat-button [matMenuTriggerFor]="menu">...</button>
                            <mat-menu #menu="matMenu" class="mat-menu">
                                <mat-toolbar class="toolbar">
                                    <div style="color:#262020">Acknowledge with updates</div>
                                    <div><mat-icon class="close-icon">
                                            close
                                        </mat-icon></div>
                                </mat-toolbar>
                                <mat-radio-group (click)="$event.stopPropagation()" class="radio-group"
                                    [formControl]="staffBookingStatus">
                                    <mat-radio-button *ngFor="let status of status" value="{{status.ID}}" class="radio-btn">
                                        <span class="radio-label">
                                            {{status.NAME}}
                                        </span>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <button class="update-btn" [disabled]="staffBookingStatus.value.length == 0 || accessType == 2"
                                    (click)="save(booking.ID, booking.SERVICE_ID.ID)">Send Update</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.SERVICE_ID.NAME}} : {{booking.CATEGORY_TYPE_ID?.CATEGORY.NAME}}
                        </span>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.CATEGORY_TYPE_ID?.DESCRIPTION}}
                        </span>
                    </div>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 6 && booking.ITEMS != null">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 2">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                        <div fxLayout="row" class="service-header">
                            {{booking.INSTRUCTION}}
                        </div>
                    </ng-container>
                </mat-card>
                <mat-card *ngIf="escalatedBookings.length == 0" class="card">
                    No Data Found
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Live Requests">
            <div fxLayout="column" class="tab-body">
                <input matInput [formControl]="searchVal" class="menu-search-staff" placeholder="Search">
                <mat-icon matSuffix class="search_icon-staff">search</mat-icon>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutGap="1%" style="width:100%">
                        <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center start">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="22%" style="font-weight: 500; font-size: 15px;">From : </div>
                                <mat-form-field class="from-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqFromDate" placeholder="From" [matDatepicker]="pickerMob3" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob3"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob3></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="24%" style="font-weight: 500; font-size: 15px;">To : </div>
                                <mat-form-field class="to-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqToDate" placeholder="To" [matDatepicker]="pickerMob4" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob4"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob4></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="start end">
                            <div>
                                <button class="clear-btn-staff" (click)="refresh()">
                                    Clear
                                </button>
                            </div>
                            <div>
                                <button class="search-btn-staff" (click)="search()">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="card" fxLayout="column" fxLayoutGap="20px" *ngFor="let booking of liveBookings">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                        <div fxFlex="90%" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="1%">
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Room no:</div>
                                <div class="room">{{booking.ROOM_ID?.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Status:</div>
                                <div class="room">{{booking.REQUEST_STATUS.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Date:</div>
                                <div class="room">{{booking.DATE | date:'d MMMM y'}}</div>
                                <div class="room" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                                    ({{booking.SLOT_ID?.SLOT_FROM?.TIME}} - {{booking.SLOT_ID?.SLOT_TO?.TIME}})
                                </div>
                            </div>
                        </div>
                        <div fxFlex="6%" fxLayoutAlign="start center">
                            <button mat-button [matMenuTriggerFor]="menu">...</button>
                            <mat-menu #menu="matMenu" class="mat-menu">
                                <mat-toolbar class="toolbar">
                                    <div style="color:#262020">Acknowledge with updates</div>
                                    <div><mat-icon class="close-icon">
                                            close
                                        </mat-icon></div>
                                </mat-toolbar>
                                <mat-radio-group (click)="$event.stopPropagation()" class="radio-group"
                                    [formControl]="staffBookingStatus">
                                    <mat-radio-button *ngFor="let status of status" value="{{status.ID}}" class="radio-btn">
                                        <span class="radio-label">
                                            {{status.NAME}}
                                        </span>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <button class="update-btn" [disabled]="staffBookingStatus.value.length == 0 || accessType == 2"
                                    (click)="save(booking.ID, booking.SERVICE_ID.ID)">Send Update</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.SERVICE_ID.NAME}} : {{booking.CATEGORY_TYPE_ID?.CATEGORY.NAME}}
                        </span>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.CATEGORY_TYPE_ID?.DESCRIPTION}}
                        </span>
                    </div>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 6 && booking.ITEMS != null">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 2">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                        <div fxLayout="row" class="service-header">
                            {{booking.INSTRUCTION}}
                        </div>
                    </ng-container>
                </mat-card>
                <mat-card *ngIf="liveBookings.length == 0" class="card">
                    No Data Found
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Completed Requests">
            <div fxLayout="column" class="tab-body">
                <input matInput [formControl]="searchVal" class="menu-search-staff" placeholder="Search">
                <mat-icon matSuffix class="search_icon-staff">search</mat-icon>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutGap="1%" style="width:100%">
                        <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center start">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="22%" style="font-weight: 500; font-size: 15px;">From : </div>
                                <mat-form-field class="from-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqFromDate" placeholder="From" [matDatepicker]="pickerMob5" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob5"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob5></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="24%" style="font-weight: 500; font-size: 15px;">To : </div>
                                <mat-form-field class="to-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqToDate" placeholder="To" [matDatepicker]="pickerMob6" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob6"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob6></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="start end">
                            <div>
                                <button class="clear-btn-staff" (click)="refresh()">
                                    Clear
                                </button>
                            </div>
                            <div>
                                <button class="search-btn-staff" (click)="search()">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="card" fxLayout="column" fxLayoutGap="20px" *ngFor="let booking of completedBookings">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="1%">
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Room no:</div>
                                <div class="room">{{booking.ROOM_ID?.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Status:</div>
                                <div class="room">{{booking.REQUEST_STATUS.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Date:</div>
                                <div class="room">{{booking.DATE | date:'d MMMM y'}}</div>
                                <div class="room" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                                    ({{booking.SLOT_ID?.SLOT_FROM?.TIME}} - {{booking.SLOT_ID?.SLOT_TO?.TIME}})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.SERVICE_ID.NAME}} : {{booking.CATEGORY_TYPE_ID?.CATEGORY.NAME}}
                        </span>
                    </div>
                    <div fxLayout="row" *ngIf="booking.SERVICE_ID.ID != 6 && booking.SERVICE_ID.ID != 2">
                        <span class="service-header">
                            {{booking.CATEGORY_TYPE_ID?.DESCRIPTION}}
                        </span>
                    </div>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 6 && booking.ITEMS != null">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.CATEGORY_ID?.NAME}} - {{item.CATEGORY_TYPE_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="booking.SERVICE_ID.ID == 2">
                        <span fxLayout="row" class="service-header">
                            {{booking.SERVICE_ID?.NAME}} -
                            <ng-container *ngFor="let item of booking?.ITEMS; let last = last;">
                                {{item.ITEM_ID?.NAME}} {{last ? '': ','}}
                            </ng-container>
                        </span>
                        <div fxLayout="row" class="service-header">
                            {{booking.INSTRUCTION}}
                        </div>
                    </ng-container>
                </mat-card>
                <mat-card *ngIf="completedBookings.length == 0" class="card">
                    No Data Found
                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>