<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div>
    <img src="../../../../assets/images/warning.png">
  </div>
  <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
    <p>Dear</p>
    <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
  </span>
  <div class="dasboard-subheading">
    <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
      functionalities of the system</p>
  </div>
  <div>
    <button class="back-button" (click)="logout()">Go Back</button>
  </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
  <div class="right-panel">
    <div class="overlay" *ngIf="loading">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 2% 2%;">
      <div fxFlex="25%" class="Policy">
        Privacy Policy
      </div>
    </div>
    <div class="input-container">
      <form [formGroup]="editorForm">
        <div class="form-group">
          <quill-editor *ngIf="this.showPrivacyPolicy" [style]="editorStyle" [modules]="config" formControlName="editor"
            (onContentChanged)="textChangedPolicy($event)"></quill-editor>
          <p style="font-size: 14px;" [innerHTML]="getData" *ngIf="!this.showPrivacyPolicy"></p>
        </div>
        <div>
          <div class="count" *ngIf="this.showPrivacyPolicy">{{textLength}} / 4800</div>
          <div class="bottom-buttons" *ngIf="this.showPrivacyPolicy">
            <button mat-stroked-button class="cancel-button" (click)="onCancel()">
              Cancel
            </button>
            <button mat-stroked-button class="save-button" style="color: rgb(255, 255, 255);" (click)="savePolicy()">
              Save
            </button>
          </div>
          <div class="bottom-buttons" *ngIf="!this.showPrivacyPolicy">
            <button mat-stroked-button class="edit-button" (click)="onEdit()" [disabled]="accessType == 2">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-stroked-button class="edit-button" (click)="openDialog()" [disabled]="accessType == 2">
              <mat-icon>delete_outline</mat-icon>
              Delete
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>