<mat-card>
  <mat-card-header class="card-header">
    <mat-card-title class="header">SLA</mat-card-title>
    <div class="selector">
      <button mat-raised-button class="custom-button">
        <mat-select [value]="selected" class="selectionlist">
          <mat-option *ngFor="let period of periods" [value]="period.value" class="optionlist" >
            {{ period.label }}
          </mat-option>
        </mat-select>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="content">

    <div class="data-container">
      <!-- Left Side: Data with colored boxes -->
      <div class="data-items">
        <div class="data-item">
          <span class="text">Total Guest Requests</span>
          <div class="color-box" style="background-color: #47D6FF;">56</div>
        </div>
        <div class="data-item">
          <span class="text">SLA Violated</span><div class="color-box" style="background-color: #D40004;">20</div>
        </div>
        <div class="data-item">
          <span class="text">Success Rate</span><div class="color-box" style="background-color: #01869E;">10</div>
        </div>
        <div class="data-item">
          <span class="text">CSAT</span><div class="color-box" style="background-color: #FFD662;">40</div>
        </div>
      </div>

      <div class="right-chart">
        <div id="chartContainer" class="chart-section"></div></div>
    </div>
  </mat-card-content>

</mat-card>