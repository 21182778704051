<div fxLayout="column" class="update-content">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <mat-dialog-actions dir="rtl">
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </mat-dialog-actions>
    </div>
    <form [formGroup]="updateBookingForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutGap="45px">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Service
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="service" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Created By
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="createdBy" readonly>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Assign to
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" [panelClass]="isLoading" formControlName="assignee"
                            [value]="!!requestDetails['ASSIGNEE'] && requestDetails['ASSIGNEE'].ID"
                            placeholder="{{requestDetails['ASSIGNEE']?.FIRST_NAME}} {{requestDetails['ASSIGNEE']?.LAST_NAME}}"
                            (click)="openAssigneeSelect()" readonly="data.requestType == 'completed'">
                            <mat-option style="display: none;"></mat-option>
                            <ng-container *ngFor="let user of selectedAssignee">
                                <mat-option *ngIf="user.USER_DISABLED != true" [value]="user.ID">
                                    <span>{{user.FIRST_NAME}} {{user.LAST_NAME}}</span>
                                </mat-option>
                            </ng-container>
                            <mat-label *ngIf="isLoading">
                                <div>
                                    <mat-spinner class="assignee-spinner" diameter="20"></mat-spinner>
                                </div>
                            </mat-label>
                        </mat-select>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Priority
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" appearance="fill" formControlName="priority">
                            <mat-option *ngFor="let priority of priorityList" [value]="priority.ID">
                                {{priority.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Status
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" appearance="fill" formControlName="status">
                            <mat-option *ngFor="let status of statusList" [value]="status.ID">
                                {{status.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <input type="text" [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" formControlName="description" maxlength="200">
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Upload
                    </div>
                    <div>
                        <div class="upload-box" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px"
                            appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                            <div>
                                <input type="file" class="doc_file" id="doc_file" style="display: none" #fileInput hidden
                                    accept=".jpg,.jpeg,.png" (change)="onFileSelected($event.target.files)" />
                                <iconify-icon icon="material-symbols:cloud-upload" (click)="fileInput.click()"
                                    class="upload-icon"></iconify-icon>
                            </div>
                            <div fxFlex="45%" class="upload-text" (click)="fileInput.click()">
                                <span class="upload-text1">Drop a file here or </span>
                                <span class="upload-text2">browse to upload jpeg, jpg, png</span>
                            </div>
                        </div>
                        <div class="file-name" (click)="preview(source_url)">
                            {{imageName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" *ngIf="data.requestType != 'completed'">
            <button mat-stroked-button class="cancel-btn" (click)="cancel()">Cancel</button>
            <button mat-stroked-button class="save-btn" [disabled]="this.updateBookingForm.invalid"
                (click)="updateBooking()">Update</button>
        </div>
    </form>
</div>