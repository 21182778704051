<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="30px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class="room-head">
                    Arrival
                </div>
                <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end center">
                    <mat-icon matPrefix class="search_icon">search</mat-icon>
                    <input matInput [formControl]="searchVal" class="menu-search" placeholder="Search">
                </div>
            </div>
            <mat-card class="reserve-card" *ngIf="!loading && zeroReservations">
                <div class="reserve-detail" style="text-align: center;">No Records Found</div>
            </mat-card>
            <div fxLayout="row" fxLayoutGap="25px" *ngIf="!loading && !zeroReservations">
                <div fxFlex="60%" class="content" fxLayout="column" fxLayoutGap="15px" ngxInfiniteScroller strategy="scrollingToBottom"
                    (onScrollDown)="onScrollDown()">
                    <mat-card class="reserve-card" fxLayout="column" fxLayoutGap="10px"
                        [ngStyle]="{'border': currentActive === i ? '1px solid #BDBDBD' : none }"
                        *ngFor="let arrival of arrivalsList; let i = index" (click)="getReservationDetails(i)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <ng-container *ngFor="let book of arrival.USER">
                                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start" *ngIf="book.USER_TYPE.ID == 1">
                                    <div class="reserve-detail">Guest Name:<span>{{book.FIRST_NAME}} {{book.LAST_NAME}}</span></div>
                                    <div class="reserve-detail">Email ID:<span>{{book.EMAIL_ID}}</span></div>
                                    <div class="reserve-detail">Phone Number:<span>{{book.MOBILE}}</span></div>
                                </div>
                            </ng-container>
                            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center end">
                                <!-- <div class="service-detail" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center end">
                                    <img src="../../../../../assets/images/broom.png">
                                    <div>Room clean - </div>
                                    <span>Yes</span>
                                </div> -->
                                <div class="service-detail" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center end">
                                    <img src="../../../../../assets/images/money-bag.png">
                                    <div>Security deposit - </div>
                                    <span>{{currency}} {{arrival.SECURITY_DEPOSITE * arrival.BOOKING.ROOMS}}</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button mat-button class="save-btn" (click)="updateReservation(arrival.BOOKING.ID)">Check-In</button>
                        </div>
                    </mat-card>
                </div>
                <div fxFlex="40%">
                    <mat-card class="info-card" fxLayout="column" fxLayoutGap="20px" *ngIf="reservationDetails.length != 0">
                        <div class="info-header" fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutGap="10px">
                                <div class="reserve-head">Reservation Details</div>
                                <div fxLayout="row" fxLayoutGap="5px" style="cursor: pointer;" (click)="editReservation(reservationDetails[0].BOOKING?.ID)">
                                    <img src="../../../../../assets/images/edit-light.png">
                                    <div class="edit-text">Edit</div>
                                </div>
                            </div>
                        </div>
                        <div class="info-body" fxLayout="column" fxLayoutGap="15px">
                            <div class="reserve-detail">Reservation ID:<span>{{reservationDetails[0].BOOKING?.RESERVATION}}</span></div>
                            <div class="reserve-detail">CheckIn - CheckOut:<span>{{reservationDetails[0].BOOKING?.FROM_DATE}} - {{reservationDetails[0].BOOKING?.TO_DATE}}</span></div>
                            <div class="reserve-detail">Number of Rooms:<span>{{reservationDetails[0].BOOKING?.ROOMS}}</span></div>
                            <div class="reserve-detail">Rooms:
                                <span *ngIf="reservationDetails[0].ROOMRESERVATION?.length != 0">
                                    <ng-container *ngFor="let room of reservationDetails[0].ROOMRESERVATION; let isLast=last">
                                        {{room.ROOM_ID.NAME}}{{!isLast? ',' : ' '}}
                                    </ng-container>
                                </span>
                                <span *ngIf="reservationDetails[0].ROOMRESERVATION?.length == 0">
                                    No rooms assigned
                                </span>
                            </div>
                            <div class="reserve-detail">Number of Guests:<span>{{reservationDetails[0].USER?.length}}</span></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="reserve-detail">Total Charges</div>
                                <div class="reserve-detail"><span>-</span></div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>