import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { Select } from '@ngxs/store';
import { MaterialModule } from '../../../../../material.module';
import { Observable } from 'rxjs/internal/Observable';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
@Component({
  selector: 'app-csat-graph',
  templateUrl: './csat-graph.component.html',
  styleUrls: ['./csat-graph.component.scss']
})
export class CsatGraphComponent implements OnInit {
  @ViewChild('chart', { static: true }) private chartContainer!: ElementRef;
  selectedService: string;
  selectedYear: number;
  years: number[] = [];
  userInfo: any;
  userName: any;
  hotelServices: any = [];
  permittedServices: any[] = [];
  services: any[] = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;

  private data = [{ label: 'Completed', value: 0 }, { label: 'Remaining', value: 0 }];

  constructor() { }

  ngOnInit(): void {
    this.createDonutChart();
    this.initializeYears();
    this.getServicevalue();
    this.selectedYear = this.selectedYear = new Date().getFullYear();
  }

  onServiceChange(event: any) {
    this.selectedService = event.value;
  }
  getServicevalue() {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.hotelServices = window.localStorage.getItem('hotel_services');
        if (this.userInfo.roleId == 2) {
          for (let service of this.services) {
            if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        } else if (this.userInfo.roleId != 2) {
          for (let service of this.services) {
            if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        }
        console.log(this.services, "checking the services valuee 123456789")
      }
    });
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 3; i--) {
      this.years.push(i);
    }
  }
  private createDonutChart(): void {
    const element = this.chartContainer.nativeElement;
    const width = 200;
    const height = 200;
    const margin = { top: 10, right: 10, bottom: 50, left: 10 }; // Adjusted bottom margin for text and rect
    const radius = Math.min(width, height) / 2 - margin.top;

    const svg = d3.select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height + margin.bottom) // Adjusted height for text and rect
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3.scaleOrdinal<string>()
      .domain(this.data.map(d => d.label))
      .range(['#F7942C', '#E0E0E0']);

    const pie = d3.pie<any>()
      .value(d => d.value)
      .sort(null);

    const arc = d3.arc<d3.PieArcDatum<any>>()
      .innerRadius(radius - 30)  // Adjust the inner radius for the donut thickness
      .outerRadius(radius);

    const arcs = svg.selectAll('arc')
      .data(pie(this.data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.label) as string);

    // Center text to display the percentage
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('font-size', '24px')
      .attr('font-weight', 'bold')
      .text(`${this.data[0].value}%`);

    // Add legend rectangle and text below the chart
    const legendX = 0;
    const legendY = height / 2 + margin.top + 20; // Position below the chart

    svg.append('rect')
      .attr('x', legendX - 15)
      .attr('y', legendY)
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', '#F7942C');

    svg.append('text')
      .attr('x', legendX + 5)
      .attr('y', legendY + 12)
      .attr('text-anchor', 'start')
      .style('fill', '#4F4F4F')
      .text('CSAT Score');
  }
}


