import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-update-guest-booking',
  templateUrl: './update-guest-booking.component.html',
  styleUrls: ['./update-guest-booking.component.scss']
})
export class UpdateGuestBookingComponent {

  userInfo: any;
  loading: boolean = false;
  updateBookingForm: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  statusList = [];
  priorityList = [];
  requestDetails = {};
  bookingIdList = [];
  isLoading: boolean = false;
  selectedAssignee = [];
  bookingId;
  roomId;
  serviceId;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<UpdateGuestBookingComponent>,
    private fb: FormBuilder,
    private hotelService: HotelServicesService,
    private adminService: AdminService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.updateBookingForm = this.fb.group({
      bookingId: [''],
      guestName: [''],
      createdBy: [''],
      roomNo: [''],
      area: [''],
      service: [''],
      category: [''],
      type: [''],
      date: [''],
      time: [''],
      assignee: [''],
      priority: ['', Validators.required],
      status: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.requestDetails = this.data.request;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.statusList = res['STATUS'];
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.priorityList = res['PRIORITY'];
      }
    })
    this.getBookingId();
    this.updateBookingForm.get("bookingId").setValue(this.requestDetails['BOOKING_ID']?.RESERVATION);
    this.updateBookingForm.get("createdBy").setValue(this.userInfo.firstName + ' ' + this.userInfo.lastName);
    this.updateBookingForm.get("roomNo").setValue(this.requestDetails['ROOM_ID']?.NAME);
    this.updateBookingForm.get("area").setValue(this.requestDetails['ROOM_ID']?.AREA?.NAME);
    this.updateBookingForm.get("service").setValue(this.requestDetails['SERVICE_ID']?.NAME);
    this.updateBookingForm.get("category").setValue(this.requestDetails['ROOM_ID']?.CATEGORY?.NAME);
    this.updateBookingForm.get("type").setValue(this.requestDetails['ROOM_ID']?.CATEGORY_TYPE?.NAME);
    this.updateBookingForm.get("date").setValue(this.requestDetails['DATE']);
    this.updateBookingForm.get("time").setValue(this.requestDetails['SLOT_ID']?.SLOT_FROM?.TIME + ' ' + '-' + ' ' + this.requestDetails['SLOT_ID']?.SLOT_TO?.TIME);
    this.updateBookingForm.get("assignee").setValue(this.requestDetails['ASIGNEE']?.ID);
    this.updateBookingForm.get("priority").setValue(this.requestDetails['PRIORITY']?.ID);
    this.updateBookingForm.get("status").setValue(this.requestDetails['REQUEST_STATUS']?.ID);
    this.bookingId = this.requestDetails['ID'];
    this.roomId = this.requestDetails['ROOM_ID']?.ID == null ? '' : this.requestDetails['ROOM_ID']?.ID;
    this.serviceId = this.requestDetails['SERVICE_ID']?.ID;
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getBookingId() {
    this.hotelService.getBookingIdList().subscribe({
      next: (data) => {
        this.bookingIdList = data.object;
        for (let reserveId of this.bookingIdList) {
          if (reserveId.ID == this.requestDetails['BOOKING_ID']?.ID) {
            this.updateBookingForm.get("guestName").setValue(reserveId.HOST.FIRST_NAME + ' ' + reserveId.HOST.LAST_NAME);
          }
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  openAssigneeSelect() {
    this.isLoading = true;
    this.getAllInternalMembersByRoomAndServiceID();
  }

  getAllInternalMembersByRoomAndServiceID() {
    this.adminService.getInternalMembersByRoomIdAndServiceId(this.roomId, this.serviceId).subscribe({
      next: (data) => {
        this.selectedAssignee = data.object;
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateBooking() {
    this.loading = true;
    let obj = {
      "SERVICE_ID": this.serviceId,
      "RESERVATION_ID": this.bookingId,
      "ASSIGNEE": this.updateBookingForm.value.assignee,
      "STATUS": this.updateBookingForm.value.status,
      "PRIORITY": this.updateBookingForm.value.priority
    }
    this.hotelService.updateGuestBooking(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.dialogRef.close(this.data.requestType);
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.dialogRef.close();
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}