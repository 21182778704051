import { Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { UploadUsersSheetComponent } from './upload-users-sheet/upload-users-sheet.component';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { saveAs as importedSaveAs } from "file-saver";
import { environment } from 'src/environments/environment';
import { AddAppUsersComponent } from './add-app-users/add-app-users.component';
import * as moment from 'moment';

@Component({
  selector: 'app-uploaded-users',
  templateUrl: './uploaded-users.component.html',
  styleUrls: ['./uploaded-users.component.scss']
})
export class UploadedUsersComponent {

  uploadColumns: string[] = ['file_name', 'uploaded_by', 'uploaded_date'];
  usersColumns: string[] = ['booking_id', 'name', 'communication', 'date', 'room', 'totalGuests', 'action'];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  showSheets: boolean = true;
  showUsers: boolean = false;
  loading: boolean;
  sheetLimit = 5;
  userLimit = 10;
  pageSize = 5;
  pageIndex = 0;
  sheetsPageNumber: number;
  usersPageNumber: number;
  uploadResArray: Array<any> = [];
  userResArray: Array<any> = [];
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('paginatorRef') paginatorRef: MatPaginator;
  private _userData = new BehaviorSubject<any[]>([]);
  private userDataStore: { $userData: any[] } = { $userData: [] };
  readonly $userData = this._userData.asObservable();
  private _uploadData = new BehaviorSubject<any[]>([]);
  private uploadDataStore: { $uploadData: any[] } = { $uploadData: [] };
  readonly $uploadData = this._uploadData.asObservable();
  currentPageIndex: any;
  previousPageIndex: any;
  visitedIndex: Array<any> = [0];
  lastPage: number;
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  templateUrl;
  public bucketUrl = `${environment.BUCKET_URL}`;
  currentDate = new Date();
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public adminService: AdminService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.showUploadedUsers();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  showUploadedUsers() {
    this.showSheets = true;
    this.showUsers = false;
    this.sheetsPageNumber = 0;
    this.dataSource.data = [];
    this.uploadResArray = [];
    this.getUploadedUsers(0);
  }

  showAppUsers() {
    this.showSheets = false;
    this.showUsers = true;
    this.usersPageNumber = 0;
    this.dataSource1.data = [];
    this.userResArray = [];
    this.getAppUsers(0);
  }

  getUploadedUsers(pageNo) {
    this.loading = true;
    this.adminService.getUploadedSheetList(pageNo, this.sheetLimit).subscribe({
      next: (data) => {
        this.uploadResArray = this.uploadResArray.concat(data.object);
        this.uploadDataStore.$uploadData = this.uploadResArray;
        this.dataSource.data = this.uploadDataStore.$uploadData;
        this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
        this._uploadData.next(Object.assign({}, this.uploadDataStore).$uploadData);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changePage(event) {
    if (this.visitedIndex.indexOf(event.pageIndex) == -1) {
      this.visitedIndex.push(event.pageIndex);
    }
    this.currentPageIndex = event.pageIndex;
    this.previousPageIndex = event.previousPageIndex;
    if (this.currentPageIndex > this.previousPageIndex) {
      if (this.visitedIndex.indexOf(this.currentPageIndex + 1)) {
        this.loading = true;
        if (this.showSheets == true) {
          this.sheetsPageNumber++;
          this.getUploadedUsers(this.sheetsPageNumber);
        }
        else if (this.showUsers == true) {
          this.usersPageNumber++;
          this.getAppUsers(this.usersPageNumber);
        }
      }
    }
  }

  uploadUsers() {
    const dialogRef = this.dialog.open(UploadUsersSheetComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.sheetsPageNumber = 0;
      this.uploadResArray = [];
      this.dataSource.data = [];
      this.getUploadedUsers(this.sheetsPageNumber);
    });
  }

  getAppUsers(pageNo) {
    this.loading = true;
    this.adminService.getAppUsersList(pageNo, this.userLimit).subscribe({
      next: (res) => {
        res.object.forEach(element => {
          const endDate = moment(element.TO_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
          element.toDate = new Date(endDate);
        })
        this.userResArray = this.userResArray.concat(res.object);
        this.userDataStore.$userData = this.userResArray;
        this.dataSource1.data = this.userDataStore.$userData;
        this.dataSource1 = new MatTableDataSource<any>(this.dataSource1.data);
        this._userData.next(Object.assign({}, this.userDataStore).$userData);
        this.dataSource1.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addAppUsers() {
    const dialogRef = this.dialog.open(AddAppUsersComponent, {
      width: '1000px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.usersPageNumber = 0;
        this.userResArray = [];
        this.dataSource1.data = [];
        this.getAppUsers(this.usersPageNumber);
      }
    });
  }

  editAppUser(id) {
    this.loading = true;
    const dialogRef = this.dialog.open(AddAppUsersComponent, {
      width: '1000px',
      height: 'auto',
      data: { 'ID': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.updateAppUserDetails(result);
      }
      this.loading = false;
    });
  }

  updateAppUserDetails(obj) {
    this.userDataStore.$userData.forEach((t: any, i) => {
      if (t.HOST.ID === obj?.HOST.ID) {
        this.userDataStore.$userData[i] = obj;
      }
    });
    this._userData.next(Object.assign({}, this.userDataStore).$userData);
    this.$userData.subscribe((res) => {
      this.userResArray = res;
      this.dataSource1.data = res;
      this.dataSource1 = new MatTableDataSource<any>(res);
      this.dataSource1.paginator = this.paginator.toArray()[0];
    })
  }

  deleteAppUser(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this user ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id
        }
        this.adminService.deleteAppUser(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.userDataStore.$userData.forEach((t: any, i) => {
              if (t.HOST.ID === id) {
                this.userDataStore.$userData.splice(i, 1);
              }
            });
            this._userData.next(Object.assign({}, this.userDataStore).$userData);
            this.$userData.subscribe((res) => {
              this.userResArray = res;
              this.dataSource1 = new MatTableDataSource<any>(res);
              this.dataSource1.paginator = this.paginator.toArray()[0];
              if (this.userDataStore.$userData.length % 5 == 0) {
                this.paginatorRef.previousPage();
              }
            })
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  downloadTemplate() {
    this.templateUrl = this.bucketUrl + 'pms/sample/guest_list_sheet.xlsx';
    importedSaveAs(this.templateUrl);
    this.notify.showNotification(
      'Template downloaded',
      "top",
      (!!colorObj[200] ? colorObj[200] : "success"),
      200
    );
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}