import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-departures',
  templateUrl: './departures.component.html',
  styleUrls: ['./departures.component.scss']
})
export class DeparturesComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  currencyInfo: any;
  currency: any;
  displayedColumns: string[] = ['date', 'service', 'quantity', 'amount', 'discount', 'tax', 'total'];
  dataSource = new MatTableDataSource<any>();
  limit = 10;
  pageNumber = 0;
  checkedInList: any = [];
  zeroReservations: boolean = false;
  reserveData: number;
  currentActive = 0;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getCheckedInList(this.pageNumber);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCheckedInList(pageNo) {
    this.loading = true;
    this.hotelService.getReservationList('2', pageNo, this.limit).subscribe({
      next: (data) => {
        this.loading = false;
        this.checkedInList = data.object;
        if (data.object.length == 0) {
          this.zeroReservations = true;
        }
        else {
          this.currentActive = 0;
          this.getPaymentDetails(this.checkedInList[0].BOOKING.ID, this.checkedInList[0].BOOKING.RESERVATION);
        }
        this.reserveData = data.object.length;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  onScrollDown() {
    this.zeroReservations = false;
    if (this.reserveData == 10) {
      this.pageNumber++;
      this.hotelService.getReservationList('2', this.pageNumber, this.limit).subscribe({
        next: (data) => {
          this.reserveData = data.object.length;
          this.checkedInList = this.checkedInList.concat(data.object);
          this.cd.detectChanges();
        },
        error: (e) => {
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  getReservationDetails(index, id, bookingId) {
    this.currentActive = index;
    this.getPaymentDetails(id, bookingId);
  }

  getPaymentDetails(id, bookingId) {
    this.loading = true;
    this.hotelService.getReservationPaymentInfo(id, bookingId).subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource.data = data.object.SERVICES_PAYMENT;
        for (let bs_i = 0; bs_i < this.dataSource.data.length; bs_i++) {
          let item = this.dataSource.data[bs_i];
          let serviceID = item.SERVICE_ID.ID;
          let offerValue = item.OFFER_ID;
          if (serviceID === 2) return;
          let categoryPrice = item.CATEGORY_TYPE_ID?.PRICE;
          item.totalItemsPrice = categoryPrice || 0;
          if (serviceID === 6) {
            item.totalItemsPrice = this.calDiningTotalItemsPrice(item.ITEMS);
            item.OFFER_PRICE = this.calDiningOfferPrice(item);
          }
          if (offerValue && serviceID !== 6) {
            let offerPrice = Math.round(((offerValue?.DISCOUNT / 100) * categoryPrice) * 100) / 100;
            item.OFFER_PRICE = offerPrice;
            let priceAfterOffers = Math.round((categoryPrice - offerPrice) * 100) / 100;
            item.PRICE_AFTER_OFFERS = priceAfterOffers;
          }
          this.calculateTotalPrice(serviceID, bs_i);
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  calDiningTotalItemsPrice(itemArrays = []) {
    const totalItemPrice = itemArrays.reduce((acc, cur: any) => {
      return acc + Number(cur?.CATEGORY_TYPE_ID?.PRICE || 0) * (cur?.QUANTITY || 1);
    }, 0)
    return totalItemPrice;
  }

  calDiningOfferPrice(item) {
    let dinItemsPrice = 0;
    if (item.OFFER_ID !== null) {
      let offerPrice = Math.round(((item.OFFER_ID?.DISCOUNT / 100) * item?.totalItemsPrice) * 100) / 100;
      dinItemsPrice = offerPrice;
    }
    return dinItemsPrice;
  }

  calculateTotalPrice(serviceID, indx) {
    let totalPrice = parseFloat(this.dataSource.data[indx]?.CATEGORY_TYPE_ID?.PRICE);
    if (serviceID === 6) {
      totalPrice = this.calDiningTotalItemsPrice(this.dataSource.data[indx]?.ITEMS);
      this.calcDiningTaxAmount(this.dataSource.data[indx]?.ITEMS, this.dataSource.data[indx].OFFER_ID);
    }
    if (serviceID !== 6 && serviceID !== 2) {
      this.calcServiceTaxAmount(this.dataSource.data[indx]);
    }
    if (this.dataSource.data[indx].OFFER_ID !== null && serviceID !== 2) {
      const offerDiscount = this.dataSource.data[indx]?.OFFER_ID?.DISCOUNT;
      const discountedPrice = totalPrice - (totalPrice * (offerDiscount / 100));
      totalPrice = Math.round(discountedPrice * 100) / 100;
    }
    this.dataSource.data[indx].TOTAL_AMOUNT = totalPrice;
    // this.total += totalPrice;
    // this.total = parseFloat(this.total.toFixed(2));
  }

  calcDiningTaxAmount(itemArrays: any = [], offer) {
    let totalTaxAmount = 0;
    for (let item of itemArrays) {
      if (item?.CATEGORY_TYPE_ID?.SERVICETAX.length != 0) {
        let dinItemsPrice = 0;
        if (offer !== null) {
          let offerPrice = Math.round(((offer?.DISCOUNT / 100) * (item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY)) * 100) / 100;
          dinItemsPrice = offerPrice;
          for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
            totalTaxAmount += Number(((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) - dinItemsPrice) * (tax.TAX_ID.PERCENTAGE / 100));
          }
        }
        else {
          for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
            totalTaxAmount += Number((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) * (tax.TAX_ID.PERCENTAGE / 100));
          }
        }
      }
      item.TAXAMOUNT = totalTaxAmount;
    }
    // this.totalAfterTax += totalTaxAmount;
    // this.totalAfterTax = parseFloat(this.totalAfterTax.toFixed(2));
  }

  calcServiceTaxAmount(item) {
    let totalTaxAmount = 0;
    if (item?.CATEGORY_TYPE_ID?.SERVICETAX.length != 0) {
      for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
        if (item.OFFER_PRICE != null) {
          totalTaxAmount += Number(((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) - item.OFFER_PRICE) * (tax.TAX_ID.PERCENTAGE / 100));
        }
        else {
          totalTaxAmount += Number((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) * (tax.TAX_ID.PERCENTAGE / 100));
        }
      }
      item.TAXAMOUNT = totalTaxAmount;
    }
    // this.totalAfterTax += totalTaxAmount;
    // this.totalAfterTax = parseFloat(this.totalAfterTax.toFixed(2));
  }

  viewPaymentDetails(id, bookingId) {
    this.hotelService.setId(id);
    this.hotelService.setBookingId(bookingId);
    this.router.navigateByUrl('payment-details');
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}