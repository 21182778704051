import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { FormGroup, FormControl } from '@angular/forms';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { CheckInCardComponent } from '../check-in/check-in-card/check-in-card.component';

@Component({
  selector: 'app-inventory-reservations',
  templateUrl: './inventory-reservations.component.html',
  styleUrls: ['./inventory-reservations.component.scss']
})

export class InventoryReservationsComponent {

  range: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  dateSlots = new FormControl('');
  roomTypes: any[] = []; // Stores room categories
  roomClasses: any[] = []; // Stores room types
  roomType = new FormControl(''); // Control for Room Category
  roomClass = new FormControl('');
  searchVal = new FormControl('');
  minDate = new Date();
  columns: number[] = Array(8).fill(0).map((x, i) => i + 1);
  rows: number[] = Array(10).fill(0).map((x, i) => i + 1);
  roomNumbers = [];
  roomCategories: any[] = [];
  selectedCategoryDetails: any = null;
  week: Date[] = [];
  roomStatusMap: any = {}; // Maps status and user for each room on specific dates
  reservationStatus: number;
  reservationStatusArray: any[] = [];
  filteredRoomClasses: any[] = [];
  selectedStartDate: any;
  selectedEndDate: any;
  selectedDays: any[] = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private hotelService: HotelServicesService,
    public notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    // Initialize date range
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1)); // Monday of the current week
    const endOfWeek = new Date(firstDayOfWeek);
    endOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    // Set initial values for the date range picker
    this.range = new FormGroup({
      start: new FormControl(firstDayOfWeek),
      end: new FormControl(endOfWeek)
    });

    this.selectedStartDate = firstDayOfWeek;
    this.selectedEndDate = endOfWeek;
    this.selectedDays = this.getSelectedRangeDays();
    // Subscribe to range changes
    this.range.get('start')?.valueChanges.pipe(
      debounceTime(300), // Wait for 300ms after the last input before triggering the event
      distinctUntilChanged() // Ensure the value actually changes before triggering
    ).subscribe(startDate => {
      if (startDate) {
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        this.range.get('end')?.setValue(endDate, { emitEvent: false }); // Emit false to prevent recursion
        this.selectedStartDate = startDate;
        this.selectedEndDate = endDate;
        this.getReservationStatus();
      }
    });
    this.getReservationStatus();
    this.fetchRoomCategories();

    this.roomType.valueChanges.subscribe((selectedCategoryId) => {
      if (selectedCategoryId) {
        this.fetchRoomTypeByCategory(+selectedCategoryId);
      } else {
        this.roomClasses = [];
        this.filteredRoomClasses = [];
      }
    });

    this.roomClass.valueChanges.subscribe((selectedRoomClassId) => {
      this.filterRoomsByType(selectedRoomClassId);
    });

    Object.keys(this.roomNumbers || {}).forEach((roomClass) => {
      this.roomNumbers[roomClass].forEach((roomNumber) => {
        this.week.forEach((day) => {
          const key = this.generateKey(roomClass, roomNumber, day);
          this.roomStatusMap[key] = { status: '', user: '', reservationID: '', bookingID: '' };
        });
      });
    });
  }

  thisWeekdata() {
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    const endOfWeek = new Date(firstDayOfWeek);
    endOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    this.range.get('start')?.setValue(firstDayOfWeek);
    this.range.get('end')?.setValue(endOfWeek);
    this.selectedStartDate = firstDayOfWeek;
    this.selectedEndDate = endOfWeek;
    this.selectedDays = this.getSelectedRangeDays();
    this.getReservationStatus();
  }

  getSelectedRangeDays() {
    const days = [];
    if (this.selectedStartDate && this.selectedEndDate) {
      let currentDate = new Date(this.selectedStartDate);
      while (currentDate <= this.selectedEndDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return days;
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return date1 && date2 && date1.toDateString() === date2.toDateString();
  }

  isInRange(date: Date, startDate: Date, endDate: Date): boolean {
    return date >= startDate && date <= endDate;
  }

  formatDate(date: Date = new Date()): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  convertToDate(dateString) {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}T00:00:00Z`);
    return date;
  }

  getReservationStatus(): void {
    this.loading = true;
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    const lastDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

    const checkInDate = this.formatDate(this.selectedStartDate);
    const checkOutDate = this.formatDate(this.selectedEndDate);

    this.selectedDays = this.getSelectedRangeDays();
    this.hotelService.getReservationData(checkInDate, checkOutDate).subscribe({
      next: (response: any) => {
        if (response.status === 200 && response.object?.length) {
          response.object.forEach((reservation: any) => {
            const booking = reservation.BOOKING;
            const users = reservation.USER || [];
            const roomReservation = reservation.ROOMRESERVATION;

            roomReservation.forEach((room: any) => {
              const roomClass = room?.ROOM_ID?.ID; // Ensure ROOM_ID exists in the structure
              const roomNumber = room?.ROOM_ID?.NAME;

              if (roomClass && roomNumber) {
                users.forEach((user: any) => {
                  if (user.USER_TYPE?.NAME === 'Host') {
                    const hostName = `${user.FIRST_NAME} ${user.LAST_NAME}`;
                    const status = booking.RESERVATION_STATUS?.NAME || '';
                    const reservationID = booking.RESERVATION;
                    const bookingID = booking.ID;
                    const fromDate = this.convertToDate(booking.FROM_DATE);
                    const toDate = this.convertToDate(booking.TO_DATE);

                    for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
                      const key = this.generateKey(roomClass, +roomNumber, date);
                      this.roomStatusMap[key] = { status, user: hostName, reservationID, bookingID };
                    }
                  }
                });
              }
            });
          });
        }
      },
      error: (err) => {
        console.error('Error fetching reservation data', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  generateKey(roomClass: string, roomNumber: number, day: any): string {
    return `${roomClass} ${roomNumber} ${this.formatDate(day)}`;
  }

  getRoomStatus(roomClass: string, roomNumber: number, day: any): string {
    const key = this.generateKey(roomClass, roomNumber, day);
    return this.roomStatusMap[key]?.status || '';
  }

  getUser(roomClass: string, roomNumber: number, day: any): string {
    return this.roomStatusMap[this.generateKey(roomClass, roomNumber, day)]?.user || '';
  }

  getReservationID(roomClass: string, roomNumber: number, day: any): string {
    return this.roomStatusMap[this.generateKey(roomClass, roomNumber, day)]?.reservationID || '';
  }

  getBookingId(roomClass: string, roomNumber: number, day: any): string {
    return this.roomStatusMap[this.generateKey(roomClass, roomNumber, day)]?.bookingID || '';
  }

  getStatusColor(roomClass: string, roomNumber: number, day: any): string {
    const status = this.getRoomStatus(roomClass, roomNumber, day);
    switch (status) {
      case 'Checked-In':
        return '#185C0033';
      case 'Reserved':
        return '#EF9E001A';
      case 'Checked-Out':
        return '#efefef';
      case 'Cancelled':
        return '#D5000033';
      default:
        return '#FFFFFF';
    }
  }

  getColorBasedOnStatus(status: string): string {
    switch (status) {
      case 'Checked-In':
        return '#185C00';
      case 'Reserved':
        return 'rgb(213 119 0)';
      case 'Checked-Out':
        return 'rgb(103 103 103)';
      case 'Cancelled':
        return '#D50000';
      default:
        return '#000000';
    }
  }

  fetchRoomCategories(): void {
    this.hotelService.getRoomCategoryValue().subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomTypes = response.object;
          this.fetchAllRoomTypes(); // Fetch all room types initially
        }
      },
      error: (err) => {
        console.error('Error fetching room categories:', err);
      },
    });
  }

  fetchAllRoomTypes(): void {
    this.hotelService.getRoomCategoryValue().subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomClasses = response.object.flatMap((category) => category.CATEGORY_TYPE);
          this.filteredRoomClasses = this.roomClasses;
          this.enrichRoomData();
        }
      },
      error: (err) => {
        console.error('Error fetching all room types:', err);
      },
    });
  }

  fetchRoomTypeByCategory(categoryId: number): void {
    this.hotelService.getRoomCategoryTypeValue(categoryId).subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomClasses = response.object[0].CATEGORY_TYPE;
          this.filteredRoomClasses = this.roomClasses;
          this.enrichRoomData();
        } else {
          this.roomClasses = [];
          this.filteredRoomClasses = [];
        }
      },
      error: (err) => {
        console.error('Error fetching room types:', err);
      },
    });
  }

  enrichRoomData(): void {
    this.filteredRoomClasses.map((reservation: any) => {
      reservation.ROOMS = reservation.ROOMS.map((room) => ({
        ...room,
        menuActions: this.selectedDays.map((day) => this.getAvailableActions(room.ID, room.NAME, day)),

      }));
    });
  }

  getAvailableActions(roomClass: string, roomNumber: number, day: Date): string[] {
    const status = this.getRoomStatus(roomClass, roomNumber, day);
    if (status === 'Reserved') {
      return ['Edit', 'Check-In', 'Cancellation'];
    } else if (status === 'Checked-In') {
      return ['Edit'];
    } else {
      return [];
    }
  }

  hasMenuActions(roomClass: string, roomNumber: number, day: Date): boolean {
    const actions = this.getAvailableActions(roomClass, roomNumber, day);
    return actions && actions.length > 0;
  }

  bookingActions(action, bookingId) {
    if (action == 'Edit') {
      this.hotelService.setId(bookingId);
      this.router.navigateByUrl('edit-reservation');
    }
    else if (action == 'Check-In') {
      this.checkInReservation(bookingId);
    }
    else if (action == 'Cancellation') {
      this.cancelReservation(bookingId);
    }
  }

  checkInReservation(id) {
    let obj = {
      "ID": id,
      "RESERVATION_STATUS": 2
    }
    this.hotelService.updateReservationStatus(obj).subscribe({
      next: (data) => {
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
        this.openCheckIn(id);
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  openCheckIn(id) {
    const dialogRef = this.dialog.open(CheckInCardComponent, {
      width: '700px',
      height: '600px',
      data: { bookingId: id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getReservationStatus();
      }
    });
  }

  cancelReservation(id) {
    let obj = {
      "ID": id,
      "RESERVATION_STATUS": 4
    }
    this.hotelService.updateReservationStatus(obj).subscribe({
      next: (data) => {
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
        this.getReservationStatus();
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  filterRoomsByType(selectedRoomClassId: string): void {
    if (selectedRoomClassId) {
      this.filteredRoomClasses = this.roomClasses.filter((roomClass) => roomClass.ID === selectedRoomClassId);
    } else {
      this.filteredRoomClasses = this.roomClasses; // Show all room classes if no room class is selected
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    );
  }

  navigateBackward() {
    const prevDate = new Date(this.selectedStartDate);
    prevDate.setDate(prevDate.getDate() - 1);
    this.minDate = prevDate;
  }

  navigateForward() {
    const nextDate = new Date(this.selectedStartDate);
    nextDate.setDate(nextDate.getDate() + 1);
    this.minDate = nextDate;
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}