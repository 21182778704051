<div class="filter-sidebar">
    <div fxLayout="column" style="height: 100%;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
                <iconify-icon icon="material-symbols:filter-alt" class="filter-icon"></iconify-icon>
                <div class="header">Filter by</div>
            </div>
            <div fxFlex="20%">
                <mat-dialog-actions>
                    <button mat-button mat-dialog-close class="close-btn">
                        <mat-icon class="close-icon">
                            close
                        </mat-icon>
                    </button>
                </mat-dialog-actions>
            </div>
        </div>
        <div fxLayout="column" class="filter-list" fxLayoutAlign="space-between">
            <div fxLayout="column" fxLayoutGap="30px">
                <div class="filter-option" fxLayout="row" fxLayoutAlign="space-between center"
                    [matMenuTriggerFor]="dateFilter">
                    <div>Date</div>
                    <iconify-icon icon="material-symbols:arrow-forward-ios-rounded"></iconify-icon>
                </div>
                <mat-menu #dateFilter="matMenu" class="date-menu">
                    <div class="option-container">
                        <div fxLayout="row" fxLayoutGap="15px">
                            <div fxLayout="column" fxLayoutGap="5px">
                                <div class="box-name">
                                    From
                                </div>
                                <div>
                                    <div class="text-box-date">
                                        <input matInput [matDatepicker]="picker1" [formControl]="fromDate"
                                            class="text-box" placeholder="Select" (dateChange)="changeDate()" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxLayoutGap="5px">
                                <div class="box-name">
                                    To
                                </div>
                                <div>
                                    <div class="text-box-date">
                                        <input matInput [matDatepicker]="picker2" [formControl]="toDate"
                                            class="text-box" placeholder="Select" [min]="fromDate.value" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-menu>
                <div class="filter-option" fxLayout="row" fxLayoutAlign="space-between center"
                    [matMenuTriggerFor]="roomFilter">
                    <div>Room No.</div>
                    <iconify-icon icon="material-symbols:arrow-forward-ios-rounded"></iconify-icon>
                </div>
                <mat-menu #roomFilter="matMenu" class="room-menu">
                    <div class="option-container">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <div class="box-name">
                                Room
                            </div>
                            <div>
                                <input matInput [formControl]="roomNo" class="text-box1" placeholder="Enter Room"
                                    (click)="$event.stopPropagation()">
                            </div>
                        </div>
                    </div>
                </mat-menu>
                <div class="filter-option" fxLayout="row" fxLayoutAlign="space-between center"
                    [matMenuTriggerFor]="serviceFilter">
                    <div>Service</div>
                    <iconify-icon icon="material-symbols:arrow-forward-ios-rounded"></iconify-icon>
                </div>
                <mat-menu #serviceFilter="matMenu" class="service-menu">
                    <div class="option-container">
                        <mat-checkbox fxLayout="column" #menuItems (click)="$event.stopPropagation()"
                            *ngFor="let service of permittedServices; let i = index;"
                            (change)="selectServiceFilter(menuItems.checked, service.ID)">{{ service.NAME
                            }}</mat-checkbox>
                    </div>
                </mat-menu>
                <div class="filter-option" fxLayout="row" fxLayoutAlign="space-between center"
                    [matMenuTriggerFor]="bookingIdFilter">
                    <div>Booking ID</div>
                    <iconify-icon icon="material-symbols:arrow-forward-ios-rounded"></iconify-icon>
                </div>
                <mat-menu #bookingIdFilter="matMenu" class="booking-menu">
                    <div class="option-container">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <div class="box-name">
                                Booking ID
                            </div>
                            <div>
                                <input matInput [formControl]="bookingId" class="text-box1"
                                    placeholder="Enter Booking ID" (click)="$event.stopPropagation()">
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-stroked-button class="clear-btn">
                    Clear All
                </button>
                <button mat-stroked-button class="apply-btn">
                    Apply
                </button>
            </div>
        </div>
    </div>
</div>