<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div class="spa-services" fxLayout="column">
            <div class="spa-header" fxLayout="row" fxFlex="3pc">
                <div>
                    <span class="spa-header1">
                        Create Services /
                    </span>
                    <span class="spa-header2">
                        House Keeping
                    </span>
                </div>
                <div *ngFor="let time of serviceTiming">
                    <span *ngIf="time.SERVICE.ID == 2 && time.ENABLED == true" class="timing">
                        <u style="font-family: 'Montserrat-SemiBold';">Service Available</u> : <u>From</u>:
                        {{time.FROM.TIME}} - <u>To</u>: {{time.TO.TIME}}
                    </span>
                    <span *ngIf="time.SERVICE.ID == 2 && time.ENABLED == false" class="timing">
                        <u style="font-family: 'Montserrat-SemiBold';">Service Available</u> : 24/7
                    </span>
                </div>
            </div>
            <div class="spa-banners" fxLayout="column" fxFlex="10pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Banners
                    </div>
                    <div>
                        <button class="add-btn" (click)="addBanners()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 25px">
                    <div style="align-self: center;" *ngIf="housekeepingBanners.length != 0">
                        <mat-button (click)="bannerScrollLeft()" class="scroll-btn">
                            <img src="assets/images/arrow_back.svg">
                        </mat-button>
                    </div>
                    <div #bannerContent fxLayout="row" fxLayoutGap="30px" class="banners"
                        *ngIf="housekeepingBanners.length != 0">
                        <div *ngFor="let banner of housekeepingBanners; let i=index;">
                            <div id="banner-images" fxLayout="row">
                                <img src="{{bucketUrl + banner.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="img_preview"
                                    (click)="preview(banner.ATTACHMENT_SOURCE)">
                                <div id="swap" style="color: #000000; font-weight: 600; cursor: pointer;"
                                    [matMenuTriggerFor]="bannerOptions">
                                    <mat-icon>more_vert</mat-icon>
                                </div>
                                <mat-menu #bannerOptions="matMenu">
                                    <div class="option-container">
                                        <button mat-menu-item (click)="updateBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -7px;">edit</mat-icon></span>
                                            <span class="option-text">Edit</span>
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button mat-menu-item (click)="deleteBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -9px;">delete_outline</mat-icon></span>
                                            <span class="option-text">Delete</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div style="align-self: center;" *ngIf="housekeepingBanners.length != 0">
                        <mat-button (click)="bannerScrollRight()" class="scroll-btn">
                            <img src="assets/images/arrow_forward.svg">
                        </mat-button>
                    </div>
                    <div class="text-message" *ngIf="housekeepingBanners.length == 0">
                        <span>
                            <br>
                            No banners available ....
                            <br>
                        </span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="spa-categories" fxLayout="column" fxFlex="20pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Services
                    </div>
                    <div>
                        <button class="add-btn" (click)="addService()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutGap="30px" fxLayoutAlign="flex-start" style="margin-bottom: 25px"
                    class="services">
                    <div *ngFor="let service of housekeepingServices; let i=index;">
                        <div class="service-row" fxLayout="row" fxLayoutGap="15px">
                            <div>{{service.NAME}}</div>
                            <div fxLayout="row">
                                <button mat-icon-button (click)="updateService(service.ID)" [disabled]="accessType == 2">
                                    <div class="material-icons-outlined" style="font-size: 19px;">edit</div>
                                </button>
                                <button mat-icon-button (click)="deleteService(service.ID)" [disabled]="accessType == 2">
                                    <div class="material-icons-outlined" style="color:#FF4242; font-size: 19px;">delete_outline</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-message" *ngIf="housekeepingServices.length == 0">
                        <span>
                            <br>
                            No services available ....
                            <br>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>