import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-upload-users-sheet',
  templateUrl: './upload-users-sheet.component.html',
  styleUrls: ['./upload-users-sheet.component.scss']
})
export class UploadUsersSheetComponent {

  uploadForm: FormGroup;
  uploadedSheets = [];
  loading: boolean = false;
  imageName: any;
  docPath: any;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<UploadUsersSheetComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService
  ) {
    this.uploadForm = this.fb.group({
      image: [[]],
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  async onFileSelected(files) {
    this.loading = true;
    for (let i = 0; i <= files.length - 1; i++) {
      var selectedFile = files[i];
      let tempObjforGetsigned = {
        FILE_NAME: selectedFile['name']
      }
      await this.uploadService.getPreSignedUrlforCustomerSheet(tempObjforGetsigned).then(async (res) => {
        if (res && res['SIGNED_URL']) {
          this.docPath = res['PATH'];
          let tempObjForUpload = {
            url: res['SIGNED_URL'],
            file: selectedFile
          }
          await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
            if (data) {
              this.saveToDB();
              this.imageName = files[0].name;
            }
          }).catch((err) => {
            this.handleError(err);
          });
        }
      })
        .catch((err) => {
          this.uploadedSheets.splice(i, 1);
          this.handleError(err);
        });
    }
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  saveToDB() {
    let obj = {
      "PATH": this.docPath
    }
    this.uploadService.saveToDBCustomerSheet(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.dialogRef.close();
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.dialogRef.close();
      }
    })
  }

}