<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    Members List
                    <button class="refresh-btn">
                        <span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">refresh</span>
                    </button>
                </div>
                <div>
                    <input matInput [formControl]="searchVal" class="menu-search" placeholder="Search">
                    <mat-icon matSuffix class="search_icon">search</mat-icon>
                    <button class="search-btn" (click)="searchMember()">
                        Search
                    </button>
                    <button class="add-btn" style="margin-left: 40px;" (click)="addUser()" [disabled]="accessType == 2">
                        + Add Member
                    </button>
                </div>
            </div>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Name </th>
                    <td mat-cell *matCellDef="let row">{{row.FIRST_NAME}} {{row.LAST_NAME}}</td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Department </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngFor="let role of row.SERVICE;; let isLast=last">
                            {{role.NAME}} {{!isLast? ',' : ' '}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Role </th>
                    <td mat-cell *matCellDef="let row">{{row.ROLE.NAME}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Email </th>
                    <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile-no">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Mobile Number</th>
                    <td mat-cell *matCellDef="let row">{{row.MOBILE}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <button mat-icon-button (click)="editUser(row.ID)" [disabled]="accessType == 2">
                                <span class="material-icons-outlined">edit</span>
                            </button>
                            <button mat-icon-button (click)="deleteUser(row.ID)" [disabled]="accessType == 2">
                                <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                            </button>
                            <div fxLayout="column">
                                <p style="font-size: 12px;">{{row.USER_DISABLED ? 'Disabled':'Enabled'}}</p>
                                <label class="switch">
                                    <input #checkbox id="{{row.ID}}" type="checkbox" [checked]="!row.USER_DISABLED"
                                        (change)="enable(checkbox.checked, row.ID)" [disabled]="accessType == 2">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true"
                (page)="changePage($event)"></mat-paginator>
        </div>
    </div>
</div>