import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { GetHotelInfo } from "../actions/hotel-info.actions";
import { AdminService } from "src/app/services/admin.service";

// Hotel State Model
export class HotelStateModel {
    hotelInfo: any[];
    hotelInfoLoaded: boolean;
}

//Hotel state
@State<HotelStateModel>({
    name: 'hotel',
    defaults: {
        hotelInfo: [],
        hotelInfoLoaded: false
    }
})

// Injectable
@Injectable()
export class HotelState {
    constructor(private adminService: AdminService) { }

    @Selector()
    static getClientInfo(state: HotelStateModel) {
        return state.hotelInfo
    }

    @Selector()
    static getClientInfoLoaded(state: HotelStateModel) {
        return state.hotelInfoLoaded
    }

    @Action(GetHotelInfo)
    getHotelInfo({ getState, setState }: StateContext<HotelStateModel>) {
        return this.adminService.getClientInfo().pipe(tap(res => {
            const hotelInformation = getState();
            setState({
                ...hotelInformation,
                hotelInfo: res,
                hotelInfoLoaded: true
            })
        }))
    }
}