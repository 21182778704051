import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-in-card',
  templateUrl: './check-in-card.component.html',
  styleUrls: ['./check-in-card.component.scss']
})
export class CheckInCardComponent {

  hotelName: string;
  imageURL: string;
  public bucket_url = `${environment.BUCKET_URL}`;
  loading: boolean = true;
  reservationDetails: any = {};
  currentDate = new Date();
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<CheckInCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public notify: NotificationService,
    private hotelService: HotelServicesService
  ) { }

  ngOnInit() {
    this.hotelName = window.localStorage.getItem('hotel_name');
    this.imageURL = this.bucket_url + window.localStorage.getItem('hotel_icon');
    this.checkScreenWidth();
    this.getReservationDetails();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getReservationDetails() {
    this.hotelService.getReservationById(this.data.bookingId).subscribe({
      next: (data) => {
        this.loading = false;
        this.reservationDetails = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  printReceipt() {
    window.print();
  }

  closeDialog() {
    this.dialogRef.close('Closed');
  }

}