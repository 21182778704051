import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RatingComponent implements OnInit {

  @Input('rating')  rating: number = 4;
  @Input('starCount')  starCount: number = 5;
  @Output()  ratingUpdated = new EventEmitter();

   ratingArr = [];
   selected: string;
  periods = [];
  constructor(private snackBar: MatSnackBar) {
  }


  ngOnInit() {
    this.initializePeriods();
    console.log("a "+this.starCount)
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  initializePeriods() {
    this.periods = [
      { label: 'Today', value: 'today' },
      { label: 'One Month', value: '1_month' },
      { label: 'Six Months', value: '6_months' },
      { label: 'One Year', value: '1_year' }
    ];
    this.selected = this.periods[0].value; // Default selection
  }
  onClick(rating:number) {
    // console.log(rating)
    // // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    // //   duration: this.snackBarDuration
    // // });
    // this.ratingUpdated.emit(rating);
    // return false;
  }

  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}


