<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update Guests' : 'Add Guests'}}</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addAppUserForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div class="guest-type">{{data ? 'Update Primary Guest' : 'Add Primary Guest'}}</div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        Booking ID
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="bookingId" placeholder="Enter Booking ID">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['bookingId'].touched && addAppUserForm.get('bookingId').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        First Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="firstName" placeholder="Enter First Name">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['firstName'].touched && addAppUserForm.get('firstName').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        Last Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="lastName" placeholder="Enter Last Name">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['lastName'].touched && addAppUserForm.get('lastName').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        Email ID
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="email" placeholder="Enter Email ID">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['email'].touched && addAppUserForm.get('email').hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="error" *ngIf="addAppUserForm.get('email').hasError('pattern')">
                            Invalid Pattern
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        Mobile Number
                    </div>
                    <div>
                        <input type="number" class="text-box" formControlName="mobileNumber"
                            placeholder="Enter Mobile Number">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['mobileNumber'].touched && addAppUserForm.get('mobileNumber').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        Room Number
                    </div>
                    <div>
                        <input type="number" class="text-box" formControlName="room" placeholder="Enter Room Number">
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['room'].touched && addAppUserForm.get('room').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        From Date
                    </div>
                    <div>
                        <div class="text-box-date">
                            <input matInput [matDatepicker]="picker1" formControlName="fromDate" class="text-box1"
                                placeholder="Select From Date" (dateChange)="changeDate()" [min]="minFromDate" readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </div>
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['fromDate'].touched && addAppUserForm.get('fromDate').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="47%">
                    <div class="box-name">
                        To Date
                    </div>
                    <div>
                        <div class="text-box-date">
                            <input matInput [matDatepicker]="picker2" formControlName="toDate" class="text-box1"
                                placeholder="Select To Date" [min]="minToDate" readonly matTooltip="{{tooltip}}">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                        <mat-error class="error"
                            *ngIf="addAppUserForm.controls['toDate'].touched && addAppUserForm.get('toDate').hasError('required')">
                            Required
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="guest-type" *ngIf="!data">Add Secondary Guests</div>
            <div class="guest-type" *ngIf="data && guestsInfo.length > 0">Update Secondary Guests</div>
            <div formArrayName="secondaryGuestsArray">
                <div fxLayout="column" fxLayoutGap="30px"
                    *ngFor="let guestControl of getSecondaryGuests(); let in = index;" formGroupName="{{in}}">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="array-title">
                        <mat-label style="color: #0088CB; font-size: 13px; padding: 1% 0;">Guest {{in+1}}</mat-label>
                        <button mat-icon-button class="remove-btn" (click)="removeSecondaryGuests(in)" *ngIf="!data"
                            [disabled]="addAppUserForm.controls.secondaryGuestsArray.controls.length == 1">
                            <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                        </button>
                    </div>
                    <div fxLayout="row" fxLayoutGap="30px">
                        <div fxLayout="column" fxFlex="30%">
                            <div class="box-name">
                                First Name
                            </div>
                            <div>
                                <input type="text" class="text-box" formControlName="guestFirstName" placeholder="Enter First Name">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%">
                            <div class="box-name">
                                Last Name
                            </div>
                            <div>
                                <input type="text" class="text-box" formControlName="guestLastName" placeholder="Enter Last Name">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%">
                            <div class="box-name">
                                Room Number
                            </div>
                            <div>
                                <input type="number" class="text-box" formControlName="guestRoom" placeholder="Enter Room Number">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="30px">
                        <div fxLayout="column" fxFlex="30%">
                            <div class="box-name">
                                Email ID
                            </div>
                            <div>
                                <input type="text" class="text-box" formControlName="guestEmail" placeholder="Enter Email ID">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%">
                            <div class="box-name">
                                Mobile Number
                            </div>
                            <div>
                                <input type="number" class="text-box" formControlName="guestMobileNumber" placeholder="Enter Mobile Number">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" *ngIf="hideID">
                            <div class="box-name">
                                Guest ID
                            </div>
                            <div>
                                <input type="number" class="text-box" formControlName="guestID">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!data">
                    <button mat-button class="add-guest-button" [disabled]="getSecondaryGuests().length == 20"
                        (click)="addSecondaryGuests()">
                        + Add Guests
                    </button>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl" class="actions">
                <button mat-stroked-button [ngClass]="this.addAppUserForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addAppUserForm.invalid" (click)="addAppUser()">Save</button>
                <button mat-stroked-button class="button" style="margin-right: 18px;" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>