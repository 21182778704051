<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update member':'Create new member'}}</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addUserForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Client
                    </div>
                    <div>
                        <mat-select class="text-box" style="font-size: 13px;" appearance="fill" formControlName="client"
                            placeholder="Select Client">
                            <mat-option *ngFor="let client of clients" [value]="client.ID">{{client.NAME}}</mat-option>
                        </mat-select>
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['client'].touched && addUserForm.get('client').hasError('required')">
                            Please select client from the list.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        First Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="firstName" placeholder="Enter First Name">
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['firstName'].touched && addUserForm.get('firstName').hasError('required')">
                            Please enter the first name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['firstName'].errors?.minlength || addUserForm.controls['firstName'].errors?.maxlength || addUserForm.controls['firstName'].errors?.pattern">
                            First name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Last Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="lastName" placeholder="Enter Last Name">
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['lastName'].touched && addUserForm.get('lastName').hasError('required')">
                            Please enter the last name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['lastName'].errors?.minlength || addUserForm.controls['lastName'].errors?.maxlength || addUserForm.controls['lastName'].errors?.pattern">
                            Last name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Email ID
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="email" placeholder="Enter Email Id">
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['email'].touched && addUserForm.get('email').hasError('required')">
                            Please enter a valid email address (e.g., example@example.com).
                        </mat-error>
                        <mat-error class="error" *ngIf="addUserForm.get('email').hasError('pattern')">
                            Please enter a valid email address.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Mobile Number
                    </div>
                    <div>
                        <input type="number" class="text-box" formControlName="mobileNumber"
                            placeholder="Enter Mobile Number">
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['mobileNumber'].touched && addUserForm.get('mobileNumber').hasError('required')">
                            Please enter the mobile number (digits only, 10-15 characters).
                        </mat-error>
                        <mat-error class="error" *ngIf="addUserForm.controls['mobileNumber'].errors?.pattern">
                            Mobile number should contain only digits and be between 10 and 15 characters long.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Password
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="password" placeholder="Enter Password"
                            *ngIf="!data">
                        <input type="text" class="text-box" formControlName="password" placeholder="********"
                            *ngIf="!!data">
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['password'].touched && addUserForm.get('password').hasError('required')">
                            Please create a password (8-20 characters, at least one uppercase letter, one lowercase
                            letter, one number and one special character).
                        </mat-error>
                        <mat-error class="error" *ngIf="addUserForm.get('password').hasError('pattern')">
                            Password should contain at least one uppercase letter, one lowercase
                            letter, one number and one special character (#?!@$%^&*-) and should
                            be of length 8-20 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Access Type
                    </div>
                    <div>
                        <mat-select class="text-box" style="font-size: 13px;" appearance="fill"
                            formControlName="accessType" placeholder="Select Access Type">
                            <mat-option *ngFor="let type of accessTypes" [value]="type.ID">{{type.NAME}}</mat-option>
                        </mat-select>
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['accessType'].touched && addUserForm.get('accessType').hasError('required')">
                            Please select access type from the list.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Country
                    </div>
                    <div>
                        <mat-select class="text-box" style="font-size: 13px;" appearance="fill"
                            formControlName="country" placeholder="Select Country">
                            <mat-option *ngFor="let country of countries"
                                [value]="country.ID">{{country.NAME}}</mat-option>
                        </mat-select>
                        <mat-error class="error"
                            *ngIf="addUserForm.controls['country'].touched && addUserForm.get('country').hasError('required')">
                            Please select country from the list.
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl" class="actions">
                <button mat-stroked-button [ngClass]="this.addUserForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addUserForm.invalid" (click)="addUser()">Save</button>
                <button mat-stroked-button class="button" style="margin-right: 18px;" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>