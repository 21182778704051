import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { colorObj } from 'src/app/shared/color-object';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulk-rate',
  templateUrl: './bulk-rate.component.html',
  styleUrls: ['./bulk-rate.component.scss']
})
export class BulkRateComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  bulkRateForm: FormGroup;
  currentDate = new Date();
  minToDate: Date;
  minBlackoutToDate: Date;
  roomCategories = [];
  roomTypesList = [];
  selectedRoomTypes = [];
  selectedRatePlans = []
  ratePlanInfo: any = {};
  ratePlans = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.bulkRateForm = this.fb.group({
      bulkUpdateFrom: ['', Validators.required],
      bulkUpdateTo: ['', Validators.required],
      roomCategory: ['', Validators.required],
      roomType: [[], Validators.required],
      roomRateArray: fb.array([]),
      blackOutArray: fb.array([])
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getCategories();
    this.addBlackOut();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  selectDate() {
    this.minToDate = new Date(this.bulkRateForm.value.bulkUpdateFrom);
    this.bulkRateForm.controls['bulkUpdateTo'].reset();
  }

  selectBlackoutDate(index) {
    this.minBlackoutToDate = new Date((this.bulkRateForm.get('blackOutArray') as FormArray).at(index).value.blackOutFrom);
    (this.bulkRateForm.get('blackOutArray') as FormArray).at(index).get('blackOutTo').reset();
  }

  resetBlackoutDate(index) {
    this.minBlackoutToDate = new Date((this.bulkRateForm.get('blackOutArray') as FormArray).at(index).value.blackOutFrom);
  }

  getCategories() {
    this.hotelService.getRoomCategory().subscribe({
      next: (data) => {
        this.roomCategories = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectRoomCategory(category) {
    this.getRoomTypes(category);
  }

  getRoomTypes(categoryId) {
    this.hotelService.getRoomTypeList(categoryId).then((data) => {
      if (data) {
        this.roomTypesList = data.object;
      }
    }).catch((err) => {
      this.notify.showNotification(
        err.error.message,
        "top",
        (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
        err.error.status
      )
    });
  }

  async selectRoomTypes() {
    this.selectedRoomTypes = [];
    (this.bulkRateForm.controls['roomRateArray'] as FormArray).clear();
    this.bulkRateForm.controls['roomType'].value.forEach(element => {
      this.roomTypesList.forEach(type => {
        if (element == type.ID) {
          this.selectedRoomTypes.push(type);
        }
      })
    });
    await this.getRatePlansByRoomType(this.bulkRateForm.controls['roomType'].value);
  }

  async getRatePlansByRoomType(id) {
    await this.hotelService.getRatePlanByTypeId(id).subscribe({
      next: async (data) => {
        this.ratePlans = [];
        this.ratePlanInfo = data.object;
        if (Object.keys(this.ratePlanInfo).length != 0) {
          this.selectedRoomTypes.forEach(type => {
            Object.entries(this.ratePlanInfo).forEach(([key, value]) => {
              if (type.ID == key) {
                this.ratePlans.push({
                  TYPE_ID: type.ID,
                  TYPE_NAME: type.NAME,
                  RATE_PLANS: value
                });
              }
            })
          })
        }
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectPlans(event, type_id, plan_id, plan_name) {
    if (event.checked == true) {
      this.roomTypesList.forEach(type => {
        if (type_id == type.ID) {
          let obj = {
            RATE_PLAN_ID: plan_id,
            RATE_PLAN_NAME: plan_name,
            ROOM_TYPE: type
          }
          this.selectedRatePlans.push(obj);
          this.addRateUpdate(obj);
        }
      })
    }
    else if (event.checked == false) {
      const index = this.selectedRatePlans.findIndex(el => el.RATE_PLAN_ID === plan_id);
      this.selectedRatePlans.splice(index, 1);
      (<FormArray>this.bulkRateForm.get('roomRateArray')).removeAt(index);
    }
  }

  addRateUpdate(type?: any) {
    let typeIndex = (<FormArray>this.bulkRateForm.get('roomRateArray')).length;
    let fg = this.fb.group({
      roomTypeId: type.ROOM_TYPE.ID,
      roomTypeName: type.ROOM_TYPE.NAME,
      ratePlanId: type.RATE_PLAN_ID,
      ratePlanName: type.RATE_PLAN_NAME,
      occupantRateArray: this.fb.array([])
    });
    (<FormArray>this.bulkRateForm.get('roomRateArray')).push(fg);
    if (!!type.ROOM_TYPE.ADULT_OCCUPANCY) {
      for (let i = 0; i < type.ROOM_TYPE.ADULT_OCCUPANCY; i++) {
        this.addOccupantRates(typeIndex);
        let occupArray = (<FormArray>this.bulkRateForm.controls['roomRateArray']).at(typeIndex).get('occupantRateArray') as FormArray;
        let occupArrayObj = occupArray.controls[i] as FormGroup;
        occupArrayObj.get('occupantNo').patchValue(i + 1);
      }
    }
  }

  addOccupantRates(typeIndex) {
    let fg = this.fb.group({
      occupantNo: this.fb.control('', Validators.required),
      occupantAmount: this.fb.control('', Validators.required)
    });
    (<FormArray>(<FormGroup>(<FormArray>this.bulkRateForm.controls['roomRateArray']).controls[typeIndex]).controls['occupantRateArray']).push(fg);
  }

  getBlackOut() {
    return (this.bulkRateForm.get('blackOutArray') as FormArray).controls;
  }

  removeBlackOut(index: number) {
    (this.bulkRateForm.get('blackOutArray') as FormArray).removeAt(index);
  }

  addBlackOut() {
    let codeIndex = (this.bulkRateForm.get('blackOutArray') as FormArray).length;
    if (codeIndex < 20) {
      let fg = this.fb.group({
        blackOutName: this.fb.control('', Validators.required),
        blackOutFrom: this.fb.control(''),
        blackOutTo: this.fb.control('')
      });
      (this.bulkRateForm.get('blackOutArray') as FormArray).push(fg);
    }
  }

  updateBulkRate() {
    this.loading = true;
    const formattedDateFrom = this.datePipe.transform(this.bulkRateForm.value.bulkUpdateFrom, 'dd-MM-yyyy');
    const formattedDateTo = this.datePipe.transform(this.bulkRateForm.value.bulkUpdateTo, 'dd-MM-yyyy');
    let formData: any;
    formData = this.bulkRateForm.value;
    let rateArray = formData['roomRateArray'];
    let roomPricing = [];
    for (let price of rateArray) {
      let occupantObj = {};
      for (let occup of price.occupantRateArray) {
        Object.assign(occupantObj, {
          [occup.occupantNo]: occup.occupantAmount
        });
      }
      roomPricing.push({
        "CATEGORY_ID": this.bulkRateForm.value.roomCategory,
        "TYPE_ID": price.roomTypeId,
        "RATE_PLAN_ID": price.ratePlanId,
        "OCCUPANT": occupantObj
      })
    }
    let array = formData['blackOutArray'];
    let blackOutArray = [];
    for (let i = 0; i < array.length; i++) {
      const blackOutFrom = this.datePipe.transform(array[i].blackOutFrom, 'dd-MM-yyyy');
      const blackOutTo = this.datePipe.transform(array[i].blackOutTo, 'dd-MM-yyyy');
      blackOutArray.push({
        "NAME": array[i].blackOutName,
        "DATE_FROM": blackOutFrom,
        "DATE_TO": blackOutTo
      })
    }
    let savedTypeFiles = {
      "DATE_FROM": formattedDateFrom,
      "DATE_TO": formattedDateTo,
      "PRICING": roomPricing,
      "BLACKOUT": blackOutArray
    }
    this.hotelService.bulkUpdateRatePlan(savedTypeFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.cancelUpdate();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.cancelUpdate();
      }
    })
  }

  cancelUpdate() {
    this.bulkRateForm.reset();
    this.selectedRoomTypes = [];
    this.selectedRatePlans = [];
    this.ratePlans = [];
    (this.bulkRateForm.controls['roomRateArray'] as FormArray).clear();
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}