import { Component, HostListener, Inject } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-transport-calendar',
  templateUrl: './transport-calendar.component.html',
  styleUrls: ['./transport-calendar.component.scss']
})
export class TransportCalendarComponent {
  minDate = new Date();
  currentDate = new Date();
  generateSlotsForm: FormGroup;
  date: any;
  disableBackArrow: boolean = true;
  loading: boolean = true;
  slots = [];
  difference = environment.SLOT_DIFFERENCE;
  minimumDuration = environment.SLOT_DIFFERENCE;
  calendarStartTime: any;
  calendarEndTime: any;
  calendarTime = [];
  updatedSlots = [];
  createdSlots = [];
  copyCreatedSlots = [];
  timeArr1 = [];
  timeArr2 = [];
  isGenerate: boolean = false;
  startTime: string;
  endTime: string;
  duration: any;
  minute: any;
  seconds: any;
  endTimeMinute: any;
  endTimeSeconds: any;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  endIndex: any;
  startIndex: any;
  disableGenearte: boolean = false;
  confirmationMsg: any = {};
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<TransportCalendarComponent>,
    private adminService: AdminService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.generateSlotsForm = this.fb.group({
      slotDate: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      duration: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
    this.generateSlotsForm.get("slotDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.generateSlotsForm.get("startDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.generateSlotsForm.get("endDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.calendarTime = res['SLOTS'];
      }
    })
    this.getSlotsByDate(this.currentDate);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getSlotsByDate(currentDate) {
    this.date = moment(currentDate).format("DD-MM-YYYY");
    this.adminService.getTransportSlotsByDate(this.date, this.data.TYPE_ID, this.data.CATEGORY_ID).subscribe({
      next: (data) => {
        this.loading = false;
        if (Array.isArray(data.object)) {
          this.slots = data.object;
          let SLOT_FROM = this.calendarTime[this.calendarTime.length - 1].ID;
          let SLOT_TO = this.calendarTime[0].ID;
          for (let sa_i = 0; sa_i < this.slots.length; sa_i++) {
            if (SLOT_FROM > this.slots[sa_i]["SLOT_FROM"]["ID"]) {
              SLOT_FROM = this.slots[sa_i]["SLOT_FROM"]["ID"];
            }
            if (SLOT_TO < this.slots[sa_i]["SLOT_TO"]["ID"]) {
              SLOT_TO = this.slots[sa_i]["SLOT_TO"]["ID"];
            }
          }
          if (this.slots.length != 0) {
            this.generateSlotsForm.controls['startTime'].setValue(SLOT_FROM);
            this.generateSlotsForm.controls['endTime'].setValue(SLOT_TO);
            this.generateSlotsForm.controls['duration'].setValue(data.object[0].DURATION);
            this.generateSlotsForm.controls['startDate'].disable();
            this.generateSlotsForm.controls['endDate'].disable();
            this.generateSlotsForm.controls['startTime'].disable();
            this.generateSlotsForm.controls['endTime'].disable();
            this.generateSlotsForm.controls['duration'].disable();
          } else {
            this.generateSlotsForm.controls['startDate'].enable();
            this.generateSlotsForm.controls['endDate'].enable();
            this.generateSlotsForm.controls['startTime'].enable();
            this.generateSlotsForm.controls['endTime'].enable();
            this.generateSlotsForm.controls['duration'].enable();
            this.disableGenearte = false;
          }
        }
        else {
          if (data.object.PROCESS_STATUS == 2) {
            this.slots = [];
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "warning"),
              data.status
            );
            this.disableGenearte = true;
            this.generateSlotsForm.controls['startDate'].disable();
            this.generateSlotsForm.controls['endDate'].disable();
            this.generateSlotsForm.controls['startTime'].disable();
            this.generateSlotsForm.controls['endTime'].disable();
            this.generateSlotsForm.controls['duration'].disable();
          }
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateValues() {
    this.minimumDuration = environment.SLOT_DIFFERENCE;
    this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
  }

  decreaseDuration() {
    if (this.generateSlotsForm.controls['startTime'].value && this.generateSlotsForm.controls['endTime'].value) {
      if (this.minimumDuration - this.difference != 0) {
        this.minimumDuration = this.minimumDuration - this.difference;
        this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
      }
    }
  }

  increaseDuration() {
    if (this.generateSlotsForm.controls['startTime'].value && this.generateSlotsForm.controls['endTime'].value) {
      let startIndex = this.calendarTime.findIndex(x => x.ID === this.generateSlotsForm.controls['startTime'].value);
      let endIndex = this.calendarTime.findIndex(x => x.ID === this.generateSlotsForm.controls['endTime'].value);
      var startTime = moment(this.calendarTime[startIndex].TIME, 'hh:mm');
      var endTime = moment(this.calendarTime[endIndex].TIME, 'hh:mm');
      var dif = moment.duration(endTime.diff(startTime));
      var minutes = ((dif.hours() * 60) + dif.minutes());
      if (this.minimumDuration + this.difference <= minutes) {
        this.minimumDuration = this.minimumDuration + this.difference;
        this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
      }
    }
  }

  selectDate(event) {
    this.loading = true;
    this.updatedSlots = [];
    this.startTime = '';
    this.endTime = '';
    this.duration = '';
    this.createdSlots = [];
    this.copyCreatedSlots = [];
    this.minimumDuration = environment.SLOT_DIFFERENCE;
    this.generateSlotsForm.reset();
    this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
    this.currentDate = new Date(event.value);
    this.generateSlotsForm.get("slotDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.generateSlotsForm.get("startDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.generateSlotsForm.get("endDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    this.getSlotsByDate(this.currentDate);
    if (this.currentDate.toDateString() == new Date().toDateString()) {
      this.disableBackArrow = true;
    } else {
      this.disableBackArrow = false;
    }
  }

  changeDates(action) {
    this.loading = true;
    this.updatedSlots = [];
    this.startTime = '';
    this.endTime = '';
    this.duration = '';
    this.createdSlots = [];
    this.copyCreatedSlots = [];
    this.minimumDuration = environment.SLOT_DIFFERENCE;
    this.generateSlotsForm.reset();
    this.generateSlotsForm.controls['duration'].setValue(this.minimumDuration);
    if (action == 'forward') {
      this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
      this.generateSlotsForm.get("slotDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
      this.generateSlotsForm.get("startDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
      this.generateSlotsForm.get("endDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    } else {
      this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1));
      this.generateSlotsForm.get("slotDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
      this.generateSlotsForm.get("startDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
      this.generateSlotsForm.get("endDate").setValue(this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'));
    }
    this.getSlotsByDate(this.currentDate);
    if (this.currentDate.toDateString() == new Date().toDateString()) {
      this.disableBackArrow = true;
    } else {
      this.disableBackArrow = false;
    }
  }

  generateSlots() {
    this.isGenerate = true;
    this.calendarStartTime = this.calendarTime[this.generateSlotsForm.value.startTime - 1].TIME;
    this.calendarEndTime = this.calendarTime[this.generateSlotsForm.value.endTime - 1].TIME;
    this.duration = this.generateSlotsForm.value.duration;
    let startTime = moment(this.calendarStartTime, 'HH:mm');
    let checkTime = moment(this.calendarStartTime, 'HH:mm');
    let copyTime = moment(this.calendarStartTime, 'HH:mm');
    let endTime = moment(this.calendarEndTime, 'HH:mm');
    while (startTime < endTime && endTime >= moment(checkTime.add(this.duration, 'minutes'), 'HH:mm')) {
      this.createdSlots.push({
        "FROM": startTime.format('HH:mm'),
        "TO": startTime.add(this.duration, 'minutes').format('HH:mm'),
        "ENABLED": ''
      })
      this.startIndex = this.calendarTime.find(x => x.TIME == copyTime.format('HH:mm'));
      copyTime.add(this.duration, 'minutes');
      this.endIndex = this.calendarTime.find(x => x.TIME == copyTime.format('HH:mm'));
      this.copyCreatedSlots.push({
        "FROM": this.startIndex.ID,
        "TO": this.endIndex.ID,
        "ENABLED": ''
      })
    }
  }

  updateorGenerateSlots() {
    this.loading = true;
    const formattedStartDate = this.datePipe.transform(this.generateSlotsForm.value.startDate, 'dd-MM-yyyy');
    const formattedEndDate = this.datePipe.transform(this.generateSlotsForm.value.endDate, 'dd-MM-yyyy');
    if (this.isGenerate == true) {
      let obj = {
        "FROM_DATE": formattedStartDate,
        "TO_DATE": formattedEndDate,
        "TYPE_ID": this.data.TYPE_ID,
        "CATEGORY_ID": this.data.CATEGORY_ID,
        "DURATION": this.duration,
        "SLOTS": this.copyCreatedSlots
      }
      this.adminService.saveTransportSlotsByDate(obj).subscribe({
        next: (data) => {
          this.loading = false;
          if (formattedStartDate < formattedEndDate) {
            this.dialogRef.close();
          }
          else {
            this.isGenerate = false;
            this.getSlotsByDate(this.currentDate);
          }
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "DATE": this.date,
        "TYPE_ID": this.data.TYPE_ID,
        "CATEGORY_ID": this.data.CATEGORY_ID,
        "SLOTS": this.updatedSlots
      }
      this.adminService.updateTransportSlotsByDate(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.getSlotsByDate(this.currentDate);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    this.updatedSlots = [];
    this.startTime = '';
    this.endTime = '';
    this.duration = '';
    this.createdSlots = [];
    this.copyCreatedSlots = [];
  }

  toggleforGenerateSlots(event, slot) {
    let startIndex = this.calendarTime.findIndex(x => x.TIME === slot.FROM);
    let endIndex = this.calendarTime.findIndex(x => x.TIME === slot.TO);
    let updateSlot = {
      "FROM": this.calendarTime[startIndex].ID,
      "TO": this.calendarTime[endIndex].ID,
      "ENABLED": event.checked
    }
    let index = this.copyCreatedSlots.findIndex(x => x.FROM === this.calendarTime[startIndex].ID && x.TO === this.calendarTime[endIndex].ID);
    this.copyCreatedSlots[index] = updateSlot;
  }

  toggle(event, id) {
    let updatedItem = { "SLOT_ID": id, "ENABLED": event.checked };
    let itemIndex = this.updatedSlots.findIndex(item => item.SLOT_ID == id);
    if (event.checked == true) {
      if (itemIndex != 0) {
        this.updatedSlots.push(updatedItem);
      } else {
        this.updatedSlots[itemIndex] = updatedItem;
      }
    } else {
      if (itemIndex != -1) {
        this.updatedSlots.splice(itemIndex, 1);
      }
    }
  }

  deleteSlots() {
    this.confirmationMsg.title = 'Are you sure you want to delete these slots ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        let obj = {
          "DATE": this.date,
          "TYPE_ID": this.data.TYPE_ID,
          "CATEGORY_ID": this.data.CATEGORY_ID
        }
        this.adminService.deleteTransportSlotsByDate(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Slots deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.generateSlotsForm.controls['startDate'].enable();
            this.generateSlotsForm.controls['endDate'].enable();
            this.generateSlotsForm.controls['startTime'].enable();
            this.generateSlotsForm.controls['endTime'].enable();
            this.generateSlotsForm.controls['duration'].enable();
            this.generateSlotsForm.get("startTime").setValue('');
            this.generateSlotsForm.get("endTime").setValue('');
            this.updateValues();
            this.getSlotsByDate(this.currentDate);
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

}