import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent {

  addClientForm: FormGroup;
  countries = [];
  loading: boolean = false;
  imageName = '';
  source_url;
  public bucketUrl = `${environment.BUCKET_URL}`;
  detailsPattern = /^[a-z A-Z]+$/;
  isPMSEnabled: boolean = false;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddClientComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private notify: NotificationService,
    private uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addClientForm = this.fb.group({
      image: [''],
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      company: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      owner: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      branch: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      address: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(300), Validators.pattern(this.detailsPattern)]],
      about: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(300), Validators.pattern(this.detailsPattern)]],
      description: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(300), Validators.pattern(this.detailsPattern)]]
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    if (!!this.data) {
      this.loading = true;
      this.adminService.getClientById(this.data.ID).subscribe(res => {
        this.loading = false;
        this.addClientForm.get("name").setValue(res.object.NAME);
        this.addClientForm.get("company").setValue(res.object.COMPANY);
        this.addClientForm.get("owner").setValue(res.object.OWNER);
        this.addClientForm.get("branch").setValue(res.object.BRANCH);
        this.addClientForm.get("address").setValue(res.object.ADDRESS);
        this.addClientForm.get("about").setValue(res.object.ABOUT);
        this.addClientForm.get("description").setValue(res.object.DESCRIPTION);
        this.isPMSEnabled = res.object.PMS;
        this.source_url = res.object.ATTACHMENT_SOURCE;
        let image = res.object.ATTACHMENT_SOURCE;
        if (image != null) {
          let selectedImage = image.split("/");
          this.imageName = selectedImage[selectedImage.length - 1];
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  enablePMS(isChecked) {
    this.isPMSEnabled = isChecked;
  }

  addClient() {
    this.loading = true;
    if (!!this.data) {
      let obj = {
        "ID": this.data.ID,
        "NAME": this.addClientForm.value.name,
        "SOURCE": this.source_url,
        "COMPANY": this.addClientForm.value.company,
        "BRANCH": this.addClientForm.value.branch,
        "ADDRESS": this.addClientForm.value.address,
        "OWNER": this.addClientForm.value.owner,
        "ABOUT": this.addClientForm.value.about,
        "DESCRIPTION": this.addClientForm.value.description,
        "PMS": this.isPMSEnabled
      }
      this.adminService.updateClient(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.addClientForm.value.name,
        "COMPANY": this.addClientForm.value.company,
        "BRANCH": this.addClientForm.value.branch,
        "ADDRESS": this.addClientForm.value.address,
        "OWNER": this.addClientForm.value.owner,
        "ABOUT": this.addClientForm.value.about,
        "DESCRIPTION": this.addClientForm.value.description,
        "PMS": this.isPMSEnabled
      }
      this.adminService.createClient(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      C_ID: this.data.ID,
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrlforClientLogo(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.source_url = res['PATH'];
      }
      let tempObjForUpload = {
        url: res['SIGNED_URL'],
        file: selectedFile
      }
      await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
        if (data) {
          this.notify.showNotification(
            'File uploaded successfully',
            "top",
            (!!colorObj[200] ? colorObj[200] : "success"),
            200
          );
          this.imageName = files[0].name;
        }
      }).catch((err) => {
        this.handleError(err);
      });
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

}