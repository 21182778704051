<mat-toolbar class="toolbar" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!mobile">
    <div fxFlex="60%"
        *ngIf="roleId == 1 || ((roleId == 2 || roleId == 3) && (currentUrl != '/guest-request' && currentUrl != '/internal-request' && currentUrl != '/sla-dashboard' && currentUrl != '/sla-management' && currentUrl != '/rooms' && currentUrl != '/room-type' && currentUrl != '/rate-plan' && currentUrl != '/bulk-inventory-update' && currentUrl != '/bulk-rate-update' && currentUrl != '/all-reservations' && currentUrl != '/arrivals' && currentUrl != '/departures')) || (roleId == 4 && (currentUrl != '/guest-request' && currentUrl != '/internal-request'))">
    </div>
    <mat-tab-group fxFlex="60%" fxLayoutAlign="start end" (selectedTabChange)="roomRateChanged($event)" [(selectedIndex)]="roomRateSelectedIndex"
        *ngIf="(roleId == 2) && (currentUrl == '/rooms' || currentUrl == '/room-type' || currentUrl == '/rate-plan')" style="margin-top: 15px;">
        <mat-tab label="Room Type"></mat-tab>
        <mat-tab label="Rate Plan"></mat-tab>
    </mat-tab-group>
    <mat-tab-group fxFlex="60%" fxLayoutAlign="start end" (selectedTabChange)="bulkUpdateChanged($event)" [(selectedIndex)]="bulkUpdateSelectedIndex"
        *ngIf="(roleId == 2) && (currentUrl == '/bulk-inventory-update' || currentUrl == '/bulk-rate-update')" style="margin-top: 15px;">
        <mat-tab label="Bulk Update Room Type"></mat-tab>
        <mat-tab label="Bulk Update Rate Plan"></mat-tab>
    </mat-tab-group>
    <mat-tab-group fxFlex="60%" fxLayoutAlign="start end" (selectedTabChange)="reservationsChanged($event)" [(selectedIndex)]="reservationSelectedIndex"
        *ngIf="(roleId == 2) && (currentUrl == '/all-reservations' || currentUrl == '/arrivals' || currentUrl == '/departures')" style="margin-top: 15px;">
        <mat-tab label="All Reservations"></mat-tab>
        <mat-tab label="Arrivals"></mat-tab>
        <mat-tab label="Departures"></mat-tab>
    </mat-tab-group>
    <mat-tab-group fxFlex="60%" fxLayoutAlign="start end" (selectedTabChange)="requestTabChanged($event)" [(selectedIndex)]="requestSelectedIndex"
        *ngIf="(roleId == 2 || roleId == 3 || roleId == 4) && (currentUrl == '/guest-request' || currentUrl == '/internal-request')" style="margin-top: 15px;">
        <mat-tab label="Guest Requests"></mat-tab>
        <mat-tab label="Internal Requests"></mat-tab>
    </mat-tab-group>
    <mat-tab-group fxFlex="60%" fxLayoutAlign="start end" (selectedTabChange)="slaTabChanged($event)" [(selectedIndex)]="slaSelectedIndex"
        *ngIf="(roleId == 2) && (currentUrl == '/sla-dashboard' || currentUrl == '/sla-management')" style="margin-top: 15px;">
        <mat-tab label="Dashboard"></mat-tab>
        <mat-tab label="SLA"></mat-tab>
    </mat-tab-group>
    <div fxLayout="row" fxFlex="40%" fxLayoutGap="13px" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="roleId == 2">
            <div class="code-head">QR Code</div>
            <div>
                <button mat-icon-button (click)="openQRCode(clientID)">
                    <iconify-icon icon="ic:baseline-qr-code-2"></iconify-icon>
                </button>
            </div>
        </div>
        <div (click)="openNotification()" style="cursor: pointer;">
            <iconify-icon class="notification" icon="mdi:notifications"></iconify-icon>
        </div>
        <div [matMenuTriggerFor]="user" class="profile-icon" fxLayout="row" fxLayoutAlign="end center"
            fxLayoutGap="20px">
            <div><img src="../../../assets/images/profile.png"></div>
            <div class="profileName" fxLayout="column" fxLayoutGap="10px">
                <div class="userHeading">{{userInfo.firstName}} {{userInfo.lastName}}</div>
                <div class="userSubheading">{{userRole}}</div>
            </div>
        </div>
        <mat-menu #user="matMenu" class="admin-menu">
            <div class="admin-option-container">
                <button mat-menu-item class="option-btn" (click)="viewProfile()">
                    <span class="option-text">My profile</span>
                </button>
                <mat-divider class="option-divider"></mat-divider>
                <button mat-menu-item class="option-btn" (click)="logout()">
                    <span class="option-text">Log out</span>
                </button>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>
<mat-toolbar class="toolbar" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="mobile">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div><img class="logo" src="{{imageURL}}"></div>
        <div class="head-text">{{hotelName}}</div>
    </div>
    <span style="flex: 1 1 auto;"></span>
    <div [matMenuTriggerFor]="user" class="profile-icon" fxLayout="row" fxLayoutAlign="end center">
        <div><img src="../../../assets/images/profile.png"></div>
    </div>
    <mat-menu #user="matMenu" class="admin-menu">
        <div class="admin-option-container">
            <button mat-menu-item class="option-btn" (click)="viewProfile()">
                <span class="option-text">My profile</span>
            </button>
            <mat-divider class="option-divider"></mat-divider>
            <button mat-menu-item class="option-btn" (click)="logout()">
                <span class="option-text">Log out</span>
            </button>
        </div>
    </mat-menu>
</mat-toolbar>