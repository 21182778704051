<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="header">
                    Check-Out List
                </div>
            </div>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="reservationId">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="17%"> Reservation ID </th>
                    <td mat-cell *matCellDef="let row">{{row.BOOKING.RESERVATION}}</td>
                </ng-container>
                <ng-container matColumnDef="guestName">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="23%"> Guest Name </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngFor="let user of row.USER">
                            <ng-container *ngIf="user.USER_TYPE.ID == 1">
                                {{user.FIRST_NAME}} {{user.LAST_NAME}}
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="checkInDate">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-In Date </th>
                    <td mat-cell *matCellDef="let row">{{row.BOOKING.FROM_DATE}}</td>
                </ng-container>
                <ng-container matColumnDef="checkOutDate">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-Out Date </th>
                    <td mat-cell *matCellDef="let row">{{row.BOOKING.TO_DATE}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Reservation Status </th>
                    <td mat-cell *matCellDef="let row">{{row.BOOKING.RESERVATION_STATUS.NAME}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <iconify-icon icon="iconamoon:eye-light" class="view-icon"></iconify-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true"
                (page)="changePage($event)"></mat-paginator>
        </div>
    </div>
</div>