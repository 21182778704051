<div fxLayout="column" style="padding: 30px;">
    <div fxLayout="row" fxLayoutAlign="end center">
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <qrcode elementType="svg" (qrCodeURL)="onChangeURL($event)" [width]="250" [qrdata]="clientAppUrl"
            [errorCorrectionLevel]="'H'"></qrcode>
    </div>
    <div>
        <a [href]="clientAppUrl" download="QR_Code" class="url-link">
            <iconify-icon icon="lucide:download" style="margin-right: 8px;"></iconify-icon>Download QR Code</a>
    </div>
</div>