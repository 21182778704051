<div class="container">
  <div>
    <p class="header" style="margin-top: -20px;">Today </p>
    <div class="card-container">
      <div class="card card1">
        <div class="card-header">Total Guest Requests</div>
        <div class="value">{{ total  }}</div>
      </div>
      <div class="card card2">
        <div class="card-header">SLA Violated</div>
        <div class="value">{{ slaViolated }}</div>
      </div>
      <div class="card card3">
        <div class="card-header">Success Rate</div>
        <div class="value"> {{successRate }}%</div>
      </div>
      <div class="card card4">
        <div class="card-header">CSAT</div>
        <div class="value"> - </div>
      </div>
    </div>
  </div>

  <div>
    <p class="header">Total vs Violated SLA's</p>
    <div>
      <app-sla-graph></app-sla-graph>
    </div>
  </div>
  <div class="flex-container">
    <div class="csat-header">
      <p class="header">CSAT</p>
      <div>
        <app-csat-graph></app-csat-graph>
      </div>
    </div>

    <div class="success-header">
      <p class="header">Success Ratio</p>
      <div>
        <app-success-rate-graph></app-success-rate-graph>
      </div>
    </div>
  </div>

  <div>
    <!-- <p class="header">Total vs Violated SLA's</p> -->
    <div>
      <!-- <app-customer-support></app-customer-support> -->
    </div>
  </div>
</div>
