<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div>
            <div fxLayout="row" fxLayoutGap="25px">
                <div fxFlex="65%" fxLayout="column" fxLayoutGap="15px">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                        <div class="back-arrow" (click)="backToRoomTypes()">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="room-head">
                            {{updateType ? 'Edit Room Type' : 'Add Room Type'}}
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <form [formGroup]="roomTypeForm" fxLayout="column" fxLayoutGap="3%">
                        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        What type of room is this?
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="roomType"
                                            placeholder="Select">
                                            <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                                                {{room.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        What is its room class?
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="roomClass"
                                            placeholder="Select">
                                            <mat-option *ngFor="let class of roomClasses" [value]="class.ID">
                                                {{class.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Number of bathrooms
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="bathrooms"
                                            placeholder="Select">
                                            <mat-option *ngFor="let num of quantities" [value]="num.ID">
                                                {{num.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Is smoking allowed in this room type?
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="isSmoking"
                                            placeholder="Select">
                                            <mat-option *ngFor="let smoke of smokingAllowed" [value]="smoke.ID">
                                                {{smoke.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Is this room type wheelchair accessible?
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="isWheelchair"
                                            placeholder="Select">
                                            <mat-option *ngFor="let res of responses" [value]="res.ID">
                                                {{res.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Service
                                    </div>
                                    <div>
                                        <mat-radio-group (click)="$event.stopPropagation()" formControlName="roomSpace">
                                            <mat-radio-button class="radio-btn" *ngFor="let space of roomSpaces" [value]="space.ID">
                                                <span class="radio-label">{{space.NAME}}</span>
                                                <span class="radio-desc">({{space.DETAIL}})</span>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Add Custom Room Name
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="roomName"
                                            placeholder="Enter Custom Name" maxlength="200">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Room Code
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="roomCode"
                                            placeholder="Enter Room Code" maxlength="200">
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="99%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Room Description
                                    </div>
                                    <div>
                                        <textarea class="text-area" formControlName="roomDescription" placeholder="Enter Description" maxlength="500"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Upload Image
                                    </div>
                                    <div class="text-box" fxLayout="row" style="height:auto" appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                                        <div>
                                            <input type="file" formControlName="roomImage" class="doc_file" id="doc_file"
                                                style="display: none" #fileInput hidden accept=".jpg,.jpeg,.png"
                                                (change)="onFileSelected($event.target.files)" />
                                            <mat-icon (click)="fileInput.click()" style="cursor: pointer;">upload</mat-icon>
                                        </div>
                                        <div (click)="fileInput.click()">
                                            <span class="upload-text1">Click to upload </span><span class="upload-text2">
                                                or drag and drop jpeg, jpg, png
                                            </span>
                                        </div>
                                    </div>
                                    <div class="file-name" (click)="preview(source_url)" style="cursor: pointer;" *ngIf="!!imageName">
                                        {{imageName}}
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Choose Room Amenities
                                    </div>
                                    <div>
                                        <mat-select class="text-box" formControlName="roomAmenities"
                                            placeholder="Select" multiple>
                                            <mat-select-trigger>
                                                <mat-chip-listbox>
                                                    <mat-chip #chip
                                                        *ngFor="let room of roomTypeForm.controls['roomAmenities'].value"
                                                        [removable]="true" (removed)="removeAmenities(room)"
                                                        class="chip-label">
                                                        {{room}}
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let amenity of roomAmenities" [value]="amenity.NAME">
                                                {{amenity.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Bed and Occupancy
                                    </div>
                                    <div formArrayName="occupancyArray">
                                        <div fxLayout="column" fxLayoutGap="30px"
                                            *ngFor="let guestControl of getOccupancy(); let in = index;"
                                            formGroupName="{{in}}">
                                            <div fxLayout="row" fxLayoutGap="35px" style="padding-bottom: 10px;">
                                                <div fxFlex="46%">
                                                    <mat-select class="text-box" appearance="fill" formControlName="bedType" placeholder="Select">
                                                        <mat-option *ngFor="let bed of bedOccupancy" [value]="bed.ID">
                                                            {{bed.NAME}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <div fxFlex="35%">
                                                    <mat-select class="text-box" appearance="fill" formControlName="occupancyNo" placeholder="Select">
                                                        <mat-option *ngFor="let num of occupants" [value]="num.ID">
                                                            {{num.NAME}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <button mat-icon-button class="remove-btn" (click)="removeOccupancy(in)"
                                                    [disabled]="roomTypeForm.controls.occupancyArray.controls.length == 1">
                                                    <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <button mat-button class="add-occup-btn"
                                                [disabled]="getOccupancy().length == 10" (click)="addOccupancy()">
                                                + Add More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Adult Occupancy
                                    </div>
                                    <div>
                                        <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                            class="text-box" formControlName="adultOccupancy" placeholder="Enter Adult Occupancy">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Child Occupancy
                                    </div>
                                    <div>
                                        <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                            class="text-box" formControlName="childOccupancy" placeholder="Enter Child Occupancy">
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="10px">
                                    <div fxLayout="column" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Can extra bed or cots be added to this room?
                                        </div>
                                        <div>
                                            <mat-radio-group (click)="$event.stopPropagation()" formControlName="isExtraBed">
                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'isExtraBed')">
                                                    <span class="radio-label">{{res.NAME}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="extraBedNeeded">
                                        <div>
                                            <mat-checkbox class="checkbox-area" formControlName="extraCot" (change)="toggle($event, 'extraCot')">
                                                <span class="bed-options">
                                                    Cots/Infant Beds
                                                </span>
                                            </mat-checkbox>
                                        </div>
                                        <div fxLayout="column" fxLayoutGap="15px" *ngIf="extraCotDetails">
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        How Many
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="extraCotNo" placeholder="Select">
                                                            <mat-option *ngFor="let num of quantities" [value]="num.ID">
                                                                {{num.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Cost
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="extraCotCost" placeholder="Select"
                                                            (selectionChange)="toggle($event, 'extraCotCost')">
                                                            <mat-option *ngFor="let price of pricingTypes" [value]="price.ID">
                                                                {{price.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px" *ngIf="cotCost">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Amount/per day
                                                    </div>
                                                    <div>
                                                        <input type="number" class="text-box" formControlName="extraCotAmount" placeholder="Enter">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <mat-checkbox class="checkbox-area" formControlName="extraBed" (change)="toggle($event, 'extraBed')">
                                                <span class="bed-options">
                                                    Extra Beds
                                                </span>
                                            </mat-checkbox>
                                        </div>
                                        <div fxLayout="column" fxLayoutGap="15px" *ngIf="extraBedDetails">
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div>
                                                        <div>
                                                            <mat-select class="text-box" appearance="fill" formControlName="extraBedType" placeholder="Select">
                                                                <mat-option *ngFor="let bed of extraBedOccupancy" [value]="bed.ID">
                                                                    {{bed.NAME}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="extraBedNo" placeholder="Select">
                                                            <mat-option *ngFor="let num of quantities" [value]="num.ID">
                                                                {{num.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Cost
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="extraBedCost" placeholder="Select"
                                                            (selectionChange)="toggle($event, 'extraBedCost')">
                                                            <mat-option *ngFor="let price of pricingTypes" [value]="price.ID">
                                                                {{price.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px" *ngIf="bedCost">
                                                    <div class="box-name">
                                                        Amount/per day
                                                    </div>
                                                    <div>
                                                        <input type="number" class="text-box" formControlName="extraBedAmount" placeholder="Enter">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                    <div fxLayout="column" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Do you want to set Over-Booking Limit?
                                        </div>
                                        <div>
                                            <mat-radio-group (click)="$event.stopPropagation()" formControlName="overBookingLimit">
                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'overBookingLimit')">
                                                    <span class="radio-label">{{res.NAME}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="overBookingSet">
                                        <div fxLayout="row" fxLayoutGap="35px" style="padding-bottom: 10px;">
                                            <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Capacity
                                                </div>
                                                <div>
                                                    <div>
                                                        <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                            class="text-box" formControlName="overBookingCapacity" placeholder="Enter">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                    <div fxLayout="column" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Do you want to Derive?
                                        </div>
                                        <div>
                                            <mat-radio-group (click)="$event.stopPropagation()" formControlName="derivedRoom">
                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID">
                                                    <span class="radio-label">{{res.NAME}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Room Type
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill" formControlName="derivedRoomType" placeholder="Select">
                                                    <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                                                        {{room.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Discount
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxFlex="46%">
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="derivedRoomDiscount" placeholder="Select">
                                                            <mat-option>%</mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                                <div fxFlex="46%">
                                                    <div>
                                                        <input type="number" class="text-box" formControlName="derivedRoomDiscountValue" placeholder="Enter">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                            <button mat-button class="save-btn" *ngIf="!updateType" (click)="saveRoomType()"
                                [disabled]="roomTypeForm.invalid || source_url == null || source_url == ''">Save</button>
                            <button mat-button class="save-btn" *ngIf="updateType" (click)="updateRoomType()"
                                [disabled]="roomTypeForm.invalid || source_url == null || source_url == ''">Update</button>
                            <button mat-button class="cancel-btn" (click)="backToRoomTypes()">Cancel</button>
                        </div>
                    </form>
                </div>
                <div fxFlex="35%">
                    <mat-card class="info-card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                        <img src="../../../../../assets/images/info-image.png">
                        <div class="info-head">Need your Room Information</div>
                        <div class="info-desc">Add a new room type, room, or individual unit. To set up a complex room
                            type then this form allows. Select what type of room, so that hoteliers can create right
                            room for guests.</div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>