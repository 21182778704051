import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-app-users',
  templateUrl: './add-app-users.component.html',
  styleUrls: ['./add-app-users.component.scss']
})
export class AddAppUsersComponent {

  addAppUserForm: FormGroup;
  userTypes = [];
  guestsInfo: any = [];
  loading: boolean = false;
  public emailPattern = `${environment.EMAIL_PATTERN}`;
  minFromDate: Date;
  minToDate: Date;
  hideID: boolean = false;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  userIndex: number;
  tooltip: string = "";
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddAppUsersComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addAppUserForm = this.fb.group({
      bookingId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      mobileNumber: ['', Validators.required],
      room: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      secondaryGuestsArray: fb.array([])
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.userTypes = res['USER_TYPE'];
      }
    })
    if (this.data) {
      this.loading = true;
      this.adminService.getAppUserByID(this.data.ID).subscribe(res => {
        this.loading = false;
        this.addAppUserForm.get("bookingId").setValue(res.object.BOOKING_ID);
        this.addAppUserForm.get("firstName").setValue(res.object.HOST.FIRST_NAME);
        this.addAppUserForm.get("lastName").setValue(res.object.HOST.LAST_NAME);
        this.addAppUserForm.get("email").setValue(res.object.HOST.EMAIL_ID);
        this.addAppUserForm.get("mobileNumber").setValue(res.object.HOST.MOBILE);
        this.addAppUserForm.get("room").setValue(res.object.HOST.ROOM);
        const startDate = moment(res.object.FROM_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        const endDate = moment(res.object.TO_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        this.addAppUserForm.get("fromDate").setValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
        this.addAppUserForm.get("toDate").setValue(this.datePipe.transform(endDate, 'yyyy-MM-dd'));
        let currentDate = new Date();
        let fromDate = new Date(startDate);
        if (currentDate <= fromDate) {
          this.minToDate = new Date(startDate);
        }
        else {
          this.minToDate = new Date();
        }
        let toDate = new Date(endDate);
        if (toDate < currentDate) {
          this.addAppUserForm.controls['toDate'].disable();
          this.tooltip = "Your checkout date cannot be extended";
        }
        this.guestsInfo = res.object.GUEST;
        if (Array.isArray(this.guestsInfo) && this.guestsInfo.length > 0) {
          for (let i = 0; i < this.guestsInfo.length; i++) {
            this.addSecondaryGuests();
            let guestArray = this.addAppUserForm.get('secondaryGuestsArray') as FormArray;
            let guestArrayObj = guestArray.controls[i] as FormGroup;
            guestArrayObj.get('guestID').patchValue(this.guestsInfo[i]['ID']);
            guestArrayObj.get('guestFirstName').patchValue(this.guestsInfo[i]['FIRST_NAME']);
            guestArrayObj.get('guestLastName').patchValue(this.guestsInfo[i]['LAST_NAME']);
            guestArrayObj.get('guestRoom').patchValue(this.guestsInfo[i]['ROOM']);
            guestArrayObj.get('guestEmail').patchValue(this.guestsInfo[i]['EMAIL_ID']);
            guestArrayObj.get('guestMobileNumber').patchValue(this.guestsInfo[i]['MOBILE']);
            guestArrayObj.controls['guestRoom'].disable();
            guestArrayObj.controls['guestEmail'].disable();
            guestArrayObj.controls['guestMobileNumber'].disable();
          };
        }
        // else {
        //   this.addSecondaryGuests();
        // }
      });
      this.addAppUserForm.controls['bookingId'].disable();
      this.addAppUserForm.controls['email'].disable();
      this.addAppUserForm.controls['mobileNumber'].disable();
      this.addAppUserForm.controls['room'].disable();
      this.addAppUserForm.controls['fromDate'].disable();
    }
    else {
      this.addSecondaryGuests();
      this.minFromDate = new Date();
      this.minToDate = new Date();
      this.addAppUserForm.controls['bookingId'].enable();
      this.addAppUserForm.controls['email'].enable();
      this.addAppUserForm.controls['mobileNumber'].enable();
      this.addAppUserForm.controls['room'].enable();
      this.addAppUserForm.controls['fromDate'].enable();
      this.addAppUserForm.controls['toDate'].enable();
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  changeDate() {
    this.minToDate = new Date(this.addAppUserForm.value.fromDate);
    this.addAppUserForm.controls['toDate'].reset();
  }

  getSecondaryGuests() {
    return (this.addAppUserForm.get('secondaryGuestsArray') as FormArray).controls;
  }

  removeSecondaryGuests(index: number) {
    (<FormArray>this.addAppUserForm.get('secondaryGuestsArray')).removeAt(index);
  }

  addSecondaryGuests() {
    let codeIndex = (<FormArray>this.addAppUserForm.get('secondaryGuestsArray')).length;
    if (codeIndex < 20) {
      let fg = this.fb.group({
        guestID: this.fb.control(''),
        guestFirstName: this.fb.control(''),
        guestLastName: this.fb.control(''),
        guestRoom: this.fb.control(''),
        guestEmail: this.fb.control('', Validators.pattern(this.emailPattern)),
        guestMobileNumber: this.fb.control('')
      });
      (<FormArray>this.addAppUserForm.get('secondaryGuestsArray')).push(fg);
      this.userIndex = (<FormArray>this.addAppUserForm.get('secondaryGuestsArray')).length - 1;
    }
  }

  addAppUser() {
    let formData: any;
    formData = this.addAppUserForm.value;
    let secondaryGuest = formData['secondaryGuestsArray'];
    let secondaryGuestsArray = [];
    if (this.data != null) {
      for (let i = 0; i < secondaryGuest.length; i++) {
        if (!!secondaryGuest[i].guestFirstName) {
          secondaryGuestsArray.push({
            "ID": secondaryGuest[i].guestID,
            "USER_TYPE": "2",
            "FIRST_NAME": secondaryGuest[i].guestFirstName,
            "LAST_NAME": secondaryGuest[i].guestLastName
          })
        }
      }
      let obj = {
        "TO_DATE": moment(this.addAppUserForm.value.toDate).format('DD-MM-YYYY'),
        "HOST": {
          "ID": this.data.ID,
          "USER_TYPE": "1",
          "FIRST_NAME": this.addAppUserForm.value.firstName,
          "LAST_NAME": this.addAppUserForm.value.lastName
        },
        "GUEST": secondaryGuestsArray
      }
      this.loading = true;
      this.adminService.updateAppUser(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else {
      this.loading = true;
      for (let i = 0; i < secondaryGuest.length; i++) {
        if (!!secondaryGuest[i].guestFirstName) {
          secondaryGuestsArray.push({
            "USER_TYPE": "2",
            "FIRST_NAME": secondaryGuest[i].guestFirstName,
            "LAST_NAME": secondaryGuest[i].guestLastName,
            "ROOM": secondaryGuest[i].guestRoom,
            "MOBILE": secondaryGuest[i].guestMobileNumber,
            "EMAIL_ID": secondaryGuest[i].guestEmail
          })
        }
      }
      let obj = {
        "BOOKING_ID": this.addAppUserForm.value.bookingId,
        "FROM_DATE": moment(this.addAppUserForm.value.fromDate).format('MM-DD-YYYY'),
        "TO_DATE": moment(this.addAppUserForm.value.toDate).format('MM-DD-YYYY'),
        "HOST": {
          "USER_TYPE": "1",
          "FIRST_NAME": this.addAppUserForm.value.firstName,
          "LAST_NAME": this.addAppUserForm.value.lastName,
          "ROOM": this.addAppUserForm.value.room,
          "MOBILE": this.addAppUserForm.value.mobileNumber,
          "EMAIL_ID": this.addAppUserForm.value.email
        },
        "GUEST": secondaryGuestsArray
      }
      this.adminService.createAppUser(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}