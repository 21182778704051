<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span class="header">{{notCreate ? 'Update rating question':'Add rating question'}}</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button mat-dialog-close class="close-btn">
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="addForm">
        <div mat-dialog-content>
            <div fxLayout="column" fxLayoutGap="9px">
                <div class="heading">Question</div>
                <div>
                    <textarea type="text" class="form" formControlName="question" placeholder="Enter Question"></textarea>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button [ngClass]="this.addForm.invalid ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.addForm.invalid" (click)="saveRating()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>