<div fxLayout="column" fxLayoutAlign="center center">
    <div mat-dialog-content class="content">
        You have made the same type of booking today. Please select YES to proceed and NO to cancel.
    </div>
    <mat-dialog-actions class="actions">
        <button mat-stroked-button class="save-button" mat-dialog-close (click)="onSubmit()">
            Yes
        </button>
        <button mat-stroked-button class="button" mat-dialog-close (click)="onClose()">
            No
        </button>
    </mat-dialog-actions>
</div>