import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-room-dining-types',
  templateUrl: './room-dining-types.component.html',
  styleUrls: ['./room-dining-types.component.scss']
})
export class RoomDiningTypesComponent {

  diningDetailForm: FormGroup;
  foodTypes = [];
  imageName: any
  savedTypeFiles = {};
  source_url = '';
  typeById: any = [];
  foodFilters = [];
  enabledFilters: any = [];
  appliedFilters = [];
  filterIds = [];
  isAvailable: boolean = true;
  availability: string = "Available";
  loading: boolean = false;
  public bucketUrl = `${environment.BUCKET_URL}`;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  notCreate: boolean = false;
  currencyInfo: any;
  currency: any;
  diningTaxes: any = [];
  taxIds = [];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<RoomDiningTypesComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService,
    public hotelService: HotelServicesService,
    public adminService: AdminService,
    private cs: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.diningDetailForm = this.fb.group({
      image: [''],
      diningName: ['', Validators.required],
      tag: [''],
      price: ['', Validators.required],
      description: [''],
      foodType: [''],
      privacyPolicy: [''],
      tax: [[]]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.foodTypes = res['FOOD_TYPE'];
      }
    })
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getTaxes();
    if (this.data.name == "add_type") {
      this.getDiningFilters();
      this.diningDetailForm.get("foodType").setValue(1);
    }
    if (this.data.name == "edit_type") {
      this.notCreate = true;
      this.loading = true;
      this.hotelService.getDiningTypeById(this.data.typeID).subscribe({
        next: (res) => {
          this.loading = false;
          this.typeById = res.object;
          let image = res.object.ATTACHMENT_SOURCE;
          let selectedImage = image.split("/");
          this.imageName = selectedImage[selectedImage.length - 1];
          this.source_url = res.object.ATTACHMENT_SOURCE;
          this.diningDetailForm.get("diningName").setValue(res.object.NAME);
          this.diningDetailForm.get("tag").setValue(res.object.TAG);
          this.diningDetailForm.get("price").setValue(res.object.PRICE);
          this.diningDetailForm.get("description").setValue(res.object.DESCRIPTION);
          this.diningDetailForm.get("foodType").setValue(res.object.FOOD_TYPE.ID);
          this.diningDetailForm.get("privacyPolicy").setValue(res.object.TERMS_CONDITIONS);
          for (let id of res.object.SERVICETAX) {
            this.taxIds.push(id.TAX_ID?.ID);
          }
          this.diningDetailForm.get("tax").setValue(this.taxIds);
          if (!!res.object.FILTER_DATA) {
            this.enabledFilters = res.object.FILTER_DATA;
            for (let filter of this.enabledFilters) {
              if (filter.ENABLED == true) {
                this.filterIds.push(filter.FILTER_ID.ID);
              }
            }
          }
          else {
            this.filterIds = [];
          }
          this.isAvailable = res.object.AVAILABLE;
          if (this.isAvailable == true) {
            this.availability = "Available";
          }
          else if (this.isAvailable == false) {
            this.availability = "Not available";
          }
          this.getDiningFilters();
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getTaxes() {
    this.loading = true;
    this.cs.getTaxBySearchKey('', 6).subscribe({
      next: (res) => {
        this.diningTaxes = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getDiningFilters() {
    this.loading = true;
    this.adminService.getDiningFilter().subscribe({
      next: (data) => {
        for (let ind of data.object) {
          if (this.filterIds.indexOf(ind.ID) != -1) {
            let obj = {
              "ID": ind.ID,
              "NAME": ind.NAME,
              "ENABLED": true
            }
            this.foodFilters.push(obj);
          }
          if (this.filterIds.indexOf(ind.ID) == -1) {
            let obj = {
              "ID": ind.ID,
              "NAME": ind.NAME,
              "ENABLED": false
            }
            this.foodFilters.push(obj);
          }
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: "6",
      SECTION: 'type',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
      }
      let tempObjForUpload = {
        url: res['SIGNED_URL'],
        file: selectedFile
      }
      await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
        if (data) {
          this.loading = false;
          this.notify.showNotification(
            'File uploaded successfully',
            "top",
            (!!colorObj[200] ? colorObj[200] : "success"),
            200
          );
          this.imageName = files[0].name;
        }
      }).catch((err) => {
        this.handleError(err);
      });
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  available(isChecked) {
    this.isAvailable = isChecked;
    if (this.isAvailable == true) {
      this.availability = "Available";
    }
    else {
      this.availability = "Not available";
    }
  }

  applyFilter(id, isChecked) {
    if (isChecked == true) {
      this.filterIds.push(id);
    }
    else if (isChecked == false) {
      for (let i = 0; i < this.filterIds.length; i++) {
        if (this.filterIds[i] == id) {
          this.filterIds.splice(i, 1);
        }
      }
    }
  }

  saveTypes() {
    for (let filter of this.foodFilters) {
      if (this.filterIds.indexOf(filter.ID) != -1) {
        let obj = {
          "ID": filter.ID,
          "ENABLED": true
        }
        this.appliedFilters.push(obj);
      }
      if (this.filterIds.indexOf(filter.ID) == -1) {
        let obj = {
          "ID": filter.ID,
          "ENABLED": false
        }
        this.appliedFilters.push(obj);
      }
    }
    let name = this.diningDetailForm.value.diningName.trim();
    if (name.length == 0) {
      this.notify.showMessage(
        "Name should not be empty",
        "top",
        "warning"
      )
      this.diningDetailForm.get("diningName").setValue('');
    }
    else {
      this.loading = true;
      if (this.data.name == "add_type") {
        this.savedTypeFiles = {
          "NAME": this.diningDetailForm.value.diningName.trim(),
          "TAG": this.diningDetailForm.value.tag,
          "PRICE": this.diningDetailForm.value.price,
          "DESCRIPTION": this.diningDetailForm.value.description,
          "TERMS_CONDITIONS": this.diningDetailForm.value.privacyPolicy,
          "TAX_ID": this.diningDetailForm.value.tax,
          "CATEGORY_ID": this.data.categoryID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111, //ToDo: temp value
          "FOOD_ID": this.diningDetailForm.value.foodType,
          "AVAILABLE": this.isAvailable,
          "FILTER_DATA": this.appliedFilters
        }
        this.hotelService.addDiningType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
      else if (this.data.name == "edit_type") {
        this.savedTypeFiles = {
          "ID": this.typeById.ID,
          "NAME": this.diningDetailForm.value.diningName.trim(),
          "TAG": this.diningDetailForm.value.tag,
          "PRICE": this.diningDetailForm.value.price,
          "DESCRIPTION": this.diningDetailForm.value.description,
          "TERMS_CONDITIONS": this.diningDetailForm.value.privacyPolicy,
          "TAX_ID": this.diningDetailForm.value.tax,
          "CATEGORY_ID": this.typeById.CATEGORY.ID,
          "ATTACHMENT_SOURCE": this.source_url,
          "POSITION": 1111, //ToDo: this.typeById.POSITION,
          "FOOD_TYPE_ID": this.diningDetailForm.value.foodType,
          "AVAILABLE": this.isAvailable,
          "FILTER_DATA": this.appliedFilters
        }
        this.hotelService.updateDiningType(this.savedTypeFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
    }
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

}