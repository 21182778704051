<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div class="spa-services" fxLayout="column">
            <div class="spa-header" fxLayout="row" fxFlex="3pc">
                <div class="room-head">
                    Room Type and Rate Plans
                </div>
            </div>
            <div class="spa-banners" fxLayout="column" fxFlex="10pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Banners
                    </div>
                    <div>
                        <button class="add-btn" (click)="addBanners()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 25px">
                    <div style="align-self: center;" *ngIf="roomBanners.length != 0">
                        <mat-button (click)="bannerScrollLeft()" class="scroll-btn">
                            <img src="assets/images/arrow_back.svg">
                        </mat-button>
                    </div>
                    <div #bannerContent fxLayout="row" fxLayoutGap="30px" class="banners"
                        *ngIf="roomBanners.length != 0">
                        <div *ngFor="let banner of roomBanners; let i=index;">
                            <div id="banner-images" fxLayout="row">
                                <img src="{{bucketUrl + banner.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="img_preview"
                                    (click)="preview(banner.ATTACHMENT_SOURCE)">
                                <div id="swap" style="color: #000000; font-weight: 600; cursor: pointer;"
                                    [matMenuTriggerFor]="bannerOptions">
                                    <mat-icon>more_vert</mat-icon>
                                </div>
                                <mat-menu #bannerOptions="matMenu">
                                    <div class="option-container">
                                        <button mat-menu-item (click)="updateBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -7px;">edit</mat-icon></span>
                                            <span class="option-text">Edit</span>
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button mat-menu-item (click)="deleteBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -9px;">delete_outline</mat-icon></span>
                                            <span class="option-text">Delete</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div style="align-self: center;" *ngIf="roomBanners.length != 0">
                        <mat-button (click)="bannerScrollRight()" class="scroll-btn">
                            <img src="assets/images/arrow_forward.svg">
                        </mat-button>
                    </div>
                    <div class="text-message" *ngIf="roomBanners.length == 0">
                        <span>
                            <br>
                            No banners available ....
                            <br>
                        </span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="spa-categories" fxLayout="column" fxFlex="11pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Category
                    </div>
                    <div>
                        <button class="add-btn" (click)="addCategories()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 25px">
                    <div style="align-self: center;" *ngIf="roomCategories.length != 0">
                        <mat-button (click)="categoryScrollLeft()" class="scroll-btn">
                            <img src="assets/images/arrow_back.svg">
                        </mat-button>
                    </div>
                    <div #categoryContent fxLayout="row" fxLayoutGap="30px" class="banners"
                        *ngIf="roomCategories.length != 0">
                        <div *ngFor="let category of roomCategories; let i=index;"
                            [ngStyle]="{'border-bottom': currentActive === i ? '7px solid #000;' : '1px solid #E8E8E8' }"
                            fxLayout="column" fxLayoutAlign="center center"
                            (click)="displayDetails(i, category.NAME, category.ID)">
                            <div fxLayout="row" id="category-images">
                                <img src="{{bucketUrl + category.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="img_preview">
                                <div id="category-swap"><mat-icon
                                        style="color: #000000; font-weight: 600; cursor: pointer;"
                                        [matMenuTriggerFor]="categoryOptions">more_vert</mat-icon></div>
                                <mat-menu #categoryOptions="matMenu">
                                    <div class="option-container">
                                        <button mat-menu-item (click)="updateCategory(category.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -7px;">edit</mat-icon></span>
                                            <span class="option-text">Edit</span>
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button mat-menu-item (click)="deleteCategory(category.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -9px;">delete_outline</mat-icon></span>
                                            <span class="option-text">Delete</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                            <div style="padding:4px" class="category-name">{{category.NAME}}</div>
                        </div>
                    </div>
                    <div style="align-self: center;" *ngIf="roomCategories.length != 0">
                        <mat-button (click)="categoryScrollRight()" class="scroll-btn">
                            <img src="assets/images/arrow_forward.svg">
                        </mat-button>
                    </div>
                    <div class="text-message" *ngIf="roomCategories.length == 0">
                        <span>
                            <br>
                            No categories available ....
                            <br>
                        </span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>

            <div class="spa-details" fxLayout="column" fxLayoutGap="15px" fxFlex="23%" style="margin-top: 20px;">
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div class="box-headers">
                        Type
                    </div>
                    <div>
                        <button class="add-btn" (click)="addTypes()" [disabled]="roomCategories.length == 0 || accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="15px">
                    <mat-card class="type-card" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngFor="let type of roomTypes; let i=index;">
                        <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                            <div (click)="preview(type.ATTACHMENT_SOURCE)">
                                <img src="{{bucketUrl + type.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="typeimg_preview">
                            </div>
                            <div fxLayout="column" fxLayoutGap="5px">
                                <div class="type-name">{{type.NAME}} - {{type.ROOM_CLASS_ID.NAME}}</div>
                                <div class="type-desc" matTooltip="{{type.DESCRIPTION}}">{{type.DESCRIPTION}}</div>
                            </div>
                        </div>
                        <div fxFlex="45%" fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="60%" fxLayout="column" fxLayoutGap="10px" class="amenities-col">
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <iconify-icon icon="material-symbols:info-outline-rounded" matTooltip="{{type.ROOM_SPACE_ID.DETAIL}}" style="color: #EF9E00;"></iconify-icon>
                                    <div class="customer-no">{{type.ROOM_SPACE_ID.NAME}}</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <iconify-icon icon="material-symbols:info-outline-rounded" style="color: #EF9E00;"></iconify-icon>
                                    <div class="customer-no">{{type.ROOM_TYPE_ID.NAME}}</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <iconify-icon icon="material-symbols:info-outline-rounded" style="color: #EF9E00;"></iconify-icon>
                                    <div class="customer-no">{{type.CODE}}</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <img src="../../../../assets/images/tick.png" *ngIf="type.SMOKING_ALLOWED_ID.ID == 2">
                                    <img src="../../../../assets/images/cancel.png" *ngIf="type.SMOKING_ALLOWED_ID.ID == 1">
                                    <div class="customer-no">Smoking</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <img src="../../../../assets/images/tick.png" *ngIf="type.WHEELCHAIR_ACCESSIBLE_ID.ID == 1">
                                    <img src="../../../../assets/images/cancel.png" *ngIf="type.WHEELCHAIR_ACCESSIBLE_ID.ID == 2">
                                    <div class="customer-no">Wheelchair</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px">
                                    <img src="../../../../assets/images/tick.png">
                                    <div class="customer-no">{{type.BATHROOM_ID.NAME}} bathrooms</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" *ngFor="let amenity of type.ROOM_AMENITIES_ID">
                                    <img src="../../../../assets/images/tick.png">
                                    <div class="customer-no">{{amenity.AMENITIES_ID.NAME}}</div>
                                </div>
                            </div>
                            <div fxFlex="40%" fxLayout="column" fxLayoutGap="10px">
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <img src="../../../../assets/images/adult.png">
                                    <div class="customer-no">{{type.ADULT_OCCUPANCY}} Adults</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <img src="../../../../assets/images/child.png">
                                    <div class="customer-no">{{type.CHILD_OCCUPANCY}} Child</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <iconify-icon icon="material-symbols:meeting-room-outline-rounded" style="color: #828282;"></iconify-icon>
                                    <div class="customer-no">{{type.ROOMS.length}} room{{type.ROOMS.length > 1? 's' : ''}} associated</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px" (click)="editTypes(type.ID)" [ngClass]="accessType == 2 ? 'edit-disabled' : 'edit-btn'">
                                    <img src="../../../../assets/images/edit_icon.png">
                                    <div class="actions">Edit</div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px" class="edit-btn" (click)="viewRatePlans(type.ID)">
                                    <img src="../../../../assets/images/view_icon.png">
                                    <div class="actions">{{type.RATE_PLAN}} rate plan{{type.RATE_PLAN > 1? 's' : ''}}</div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="text-message" *ngIf="roomTypes.length == 0">
                    <span>
                        <br>
                        No types available ....
                        <br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>