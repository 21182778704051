import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { HotelServicesService } from 'src/app/services/hotel-services.service';

@Component({
  selector: 'app-sla-graph',
  templateUrl: './sla-graph.component.html',
  styleUrls: ['./sla-graph.component.scss']
})
export class SlaGraphComponent implements OnInit {
  @ViewChild('chart', { static: true }) private chartContainer!: ElementRef;
  selectedYear: number;
  years: number[] = [];
  loading: boolean = false;
  slaData: any = {};
  errorMessage: string = '';
  month: string = '';

  constructor(public hotelService: HotelServicesService) { }

  ngOnInit(): void {
    this.initializeYears();
    this.selectedYear = new Date().getFullYear();
    this.getSlaData(); // Fetch data on component initialization
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 3; i--) {
      this.years.push(i);
    }
  }

  getSlaData() {
    this.loading = true;
    this.month = "monthly"
    this.hotelService.getSLAGraphData(this.month).subscribe({
      next: (data) => {
        this.loading = false;
        this.slaData = data.object;
        const formattedData = this.formatDataForChart(this.slaData);
        d3.select(this.chartContainer.nativeElement).selectAll('*').remove(); // Clear previous chart
        if (formattedData.length > 0) {
          this.createBarChart(formattedData);
        } else {
          console.log('No data available for the selected year.');
        }
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching data:', error);
      }
    });
  }

  formatDataForChart(data: any[]): any[] {
    return data.map(monthData => ({
      month: monthData.MONTH,
      slaPromised: monthData.TOTAL,
      slaViolated: monthData.SLA_VIOLATED
    }));
  }

  private createBarChart(data: any[]): void {
    const margin = { top: 40, right: 50, bottom: 100, left: 50 };
    const width = 1000 - margin.left - margin.right;
    const height = 380 - margin.top - margin.bottom;
    const svg = d3
      .select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    if (data.length === 0) {
      console.log('No data available for the selected year.');
      svg.append('text')
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('text-anchor', 'middle')
        .text('No data available for the selected year')
        .style('fill', 'red');
      return;
    }

    const x = d3.scaleBand().range([0, width]).domain(data.map(d => d.month)).padding(0.5);
    const y = d3.scaleLinear().range([height, 0]).domain([0, d3.max(data, d => Math.max(d.slaPromised, d.slaViolated) + 10)!]);

    svg.selectAll('.bar-slaPromised')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar-slaPromised')
      .attr('x', d => x(d.month)!)
      .attr('width', x.bandwidth() / 2)
      .attr('y', d => y(d.slaPromised)!)
      .attr('height', d => height - y(d.slaPromised)!)
      .attr('fill', '#ED894D');

    svg.selectAll('.bar-slaViolated')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar-slaViolated')
      .attr('x', d => x(d.month)! + x.bandwidth() / 2)
      .attr('width', x.bandwidth() / 2)
      .attr('y', d => y(d.slaViolated)!)
      .attr('height', d => height - y(d.slaViolated)!)
      .attr('fill', '#5BA4B7');

    svg.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x));

    svg.append('g').call(d3.axisLeft(y));

    const legendX = width / 2;
    const rectWidth = 15;
    const textPaddingTop = 4; // Adjust this value to add more padding to the top

    svg.append('rect')
      .attr('x', legendX - 140)
      .attr('y', height + margin.bottom / 2 - 10)
      .attr('width', rectWidth)
      .attr('height', 15)
      .attr('fill', '#ED894D');

    svg.append('text')
      .attr('x', legendX - 130 + rectWidth + 5)
      .attr('y', height + margin.bottom / 2 + textPaddingTop)
      .attr('text-anchor', 'start')
      .style('fill', '#4F4F4F')
      .text('Total Responses');

    svg.append('rect')
      .attr('x', legendX + 60)
      .attr('y', height + margin.bottom / 2 - 10)
      .attr('width', rectWidth)
      .attr('height', 15)
      .attr('fill', '#5BA4B7');

    svg.append('text')
      .attr('x', legendX + 70 + rectWidth + 5)
      .attr('y', height + margin.bottom / 2 + textPaddingTop)
      .attr('text-anchor', 'start')
      .style('fill', '#4F4F4F')
      .text('Violated SLA');
  }
}
