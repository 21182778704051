<mat-card class="matCard">
    <mat-card-header class="container">
      <mat-card-title class="header">Revenue<span class="subheader"> (Hotels track these streams of revenue to assess performance)</span></mat-card-title>
      <div class="selector">
        <button mat-raised-button class="custom-button">
          <mat-select [value]="selected" class="selectionlist">
            <mat-option *ngFor="let period of periods" [value]="period.value" class="optionlist" >
              {{ period.label }}
            </mat-option>
          </mat-select>
        </button>
      </div>
    </mat-card-header>
    <mat-card-actions>
     <div class="chartContainer" #chart></div> 
    </mat-card-actions>
  </mat-card>