import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { RequestConfirmedPopupComponent } from '../request-confirmed-popup/request-confirmed-popup.component';
import * as moment from 'moment';

@Component({
  selector: 'app-book-dining',
  templateUrl: './book-dining.component.html',
  styleUrls: ['./book-dining.component.scss']
})
export class BookDiningComponent {

  loading: boolean = false;
  currencyInfo: any;
  currencySymbol: any;
  bookingID;
  serviceId;
  roomId;
  orderInfo;
  public bucketUrl = `${environment.BUCKET_URL}`;
  availableOffers = [];
  totalPrice: number = 0;
  totalTaxAmount: number = 0;
  offerPrice: number = 0;
  priceAfterOffers: number = 0;
  totalAmountPayable: number = 0;
  selectedIndex: any;
  appliedOfferId: any;
  currentDiscount: number = 0;
  orderObj: any;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<BookDiningComponent>,
    private hotelService: HotelServicesService,
    private notify: NotificationService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currencySymbol = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.bookingID = this.data.bookingId;
    this.serviceId = this.data.serviceId;
    this.roomId = this.data.roomId;
    this.orderInfo = this.data.order;
    for (let k in this.orderInfo) {
      this.totalPrice += Number((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity).toFixed(2));
      for (let tax of this.orderInfo[k].data['SERVICETAX']) {
        this.totalTaxAmount += Number(((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
      }
    }
    this.totalAmountPayable = this.totalPrice + this.totalTaxAmount;
    this.getOffersByService();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getOffersByService() {
    var currentDate = new Date();
    this.hotelService.getOffersByServiceId(this.serviceId).subscribe({
      next: (data) => {
        data.object.forEach(element => {
          var endDate = moment(element.END_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
          let toDate = new Date(endDate);
          if (currentDate <= toDate) {
            this.availableOffers.push(element);
          }
        });
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getQuantity(id) {
    if (this.orderInfo[id]) {
      return this.orderInfo[id].quantity;
    } else {
      return '';
    }
  }

  addQuantity(id) {
    if (this.orderInfo[id]) {
      this.orderInfo[id].quantity = this.orderInfo[id].quantity + 1;
      this.totalPrice = this.totalPrice + Number(this.orderInfo[id].data['PRICE']);
      this.totalPrice = Math.round((this.totalPrice) * 100) / 100;
      if (this.offerPrice == 0) {
        this.totalTaxAmount = 0;
        for (let k in this.orderInfo) {
          for (let tax of this.orderInfo[k].data['SERVICETAX']) {
            this.totalTaxAmount += Number(((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
          }
        }
      }
      else if (this.offerPrice != 0) {
        this.offerPrice = Math.round(((this.currentDiscount / 100) * this.totalPrice) * 100) / 100;
        this.priceAfterOffers = Math.round((this.totalPrice - this.offerPrice) * 100) / 100;
        this.totalTaxAmount = 0;
        for (let k in this.orderInfo) {
          for (let tax of this.orderInfo[k].data['SERVICETAX']) {
            this.totalTaxAmount += Number((((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) - ((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (this.currentDiscount / 100))) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
          }
        }
        if (this.totalTaxAmount != 0) {
          this.priceAfterOffers = Math.round((this.priceAfterOffers + this.totalTaxAmount) * 100) / 100;
        }
      }
      this.totalAmountPayable = this.totalPrice + this.totalTaxAmount;
    }
  }

  reduceQuantity(id) {
    if (this.orderInfo[id]) {
      if (this.orderInfo[id].quantity - 1 >= 1) {
        this.orderInfo[id].quantity = this.orderInfo[id].quantity - 1;
        this.totalPrice = this.totalPrice - Number(this.orderInfo[id].data['PRICE']);
        this.totalPrice = Math.round((this.totalPrice) * 100) / 100;
        if (this.currentDiscount == 0) {
          this.totalTaxAmount = 0;
          for (let k in this.orderInfo) {
            for (let tax of this.orderInfo[k].data['SERVICETAX']) {
              this.totalTaxAmount += Number(((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
            }
          }
        }
        else if (this.currentDiscount != 0) {
          this.offerPrice = Math.round(((this.currentDiscount / 100) * this.totalPrice) * 100) / 100;
          this.priceAfterOffers = Math.round((this.totalPrice - this.offerPrice) * 100) / 100;
          this.totalTaxAmount = 0;
          for (let k in this.orderInfo) {
            for (let tax of this.orderInfo[k].data['SERVICETAX']) {
              this.totalTaxAmount += Number((((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) - ((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (this.currentDiscount / 100))) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
            }
          }
          if (this.totalTaxAmount != 0) {
            this.priceAfterOffers = Math.round((this.priceAfterOffers + this.totalTaxAmount) * 100) / 100;
          }
        }
      }
      else {
        this.totalPrice = this.totalPrice - Number(this.orderInfo[id].data['PRICE']);
        this.totalPrice = Math.round((this.totalPrice) * 100) / 100;
        if (this.offerPrice != 0) {
          this.offerPrice = Math.round(((this.currentDiscount / 100) * this.totalPrice) * 100) / 100;
          this.priceAfterOffers = Math.round((this.totalPrice - this.offerPrice) * 100) / 100;
          this.totalTaxAmount = 0;
          for (let k in this.orderInfo) {
            for (let tax of this.orderInfo[k].data['SERVICETAX']) {
              this.totalTaxAmount += Number((((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) - ((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (this.currentDiscount / 100))) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
            }
          }
          this.selectedIndex = -1;
          this.currentDiscount = 0;
        }
        else if (this.offerPrice == 0) {
          this.totalTaxAmount = 0;
          for (let k in this.orderInfo) {
            for (let tax of this.orderInfo[k].data['SERVICETAX']) {
              this.totalTaxAmount += Number(((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
            }
          }
        }
        delete this.orderInfo[id];
        if (this.totalTaxAmount != 0) {
          this.priceAfterOffers = Math.round((this.priceAfterOffers + this.totalTaxAmount) * 100) / 100;
        }
      }
      this.totalAmountPayable = this.totalPrice + this.totalTaxAmount;
    }
  }

  applyOffer(discount, index, offerID, price) {
    this.offerPrice = Math.round(((discount / 100) * price) * 100) / 100;
    this.priceAfterOffers = Math.round((price - this.offerPrice) * 100) / 100;
    if (this.totalTaxAmount != 0) {
      this.totalTaxAmount = 0;
      for (let k in this.orderInfo) {
        for (let tax of this.orderInfo[k].data['SERVICETAX']) {
          this.totalTaxAmount += Number((((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) - ((this.orderInfo[k].data['PRICE'] * this.orderInfo[k].quantity) * (discount / 100))) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2));
        }
      }
      this.priceAfterOffers = Math.round((this.priceAfterOffers + this.totalTaxAmount) * 100) / 100;
    }
    this.selectedIndex = index;
    this.appliedOfferId = offerID;
    this.currentDiscount = discount;
  }

  bookDining() {
    this.loading = true;
    this.orderObj = {
      "BOOKING_ID": this.bookingID,
      "ROOM_ID": this.roomId,
      "OFFER_ID": this.appliedOfferId,
      "ORDER": []
    }
    for (let k in this.orderInfo) {
      let obj = {
        "CATEGORY_ID": this.orderInfo[k].data.CATEGORY.ID,
        "TYPE_ID": this.orderInfo[k].data.ID,
        "QUANTITY": this.orderInfo[k].quantity
      }
      this.orderObj.ORDER.push(obj);
    }
    this.hotelService.bookDiningService(this.orderObj).subscribe({
      next: (data) => {
        this.loading = false;
        const dialogRef = this.dialog.open(RequestConfirmedPopupComponent, {
          width: '500px',
          height: 'auto',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'refresh') {
            this.dialogRef.close('refresh');
          }
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.dialogRef.close();
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}