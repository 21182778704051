<div fxLayout="column">
  <div class="overlay" *ngIf="loading">
    <div class="overlay__inner">
      <div class="overlay__content"><span class="spinner"></span></div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span mat-dialog-title class="header">{{data ? 'Update Offer':'Create Offers'}}</span>
    </div>
    <div>
      <button mat-button mat-dialog-close class="close-btn">
        <mat-icon class="close-icon">
          close
        </mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="addForm">
    <div mat-dialog-content class="control-container">
      <mat-label class="heading">Service</mat-label>
      <mat-form-field appearance="outline" class="input-field_fname" class="category">
        <mat-select formControlName="service" placeholder="Select service" class="inputfield">
          <mat-option *ngFor="let service of permittedServices" [value]="service.ID">{{service.NAME}}</mat-option>
        </mat-select>
        <mat-error *ngIf="addForm.controls['service'].touched && addForm.get('service').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>

      <mat-label class="heading">Discount %</mat-label>
      <mat-form-field appearance="outline" class="input-field_fname" class="category">
        <mat-select formControlName="percentage" placeholder="Select percentage" class="inputfield" appearance="fill">
          <mat-option *ngFor="let perc of percentages" [value]="perc">{{perc}}</mat-option>
        </mat-select>
        <mat-error *ngIf="addForm.controls['percentage'].touched && addForm.get('percentage').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <div>
        <mat-label class="heading">Enter a Starting and Ending Date Range</mat-label>
        <mat-form-field appearance="outline" class="example-form-field" class="inputfield">
          <mat-date-range-input class="date-field" [formGroup]="addForm" [rangePicker]="picker" [min]="currentDate">
            <input matStartDate formControlName="start" placeholder="Start date" readonly>
            <input matEndDate formControlName="end" placeholder="End date" readonly>
          </mat-date-range-input>
          <mat-hint style="font-size:8px ;">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </div>

      <mat-label class="heading">Special Deal</mat-label>
      <mat-form-field appearance="outline" class="inputfield">
        <input matInput #input type="text" formControlName="deal" placeholder="Enter Deal">
        <mat-error class="error" *ngIf="addForm.controls['deal'].touched && addForm.get('deal').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>

      <mat-label class="heading">Description</mat-label>
      <mat-form-field appearance="outline" class="inputfield">
        <textarea matInput #input type="text" formControlName="description" placeholder="Enter Description"></textarea>
        <mat-error class="error"
          *ngIf="addForm.controls['description'].touched && addForm.get('description').hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-dialog-actions dir="rtl">
        <button mat-stroked-button [ngClass]="this.addForm.invalid ? 'action-btn' : 'selected-action-btn'"
          [disabled]="this.addForm.invalid" (click)="save()">Save</button>
        <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>
    </div>
  </form>
</div>