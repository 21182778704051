<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">Logout</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <div mat-dialog-content class="content">
        Are you sure you want to logout?
    </div>
    <mat-dialog-actions align="end" class="actions">
        <button mat-stroked-button class="save-button" mat-dialog-close (click)="closeDialog('yes')">
            Yes
        </button>
        <button mat-stroked-button class="button" mat-dialog-close (click)="closeDialog('no')">
            No
        </button>
    </mat-dialog-actions>
</div>