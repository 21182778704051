import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { AdminService, BYPASS_LOG, BYPASS_TOKEN } from './services/admin.service';
import { Router } from '@angular/router';

@Injectable()
export class ConfigInterceptor implements HttpInterceptor {

  constructor(
    public adminService: AdminService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    let expiresTime = new Date(userInfo?.loggedInTime);
    let expiryTime = expiresTime.setSeconds(expiresTime.getSeconds() + (userInfo?.expiresIn - 60));
    if (new Date().getTime() >= expiryTime && request.context.get(BYPASS_TOKEN) === false) {
      return this.adminService.refreshToken(userInfo?.refresh_token).pipe(
        tap({
          next: (data: any) => {
            userInfo.access_token = data.object.ACCESS_TOKEN;
            userInfo.id_token = data.object.ID_TOKEN;
            userInfo.loggedInTime = new Date();
            userInfo.expiresIn = data.object.EXPIRES_IN;
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
          },
          error: () => {
            window.localStorage.clear();
            this.router.navigate(['/auth/login']);
          },
        }),
        switchMap(() => {
          let updatedHeaders = this.setHeader(request);
          let updatedHeadersClonedRequest = request.clone(updatedHeaders);
          return next.handle(updatedHeadersClonedRequest);
        })
      );
    }
    else {
      let updatedHeaders = this.setHeader(request);
      let updatedHeadersClonedRequest = request.clone(updatedHeaders);
      return next.handle(updatedHeadersClonedRequest);
    }
  }

  setHeader(request) {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (request.context.get(BYPASS_LOG) === true) {
      return {};
    }
    else {
      return {
        setHeaders: {
          'x-access-token': userInfo?.access_token,
          'id-token': userInfo?.id_token
        }
      };
    }
  }

}