<div fxLayout="column" class="update-content">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <mat-dialog-actions dir="rtl">
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </mat-dialog-actions>
    </div>
    <form [formGroup]="updateBookingForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Booking ID
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="bookingId" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Guest Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="guestName" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Created By
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="createdBy" readonly>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Room No.
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="roomNo" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Area
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="area" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Service
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="service" readonly>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="data.requestType == 'live'">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Category
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="category" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Type
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="type" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Date of Service
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="date" readonly>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="30%" *ngIf="serviceId != 2 && serviceId != 6 && data.requestType == 'live'">
                    <div class="box-name">
                        Time
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="time" readonly>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Assign to
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" [panelClass]="isLoading" formControlName="assignee"
                            [value]="!!requestDetails['ASIGNEE'] && requestDetails['ASIGNEE'].ID"
                            placeholder="{{requestDetails['ASIGNEE']?.FIRST_NAME}} {{requestDetails['ASIGNEE']?.LAST_NAME}}"
                            (click)="openAssigneeSelect()" readonly="data.requestType == 'completed'">
                            <mat-option style="display: none;"></mat-option>
                            <ng-container *ngFor="let user of selectedAssignee">
                                <mat-option *ngIf="user.USER_DISABLED != true" [value]="user.ID">
                                    <span>{{user.FIRST_NAME}} {{user.LAST_NAME}}</span>
                                </mat-option>
                            </ng-container>
                            <mat-label *ngIf="isLoading">
                                <div>
                                    <mat-spinner class="assignee-spinner" diameter="20"></mat-spinner>
                                </div>
                            </mat-label>
                        </mat-select>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Priority
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" appearance="fill" formControlName="priority">
                            <mat-option *ngFor="let priority of priorityList" [value]="priority.ID">
                                {{priority.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30%" *ngIf="((serviceId == 2 || serviceId == 6) && data.requestType == 'live') || (data.requestType == 'completed') || (data.requestType == 'escalated')">
                    <div class="box-name">
                        Status
                    </div>
                    <div>
                        <mat-select [ngClass]="data.requestType == 'completed' ? 'text-box' : 'text-box-edit'" appearance="fill" formControlName="status">
                            <mat-option *ngFor="let status of statusList" [value]="status.ID">
                                {{status.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="serviceId != 2 && serviceId != 6 && data.requestType == 'live'">
                <div fxLayout="column" fxFlex="30%">
                    <div class="box-name">
                        Status
                    </div>
                    <div>
                        <mat-select class="text-box-edit" appearance="fill" formControlName="status">
                            <mat-option *ngFor="let status of statusList" [value]="status.ID">
                                {{status.NAME}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" *ngIf="data.requestType != 'completed'">
            <button mat-stroked-button class="cancel-btn" (click)="cancel()">Cancel</button>
            <button mat-stroked-button class="save-btn" [disabled]="this.updateBookingForm.invalid"
                (click)="updateBooking()">Update</button>
        </div>
    </form>
</div>