import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { NotificationService } from 'src/app/services/notification.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { colorObj } from 'src/app/shared/color-object';
import { Observable, Subject } from 'rxjs';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UpdateInternalBookingComponent } from './update-internal-booking/update-internal-booking.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { UploadService } from 'src/app/services/upload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-internal-requests',
  templateUrl: './internal-requests.component.html',
  styleUrls: ['./internal-requests.component.scss']
})
export class InternalRequestsComponent {

  loading: boolean;
  userInfo: any;
  userName: any;
  accessType: any;
  selectedTab;
  isNewRequest: boolean = false;
  addRequestForm: FormGroup;
  displayedColumns: string[] = ['service', 'priority', 'assignee', 'status', 'description', 'button'];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  priorityList = [];
  statusList = [];
  services: any;
  permittedServices: any;
  allowedServices: any = [];
  bookingIdList = [];
  selectedService: number = 0;
  isLoading: boolean = false;
  selectedAssignee = [];
  liveBookings = [];
  completedBookings = [];
  private _onDestroy = new Subject<void>();
  searchVal = new FormControl('');
  reqFromDate = new FormControl('');
  service = new FormControl('');
  reqToDate = new FormControl('');
  toBookingDate: string = '';
  fromBookingDate: string = '';
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  staffBookingStatus = new FormControl('');
  imageName: any;
  source_url;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private notify: NotificationService,
    private hotelService: HotelServicesService,
    private adminService: AdminService,
    private uploadService: UploadService,
    private fb: FormBuilder
  ) {
    this.addRequestForm = this.fb.group({
      service: ['', Validators.required],
      assignee: ['', Validators.required],
      priority: ['', Validators.required],
      createdBy: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.priorityList = res['PRIORITY'];
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.statusList = res['STATUS'];
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.permittedServices = window.localStorage.getItem('hotel_services');
        for (let i = 0; i < this.permittedServices.length; i++) {
          if (this.permittedServices[i] != ',') {
            let index = this.services?.findIndex(x => x.ID == this.permittedServices[i]);
            if (this.services[index].CONFIGURABLE == true) {
              if (this.userInfo.roleId != 4 && this.userInfo.roleId != 3) {
                this.allowedServices.push(this.services[index]);
              } else {
                this.userInfo.serviceId.forEach(element => {
                  if (this.services[index].ID == element) {
                    this.allowedServices.push(this.services[index]);
                  }
                });
              }
            }
          }
        }
      }
    })
    this.selectedTab = 0;
    // this.getEscalatedBookings();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onTabChanged(event) {
    this.selectedTab = event.index;
    this.service.setValue('');
    this.reqFromDate.setValue('');
    this.fromBookingDate = '';
    this.reqToDate.setValue('');
    this.toBookingDate = '';
    this.searchVal.setValue('');
    if (this.selectedTab == 0) {
      // this.getEscalatedBookings();
    }
    else if (this.selectedTab == 1) {
      this.getLiveBookings();
    }
    else if (this.selectedTab == 2) {
      this.getCompletedBookings();
    }
  }

  refresh() {
    this.service.setValue('');
    this.reqFromDate.setValue('');
    this.fromBookingDate = '';
    this.reqToDate.setValue('');
    this.toBookingDate = '';
    this.searchVal.setValue('');
    if (this.selectedTab == 0) {
      // this.getEscalatedBookings();
    }
    else if (this.selectedTab == 1) {
      this.getLiveBookings();
    }
    else if (this.selectedTab == 2) {
      this.getCompletedBookings();
    }
  }

  getLiveBookings() {
    this.loading = true;
    this.hotelService.getInternalLiveBookings().subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource = data.object;
        this.liveBookings = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getCompletedBookings() {
    this.loading = true;
    this.hotelService.getInternalCompletedBookings().subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource = data.object;
        this.completedBookings = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: this.addRequestForm.value.service,
      SECTION: 'internal-request',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
        let tempObjForUpload = {
          url: res['SIGNED_URL'],
          file: selectedFile
        }
        await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
          if (data) {
            this.loading = false;
            this.notify.showNotification(
              'File uploaded successfully',
              "top",
              (!!colorObj[200] ? colorObj[200] : "success"),
              200
            );
            this.imageName = files[0].name;
          }
        }).catch((err) => {
          this.handleError(err);
        });
      }
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  createBooking() {
    this.loading = true;
    let obj = {
      "SERVICE_ID": this.addRequestForm.value.service,
      "ASSIGNEE": this.addRequestForm.value.assignee,
      "PRIORITY": this.addRequestForm.value.priority,
      "ATTACHMENT_SOURCE": this.source_url,
      "DESCRIPTION": this.addRequestForm.value.description
    }
    this.hotelService.createInternalBooking(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
        this.addRequestForm.reset();
        this.isNewRequest = false;
        this.selectedTab = 1;
        this.getLiveBookings();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateBooking(element, type) {
    const dialogRef = this.dialog.open(UpdateInternalBookingComponent, {
      width: '70%',
      height: '500px',
      data: { request: element, requestType: type },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'escalated') {
        this.selectedTab = 0;
        // this.getEscalatedBookings();
      }
      else if (result === 'live') {
        this.selectedTab = 1;
        this.getLiveBookings();
      }
    })
  }

  save(reservation_id, service_id) {
    this.trigger.closeMenu();
    this.loading = true;
    let obj = {
      "ID": reservation_id,
      "SERVICE_ID": service_id,
      "STATUS": this.staffBookingStatus.value
    }
    this.update(obj);
    this.staffBookingStatus.setValue('');
  }

  update(obj) {
    this.hotelService.updateInternalBooking(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
        if (this.selectedTab == 0) {
          // this.getEscalatedBookings();
        }
        else if (this.selectedTab == 1) {
          this.getLiveBookings();
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  search() {
    if (this.selectedTab == 0) {
      // this.escalatedBookings = [];
      // if (this.reqFromDate.value) {
      //   this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      // }
      // if (this.reqToDate.value) {
      //   this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      // }
      // this.loading = true;
      // this.hotelService.getInternalEscalatedBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
      //   next: (data) => {
      //     this.loading = false;
      //     this.dataSource = data.object;
      //     this.escalatedBookings = data.object;
      //   },
      //   error: (e) => {
      //     this.loading = false;
      //     this.notify.showNotification(
      //       e.error.message,
      //       "top",
      //       (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
      //       e.error.status
      //     )
      //   }
      // })
    }
    else if (this.selectedTab == 1) {
      this.liveBookings = [];
      if (this.reqFromDate.value) {
        this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      }
      if (this.reqToDate.value) {
        this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      }
      this.loading = true;
      this.hotelService.getInternalLiveBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
        next: (data) => {
          this.loading = false;
          this.dataSource = data.object;
          this.liveBookings = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 2) {
      this.completedBookings = [];
      if (this.reqFromDate.value) {
        this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      }
      if (this.reqToDate.value) {
        this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      }
      this.loading = true;
      this.hotelService.getInternalCompletedBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
        next: (data) => {
          this.loading = false;
          this.dataSource = data.object;
          this.completedBookings = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  createRequest() {
    this.isNewRequest = true;
    this.getBookingId();
    this.addRequestForm.controls["createdBy"].setValue(this.userInfo.firstName + ' ' + this.userInfo.lastName);
  }

  backToRequests() {
    this.isNewRequest = false;
    this.addRequestForm.reset();
    // this.selectedTab = 0;
    // this.getEscalatedBookings();
  }

  getBookingId() {
    this.hotelService.getBookingIdList().subscribe({
      next: (data) => {
        this.bookingIdList = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectRequestService(service) {
    this.selectedService = service;
    this.addRequestForm.controls["assignee"].setValue('');
  }

  openAssigneeSelect() {
    this.isLoading = true;
    this.getAllInternalMembersByServiceID();
  }

  getAllInternalMembersByServiceID() {
    this.adminService.getInternalMembersByServiceId(this.selectedService).subscribe({
      next: (data) => {
        this.selectedAssignee = data.object;
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}