<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                <div class="back-arrow" (click)="backToReservations()">
                    <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                </div>
                <div class="bulk-head">
                    Edit Reservation
                </div>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="reserveForm" fxLayout="column" fxLayoutGap="3%">
                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="30px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div class="sub-head">Guest Profile</div>
                        <div fxLayout="column" fxLayoutGap="20px" class="info-block">
                            <div class="price-head" style="text-decoration: underline;">Host</div>
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        First Name
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="firstName"
                                            placeholder="Enter First Name">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Last Name
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="lastName"
                                            placeholder="Enter Last Name">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Email ID
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="emailId"
                                            placeholder="Enter Email ID">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21.5%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Phone Number
                                    </div>
                                    <div>
                                        <input type="number" class="text-box" formControlName="phoneNumber"
                                            placeholder="Enter Phone Number">
                                    </div>
                                </div>
                            </div>
                            <div formArrayName="guestsArray">
                                <div fxLayout="column" fxLayoutGap="20px"
                                    *ngFor="let guestControl of getGuests(); let in = index;" formGroupName="{{in}}">
                                    <div class="price-head" style="text-decoration: underline;">Guest {{in+1}}</div>
                                    <div fxLayout="row" fxLayoutGap="40px" style="padding-bottom: 20px;">
                                        <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                First Name
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="guestFirstName"
                                                    placeholder="Enter First Name">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Last Name
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="guestLastName"
                                                    placeholder="Enter Last Name">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Email ID
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="guestEmail"
                                                    placeholder="Enter Email ID">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="21.5%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Phone Number
                                            </div>
                                            <div>
                                                <input type="number" class="text-box" formControlName="guestMobileNumber"
                                                    placeholder="Enter Phone Number">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div class="sub-head">Reservation Profile</div>
                        <div fxLayout="column" fxLayoutGap="20px" class="info-block">
                            <div fxLayout="row" fxLayoutGap="30px">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Booking ID
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="bookingId"
                                            placeholder="Enter Booking ID">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="23%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Check-In Date
                                    </div>
                                    <div class="text-box-date">
                                        <input matInput [matDatepicker]="picker3" (dateChange)="selectReserveDate($event)"
                                            formControlName="checkInDate" class="date-box" placeholder="Select"
                                            [min]="currentDate" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="23%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Check-Out Date
                                    </div>
                                    <div class="text-box-date">
                                        <input matInput [matDatepicker]="picker4" formControlName="checkOutDate"
                                            class="date-box" placeholder="Select" [min]="minReserveToDate" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker #picker4></mat-datepicker>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        ETA(Expected time of Arrival)
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="arrivalTime"
                                            placeholder="Select">
                                            <mat-option *ngFor="let time of timings" [value]="time.ID">
                                                {{time.TIME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start end">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        DTA(Differential Thermal Analysis)
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="thermalAnalysis"
                                            placeholder="Select">
                                            <!-- <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                                {{room.NAME}}
                                            </mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Number of rooms
                                    </div>
                                    <div>
                                        <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="text-box"
                                            formControlName="noOfRooms" placeholder="Enter">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21.5%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Price
                                    </div>
                                    <div>
                                        <input type="number" class="text-box" formControlName="price"
                                            placeholder="Enter">
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Comments
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="comments">
                                    </div>
                                </div>
                            </div>
                            <div formArrayName="roomArray">
                                <div fxLayout="column" fxLayoutGap="20px"
                                *ngFor="let roomControl of getRooms(); let in = index;" formGroupName="{{in}}">
                                    <div class="price-head" style="text-decoration: underline;">Room {{in+1}}</div>
                                    <div fxLayout="row" fxLayoutGap="40px" style="padding-bottom: 20px;">
                                        <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Room Number
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="roomNumber"
                                                    placeholder="Enter Room Number">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Room Type
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="roomType"
                                                    placeholder="Enter Room Type">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Rate Plan
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="ratePlan"
                                                    placeholder="Enter Rate Plan">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div class="sub-head">Source Details</div>
                        <div fxLayout="column" fxLayoutGap="20px" class="info-block">
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Source ID
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="sourceId"
                                            placeholder="Enter Source">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Market Segment
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill"
                                            formControlName="marketSegment" placeholder="Select">
                                            <!-- <mat-option *ngFor="let seg of marketSegments" [value]="seg.ID">
                                                {{seg.NAME}}
                                            </mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Source
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="sourceName"
                                            placeholder="Select">
                                            <!-- <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                                {{room.NAME}}
                                            </mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21.5%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Commission Percentage
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill"
                                            formControlName="commission" placeholder="Select">
                                            <!-- <mat-option *ngFor="let perc of percentages" [value]="perc">{{perc}}</mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Booker
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="bookerName"
                                            placeholder="Enter Booker">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Company
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="companyName"
                                            placeholder="Enter Company">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="22%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Travel Agent
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="travelAgent"
                                            placeholder="Enter">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div class="sub-head">Payment Profile</div>
                        <div fxLayout="column" fxLayoutGap="20px" class="info-block">
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="21%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Payment Mode
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="paymentMode"
                                            placeholder="Select">
                                            <!-- <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                                {{room.NAME}}
                                            </mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <button mat-button class="save-btn" (click)="updateReservation()">Update</button>
                    <!--[disabled]="reserveForm.invalid"-->
                    <button mat-button class="cancel-btn" (click)="backToReservations()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>