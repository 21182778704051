import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<LogoutConfirmationComponent>
  ) { }

  closeDialog(action) {
    this.dialogRef.close(action);
  }

}