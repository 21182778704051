import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Select } from '@ngxs/store';
import * as d3 from 'd3';
import { Observable } from 'rxjs/internal/Observable';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { HttpErrorResponse } from '@angular/common/http';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-success-rate-graph',
  templateUrl: './success-rate-graph.component.html',
  styleUrls: ['./success-rate-graph.component.scss']
})
export class SuccessRateGraphComponent implements OnInit {
  @ViewChild('chart', { static: true }) private chartContainer!: ElementRef;
  selectedService: string;
  selectedYear: number;
  years: number[] = [];
  userInfo: any;
  userName: any;
  hotelServices: any = [];
  permittedServices: any[] = [];
  services: any[] = [];
  total: number | null = null;
  slaViolated: number | null = null;
  successRate: number | null = null;
  successValue: number | null = null;
  fromDate: FormControl = new FormControl('');
  toDate: FormControl = new FormControl('');
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;

  private data = [{ label: 'Completed', value: 0 }, { label: 'Remaining', value: 0 }];

  constructor(public hotelService: HotelServicesService) { }

  ngOnInit(): void {
    this.initializeYears();
    this.getServicevalue();
    this.getSLAData();
    this.selectedYear = new Date().getFullYear();
  }

  onYearChange(event: any) {
    this.selectedYear = event.value;
    this.getSLAData();
  }

  onServiceChange(event: any) {
    this.selectedService = event.value;
    this.getSLAData();
  }

  onDateChange() {
    this.getSLAData();
  }

  getSLAData() {
    const serviceId = this.selectedService || ''; // Default to empty string if no service is selected
    const fromDate = this.fromDate.value ? this.formatDate(this.fromDate.value) : '';
    const toDate = this.toDate.value ? this.formatDate(this.toDate.value) : '';

    this.hotelService.getSLAData(serviceId, fromDate, toDate).subscribe({
      next: (data) => {
        this.total = data.object.TOTAL;
        this.slaViolated = data.object.SLA_VIOLATED;

        if (this.total !== null && this.slaViolated !== null) {
          const successValue = this.total - this.slaViolated;
          if (this.total === 0) {
            this.successRate = 0; // Set to 0 when total is 0
          } else {
            this.successRate = Math.round((successValue * 100) / this.total);
          }
          this.successValue = successValue;
          this.updateChartData();
        }

        console.log(this.total, "checking the total data");
      },
      error: (error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          console.error('Client-side error:', error.error.message);
        } else {
          console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
      }
    });
  }

  updateChartData() {
    if (this.successRate !== null) {
      this.data = [
        { label: 'Completed', value: this.successRate },
        { label: 'Remaining', value: 100 - this.successRate }
      ];
      this.createDonutChart();
    }
  }

  getServicevalue() {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.hotelServices = window.localStorage.getItem('hotel_services');
        if (this.userInfo.roleId == 2) {
          for (let service of this.services) {
            if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        } else if (this.userInfo.roleId != 2) {
          for (let service of this.services) {
            if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        }
        console.log(this.services, "checking the services valuee 123456789")
      }
    });
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 3; i--) {
      this.years.push(i);
    }
  }

  private formatDate(date: Date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  private createDonutChart(): void {
    const element = this.chartContainer.nativeElement;
    const width = 200;
    const height = 200;
    const margin = { top: 10, right: 10, bottom: 50, left: 10 }; // Adjusted bottom margin for text and rect
    const radius = Math.min(width, height) / 2 - margin.top;

    d3.select(element).select('svg').remove(); // Remove any existing svg before creating a new one

    const svg = d3.select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height + margin.bottom) // Adjusted height for text and rect
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3.scaleOrdinal<string>()
      .domain(this.data.map(d => d.label))
      .range(['#74CFB3', '#E0E0E0']);

    const pie = d3.pie<any>()
      .value(d => d.value)
      .sort(null);

    const arc = d3.arc<d3.PieArcDatum<any>>()
      .innerRadius(radius - 30)  // Adjust the inner radius for the donut thickness
      .outerRadius(radius);

    const arcs = svg.selectAll('arc')
      .data(pie(this.data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.label) as string);

    // Center text to display the percentage
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('font-size', '24px')
      .attr('font-weight', 'bold')
      .text(`${this.data[0].value}%`);

    // Add legend rectangle and text below the chart
    const legendX = 0;
    const legendY = height / 2 + margin.top + 20; // Position below the chart

    svg.append('rect')
      .attr('x', legendX - 15)
      .attr('y', legendY)
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', '#74CFB3');

    svg.append('text')
      .attr('x', legendX + 5)
      .attr('y', legendY + 12)
      .attr('text-anchor', 'start')
      .style('fill', '#4F4F4F')
      .text('Success Rate');
  }
}
