import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetMasterData } from 'src/app/session/shared/store/actions/user-info.actions';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-edit-service-timing',
  templateUrl: './edit-service-timing.component.html',
  styleUrls: ['./edit-service-timing.component.scss']
})
export class EditServiceTimingComponent {

  loading: boolean = false;
  slots = [];
  timingForm: FormGroup;
  isEnabled: boolean;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<EditServiceTimingComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.timingForm = this.fb.group({
      from_time: [''],
      to_time: ['']
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.slots = res['SLOTS']
      }
    })
    if (this.data) {
      this.cs.getServiceTimingById(this.data.S_ID).subscribe({
        next: (res) => {
          if (Object.keys(res.object).length != 0) {
            let timingFromIndex = this.slots.findIndex(x => x.TIME == res.object.FROM.TIME);
            let timingToIndex = this.slots.findIndex(x => x.TIME == res.object.TO.TIME);
            this.loading = false;
            this.timingForm.get("from_time").setValue(this.slots[timingFromIndex].ID);
            this.timingForm.get("to_time").setValue(this.slots[timingToIndex].ID);
            this.isEnabled = res.object.ENABLED;
          }
          else {
            this.isEnabled = false;
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  updateTiming() {
    if (this.timingForm.value.from_time >= this.timingForm.value.to_time) {
      this.notify.showNotification(
        "Timing From shouldn't be greater than or equal to Timing To",
        "top",
        "error",
        "0"
      )
      return;
    }
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "SERVICE": [
          {
            "S_ID": this.data.S_ID,
            "FROM": this.timingForm.value.from_time,
            "TO": this.timingForm.value.to_time,
            "ENABLED": this.isEnabled
          }
        ]
      }
      this.cs.updateServiceTiming(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}