import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { colorObj } from 'src/app/shared/color-object';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-sla-management',
  templateUrl: './sla-management.component.html',
  styleUrls: ['./sla-management.component.scss']
})
export class SlaManagementComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  isSlaMngmt: boolean = true;
  createSla: boolean = false;
  updateSla: boolean = false;
  serviceSla: boolean = false;
  viewSla: boolean = false;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  services = [];
  hotelServices: any = [];
  permittedServices = [];
  slaList = [];
  noSLA: boolean = false;
  serviceSlaList = {};
  selectedService;
  requestStatus = [];
  startStatus;
  startId: number = 0;
  stopStatus;
  stopId: number = 0;
  slaForm: FormGroup;
  TATInfo: any = [];
  slaId: number;
  slaStatus: boolean;
  slaDetails = {};
  displayedColumns: string[] = ['status_from', 'status_to', 'duration'];
  dataSource = new MatTableDataSource<any>();
  public bucketUrl = `${environment.BUCKET_URL}`;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.slaForm = this.fb.group({
      slaName: ['', Validators.required],
      slaService: ['', Validators.required],
      slaDescription: ['', Validators.required],
      startCondition: ['', Validators.required],
      stopCondition: ['', Validators.required],
      slaDuration: ['', Validators.required],
      TATArray: fb.array([])
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.hotelServices = window.localStorage.getItem('hotel_services');
        if (this.userInfo.roleId == 2) {
          for (let service of this.services) {
            if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        }
        else if (this.userInfo.roleId != 2) {
          for (let service of this.services) {
            if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.permittedServices.push(service);
            }
          }
        }
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        for (let status of res['STATUS']) {
          if (status.SLA_APPLICABLE == true) {
            this.requestStatus.push(status);
          }
        }
      }
    })
    this.getSLAs();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getSLAs() {
    this.loading = true;
    this.hotelService.getSLA().subscribe({
      next: (data) => {
        this.loading = false;
        this.slaList = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getSLAsByService(serviceId) {
    this.loading = true;
    for (let service of this.permittedServices) {
      if (service.ID == serviceId) {
        this.selectedService = service.NAME;
      }
    }
    this.hotelService.getSLAByServiceId(serviceId).subscribe({
      next: (data) => {
        this.loading = false;
        if (Object.keys(data.object).length != 0) {
          this.noSLA = false;
          this.serviceSlaList = data.object;
        }
        else {
          this.noSLA = true;
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  createSLA() {
    this.createSla = true;
    this.isSlaMngmt = false;
    this.serviceSla = false;
    this.updateSla = false;
    this.viewSla = false;
  }

  backToSLAs() {
    this.slaForm.reset();
    this.clearFormArray();
    this.isSlaMngmt = true;
    this.createSla = false;
    this.serviceSla = false;
    this.updateSla = false;
    this.viewSla = false;
    this.getSLAs();
  }

  listServiceSLAs(id) {
    this.isSlaMngmt = false;
    this.createSla = false;
    this.serviceSla = true;
    this.updateSla = false;
    this.viewSla = false;
    this.getSLAsByService(id);
  }

  viewSLA(slaID) {
    this.slaId = slaID;
    this.isSlaMngmt = false;
    this.createSla = false;
    this.serviceSla = false;
    this.updateSla = false;
    this.viewSla = true;
    this.loading = true;
    this.hotelService.getSLAById(slaID).subscribe({
      next: (data) => {
        this.loading = false;
        this.slaStatus = data.object.ENABLED;
        this.slaDetails = data.object;
        if (Array.isArray(data.object.TAT) && data.object.TAT.length > 0) {
          for (let i = 0; i < data.object.TAT.length; i++) {
            let duration = data.object.TAT[i]['DURATION'];
            let days = 0;
            let hours = 0
            let mins = 0;
            let durationToHours = duration / 60;
            if (duration < 60) {
              mins = duration;
            }
            else if (durationToHours < 24) {
              hours = Math.floor(duration / 60);
              duration = duration - (hours * 60);
              mins = duration;
            }
            else if (durationToHours >= 24) {
              days = Math.floor(durationToHours / 24);
              duration = duration - ((days * 24) * 60);
              hours = Math.floor(duration / 60);
              duration = duration - (hours * 60);
              mins = duration;
            }
            data.object.TAT[i]['DAYS'] = days;
            data.object.TAT[i]['HOURS'] = hours;
            data.object.TAT[i]['MINUTES'] = mins;
          }
        }
        this.dataSource.data = data.object.TAT;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  editSLA(slaID) {
    this.slaId = slaID;
    this.isSlaMngmt = false;
    this.createSla = true;
    this.serviceSla = false;
    this.updateSla = true;
    this.viewSla = false;
    this.loading = true;
    this.hotelService.getSLAById(slaID).subscribe({
      next: (data) => {
        this.loading = false;
        this.slaStatus = data.object.ENABLED;
        this.slaForm.get("slaName").setValue(data.object.NAME);
        this.slaForm.get("slaService").setValue(data.object.SERVICE_ID.ID);
        this.slaForm.get("slaDescription").setValue(data.object.DESCRIPTION);
        this.slaForm.get("startCondition").setValue(data.object.START_STATUS.ID);
        this.startStatus = data.object.START_STATUS.NAME;
        this.startId = data.object.START_STATUS.ID;
        this.slaForm.get("stopCondition").setValue(data.object.END_STATUS.ID);
        this.stopStatus = data.object.END_STATUS.NAME;
        this.stopId = data.object.END_STATUS.ID;
        this.slaForm.get("slaDuration").setValue(data.object.DURATION);
        this.TATInfo = data.object.TAT;
        if (Array.isArray(this.TATInfo) && this.TATInfo.length > 0) {
          for (let i = 0; i < this.TATInfo.length; i++) {
            this.addTAT();
            let durationArray = this.slaForm.get('TATArray') as FormArray;
            let durationArrayObj = durationArray.controls[i] as FormGroup;
            durationArrayObj.get('statusFrom').patchValue(this.TATInfo[i]['START_STATUS']['ID']);
            durationArrayObj.get('statusTo').patchValue(this.TATInfo[i]['END_STATUS']['ID']);
            let duration = this.TATInfo[i]['DURATION'];
            let days = 0;
            let hours = 0
            let mins = 0;
            let durationToHours = duration / 60;
            if (duration < 60) {
              mins = duration;
            }
            else if (durationToHours < 24) {
              hours = Math.floor(duration / 60);
              duration = duration - (hours * 60);
              mins = duration;
            }
            else if (durationToHours >= 24) {
              days = Math.floor(durationToHours / 24);
              duration = duration - ((days * 24) * 60);
              hours = Math.floor(duration / 60);
              duration = duration - (hours * 60);
              mins = duration;
            }
            durationArrayObj.get('days').patchValue(days);
            durationArrayObj.get('hours').patchValue(hours);
            durationArrayObj.get('minutes').patchValue(mins);
          };
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectStartCondition(id) {
    for (let status of this.requestStatus) {
      if (status.ID == id) {
        this.startStatus = status.NAME;
        this.startId = status.ID;
      }
    }
    this.slaForm.controls['stopCondition'].reset();
    this.clearFormArray();
  }

  selectStopCondition(id) {
    let showArray = [];
    this.clearFormArray();
    for (let status of this.requestStatus) {
      if (status.ID == id) {
        this.stopStatus = status.NAME;
        this.stopId = status.ID;
      }
    }
    for (let i = this.startId - 1; i < id; i++) {
      showArray.push(this.requestStatus[i]);
    }
    for (let i = 0; i < showArray.length; i++) {
      if (showArray[i].ID < this.stopId) {
        this.addTAT();
        let timeArray = this.slaForm.get('TATArray') as FormArray;
        let timeArrayObj = timeArray.controls[i] as FormGroup;
        timeArrayObj.get('statusFrom').setValue(showArray[i].ID);
        timeArrayObj.get('statusTo').setValue(showArray[i + 1].ID);
      }
    }
  }

  getTAT() {
    return (this.slaForm.get('TATArray') as FormArray).controls;
  }

  addTAT() {
    let fg = this.fb.group({
      statusFrom: this.fb.control(''),
      statusTo: this.fb.control(''),
      days: this.fb.control(''),
      hours: this.fb.control(''),
      minutes: this.fb.control('')
    });
    (<FormArray>this.slaForm.get('TATArray')).push(fg);
  }

  clearFormArray() {
    this.stopId = 0;
    let formArray: FormArray = (<FormArray>this.slaForm.get('TATArray'));
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  saveSLA() {
    this.loading = true;
    let formData: any;
    formData = this.slaForm.value;
    let array = formData['TATArray'];
    let timeArray = [];
    let totalDuration = 0;
    for (let i = 0; i < array.length; i++) {
      if (!!array[i].statusFrom) {
        timeArray.push({
          "START_STATUS": array[i].statusFrom,
          "END_STATUS": array[i].statusTo,
          "DURATION": (array[i].days == '' ? 0 : array[i].days * 24 * 60) + (array[i].hours == '' ? 0 : array[i].hours * 60) + (array[i].minutes == '' ? 0 : array[i].minutes)
        })
        totalDuration += ((array[i].days == '' ? 0 : array[i].days * 24 * 60) + (array[i].hours == '' ? 0 : array[i].hours * 60) + (array[i].minutes == '' ? 0 : array[i].minutes));
      }
    }
    let obj = {
      "NAME": this.slaForm.value.slaName,
      "SERVICE_ID": this.slaForm.value.slaService,
      "DESCRIPTION": this.slaForm.value.slaDescription,
      "START_STATUS": this.slaForm.value.startCondition,
      "END_STATUS": this.slaForm.value.stopCondition,
      "DURATION": this.slaForm.value.slaDuration,
      "ENABLED": false,
      "TAT": timeArray
    }
    if (this.slaForm.value.slaDuration != totalDuration) {
      this.loading = false;
      this.notify.showMessage(
        "Total duration is not equals to total TAT",
        "top",
        "warning"
      )
    }
    else if (this.slaForm.value.slaDuration == totalDuration) {
      this.hotelService.createSLA(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status] : "success"),
            data.status
          )
          this.backToSLAs();
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  updateSLA() {
    this.loading = true;
    let formData: any;
    formData = this.slaForm.value;
    let array = formData['TATArray'];
    let timeArray = [];
    let totalDuration = 0;
    for (let i = 0; i < array.length; i++) {
      if (!!array[i].statusFrom) {
        timeArray.push({
          "START_STATUS": array[i].statusFrom,
          "END_STATUS": array[i].statusTo,
          "DURATION": (array[i].days == '' ? 0 : array[i].days * 24 * 60) + (array[i].hours == '' ? 0 : array[i].hours * 60) + (array[i].minutes == '' ? 0 : array[i].minutes)
        })
        totalDuration += ((array[i].days == '' ? 0 : array[i].days * 24 * 60) + (array[i].hours == '' ? 0 : array[i].hours * 60) + (array[i].minutes == '' ? 0 : array[i].minutes));
      }
    }
    let obj = {
      "ID": this.slaId,
      "NAME": this.slaForm.value.slaName,
      "SERVICE_ID": this.slaForm.value.slaService,
      "DESCRIPTION": this.slaForm.value.slaDescription,
      "START_STATUS": this.slaForm.value.startCondition,
      "END_STATUS": this.slaForm.value.stopCondition,
      "DURATION": this.slaForm.value.slaDuration,
      "ENABLED": this.slaStatus,
      "TAT": timeArray
    }
    if (this.slaForm.value.slaDuration != totalDuration) {
      this.loading = false;
      this.notify.showMessage(
        "Total duration is not equals to total TAT",
        "top",
        "warning"
      )
    }
    else if (this.slaForm.value.slaDuration == totalDuration) {
      this.hotelService.updateSLA(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status] : "success"),
            data.status
          )
          this.backToSLAs();
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  enableSLA(id, status, msg) {
    this.confirmationMsg.title = 'Are you sure you want to ' + msg + ' this SLA ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "ENABLED": !status
        }
        this.hotelService.updateSLA(obj).subscribe({
          next: (data) => {
            this.loading = false;
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status] : "success"),
              data.status
            )
            this.backToSLAs();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}