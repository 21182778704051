import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HotelServicesService } from 'src/app/services/hotel-services.service';

@Component({
  selector: 'app-sla-dashboard',
  templateUrl: './sla-dashboard.component.html',
  styleUrls: ['./sla-dashboard.component.scss']
})
export class SlaDashboardComponent implements OnInit {
  hotelServices: any = [];
  total: number | null = null;
  slaViolated: number | null = null;
  successRate: number | null = null;

  constructor(public hotelService: HotelServicesService) { }

  ngOnInit(): void {
    this.getSLAData()
  }

  getSLAData() {
    this.hotelService.getDashboardSLAData().subscribe({
      next: (data) => {
        this.total = data.object.TOTAL;
        this.slaViolated = data.object.SLA_VIOLATED;

        if (this.total !== null && this.slaViolated !== null) {
          if (this.total === 0) {
            this.successRate = 0; // Set to 0 when total is 0 to avoid division by zero
          } else {
            const successValue = this.total - this.slaViolated;
            this.successRate = Math.round((successValue * 100) / this.total);
          }
        }

        console.log(this.total, "checking the total data");
      },
      error: (error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          console.error('Client-side error:', error.error.message);
        } else {
          console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
      }
    });
  }
}
