<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Edit Tax':'Add Tax'}}</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addTaxForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="35px">
                <div>
                    <div class="box-name">
                        Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Enter Tax Name">
                    </div>
                    <mat-error class="error"
                        *ngIf="addTaxForm.controls['name'].touched && addTaxForm.get('name').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div fxLayout="row" fxLayoutGap="24px">
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Percentage
                        </div>
                        <div>
                            <mat-select formControlName="percentage" class="select" placeholder="Select Percentage"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let value of percentageValues" [value]="value">{{value}}%</mat-option>
                            </mat-select>
                            <mat-error class="error"
                                *ngIf="addTaxForm.controls['percentage'].touched && addTaxForm.get('percentage').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Service
                        </div>
                        <div>
                            <mat-select formControlName="service" class="select" placeholder="Select Service"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let service of permittedServices" [value]="service.ID">{{service.NAME}}</mat-option>
                            </mat-select>
                            <mat-error class="error"
                                *ngIf="addTaxForm.controls['service'].touched && addTaxForm.get('service').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button mat-stroked-button class="button" (click)="cancel()">
                    Cancel
                </button>
                <button mat-stroked-button [ngClass]="this.addTaxForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addTaxForm.invalid" (click)="addRoom()">
                    Save
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</div>