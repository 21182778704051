<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p><p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div class="main-header">Configurations</div>
            <div class="input-container">
                <mat-card class="card">
                    <div fxLayout="row" style="height:inherit">
                        <div fxLayout="column" class="services-buttons" fxFlex="20%">
                            <button mat-button class="service-button" (click)="showService()">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Service</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="manageCurrency()">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Currency</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="diningFilter()">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Dining Filter</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                        </div>
                        <mat-divider [vertical]="true" style="min-height: 71vh;"></mat-divider>
                        <div fxLayout="column" *ngIf="showServices" class="container" fxFlex="80%">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="header">
                                    Services List
                                </div>
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <div class="header-client">
                                        Clients
                                    </div>
                                    <mat-select class="text-box" style="font-size: 13px;" appearance="fill"
                                        [formControl]="client" placeholder="Select client">
                                        <mat-option *ngFor="let client of clients" [value]="client.ID"
                                            (click)="changeClient(client.ID)">{{client.NAME}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource">
                                    <ng-container matColumnDef="sr.no">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> S No. </th>
                                        <td mat-cell *matCellDef="let row; let i = index;"> {{i+1}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Name </th>
                                        <td mat-cell *matCellDef="let row;">{{row.NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                                        <td mat-cell *matCellDef="let row">
                                            <div fxLayout="row" fxLayoutAlign="center">
                                                <label class="switch">
                                                    <input #checkbox type="checkbox" [checked]="row.ENABLED" [disabled]="row.ID == 7 || row.ID == 8"
                                                        (change)="enable(checkbox.checked, row.ID)">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="serviceColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: serviceColumns;"></tr>
                                </table>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showCurrency" class="container" fxFlex="80%">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="header">
                                    Manage Currency
                                </div>
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <div class="header-client">
                                        Clients
                                    </div>
                                    <mat-select class="text-box" style="font-size: 13px;" appearance="fill" [formControl]="client"
                                        placeholder="Select client">
                                        <mat-option *ngFor="let client of clients" [value]="client.ID"
                                            (click)="changeClient(client.ID)">{{client.NAME}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource">
                                    <ng-container matColumnDef="country">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Country </th>
                                        <td mat-cell *matCellDef="let row;">
                                            <mat-select class="select-box" appearance="fill" [(value)]="row.CURRENCY.COUNTRY.ID" (selectionChange)="changeCurrency($event.value)">
                                                <mat-option *ngFor="let country of countries" [value]="country.ID">{{country.NAME}}</mat-option>
                                            </mat-select>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="currency">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Currency </th>
                                        <td mat-cell *matCellDef="let row;">
                                            <input matInput class="select-box1" appearance="fill" [readonly]="true" [(value)]="currencyType">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                                        <td mat-cell *matCellDef="let row">
                                            <div fxLayout="row" fxLayoutAlign="center">
                                                <button class="update-btn" (click)="updateCurrency(row.CURRENCY.COUNTRY.ID)" [disabled]="!currencyType">
                                                    Update
                                                </button>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="currencyColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: currencyColumns;"></tr>
                                </table>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showDiningFilters" class="container" fxFlex="80%">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="header">
                                    In-Room Dining Filters
                                </div>
                                <div>
                                    <button class="add-btn" (click)="addDiningFilter()">
                                        + Create
                                    </button>
                                </div>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource">
                                    <ng-container matColumnDef="sr.no">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%"> S No. </th>
                                        <td mat-cell *matCellDef="let row; let i = index;"> {{i+1}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dining_type" style="width: 70%;">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Dining Type </th>
                                        <td mat-cell *matCellDef="let row;">{{row.NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="action" style="width: 20%;">
                                        <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                                        <td mat-cell *matCellDef="let row">
                                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="14px" style="cursor: pointer;">
                                                <span class="material-icons-outlined" (click)="editDiningFilter(row.ID)">edit</span>
                                                <span class="material-icons-outlined" (click)="deleteDiningFilter(row.ID)" style="color: rgb(255, 0, 0);">
                                                    delete_outline </span>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="diningFilterColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: diningFilterColumns;"></tr>
                                </table>
                                <div class="text-message" *ngIf="dataSource.data.length == 0">
                                    <span>
                                        <br>
                                        No data available ....
                                        <br>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>