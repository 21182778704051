<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="bulk-head">
                    Bulk Update Room Type
                </div>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="bulkInventoryForm" fxLayout="column" fxLayoutGap="3%">
                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="30px">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div class="bulk-subhead">Choose a Date range</div>
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    From
                                </div>
                                <div class="text-box-date">
                                    <input matInput [matDatepicker]="picker1" (dateChange)="selectDate($event)"
                                        formControlName="bulkUpdateFrom" class="date-box" placeholder="Select" [min]="currentDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    To
                                </div>
                                <div class="text-box-date">
                                    <input matInput [matDatepicker]="picker2" formControlName="bulkUpdateTo"
                                        class="date-box" placeholder="Select" [min]="minToDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div class="bulk-subhead">Update Inventory</div>
                        <div fxLayout="column" fxLayoutGap="25px">
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Room Category
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="roomCategory"
                                            (selectionChange)="selectRoomCategory($event.value)" placeholder="Select">
                                            <mat-option *ngFor="let category of roomCategories" [value]="category.ID">
                                                {{category.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Room Type
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="roomType"
                                            (selectionChange)="selectRoomTypes()" placeholder="Select" multiple>
                                            <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                                {{room.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                <div formArrayName="inventoryUpdateArray">
                                    <div fxLayout="column" fxLayoutGap="20px"
                                        *ngFor="let inventory of bulkInventoryForm.controls.inventoryUpdateArray.controls; let i = index;"
                                        formGroupName="{{i}}" class="array-box">
                                        <div fxLayoutAlign="end " class="box-name">
                                            Rooms: {{inventory.value.availableRooms}}
                                        </div>
                                        <div fxLayout="column" fxFlex="70%" fxLayoutGap="10px">
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxFlex="47%" class="box-name">
                                                    Update Inventory for {{inventory.value.roomTypeName}}
                                                </div>
                                                <div fxFlex="46%" class="box-name">
                                                    Open/Close
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxFlex="47%">
                                                    <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="text-box"
                                                        formControlName="updateInventory" placeholder="Enter Inventory">
                                                </div>
                                                <div fxFlex="46%">
                                                    <mat-select class="text-box" appearance="fill"
                                                        formControlName="inventoryStatus" placeholder="Select">
                                                        <mat-option *ngFor="let res of responses" [value]="res.ID">
                                                            {{res.NAME}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                    <div fxLayout="column" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Do you want over-booking limit?
                                        </div>
                                        <div>
                                            <mat-radio-group (click)="$event.stopPropagation()"
                                                formControlName="overBookingLimit">
                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses"
                                                    [value]="res.ID" (change)="toggle($event, 'overBookingLimit')">
                                                    <span class="radio-label">{{res.NAME}}</span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                        <div formArrayName="overBookingsArray">
                                            <div fxLayout="column" fxLayoutGap="15px"
                                                *ngFor="let book of bulkInventoryForm.controls.overBookingsArray.controls; let i = index;"
                                                formGroupName="{{i}}" class="array-box">
                                                <div class="box-name-sub">{{book.value.roomTypeName}}</div>
                                                <div fxLayout="row" fxLayoutGap="35px">
                                                    <div fxLayout="column" fxFlex="47%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Over-Booking Limit
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <input type="number" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="text-box"
                                                                    formControlName="overBookingCapacity" placeholder="Enter">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <button mat-button class="save-btn" (click)="updateBulkInventory()"
                        [disabled]="bulkInventoryForm.invalid">Update</button>
                    <button mat-button class="cancel-btn" (click)="cancelUpdate()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>