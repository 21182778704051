import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-view-rate-plans',
  templateUrl: './view-rate-plans.component.html',
  styleUrls: ['./view-rate-plans.component.scss']
})
export class ViewRatePlansComponent {

  userInfo: any;
  accessType: any;
  loading: boolean = true;
  ratePlans: any = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<ViewRatePlansComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.accessType = this.userInfo.accessTypeId;
    this.getRatePlansByRoomType(this.data.roomTypeID);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getRatePlansByRoomType(id) {
    this.hotelService.getRatePlanByTypeId(id).subscribe({
      next: (data) => {
        this.loading = false;
        if (Object.keys(data.object).length != 0) {
          Object.values(data.object).forEach(value => {
            this.ratePlans = value;
          })
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateRatePlan(id) {
    this.router.navigateByUrl('rate-plan');
    this.hotelService.changeAction('edit_plan');
    this.hotelService.setId(id);
    this.dialogRef.close();
  }

}