<div class="spa-categories" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="category-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span class="header">{{notCreate ? 'Update dining type':'Create dining type'}}</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button mat-dialog-close class="close-btn">
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="diningTypeForm">
        <div mat-dialog-content class="content-box">
            <div class="spa-name-box" fxLayout="column" fxLayoutGap="9px">
                <div class="box-name">
                    In-Room Dining Type
                </div>
                <div>
                    <input type="text" class="text-box" formControlName="diningType" placeholder="Enter dining type">
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button
                    [ngClass]="this.diningTypeForm.invalid ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.diningTypeForm.invalid" (click)="saveCategories()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>