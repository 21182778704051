<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <mat-dialog-actions dir="rtl">
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </mat-dialog-actions>
    </div>
    <div mat-dialog-content fxLayout="column" fxLayoutGap="15px" class="content">
        <div fxLayout="column" fxLayoutGap="15px" class="summary-content">
            <div fxLayout="row" fxLayoutAlign="space-between start" *ngFor="let order of orderInfo| keyvalue"
                fxLayoutGap="25px">
                <div fxLayout="column" fxFlex="80%" fxLayoutAlign="start " fxLayoutGap="15px">
                    <div class="summary-head">{{order.value['data']['CATEGORY']['NAME']}} -
                        {{order.value['data']['NAME']}}</div>
                    <div class="summary-desc">{{order.value['data']['DESCRIPTION']}}</div>
                    <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                        <div class="item-rates">Quantity</div>
                        <div class="inventory" fxLayout="row">
                            <button class="value-button" (click)="reduceQuantity(order.key)">-</button>
                            <div class="item-value">{{getQuantity(order.key)}}</div>
                            <button class="value-button" (click)="addQuantity(order.key)">+</button>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between center">
                        <div class="item-rates">Price</div>
                        <div class="item-rates" *ngIf="offerPrice == 0">{{currencySymbol}}
                            {{(order.value['data']['PRICE'] * getQuantity(order.key)).toFixed(2)}}</div>
                        <div class="item-rates" *ngIf="offerPrice != 0">{{currencySymbol}}
                            {{(order.value['data']['PRICE'] * getQuantity(order.key) - ((order.value['data']['PRICE'] *
                            getQuantity(order.key)) * (this.currentDiscount / 100))).toFixed(2)}}</div>
                    </div>
                    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between center"
                        *ngFor="let tax of order.value['data']['SERVICETAX']">
                        <div class="summary-desc">{{tax.TAX_ID.NAME}} - {{tax.TAX_ID.PERCENTAGE}}%</div>
                        <div class="summary-desc" *ngIf="offerPrice == 0">{{currencySymbol}}
                            {{((order.value['data']['PRICE'] * getQuantity(order.key)) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2)}}</div>
                        <div class="summary-desc" *ngIf="offerPrice != 0">{{currencySymbol}}
                            {{(((order.value['data']['PRICE'] * getQuantity(order.key)) - ((order.value['data']['PRICE']
                            * getQuantity(order.key)) * (this.currentDiscount / 100))) * (tax.TAX_ID.PERCENTAGE / 100)).toFixed(2)}}</div>
                    </div>
                </div>
                <div fxFlex="20%">
                    <img src="{{bucketUrl + order.value['data']['ATTACHMENT_SOURCE']}}"
                        onerror="this.src='assets/images/question_mark.svg';" class="summary-img">
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="column" fxLayoutGap="15px">
                <div class="summary-head" style="padding-bottom: 8px;">Order Summary</div>
                <div fxLayout="column" *ngFor="let offers of availableOffers; let i = index;">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <img src="assets/images/offer.png" />
                            <div class="summary-desc">{{offers.NAME}}</div>
                        </div>
                        <button [ngClass]="selectedIndex == i? 'applied-btn' : 'apply-btn'"
                            (click)="applyOffer(offers.DISCOUNT, i, offers.ID, totalPrice)">
                            <span>{{selectedIndex == i? 'Applied' : 'Apply'}}</span>
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="item-rates">Item Total</div>
                    <div class="summary-rate">{{currencySymbol}} {{totalPrice}}</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="item-rates">Taxes $ Fees</div>
                    <div class="summary-rate">{{currencySymbol}} {{totalTaxAmount}}</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="item-rates">Offers</div>
                    <div class="summary-rate">{{currencySymbol}} {{offerPrice}}</div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="item-rates">Total</div>
                    <div class="summary-rate" *ngIf="priceAfterOffers != 0">{{currencySymbol}} {{priceAfterOffers}}
                    </div>
                    <div class="summary-rate" *ngIf="priceAfterOffers == 0">{{currencySymbol}}
                        {{totalAmountPayable.toFixed(2)}}
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" style="padding-top: 15px;">
            <button mat-button class="cancel-btn" (click)="cancel()">Cancel</button>
            <button mat-button class="book-btn" [disabled]="priceAfterOffers == 0 && totalPrice == 0"
                (click)="bookDining()">Book</button>
        </div>
    </div>
</div>