import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-housekeeping-services',
  templateUrl: './housekeeping-services.component.html',
  styleUrls: ['./housekeeping-services.component.scss']
})
export class HousekeepingServicesComponent {

  housekeepingServiceForm: FormGroup;
  savedServiceFiles = {};
  serviceById: any = [];
  loading: boolean = false;
  mobile: boolean;
  notCreate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<HousekeepingServicesComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    public hotelService: HotelServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.housekeepingServiceForm = this.fb.group({
      serviceName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "update_service") {
      this.notCreate = true;
      this.loading = true;
      this.hotelService.getHousekeepingServiceById(this.data.ID).subscribe({
        next: (res) => {
          this.loading = false;
          this.serviceById = res.object;
          this.housekeepingServiceForm.get("serviceName").setValue(res.object.NAME);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  saveCategories() {
    let name = this.housekeepingServiceForm.value.serviceName.trim();
    if (name.length == 0) {
      this.notify.showMessage(
        "Name should not be empty",
        "top",
        "warning"
      )
      this.housekeepingServiceForm.get("serviceName").setValue('');
    }
    else {
      this.loading = true;
      if (this.data.name == "add_service") {
        this.savedServiceFiles = {
          "NAME": this.housekeepingServiceForm.value.serviceName.trim(),
          "POSITION": 1111 //ToDo: temp value
        }
        this.hotelService.addHousekeepingService(this.savedServiceFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
      else if (this.data.name == "update_service") {
        this.savedServiceFiles = {
          "ID": this.data.ID,
          "NAME": this.housekeepingServiceForm.value.serviceName.trim(),
          "POSITION": 1111, //ToDo: this.serviceById.POSITION
        }
        this.hotelService.updateHousekeepingService(this.savedServiceFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
    }
  }

}