import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { environment } from 'src/environments/environment';
export const BYPASS_LOG = new HttpContextToken(() => false);
export const BYPASS_TOKEN = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  public resourceUrl = `${environment.API_BASE_URL}`;
  public textLength = new BehaviorSubject<any>({ length: 0 });

  constructor(private http: HttpClient) { }

  refreshToken(token): Observable<any> {
    let obj = {
      "REFRESH_TOKEN": token
    }
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/auth/login/refresh-token`, obj, { context: new HttpContext().set(BYPASS_TOKEN, true) });
  }

  getMasterData(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/master-data`, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  login(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/auth/login/email`, obj, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  getForgotPasswordOTP(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/auth/password/forgot`, obj, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  verifyForgotPassword(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/auth/password/forgot/verify`, obj, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  getUserInfo(obj): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member?USERNAME=${obj.USERNAME}`, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  getClientInfo(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client/info`);
  }

  getInternalMembers(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getInternalMembersByKey(pageNo, limit, key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?PAGE=${pageNo}&LIMIT=${limit}&SEARCH_KEY=${key}`);
  }

  getAllInternalMembers(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?TRIM=true`);
  }

  getInternalMembersByRoomIdAndServiceId(roomId, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?ROOM_ID=${roomId}&SERVICE_ID=${serviceId}&TRIM=true`);
  }

  getInternalMembersByServiceId(serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?SERVICE_ID=${serviceId}&TRIM=true`);
  }

  createInternalMember(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/member/internal/create`, obj);
  }

  getInternalMemberById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/internal?ID=${id}`);
  }

  updateInternalMember(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/member/internal/update`, obj);
  }

  deleteInternalMember(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/member/internal/delete`, obj);
  }

  getOffers(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/offers?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getOffersByServiceId(pageNo, limit, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/offers?PAGE=${pageNo}&LIMIT=${limit}&SERVICE_ID=${serviceId}`);
  }

  getOfferById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/offers?ID=${id}`);
  }

  addOffer(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/offers/create`, obj);
  }

  updateOffer(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/offers/update`, obj);
  }

  getPrivacyPolicy(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/privacy-policy`);
  }

  addPrivacyPolicy(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/privacy-policy/create`, obj);
  }

  updatePrivacyPolicy(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/privacy-policy/update`, obj);
  }

  getFaqs(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/faq?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  updateFaq(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/faq/update`, obj);
  }

  getFaqById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/faq?ID=${id}`);
  }

  getFaqsByServiceId(pageNo, limit, serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/faq?PAGE=${pageNo}&LIMIT=${limit}&SERVICE_ID=${serviceId}`);
  }

  addFaq(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/faq/create`, obj);
  }

  getSpaSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/spa/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  saveSpaSlotsByDate(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/spa/type/slot/save`, obj);
  }

  updateSpaSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/type/slot/update`, obj);
  }

  deleteSpaSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/spa/type/slot/delete`, obj);
  }

  getPackageSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/package/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  savePackageSlotsByDate(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/package/type/slot/save`, obj);
  }

  updatePackageSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/package/type/slot/update`, obj);
  }

  deletePackageSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/package/type/slot/delete`, obj);
  }

  getTransportSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/transport/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  saveTransportSlotsByDate(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/transport/type/slot/save`, obj);
  }

  updateTransportSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/transport/type/slot/update`, obj);
  }

  deleteTransportSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/transport/type/slot/delete`, obj);
  }

  getConferenceSlotsByDate(date, typeId, categoryId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/conference/type/slot?DATE=${date}&CATEGORY_ID=${categoryId}&TYPE_ID=${typeId}`);
  }

  saveConferenceSlotsByDate(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/conference/type/slot/save`, obj);
  }

  updateConferenceSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/conference/type/slot/update`, obj);
  }

  deleteConferenceSlotsByDate(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/conference/type/slot/delete`, obj);
  }

  getMyProfileInfo(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/profile`);
  }

  updateMyProfile(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/member/profile/update`, obj);
  }

  getClientsBySuperAdmin(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getClientById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client?ID=${id}`);
  }

  createClient(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/client/create`, obj);
  }

  updateClient(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/client/update`, obj);
  }

  getAdminsBySuperAdmin(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client/member?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getAdminById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client/member?ID=${id}`);
  }

  createAdmin(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/client/member/create`, obj);
  }

  updateAdmin(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/client/member/update`, obj);
  }

  deleteAdmin(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/client/member/delete`, obj);
  }

  getClientsList(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/client?TRIM=true`);
  }

  getServiceByClientId(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/subscription?C_ID=${id}`);
  }

  updateClientService(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/services/subscription`, obj);
  }

  getCurrencyByClientId(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/currency?C_ID=${id}`);
  }

  updateClientCurrency(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/currency`, obj);
  }

  getDiningFilter(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/dining/filters`);
  }

  getDiningFilterById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/dining/filters?ID=${id}`);
  }

  createDiningFilter(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/dining/filters/create`, obj);
  }

  updateDiningFilter(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/dining/filters/update`, obj);
  }

  getWebsiteContactUs(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/website/contact-us?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getWebsiteFreeTrial(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/website/free-trial?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getWebsiteSubscriber(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/website/subscriber?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getWebsiteConnectUs(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/website/connect-us?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getWebsiteJoinUs(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/website/join-us?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getUploadedSheetList(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/user/upload/sheet?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getAppUsersList(pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/user/?PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getAppUserByID(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/user/?ID=${id}`);
  }

  createAppUser(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/user/create`, obj);
  }

  updateAppUser(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/user/update`, obj);
  }

  deleteAppUser(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/user/delete`, obj);
  }

  updatePassword(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/member/profile/password/update`, obj);
  }

  getRating(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating`);
  }

  getRatingById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating?ID=${id}`);
  }

  addRating(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/rating/create`, obj);
  }

  updateRating(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/rating/update`, obj);
  }

  getRatingDataByStay(stayType, pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating/data?STAY=${stayType}&PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getRatingDataByService(serviceId, pageNo, limit): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating/data?SERVICE_ID=${serviceId}&PAGE=${pageNo}&LIMIT=${limit}`);
  }

  getRatingStatsByStay(stayType): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating/stats?STAY=${stayType}`);
  }

  getRatingStatsByService(serviceId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/rating/stats?SERVICE_ID=${serviceId}`);
  }

  getNotification(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/member/notification`);
  }

}