import { Component, ViewEncapsulation, Inject, ViewChild, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';

@Component({
  selector: 'app-add-edit-room',
  templateUrl: './add-edit-room.component.html',
  styleUrls: ['./add-edit-room.component.scss']
})
export class AddEditRoomComponent {
  loading: boolean = false;
  areas = [];
  roomCategories = [];
  roomTypes = [];
  addRoomForm: FormGroup;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditRoomComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    public hotelService: HotelServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addRoomForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      area: ['', Validators.required],
      category: ['', Validators.required],
      type: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.loading = true;
    this.cs.getAreas().subscribe({
      next: (res) => {
        this.loading = false;
        this.areas = res.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
    this.getCategories();
    if (this.data) {
      this.cs.getRoomById(this.data.id).subscribe({
        next: (res) => {
          this.loading = false;
          this.getTypes(res.object.CATEGORY.ID);
          this.addRoomForm.get("name").setValue(res.object.NAME);
          this.addRoomForm.get("description").setValue(res.object.DESCRIPTION);
          this.addRoomForm.get("area").setValue(res.object.AREA.ID);
          this.addRoomForm.get("category").setValue(res.object.CATEGORY.ID);
          this.addRoomForm.get("type").setValue(res.object.CATEGORY_TYPE.ID);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getCategories() {
    this.hotelService.getRoomCategory().subscribe({
      next: (data) => {
        this.roomCategories = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getTypes(id) {
    this.hotelService.getRoomType(id).subscribe({
      next: (data) => {
        this.roomTypes = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addRoom() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "AREA_ID": this.addRoomForm.value.area,
        "NAME": this.addRoomForm.value.name,
        "DESCRIPTION": this.addRoomForm.value.description,
        "CATEGORY_ID": this.addRoomForm.value.category,
        "TYPE_ID": this.addRoomForm.value.type
      }
      this.cs.updateRoom(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "AREA_ID": this.addRoomForm.value.area,
        "NAME": this.addRoomForm.value.name,
        "DESCRIPTION": this.addRoomForm.value.description,
        "CATEGORY_ID": this.addRoomForm.value.category,
        "TYPE_ID": this.addRoomForm.value.type
      }
      this.cs.addRoom(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}