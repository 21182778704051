import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userInfo: any;
  roleId: any;
  hotelServices: any = [];
  currentHierarchy: any;
  addUserForm: FormGroup;
  countries = [];
  accessTypes = [];
  services = [];
  loading: boolean = false;
  roles = [];
  isEnabled: boolean;
  serviceIds = [];
  selectedService = [];
  detailsPattern = /^[a-z A-Z]+$/;
  mobilePattern = /^[0-9]{10,15}$/;
  public emailPattern = `${environment.EMAIL_PATTERN}`;
  public passwordPattern = `${environment.PASSWORD_PATTERN}`;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  shifts: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private notify: NotificationService,
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addUserForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      mobileNumber: ['', [Validators.required, Validators.pattern(this.mobilePattern)]],
      country: ['', Validators.required],
      role: ['', Validators.required],
      accessType: ['', Validators.required],
      service: [[], Validators.required],
      shift: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.getShifts();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.hotelServices = window.localStorage.getItem('hotel_services');
    this.roleId = this.userInfo.roleId;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.countries = res['COUNTRY'];
        this.accessTypes = res['ACCESS_TYPE'];
        for (let role of res['ROLE']) {
          if (this.roleId == role.ID) {
            this.currentHierarchy = role.HIERARCHY;
          }
          if (this.currentHierarchy < role.HIERARCHY) {
            this.roles.push(role);
          }
        }
        if (this.userInfo.roleId == 2) {
          for (let service of res['SERVICE']) {
            if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.services.push(service);
            }
          }
        }
        else if (this.userInfo.roleId != 2) {
          for (let service of res['SERVICE']) {
            if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true)) {
              this.services.push(service);
            }
          }
        }
      }
    })
    if (this.data) {
      this.loading = true;
      this.adminService.getInternalMemberById(this.data.id).subscribe(res => {
        this.loading = false;
        this.isEnabled = res.object.USER_ISABLED;
        this.addUserForm.get("firstName").setValue(res.object.FIRST_NAME);
        this.addUserForm.get("lastName").setValue(res.object.LAST_NAME);
        this.addUserForm.get("email").setValue(res.object.EMAIL_ID);
        this.addUserForm.get("mobileNumber").setValue(res.object.MOBILE);
        this.addUserForm.get("country").setValue(res.object.COUNTRY?.ID);
        this.addUserForm.get("role").setValue(res.object.ROLE.ID);
        this.addUserForm.get("accessType").setValue(res.object.ACCESS_TYPE?.ID);
        if (res.object.ROLE.ID == 3) {
          for (let id of res.object.SERVICE) {
            this.serviceIds.push(id.ID);
          }
          this.addUserForm.get("service").setValue(this.serviceIds);
        }
        else {
          this.addUserForm.get("service").setValue(res.object.SERVICE[0].ID);
        }
        this.addUserForm.get("shift").setValue(res.object.WORK_SHIFT?.ID);
      });
      this.addUserForm.controls['email'].disable();
      this.addUserForm.controls['password'].disable();
      this.addUserForm.controls['mobileNumber'].disable();
      this.addUserForm.controls['country'].disable();
    } else {
      this.addUserForm.controls['email'].enable();
      this.addUserForm.controls['password'].enable();
      this.addUserForm.controls['mobileNumber'].enable();
      this.addUserForm.controls['country'].enable();
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  getShifts() {
    this.configService.getShifts().subscribe({
      next: (data) => {
        this.shifts = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changeRole() {
    this.addUserForm.controls['service'].setValue('');
    this.selectedService = [];
  }

  addUser() {
    if (this.addUserForm.value.role == 3) {
      this.selectedService = this.addUserForm.value.service;
    }
    else {
      this.selectedService.push(this.addUserForm.value.service);
    }
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "FIRST_NAME": this.addUserForm.value.firstName,
        "LAST_NAME": this.addUserForm.value.lastName,
        "SERVICE_ID": this.selectedService,
        "ROLE_ID": this.addUserForm.value.role,
        "ACCESS_TYPE_ID": this.addUserForm.value.accessType,
        "SHIFT_ID": this.addUserForm.value.shift,
        "ENABLED": !this.isEnabled
      }
      this.adminService.updateInternalMember(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "EMAIL": this.addUserForm.value.email,
        "FIRST_NAME": this.addUserForm.value.firstName,
        "LAST_NAME": this.addUserForm.value.lastName,
        "PASSWORD": this.addUserForm.value.password,
        "PHONE_NUMBER": this.addUserForm.value.mobileNumber,
        "COUNTRY_CODE_ID": this.addUserForm.value.country,
        "SERVICE_ID": this.selectedService,
        "ROLE_ID": this.addUserForm.value.role,
        "ACCESS_TYPE_ID": this.addUserForm.value.accessType,
        "SHIFT_ID": this.addUserForm.value.shift
      }
      this.adminService.createInternalMember(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}