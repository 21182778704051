<mat-toolbar class="header-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div class="title">
            Notifications
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button class="close-btn" mat-dialog-close>
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
</mat-toolbar>
<div class="container">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutGap="15px">
        <div class="notify-panel" *ngFor="let item of notificationList">
            <div class="notify-text">
                Process initiated on {{ item.CREATED_DATE | date:'MMM dd YYYY' }} for <b>{{ item.SERVICE.NAME }}</b> {{ item.MODULE }} is <u><b>{{ item.PROCESS_STATUS.NAME }}</b></u>
            </div>
        </div>
    </div>
</div>