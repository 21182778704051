<div class="dashboard-container" *ngIf="mobile && userInfo.roleId != 4" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div *ngIf="!isNewRequest">
            <div fxLayout="row">
                <mat-tab-group class="rating-tabs" (selectedTabChange)="onTabChanged($event)" [(selectedIndex)]="selectedTab">
                    <mat-tab label="Escalated Requests">
                        <mat-card class="table-card"></mat-card>
                    </mat-tab>
                    <mat-tab label="Live Requests">
                        <mat-card class="table-card">
                            <div fxLayout="column" class="content">
                                <div fxLayout="row" fxLayoutAlign="center" style="padding: 10px;">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20%" fxLayoutGap="9px">
                                        <div class="header">Live Request</div>
                                        <div>
                                            <button class="refresh-btn"><span class="material-symbols-outlined"
                                                (click)="refresh()" style="font-size: 19px;">refresh</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="11px" fxLayoutAlign="end center">
                                        <mat-select class="select-service" placeholder="Select Service"
                                            [formControl]="service">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-form-field class="from-date" appearance="outline">
                                            <input matInput [formControl]="reqFromDate" placeholder="From"
                                                [matDatepicker]="picker3" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="to-date" appearance="outline">
                                            <input matInput [formControl]="reqToDate" placeholder="To"
                                                [matDatepicker]="picker4" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker4"></mat-datepicker-toggle>
                                            <mat-datepicker #picker4></mat-datepicker>
                                        </mat-form-field>
                                        <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end center">
                                            <div style="margin-right: -20px;">
                                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                                                <input matInput [formControl]="searchVal" class="menu-search"
                                                    placeholder="Search">
                                            </div>
                                            <div>
                                                <button class="search-btn" (click)="search()">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-scroll">
                                    <table mat-table [dataSource]="dataSource">
                                        <ng-container matColumnDef="service">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:20%"> Service </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.SERVICE_ID?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%"> Priority </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.PRIORITY?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assignee">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:20%"
                                                [hidden]="userInfo.roleId == 4"> Assignee </th>
                                            <td mat-cell *matCellDef="let element" [hidden]="userInfo.roleId == 4">
                                                {{element.ASSIGNEE?.FIRST_NAME}} {{element.ASSIGNEE?.LAST_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%">
                                                Status </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.REQUEST_STATUS?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="description">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:25%">
                                                Description </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="req-desc">{{element.DESCRIPTION}}</div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="button">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%">
                                                Action </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button class="search-btn" (click)="updateBooking(element, 'live')" [disabled]="accessType == 2">Update</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <!-- <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator> -->
                            </div>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="Completed Requests">
                        <mat-card class="table-card">
                            <div fxLayout="column" class="content">
                                <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2%" style="padding: 10px;">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="9px">
                                        <div class="header">
                                            Completed Request
                                        </div>
                                        <div>
                                            <button class="refresh-btn"><span class="material-symbols-outlined"
                                                (click)="refresh()" style="font-size: 19px;">refresh</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                                        <mat-select class="select-service" placeholder="Select Service"
                                            [formControl]="service">
                                            <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                                {{service.NAME}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-form-field class="from-date" appearance="outline">
                                            <input matInput [formControl]="reqFromDate" placeholder="From"
                                                [matDatepicker]="picker5" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker5"></mat-datepicker-toggle>
                                            <mat-datepicker #picker5></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="to-date" appearance="outline">
                                            <input matInput [formControl]="reqToDate" placeholder="To"
                                                [matDatepicker]="picker6" readonly>
                                            <mat-datepicker-toggle matIconPrefix
                                                [for]="picker6"></mat-datepicker-toggle>
                                            <mat-datepicker #picker6></mat-datepicker>
                                        </mat-form-field>
                                        <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end center">
                                            <div style="margin-right: -20px;">
                                                <mat-icon matSuffix class="search_icon">search</mat-icon>
                                                <input matInput [formControl]="searchVal" class="menu-search"
                                                    placeholder="Search">
                                            </div>
                                            <div>
                                                <button class="search-btn" (click)="search()">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-scroll">
                                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                        <ng-container matColumnDef="service">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:20%"> Service </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.SERVICE_ID?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef class="table-header"
                                                style="width:10%;"> Priority </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.PRIORITY?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assignee">
                                            <th mat-header-cell *matHeaderCellDef [hidden]="userInfo.roleId ==  4"
                                                class="table-header" style="width:20%"> Assignee </th>
                                            <td mat-cell *matCellDef="let element" [hidden]="userInfo.roleId ==  4">
                                                {{element.ASSIGNEE?.FIRST_NAME}} {{element.ASSIGNEE?.LAST_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> Status </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.REQUEST_STATUS?.NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="description">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:25%">
                                                Description </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="req-desc">{{element.DESCRIPTION}}</div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="button">
                                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%">
                                                Action </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button class="search-btn" (click)="updateBooking(element, 'completed')">View</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <!-- <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="changePage($event)"></mat-paginator> -->
                            </div>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>
                <div class="btn-toolbar" fxLayout="row" *ngIf="userInfo.roleId != 4">
                    <button mat-stroked-button class="add-request-btn" (click)="createRequest()" [disabled]="accessType == 2">
                        New Request
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isNewRequest">
            <mat-card fxLayout="column" fxLayoutGap="15px" class="request-card">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxFlex="50%" fxLayoutAlign="start center" fxLayoutGap="2%">
                        <div class="back-arrow" (click)="backToRequests()">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="request-head">
                            Create New Request
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="50%" fxLayoutAlign="end center" fxLayoutGap="3%">
                        <button mat-button class="cancel-btn" (click)="backToRequests()">Cancel</button>
                        <button mat-button class="save-btn" (click)="createBooking()" [disabled]="addRequestForm.invalid">
                            Save</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <form [formGroup]="addRequestForm">
                    <div mat-dialog-content class="form-content" fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Service
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="service"
                                        placeholder="Select Service"
                                        (selectionChange)="selectRequestService($event.value)">
                                        <mat-option *ngFor="let service of allowedServices" [value]="service.ID">
                                            {{service.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Assign To
                                </div>
                                <div>
                                    <mat-select class="text-box" [panelClass]="isLoading" formControlName="assignee"
                                        (click)="openAssigneeSelect()" placeholder="Select Assignee">
                                        <mat-option style="display: none;"></mat-option>
                                        <ng-container *ngFor="let user of selectedAssignee">
                                            <mat-option *ngIf="user.USER_DISABLED != true" [value]="user.ID">
                                                <span>{{user.FIRST_NAME}} {{user.LAST_NAME}}</span>
                                            </mat-option>
                                        </ng-container>
                                        <mat-label *ngIf="isLoading">
                                            <div>
                                                <mat-spinner class="assignee-spinner" diameter="20"></mat-spinner>
                                            </div>
                                        </mat-label>
                                    </mat-select>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Priority
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="priority"
                                        placeholder="Select Priority">
                                        <mat-option *ngFor="let priority of priorityList" [value]="priority.ID">
                                            {{priority.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Created By
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="createdBy"
                                        placeholder="Created By" readonly>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Description
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="description"
                                        placeholder="Enter Description" maxlength="200">
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Upload
                                </div>
                                <div>
                                    <div class="upload-box" fxLayout="row" fxLayoutAlign="center center"
                                        fxLayoutGap="7px" appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                                        <div>
                                            <input type="file" class="doc_file" id="doc_file" style="display: none"
                                                #fileInput hidden accept=".jpg,.jpeg,.png"
                                                (change)="onFileSelected($event.target.files)" />
                                            <iconify-icon icon="material-symbols:cloud-upload"
                                                (click)="fileInput.click()" class="upload-icon"></iconify-icon>
                                        </div>
                                        <div fxFlex="45%" class="upload-text" (click)="fileInput.click()">
                                            <span class="upload-text1">Drop a file here or </span>
                                            <span class="upload-text2">browse to upload jpeg, jpg, png</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="file-name" (click)="preview(source_url)">
                                    {{imageName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>
<div class="dashboard-container" fxLayout="column" fxLayoutGap="20px" *ngIf="mobile && userInfo.roleId == 4">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <mat-tab-group class="rating-tabs" (selectedTabChange)="onTabChanged($event)" [(selectedIndex)]="selectedTab">
        <mat-tab label="Escalated Requests">
        </mat-tab>
        <mat-tab label="Live Requests">
            <div fxLayout="column" class="tab-body">
                <input matInput [formControl]="searchVal" class="menu-search-staff" placeholder="Search">
                <mat-icon matSuffix class="search_icon-staff">search</mat-icon>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutGap="1%" style="width:100%">
                        <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center start">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="22%" style="font-weight: 500; font-size: 15px;">From : </div>
                                <mat-form-field class="from-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqFromDate" placeholder="From" [matDatepicker]="pickerMob3" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob3"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob3></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="24%" style="font-weight: 500; font-size: 15px;">To : </div>
                                <mat-form-field class="to-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqToDate" placeholder="To" [matDatepicker]="pickerMob4" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob4"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob4></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="start end">
                            <div>
                                <button class="clear-btn-staff" (click)="refresh()">
                                    Clear
                                </button>
                            </div>
                            <div>
                                <button class="search-btn-staff" (click)="search()">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="card" fxLayout="column" fxLayoutGap="20px" *ngFor="let booking of liveBookings">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                        <div fxFlex="85%" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="7%">
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Service:</div>
                                <div class="room">{{booking.SERVICE_ID?.NAME}}</div>
                            </div>
                            <div style="align-self: center;">|</div>
                            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div style="font-weight: 500;">Status:</div>
                                <div class="room">{{booking.REQUEST_STATUS.NAME}}</div>
                            </div>
                        </div>
                        <div fxFlex="10%" fxLayoutAlign="start center">
                            <button mat-button [matMenuTriggerFor]="menu">...</button>
                            <mat-menu #menu="matMenu" class="mat-menu">
                                <mat-toolbar class="toolbar">
                                    <div style="color:#262020">Acknowledge with updates</div>
                                    <div><mat-icon class="close-icon">
                                            close
                                        </mat-icon></div>
                                </mat-toolbar>
                                <mat-radio-group (click)="$event.stopPropagation()" class="radio-group"
                                    [formControl]="staffBookingStatus">
                                    <mat-radio-button *ngFor="let status of statusList" value="{{status.ID}}" class="radio-btn">
                                        <span class="radio-label">
                                            {{status.NAME}}
                                        </span>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <button class="update-btn" [disabled]="staffBookingStatus.value.length == 0 || accessType == 2"
                                    (click)="save(booking.ID, booking.SERVICE_ID.ID)">Send Update</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <span class="service-header">
                            {{booking.DESCRIPTION}}
                        </span>
                    </div>
                </mat-card>
                <mat-card *ngIf="liveBookings.length == 0" class="card">
                    No Data Found
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Completed Requests">
            <div fxLayout="column" class="tab-body">
                <input matInput [formControl]="searchVal" class="menu-search-staff" placeholder="Search">
                <mat-icon matSuffix class="search_icon-staff">search</mat-icon>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutGap="1%" style="width:100%">
                        <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center start">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="22%" style="font-weight: 500; font-size: 15px;">From : </div>
                                <mat-form-field class="from-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqFromDate" placeholder="From" [matDatepicker]="pickerMob5" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob5"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob5></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="24%" style="font-weight: 500; font-size: 15px;">To : </div>
                                <mat-form-field class="to-date-staff" appearance="outline">
                                    <input matInput [formControl]="reqToDate" placeholder="To" [matDatepicker]="pickerMob6" readonly>
                                    <mat-datepicker-toggle matIconPrefix [for]="pickerMob6"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMob6></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="start end">
                            <div>
                                <button class="clear-btn-staff" (click)="refresh()">
                                    Clear
                                </button>
                            </div>
                            <div>
                                <button class="search-btn-staff" (click)="search()">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="card" fxLayout="column" fxLayoutGap="20px" *ngFor="let booking of completedBookings">
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="7%">
                        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                            <div style="font-weight: 500;">Service:</div>
                            <div class="room">{{booking.SERVICE_ID?.NAME}}</div>
                        </div>
                        <div style="align-self: center;">|</div>
                        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                            <div style="font-weight: 500;">Status:</div>
                            <div class="room">{{booking.REQUEST_STATUS.NAME}}</div>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <span class="service-header">
                            {{booking.DESCRIPTION}}
                        </span>
                    </div>
                </mat-card>
                <mat-card *ngIf="completedBookings.length == 0" class="card">
                    No Data Found
                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>