import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-add-rating',
  templateUrl: './add-rating.component.html',
  styleUrls: ['./add-rating.component.scss']
})
export class AddRatingComponent {

  categories = [];
  loading: boolean = false;
  serviceId: any;
  updateData = [];
  isEnabled: boolean = false;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  notCreate: boolean = false;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRatingComponent>,
    public adminService: AdminService,
    private notify: NotificationService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  addForm: FormGroup = this.fb.group({
    question: ['', Validators.required]
  })

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "update_rating") {
      this.notCreate = true;
      this.loading = true;
      this.adminService.getRatingById(this.data.ID).subscribe(res => {
        this.loading = false;
        this.addForm.get("question").setValue(res.object.SERVICE_INFO);
        this.isEnabled = res.object.ENABLED;
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  saveRating() {
    this.loading = true;
    if (this.data.name == "update_rating") {
      let obj = {
        "ID": this.data.ID,
        "SERVICE_INFO": this.addForm.value.question,
        "ENABLED": this.isEnabled
      }
      this.adminService.updateRating(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.data.name == "add_rating") {
      let obj = {
        "SERVICE_INFO": this.addForm.value.question,
        "ENABLED": false
      }
      this.adminService.addRating(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}