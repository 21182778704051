import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { DialogDeletePolicyComponent } from './dialog-delete-policy/dialog-delete-policy.component';
import { colorObj } from 'src/app/shared/color-object';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent implements OnInit {
  currentPolicyId: any;
  loading: boolean = true;
  getData: string;
  editorForm: FormGroup;
  isEdit: boolean;
  showPrivacyPolicy = false;
  save = false;
  textLength: number;
  editorStyle = {
    height: '330px',
    width: '100%',
    backgroundColor: "#ffffff"
  };
  config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['link'], ['clean'], [{ 'align': [] }],
    ],
  }
  userInfo: any;
  userName: any;
  accessType: any;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    private adminservice: AdminService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.editorForm = this.fb.group({
      editor: ['', Validators.maxLength(4800)]
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getPrivacyPolicy();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getPrivacyPolicy() {
    this.adminservice.getPrivacyPolicy().subscribe({
      next: (data) => {
        this.loading = false;
        data.object.POLICY
        if (data.object.ID) {
          this.showPrivacyPolicy = false;
        } else {
          this.showPrivacyPolicy = true;
        }
        this.getData = data.object.POLICY;
        this.currentPolicyId = data.object.ID;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  savePolicy() {
    //To update existing policy
    if (this.isEdit == true) {
      let body = {
        'ID': this.currentPolicyId,
        'POLICY': this.editorForm.value.editor
      }
      this.adminservice.updatePrivacyPolicy(body)
        .subscribe({
          next: (data) => {
            this.loading = true;
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.getPrivacyPolicy();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
    }
    //To add new policy
    else {
      let body = {
        'POLICY': this.editorForm.value.editor
      }
      this.adminservice.addPrivacyPolicy(body)
        .subscribe({
          next: (data) => {
            this.loading = true;
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.getPrivacyPolicy();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogDeletePolicyComponent, {
      width: '300px',
      data: { 'id': this.currentPolicyId },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.loading = true;
        this.editorForm.get("editor").patchValue('');
        this.isEdit = false;
        this.getPrivacyPolicy();
      }
    });
  }

  onCancel() {
    this.getPrivacyPolicy();
  }

  onEdit() {
    this.showPrivacyPolicy = true;
    this.isEdit = true;
    this.editorForm.get("editor").patchValue(this.getData);
    this.adminservice.textLength.subscribe(data => {
      this.textLength = data['length']
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

  textChangedPolicy($event) {
    if ($event.editor.getLength() > 4799) {
      $event.editor.deleteText(4799, $event.editor.getLength());
    }
    this.textLength = $event.editor.getLength();
    this.adminservice.textLength.next({ length: this.textLength })
  }

}