<div class="main-container">
    <div class="container">
        <div class="left-panel" *ngIf="!mobile">
            <div class="menu-content">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="logo-container">
                    <div><img class="logo" src="{{imageURL}}"></div>
                    <div class="head-text">{{hotelName}}</div>
                </div>
                <div class="menu-list">
                    <div [ngClass]="currentUrl == '/clients' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 1" routerLink="/clients" routerLinkActive="active">
                        <div><img src="../../../../assets/images/add_business.svg" class="list-icon"></div>
                        <div class="menu-text">Client Management</div>
                    </div>
                    <div [ngClass]="currentUrl == '/admins' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 1" routerLink="/admins" routerLinkActive="active">
                        <div><iconify-icon icon="material-symbols:badge-outline" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Member Management</div>
                    </div>
                    <div [ngClass]="currentUrl == '/services' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 1" routerLink="/services" routerLinkActive="active">
                        <div><iconify-icon icon="eos-icons:configuration-file-outlined" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Hotel Configurations</div>
                    </div>
                    <div [ngClass]="currentUrl == '/website' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 1" routerLink="/website" routerLinkActive="active">
                        <div><iconify-icon icon="material-symbols:web" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Website Management</div>
                    </div>
                    <div [ngClass]="currentUrl == '/dashboard' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3" routerLink="/dashboard" routerLinkActive="active">
                        <div><iconify-icon icon="ic:outline-space-dashboard" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Dashboard</div>
                    </div>
                    <div [ngClass]="currentUrl == '/members' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3" routerLink="/members" routerLinkActive="active">
                        <div><iconify-icon icon="material-symbols:badge-outline" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Member Management</div>
                    </div>
                    <div [ngClass]="currentUrl == '/configuration' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2" routerLink="/configuration" routerLinkActive="active">
                        <div><iconify-icon icon="eos-icons:configuration-file-outlined" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Hotel Configurations</div>
                    </div>
                    <div class="menu-option" (click)="showHotelOperations()" *ngIf="userInfo.roleId == 2">
                        <div><iconify-icon icon="material-symbols:inventory" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Hotel Operations</div>
                        <div *ngIf="!nestedHotelOperations"><iconify-icon icon="iconamoon:arrow-down-2-bold"></iconify-icon></div>
                        <div *ngIf="nestedHotelOperations"><iconify-icon icon="iconamoon:arrow-up-2-bold"></iconify-icon></div>
                    </div>
                    <div class="nested-menu-list" *ngIf="nestedHotelOperations && (userInfo.roleId == 2)">
                        <ul>
                            <li class="nested-option">
                                <div routerLink="/rooms" routerLinkActive="active"
                                    [ngClass]="(currentUrl == '/rooms' || currentUrl == '/room-type' || currentUrl == '/rate-plan') ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Room Type and Rates</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/bulk-inventory-update" routerLinkActive="active"
                                    [ngClass]="(currentUrl == '/bulk-inventory-update' || currentUrl == '/bulk-rate-update') ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Bulk Inventory Update</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/room-inventory" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/room-inventory' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Room Inventory</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/about" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/about' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    About</div>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-option" (click)="showReservations()" *ngIf="userInfo.roleId == 2">
                        <div><img src="../../../../assets/images/reservation-orders.png" class="list-icon" style="margin-top: 3px;"></div>
                        <div class="menu-text">Reservations</div>
                        <div *ngIf="!nestedReservations"><iconify-icon icon="iconamoon:arrow-down-2-bold"></iconify-icon></div>
                        <div *ngIf="nestedReservations"><iconify-icon icon="iconamoon:arrow-up-2-bold"></iconify-icon></div>
                    </div>
                    <div class="nested-menu-list" *ngIf="nestedReservations && (userInfo.roleId == 2)">
                        <ul>
                            <li class="nested-option">
                                <div routerLink="/reservation" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/reservation' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Add Reservation</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/all-reservations" routerLinkActive="active"
                                    [ngClass]="(currentUrl == '/all-reservations' || currentUrl == '/arrivals' || currentUrl == '/departures') ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Reservation Inventory</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/check-in" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/check-in' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Check-In’s</div>
                            </li>
                            <li class="nested-option">
                                <div routerLink="/check-out" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/check-out' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    Check-Out’s</div>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-option" (click)="showServices()" *ngIf="userInfo.roleId == 2 || (userInfo.roleId == 3 && hasService)">
                        <div><iconify-icon icon="ic:outline-manage-accounts" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Service Management</div>
                        <div *ngIf="!nestedServices"><iconify-icon icon="iconamoon:arrow-down-2-bold"></iconify-icon></div>
                        <div *ngIf="nestedServices"><iconify-icon icon="iconamoon:arrow-up-2-bold"></iconify-icon></div>
                    </div>
                    <div class="nested-menu-list" *ngIf="nestedServices && (userInfo.roleId == 2 || (userInfo.roleId == 3 && hasService))">
                        <ul>
                            <li class="nested-option" *ngFor="let service of permittedServices">
                                <div class="nested-menu-text" *ngIf="service.NAME == 'Spa'" routerLink="/spa"
                                    routerLinkActive="active"
                                    [ngClass]="currentUrl == '/spa' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                                <div class="nested-menu-text" *ngIf="service.NAME == 'House Keeping'"
                                    routerLink="/housekeeping" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/housekeeping' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                                <div class="nested-menu-text" *ngIf="service.NAME == 'Transport'"
                                    routerLink="/transport" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/transport' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                                <div class="nested-menu-text" *ngIf="service.NAME == 'Package'" routerLink="/package"
                                    routerLinkActive="active"
                                    [ngClass]="currentUrl == '/package' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                                <div class="nested-menu-text" *ngIf="service.NAME == 'Conference Room '"
                                    routerLink="/conference-room" routerLinkActive="active"
                                    [ngClass]="currentUrl == '/conference-room' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                                <div class="nested-menu-text" *ngIf="service.NAME == 'In-Room Dining'" routerLink="/dining"
                                    routerLinkActive="active"
                                    [ngClass]="currentUrl == '/dining' ? 'nested-menu-text-selected' : 'nested-menu-text'">
                                    {{service.NAME}}</div>
                            </li>
                        </ul>
                    </div>
                    <div [ngClass]="currentUrl == '/guest-management' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2" routerLink="/guest-management" routerLinkActive="active">
                        <div><iconify-icon icon="ic:outline-group-add" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Guest Management</div>
                    </div>
                    <div class="menu-option" (click)="showOperations()" *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3 || userInfo.roleId == 4">
                        <div><iconify-icon icon="icon-park-outline:reverse-operation-out" class="list-icon"></iconify-icon>
                        </div>
                        <div class="menu-text">Operations Management</div>
                        <div *ngIf="!nestedOperations"><iconify-icon icon="iconamoon:arrow-down-2-bold"></iconify-icon></div>
                        <div *ngIf="nestedOperations"><iconify-icon icon="iconamoon:arrow-up-2-bold"></iconify-icon></div>
                    </div>
                    <div class="nested-menu-list" *ngIf="nestedOperations && (userInfo.roleId == 2 || userInfo.roleId == 3 || userInfo.roleId == 4)">
                        <ul>
                            <li class="nested-option">
                                <div routerLink="/guest-request" routerLinkActive="active"
                                [ngClass]="(currentUrl == '/guest-request' || currentUrl == '/internal-request') ? 'nested-menu-text-selected' : 'nested-menu-text'">Request Management</div>
                            </li>
                            <li class="nested-option" *ngIf="userInfo.roleId == 2">
                                <div routerLink="/sla-dashboard" routerLinkActive="active"
                                [ngClass]="(currentUrl == '/sla-dashboard' || currentUrl == '/sla-management') ? 'nested-menu-text-selected' : 'nested-menu-text'">SLA Management</div>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="menu-option" *ngIf="userInfo.roleId == 2">
                        <div><iconify-icon icon="fluent-mdl2:account-management" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Guest Service Management</div>
                    </div> -->
                    <div [ngClass]="currentUrl == '/privacy-policy' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2" routerLink="/privacy-policy" routerLinkActive="active">
                        <div><iconify-icon icon="material-symbols:policy-outline" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Privacy Policy</div>
                    </div>
                    <div [ngClass]="currentUrl == '/offers' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3" routerLink="/offers" routerLinkActive="active">
                        <div><iconify-icon icon="tabler:discount-2" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Offer Management</div>
                    </div>
                    <div [ngClass]="currentUrl == '/faq' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3" routerLink="/faq" routerLinkActive="active">
                        <div><iconify-icon icon="ri:question-line" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">FAQs</div>
                    </div>
                    <div [ngClass]="currentUrl == '/rating' ? 'menu-option-selected' : 'menu-option'"
                        *ngIf="userInfo.roleId == 2 || userInfo.roleId == 3" routerLink="/rating" routerLinkActive="active">
                        <div><img src="../../../../assets/images/rate_service.png" class="list-icon"
                            style="margin-top: 5px;"></div>
                        <div class="menu-text">Ratings Management</div>
                    </div>
                    <div class="menu" *ngIf="userInfo.roleId == 4">
                        <div><iconify-icon icon="mdi-light:message-text" class="list-icon"></iconify-icon></div>
                        <div class="menu-text">Chatbot</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-panel">
            <app-toolbar (outfilter)="changeComponent($event)"></app-toolbar>
            <div class="content-container">
                <mat-card class="dynamic-component"
                    *ngIf="currentUrl != '/rating' && currentUrl != '/guest-request' && currentUrl != '/internal-request' && currentUrl != '/sla-dashboard' && currentUrl != '/sla-management' && currentUrl != '/rate-plan' && currentUrl != '/bulk-inventory-update' && currentUrl != '/bulk-rate-update' && currentUrl != '/reservation' && currentUrl != '/edit-reservation' && currentUrl != '/all-reservations' && currentUrl != '/arrivals' && currentUrl != '/departures' && currentUrl != '/payment-details' && currentUrl != '/room-inventory'">
                    <router-outlet></router-outlet>
                </mat-card>
                <mat-card class="dynamic-component"
                    *ngIf="mobile && userInfo.roleId == 4 && (currentUrl == '/guest-request' || currentUrl == '/internal-request')">
                    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex">
                        <mat-tab label="Guest Requests"></mat-tab>
                        <mat-tab label="Internal Requests"></mat-tab>
                    </mat-tab-group>
                    <router-outlet></router-outlet>
                </mat-card>
                <div class="rating-component"
                    *ngIf="currentUrl == '/rating' || ((currentUrl == '/guest-request' || currentUrl == '/internal-request') && !(mobile && userInfo.roleId == 4)) || currentUrl == '/sla-dashboard' || currentUrl == '/sla-management' || currentUrl == '/rate-plan' || currentUrl == '/bulk-inventory-update' || currentUrl == '/bulk-rate-update' || currentUrl == '/reservation' || currentUrl == '/edit-reservation' || currentUrl == '/all-reservations' || currentUrl == '/arrivals' || currentUrl == '/departures' || currentUrl == '/payment-details' || currentUrl == '/room-inventory'">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>