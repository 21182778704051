<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div>
            <div fxLayout="row" fxLayoutGap="25px">
                <div fxFlex="65%" fxLayout="column" fxLayoutGap="15px">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                        <div class="room-head">
                            {{updatePlan ? 'Edit Rate Plan' : 'Add Rate Plan'}}
                        </div>
                    </div>
                    <mat-tab-group #tabGroup class="rating-tabs" [(selectedIndex)]="selectedTab">
                        <mat-tab label="Rate Plan Details" [disabled]="ratePlanStep2.invalid || requiredFacilities.length == 0">
                            <form [formGroup]="ratePlanStep1" fxLayout="column" fxLayoutGap="3%" class="table-card">
                                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Please select the room category
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill"
                                                    formControlName="roomCategory" (selectionChange)="selectRoomCategory($event.value)" placeholder="Select">
                                                    <mat-option *ngFor="let category of roomCategories" [value]="category.ID">
                                                        {{category.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                What room are you adding this rate plan to?
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill"
                                                    formControlName="roomType" placeholder="Select" multiple>
                                                    <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                                        {{room.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Market Segment
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill"
                                                    formControlName="marketSegment" placeholder="Select">
                                                    <mat-option *ngFor="let seg of marketSegments" [value]="seg.ID">
                                                        {{seg.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px"
                                            *ngIf="!ratePlanStep1.controls['marketSegment'].errors?.required">
                                            <div class="box-name"
                                                *ngIf="ratePlanStep1.controls['marketSegment'].value == 1">
                                                Standalone Name
                                            </div>
                                            <div class="box-name"
                                                *ngIf="ratePlanStep1.controls['marketSegment'].value == 2">
                                                Corporate Name
                                            </div>
                                            <div class="box-name"
                                                *ngIf="ratePlanStep1.controls['marketSegment'].value == 3">
                                                Wedding Name
                                            </div>
                                            <div class="box-name"
                                                *ngIf="ratePlanStep1.controls['marketSegment'].value == 4">
                                                Add Type
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="segmentName"
                                                    placeholder="Enter Name" maxlength="200">
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Rate Plan Name
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="ratePlanName"
                                                    placeholder="Enter Rate Plan" maxlength="200">
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Rate Plan Code
                                            </div>
                                            <div>
                                                <input type="text" class="text-box" formControlName="ratePlanCode"
                                                    placeholder="Enter Rate Plan Code" maxlength="200">
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="99%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Description
                                            </div>
                                            <div>
                                                <textarea class="text-area" formControlName="rateDescription" placeholder="Enter Description" maxlength="500"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                            <div fxLayout="column" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Do you want to add length of the stay pricing?
                                                </div>
                                                <div>
                                                    <mat-radio-group (click)="$event.stopPropagation()" formControlName="specifyStayLength">
                                                        <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID"
                                                            (change)="toggle($event, 'specifyStayLength')">
                                                            <span class="radio-label">{{res.NAME}}</span>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px" *ngIf="stayLength">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Minimum Length of Stay
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill"
                                                            formControlName="minimumDays" placeholder="Select">
                                                            <mat-option *ngFor="let num of noOfDays" [value]="num.ID">
                                                                {{num.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Maximum Length of Stay
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill"
                                                            formControlName="maximumDays" placeholder="Select">
                                                            <mat-option *ngFor="let num of noOfDays" [value]="num.ID">
                                                                {{num.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                                    <button mat-button class="save-btn" (click)="nextStep()"
                                        [disabled]="ratePlanStep1.invalid">Next</button>
                                    <button mat-button class="cancel-btn" (click)="cancelRatePlan()">Cancel</button>
                                </div>
                            </form>
                        </mat-tab>
                        <mat-tab label="More Details" [disabled]="ratePlanStep1.invalid">
                            <form [formGroup]="ratePlanStep2" fxLayout="column" fxLayoutGap="3%" class="table-card">
                                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
                                    <div fxLayout="row">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="10px">
                                            <div class="box-name-major">
                                                What Included in the Room?
                                            </div>
                                            <div fxLayout="column">
                                                <mat-checkbox *ngFor="let facility of roomFacilities;" [checked]="facility.ENABLED"
                                                    (change)="selectRoomFacilities($event, facility.ID)">
                                                    <span class="box-name">
                                                        {{facility.NAME}}
                                                    </span>
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="35px">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                            <div class="box-name">
                                                Additional Items included in the rate
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill"
                                                    formControlName="hotelFacility" placeholder="Select" multiple>
                                                    <mat-option *ngFor="let facility of hotelFacilities" [value]="facility.ID">
                                                        {{facility.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row">
                                        <div fxLayout="column" fxFlex="100%" fxLayoutGap="20px">
                                            <div fxLayout="column" fxLayoutGap="7px">
                                                <div class="box-name">
                                                    Is this Derived Rate Plan?
                                                </div>
                                                <div>
                                                    <mat-radio-group (click)="$event.stopPropagation()" formControlName="derivedPlan">
                                                        <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'derivedPlan')">
                                                            <span class="radio-label">{{res.NAME}}</span>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                            <div fxLayout="column" fxLayoutGap="7px" *ngIf="pricingExist">
                                                <div class="box-name">
                                                    Pricing
                                                </div>
                                                <div>
                                                    <mat-radio-group (click)="$event.stopPropagation()" formControlName="planPricing">
                                                        <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" disabled>
                                                            <span class="radio-label">{{res.NAME}}</span>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="pricingExist">
                                                <div formArrayName="roomTypeArray">
                                                    <div fxLayout="column" fxLayoutGap="15px" *ngFor="let type of ratePlanStep2.controls.roomTypeArray.controls; let ind = index;"
                                                        formGroupName="{{ind}}">
                                                        <div class="box-name-major">Pricing for {{type.value.pricingName}}</div>
                                                        <mat-accordion>
                                                            <mat-expansion-panel class="pricing-tabs" [expanded]="true">
                                                                <mat-expansion-panel-header class="panel-header">
                                                                    <mat-panel-title class="price-heading">
                                                                        Add Pricing
                                                                    </mat-panel-title>
                                                                </mat-expansion-panel-header>
                                                                <div fxLayout="column" fxLayoutGap="20px" fxFlex="100%">
                                                                    <mat-divider></mat-divider>
                                                                    <div formArrayName="pricingArray">
                                                                        <div fxLayout="column" fxLayoutGap="30px"
                                                                            *ngFor="let price of type.controls.pricingArray.controls; let i = index;"
                                                                            formGroupName="{{i}}" class="array-box">
                                                                            <div fxLayout="column" fxLayoutGap="15px">
                                                                                <div fxLayout="row" fxFlex="100%" fxLayoutGap="30px">
                                                                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                                                                        <div class="box-name">
                                                                                            Date Range Active From
                                                                                        </div>
                                                                                        <div class="text-box-date">
                                                                                            <input matInput [matDatepicker]="picker1"
                                                                                                (dateChange)="selectDate(ind, i)" formControlName="activeFrom"
                                                                                                class="date-box" placeholder="Select" [min]="currentDate" readonly>
                                                                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                                                            <mat-datepicker #picker1></mat-datepicker>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                                                                        <div class="box-name">
                                                                                            Date Range Active To
                                                                                        </div>
                                                                                        <div class="text-box-date">
                                                                                            <input matInput [matDatepicker]="picker2" formControlName="activeTo"
                                                                                                class="date-box" placeholder="Select" [min]="minToDate" readonly>
                                                                                            <mat-datepicker-toggle matSuffix [for]="picker2" (click)="resetPriceDate(ind, i)"></mat-datepicker-toggle>
                                                                                            <mat-datepicker #picker2></mat-datepicker>
                                                                                        </div>
                                                                                    </div>
                                                                                    <button mat-icon-button class="remove-btn" (click)="removePricing(ind, i)"
                                                                                        [disabled]="type.controls.pricingArray.controls.length == 1">
                                                                                        <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                                                                                    </button>
                                                                                </div>
                                                                                <div formArrayName="occupantArray">
                                                                                    <div fxLayout="column" fxLayoutGap="30px"
                                                                                        *ngFor="let occupant of price.controls.occupantArray.controls; let j = index;"
                                                                                        formGroupName="{{j}}">
                                                                                        <div fxLayout="column" fxLayoutGap="15px" style="padding-bottom: 20px;">
                                                                                            <div fxLayout="row" fxFlex="100%" fxLayoutGap="50px">
                                                                                                <div fxLayout="column" fxFlex="25%" fxLayoutGap="7px">
                                                                                                    <div class="box-name">
                                                                                                        Occupant
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input type="number" class="text-box"
                                                                                                            formControlName="occupantNo" readonly>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div fxLayout="column" fxFlex="40%" fxLayoutGap="7px">
                                                                                                    <div class="box-name">
                                                                                                        Amount
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <input type="number" class="text-box"
                                                                                                            formControlName="occupantAmount" placeholder="Enter">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <button mat-button class="add-occup-btn"
                                                                                [disabled]="type.controls.pricingArray.controls.length == 10" (click)="addPricing(ind, 'manual', type)">
                                                                                + Add Date Range
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mat-expansion-panel>
                                                        </mat-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="pricingExist">
                                                <div formArrayName="blackOutArray">
                                                    <div fxLayout="column" fxLayoutGap="10px" *ngFor="let guestControl of getBlackOut(); let k = index;"
                                                        formGroupName="{{k}}" class="array-box">
                                                        <div fxLayout="row" fxLayoutGap="35px">
                                                            <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                                <div class="box-name">
                                                                    Blackout Name
                                                                </div>
                                                                <div>
                                                                    <input type="text" class="text-box" formControlName="blackOutName" placeholder="Enter Blackout Name">
                                                                </div>
                                                            </div>
                                                            <div fxFlex="49%" fxLayoutAlign="end ">
                                                                <button mat-icon-button class="remove-btn" (click)="removeBlackOut(k)"
                                                                    [disabled]="ratePlanStep2.controls.blackOutArray.controls.length == 1">
                                                                    <iconify-icon icon="material-symbols:delete-outline-rounded"></iconify-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="30px">
                                                            <div fxLayout="column" fxFlex="48%" fxLayoutGap="7px">
                                                                <div class="box-name">
                                                                    Blackout Date From
                                                                </div>
                                                                <div class="text-box-date">
                                                                    <input matInput [matDatepicker]="picker3" (dateChange)="selectBlackoutDate(k)" formControlName="blackOutFrom"
                                                                        class="date-box" placeholder="Select" [min]="currentDate" readonly>
                                                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker3></mat-datepicker>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="column" fxFlex="47%" fxLayoutGap="7px">
                                                                <div class="box-name">
                                                                    Blackout Date To
                                                                </div>
                                                                <div class="text-box-date">
                                                                    <input matInput [matDatepicker]="picker4" formControlName="blackOutTo"
                                                                        class="date-box" placeholder="Select" [min]="minBlackoutToDate" readonly>
                                                                    <mat-datepicker-toggle matSuffix [for]="picker4" (click)="resetBlackoutDate(k)"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker4></mat-datepicker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button mat-button class="add-occup-btn"
                                                            [disabled]="getBlackOut().length == 20" (click)="addBlackOut()">
                                                            + Add More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="pricingNotExist">
                                                <div fxLayout="row" fxLayoutGap="35px">
                                                    <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Room Type
                                                        </div>
                                                        <div>
                                                            <mat-select class="text-box" appearance="fill" formControlName="derivedRoomType" placeholder="Select" multiple>
                                                                <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                                                                    {{room.NAME}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Rate Plan
                                                        </div>
                                                        <div>
                                                            <mat-select class="text-box" appearance="fill" formControlName="derivedRatePlan" placeholder="Select">
                                                                <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                                                                    {{room.NAME}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutGap="35px">
                                                    <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Discount
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="35px">
                                                            <div fxFlex="46%">
                                                                <div>
                                                                    <mat-select class="text-box" appearance="fill" formControlName="derivedRoomDiscount" placeholder="Select">
                                                                        <mat-option>%</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                            <div fxFlex="46%">
                                                                <div>
                                                                    <input type="number" class="text-box" formControlName="derivedRoomDiscountValue" placeholder="Enter">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                                    <div fxLayout="column" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Do guests need to pay a deposit before check-In?
                                                        </div>
                                                        <div>
                                                            <mat-radio-group (click)="$event.stopPropagation()" formControlName="preDeposite">
                                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'preDeposite')">
                                                                    <span class="radio-label">{{res.NAME}}</span>
                                                                </mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px" *ngIf="payDeposite">
                                                        <div fxLayout="row" fxLayoutGap="35px">
                                                            <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                                <div class="box-name">
                                                                    Amount
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <input type="number" class="text-box" formControlName="preDepositeAmount" placeholder="Enter">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
                                                    <div fxLayout="column" fxLayoutGap="7px">
                                                        <div class="box-name">
                                                            Do guests need to book certain number of days in advance?
                                                        </div>
                                                        <div>
                                                            <mat-radio-group (click)="$event.stopPropagation()" formControlName="preBook">
                                                                <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'preBook')">
                                                                    <span class="radio-label">{{res.NAME}}</span>
                                                                </mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="row" fxLayoutGap="35px" *ngIf="ifPreBook">
                                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                            <div class="box-name">
                                                                Minimum Days
                                                            </div>
                                                            <div>
                                                                <mat-select class="text-box" appearance="fill"
                                                                    formControlName="minPreBookDays" placeholder="Select">
                                                                    <mat-option *ngFor="let num of noOfDays" [value]="num.ID">
                                                                        {{num.NAME}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                            <div class="box-name">
                                                                Maximum Days
                                                            </div>
                                                            <div>
                                                                <mat-select class="text-box" appearance="fill"
                                                                    formControlName="maxPreBookDays" placeholder="Select">
                                                                    <mat-option *ngFor="let num of noOfDays" [value]="num.ID">
                                                                        {{num.NAME}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="35px">
                                                <div fxLayout="column" fxFlex="46%" fxLayoutGap="7px">
                                                    <div class="box-name">
                                                        Add Tax
                                                    </div>
                                                    <div>
                                                        <mat-select class="text-box" appearance="fill" formControlName="tax" placeholder="Select">
                                                            <mat-option *ngFor="let tax of serviceTaxes" [value]="tax.ID">
                                                                {{tax.NAME}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                                    <button mat-button class="save-btn" (click)="nextStep()">Next</button>
                                    <!-- [disabled]="ratePlanStep2.invalid || requiredFacilities.length == 0" -->
                                    <button mat-button class="cancel-btn" (click)="cancelRatePlan()">Cancel</button>
                                </div>
                            </form>
                        </mat-tab>
                        <mat-tab label="Cancellation" [disabled]="ratePlanStep1.invalid || ratePlanStep2.invalid || requiredFacilities.length == 0">
                            <form [formGroup]="ratePlanStep3" fxLayout="column" fxLayoutGap="3%" class="table-card">
                                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
                                    <div fxLayout="row">
                                        <div fxLayout="column" fxFlex="46%" fxLayoutGap="10px">
                                            <div class="box-name">
                                                Cancellation
                                            </div>
                                            <div>
                                                <mat-select class="text-box" appearance="fill" formControlName="cancellation" placeholder="Select">
                                                    <mat-option *ngFor="let policy of cancellationPolicies" [value]="policy.ID">
                                                        {{policy.NAME}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                                    <button mat-button class="save-btn" *ngIf="!updatePlan" (click)="saveRatePlan()"
                                        [disabled]="ratePlanStep3.invalid">Save</button>
                                    <button mat-button class="save-btn" *ngIf="updatePlan" (click)="updateRatePlan()"
                                        [disabled]="ratePlanStep3.invalid">Update</button>
                                    <button mat-button class="cancel-btn" (click)="cancelRatePlan()">Cancel</button>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div fxFlex="35%">
                    <mat-card class="info-card" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                        <img src="../../../../../assets/images/info-image.png">
                        <div class="info-head">Need your Room Information</div>
                        <div class="info-desc">Add a new room type, room, or individual unit. To set up a complex room
                            type then this form allows. Select what type of room, so that hoteliers can create right
                            room for guests.</div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>