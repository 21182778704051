import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { AboutBannersComponent } from './about-banners/about-banners.component';
import { AboutCategoriesComponent } from './about-categories/about-categories.component';
import { AboutTypesComponent } from './about-types/about-types.component';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  displayedColumns: string[] = ['sr.no', 'image', 'name', 'tags', 'description', 'action'];
  dataSource = new MatTableDataSource<any>();
  currentActive = 0;
  currentCategoryID: Number;
  aboutBanners = [];
  aboutCategories = [];
  aboutTypes = [];
  deleteBannerBody = {};
  deleteCategoryBody = {};
  public bucketUrl = `${environment.BUCKET_URL}`;
  loading: boolean = true;
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('bannerContent', {}) public bannerContent;
  @ViewChild('categoryContent', {}) public categoryContent;

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    public hotelService: HotelServicesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getBanners();
    this.getCategories();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getBanners() {
    this.hotelService.getAboutBanner().subscribe({
      next: (data) => {
        this.loading = false;
        this.aboutBanners = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addBanners() {
    const dialogRef = this.dialog.open(AboutBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { name: "add_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  updateBanner(id) {
    const dialogRef = this.dialog.open(AboutBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { ID: id, name: "update_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  deleteBanner(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this banner ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteBannerBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updateAboutBanner(this.deleteBannerBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Document deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getBanners();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getCategories() {
    this.hotelService.getAboutCategory().subscribe({
      next: (data) => {
        this.aboutCategories = data.object;
        for (let i = 0; i < this.aboutCategories.length; i++) {
          this.currentActive = 0;
          this.currentCategoryID = this.aboutCategories[0].ID;
        }
        this.loading = false;
        if (this.aboutCategories.length != 0) {
          this.getTypes(this.currentCategoryID);
        }
        else {
          this.dataSource.data = [];
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addCategories() {
    const dialogRef = this.dialog.open(AboutCategoriesComponent, {
      width: '600px',
      height: 'auto',
      data: { name: "add_category" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  updateCategory(id) {
    const dialogRef = this.dialog.open(AboutCategoriesComponent, {
      width: '600px',
      height: 'auto',
      data: { ID: id, name: "update_category" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  deleteCategory(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this category ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteCategoryBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updateAboutCategory(this.deleteCategoryBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Document deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getCategories();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getTypes(id) {
    this.hotelService.getAboutType(id).subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource.data = data.object;
        this.aboutTypes = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addTypes() {
    const dialogRef = this.dialog.open(AboutTypesComponent, {
      width: '1200px',
      height: 'auto',
      data: { categoryID: this.currentCategoryID, name: "add_type" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getTypes(this.currentCategoryID);
      }
    });
  }

  editTypes(id) {
    const dialogRef = this.dialog.open(AboutTypesComponent, {
      width: '1200px',
      height: 'auto',
      data: { typeID: id, name: "edit_type" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getTypes(this.currentCategoryID);
      }
    });
  }

  deleteTypes(id, category_id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this type ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        let deleteTypeBody = {
          "ID": id,
          "CATEGORY_ID": category_id,
          "DELETED": true
        }
        this.hotelService.updateAboutType(deleteTypeBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'About type deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getTypes(category_id);
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  displayDetails(index, name, id) {
    this.currentActive = index;
    this.currentCategoryID = id;
    this.getTypes(this.currentCategoryID);
  }

  public bannerScrollRight(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public bannerScrollLeft(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  public categoryScrollRight(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public categoryScrollLeft(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}