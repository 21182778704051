import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { AddRatingComponent } from './add-rating/add-rating.component';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { FormControl } from '@angular/forms';
import { RatingFilterComponent } from './rating-filter/rating-filter.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rating-service',
  templateUrl: './rating-service.component.html',
  styleUrls: ['./rating-service.component.scss']
})
export class RatingServiceComponent {

  loading: boolean = true;
  ratingStats: any = [];
  overAllrating: any = 0;
  totalRatings: number = 0;
  duringStayReview: any = [];
  postStayReview: any = [];
  transportReview: any = [];
  conferenceReview: any = [];
  packageReview: any = [];
  diningReview: any = [];
  spaReview: any = [];
  housekeepingReview: any = [];
  duringPageNumber = 0;
  postPageNumber = 0;
  transportPageNumber = 0;
  conferencePageNumber = 0;
  packagePageNumber = 0;
  diningPageNumber = 0;
  spaPageNumber = 0;
  housePageNumber = 0;
  limit = 10;
  ratingDetails = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  isRatingMngmt: boolean = true;
  selectedTab;
  ratingQueFilter = new FormControl('');
  reviewSearch = new FormControl('');
  ratingSearch = new FormControl('');
  duringLastData: number;
  postLastData: number;
  transportLastData: number;
  conferenceLastData: number;
  packageLastData: number;
  diningLastData: number;
  spaLastData: number;
  houseLastData: number;
  ratingQuestions = [];
  activeQuestions = [];
  inactiveQuestions = [];
  zeroReviews: boolean = false;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    public adminService: AdminService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.selectedTab = 0;
    // this.getStayData('during');
    this.getStayData('post');
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onTabChanged(event) {
    this.selectedTab = event.index;
    // if (this.selectedTab == 0) {
    //   this.getStayData('during');
    // }
    if (this.selectedTab == 0) {  //this.selectedTab == 1, else
      this.getStayData('post');
    }
    else if (this.selectedTab == 1) {
      this.getServiceData(3);
    }
    else if (this.selectedTab == 2) {
      this.getServiceData(4);
    }
    else if (this.selectedTab == 3) {
      this.getServiceData(5);
    }
    else if (this.selectedTab == 4) {
      this.getServiceData(6);
    }
    else if (this.selectedTab == 5) {
      this.getServiceData(1);
    }
    else if (this.selectedTab == 6) {
      this.getServiceData(2);
    }
  }

  listRating() {
    this.isRatingMngmt = false;
    this.getRatingQuestions();
  }

  backToRatingDetails() {
    this.isRatingMngmt = true;
    this.selectedTab = 0;
    // this.getStayData('during');
    this.getStayData('post');
  }

  getStayData(type) {
    this.loading = true;
    this.zeroReviews = false;
    this.ratingDetails = [];
    // if (this.selectedTab == 0) {
    //   this.duringPageNumber = 0;
    //   this.adminService.getRatingDataByStay(type, this.duringPageNumber, this.limit).subscribe({
    //     next: (data) => {
    //       this.duringStayReview = data.object;
    //       this.loading = false;
    //       if (data.object.length == 0) {
    //         this.zeroReviews = true;
    //       }
    //       else {
    //         this.ratingDetails.push(this.duringStayReview[0]);
    //       }
    //       this.duringLastData = data.object.length;
    //     },
    //     error: (e) => {
    //       this.loading = false;
    //       this.notify.showNotification(
    //         e.error.message,
    //         "top",
    //         (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
    //         e.error.status
    //       )
    //     }
    //   })
    // }
    if (this.selectedTab == 0) {  //this.selectedTab == 1, else
      this.postPageNumber = 0;
      this.adminService.getRatingDataByStay(type, this.postPageNumber, this.limit).subscribe({
        next: (data) => {
          this.postStayReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.postStayReview[0]);
          }
          this.postLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    this.getStayRating(type);
  }

  getStayRating(type) {
    this.loading = true;
    this.ratingStats = [];
    this.overAllrating = 0;
    this.totalRatings = 0;
    this.adminService.getRatingStatsByStay(type).subscribe({
      next: (data) => {
        if (data.object.length != 0) {
          this.totalRatings = data.object.length;
          let sumRating = 0;
          let numRating = 0;
          let ONE = 0;
          let TWO = 0;
          let THREE = 0;
          let FOUR = 0;
          let FIVE = 0;
          for (let obj of data.object) {
            numRating += obj.RATING.length;
            for (let rate of obj.RATING) {
              sumRating += rate.RATE;
              if (rate.RATE == 1) {
                ONE++;
              }
              else if (rate.RATE == 2) {
                TWO++;
              }
              else if (rate.RATE == 3) {
                THREE++;
              }
              else if (rate.RATE == 4) {
                FOUR++;
              }
              else if (rate.RATE == 5) {
                FIVE++;
              }
            }
          }
          this.overAllrating = (sumRating / numRating).toFixed(1);
          this.ratingStats = [
            {
              rate: 1,
              number: ONE,
              width: (ONE * 100) / numRating
            },
            {
              rate: 2,
              number: TWO,
              width: (TWO * 100) / numRating
            },
            {
              rate: 3,
              number: THREE,
              width: (THREE * 100) / numRating
            },
            {
              rate: 4,
              number: FOUR,
              width: (FOUR * 100) / numRating
            },
            {
              rate: 5,
              number: FIVE,
              width: (FIVE * 100) / numRating
            }
          ].reverse();
        }
        else {
          this.ratingStats = [
            {
              rate: 1,
              number: 0,
              width: 0
            },
            {
              rate: 2,
              number: 0,
              width: 0
            },
            {
              rate: 3,
              number: 0,
              width: 0
            },
            {
              rate: 4,
              number: 0,
              width: 0
            },
            {
              rate: 5,
              number: 0,
              width: 0
            }
          ]
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getServiceData(id) {
    this.loading = true;
    this.zeroReviews = false;
    this.ratingDetails = [];
    if (this.selectedTab == 1) {  //this.selectedTab == 2
      this.transportPageNumber = 0;
      this.adminService.getRatingDataByService(id, this.transportPageNumber, this.limit).subscribe({
        next: (data) => {
          this.transportReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.transportReview[0]);
          }
          this.transportLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 2) {
      this.conferencePageNumber = 0;
      this.adminService.getRatingDataByService(id, this.conferencePageNumber, this.limit).subscribe({
        next: (data) => {
          this.conferenceReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.conferenceReview[0]);
          }
          this.conferenceLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 3) {
      this.packagePageNumber = 0;
      this.adminService.getRatingDataByService(id, this.packagePageNumber, this.limit).subscribe({
        next: (data) => {
          this.packageReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.packageReview[0]);
          }
          this.packageLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 4) {
      this.diningPageNumber = 0;
      this.adminService.getRatingDataByService(id, this.diningPageNumber, this.limit).subscribe({
        next: (data) => {
          this.diningReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.diningReview[0]);
          }
          this.diningLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 5) {
      this.spaPageNumber = 0;
      this.adminService.getRatingDataByService(id, this.spaPageNumber, this.limit).subscribe({
        next: (data) => {
          this.spaReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.spaReview[0]);
          }
          this.spaLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 6) {
      this.housePageNumber = 0;
      this.adminService.getRatingDataByService(id, this.housePageNumber, this.limit).subscribe({
        next: (data) => {
          this.housekeepingReview = data.object;
          this.loading = false;
          if (data.object.length == 0) {
            this.zeroReviews = true;
          }
          else {
            this.ratingDetails.push(this.housekeepingReview[0]);
          }
          this.houseLastData = data.object.length;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    this.getServiceRating(id);
  }

  getServiceRating(id) {
    this.loading = true;
    this.ratingStats = [];
    this.overAllrating = 0;
    this.totalRatings = 0;
    this.adminService.getRatingStatsByService(id).subscribe({
      next: (data) => {
        if (data.object.length != 0) {
          let tempStats = {
            1: data.object.ONE,
            2: data.object.TWO,
            3: data.object.THREE,
            4: data.object.FOUR,
            5: data.object.FIVE
          }
          const overAllrating: any = (Object.values(tempStats).reduce((overAllrating: any, value: any) => overAllrating + value, 0));
          this.totalRatings = overAllrating;
          this.ratingStats = [
            {
              rate: 1,
              number: data.object.ONE,
              width: (data.object.ONE * 100) / this.totalRatings
            },
            {
              rate: 2,
              number: data.object.TWO,
              width: (data.object.TWO * 100) / this.totalRatings
            },
            {
              rate: 3,
              number: data.object.THREE,
              width: (data.object.THREE * 100) / this.totalRatings
            },
            {
              rate: 4,
              number: data.object.FOUR,
              width: (data.object.FOUR * 100) / this.totalRatings
            },
            {
              rate: 5,
              number: data.object.FIVE,
              width: (data.object.FIVE * 100) / this.totalRatings
            }
          ].reverse();
          this.overAllrating = (((1 * data.object.ONE) + (2 * data.object.TWO) + (3 * data.object.THREE) + (4 * data.object.FOUR) + (5 * data.object.FIVE)) / this.totalRatings).toFixed(1);
        }
        else {
          this.ratingStats = [
            {
              rate: 1,
              number: 0,
              width: 0
            },
            {
              rate: 2,
              number: 0,
              width: 0
            },
            {
              rate: 3,
              number: 0,
              width: 0
            },
            {
              rate: 4,
              number: 0,
              width: 0
            },
            {
              rate: 5,
              number: 0,
              width: 0
            }
          ]
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  onScrollDown() {
    this.getNextPage();
  }

  getNextPage() {
    this.zeroReviews = false;
    // if (this.selectedTab == 0) {
    //   if (this.duringLastData == 10) {
    //     this.duringPageNumber++;
    //     this.adminService.getRatingDataByStay('during', this.duringPageNumber, this.limit).subscribe({
    //       next: (data) => {
    //         this.duringLastData = data.object.length;
    //         this.duringStayReview = this.duringStayReview.concat(data.object);
    //         this.cd.detectChanges();
    //       },
    //       error: (e) => {
    //         this.notify.showNotification(
    //           e.error.message,
    //           "top",
    //           (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
    //           e.error.status
    //         )
    //       }
    //     })
    //   }
    // }
    if (this.selectedTab == 0) {  //this.selectedTab == 1, else
      if (this.postLastData == 10) {
        this.postPageNumber++;
        this.adminService.getRatingDataByStay('post', this.postPageNumber, this.limit).subscribe({
          next: (data) => {
            this.postLastData = data.object.length;
            this.postStayReview = this.postStayReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 1) {
      if (this.transportLastData == 10) {
        this.transportPageNumber++;
        this.adminService.getRatingDataByService(3, this.transportPageNumber, this.limit).subscribe({
          next: (data) => {
            this.transportLastData = data.object.length;
            this.transportReview = this.transportReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 2) {
      if (this.conferenceLastData == 10) {
        this.conferencePageNumber++;
        this.adminService.getRatingDataByService(4, this.conferencePageNumber, this.limit).subscribe({
          next: (data) => {
            this.conferenceLastData = data.object.length;
            this.conferenceReview = this.conferenceReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 3) {
      if (this.packageLastData == 10) {
        this.packagePageNumber++;
        this.adminService.getRatingDataByService(5, this.packagePageNumber, this.limit).subscribe({
          next: (data) => {
            this.packageLastData = data.object.length;
            this.packageReview = this.packageReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 4) {
      if (this.diningLastData == 10) {
        this.diningPageNumber++;
        this.adminService.getRatingDataByService(6, this.diningPageNumber, this.limit).subscribe({
          next: (data) => {
            this.diningLastData = data.object.length;
            this.diningReview = this.diningReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 5) {
      if (this.spaLastData == 10) {
        this.spaPageNumber++;
        this.adminService.getRatingDataByService(1, this.spaPageNumber, this.limit).subscribe({
          next: (data) => {
            this.spaLastData = data.object.length;
            this.spaReview = this.spaReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
    else if (this.selectedTab == 6) {
      if (this.houseLastData == 10) {
        this.housePageNumber++;
        this.adminService.getRatingDataByService(2, this.housePageNumber, this.limit).subscribe({
          next: (data) => {
            this.houseLastData = data.object.length;
            this.housekeepingReview = this.housekeepingReview.concat(data.object);
            this.cd.detectChanges();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    }
  }

  getRatingDetails(index) {
    this.ratingDetails = [];
    // if (this.selectedTab == 0) {
    //   this.ratingDetails.push(this.duringStayReview[index]);
    // }
    if (this.selectedTab == 0) {  //this.selectedTab == 1, else
      this.ratingDetails.push(this.postStayReview[index]);
    }
    else if (this.selectedTab == 1) {
      this.ratingDetails.push(this.transportReview[index]);
    }
    else if (this.selectedTab == 2) {
      this.ratingDetails.push(this.conferenceReview[index]);
    }
    else if (this.selectedTab == 3) {
      this.ratingDetails.push(this.packageReview[index]);
    }
    else if (this.selectedTab == 4) {
      this.ratingDetails.push(this.diningReview[index]);
    }
    else if (this.selectedTab == 5) {
      this.ratingDetails.push(this.spaReview[index]);
    }
    else if (this.selectedTab == 6) {
      this.ratingDetails.push(this.housekeepingReview[index]);
    }
  }

  openFilterOptions() {
    const dialogRef = this.dialog.open(RatingFilterComponent, {
      width: '330px',
      height: '100vh',
      position: { right: '0', top: '0' },
      // data: { name: "add_rating" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        // this.loading = true;
      }
    })
  }

  getRatingQuestions() {
    this.activeQuestions = [];
    this.inactiveQuestions = [];
    this.adminService.getRating().subscribe({
      next: (data) => {
        this.loading = false;
        this.ratingQuestions = data.object;
        for (let que of this.ratingQuestions) {
          if (que.ENABLED == true) {
            this.activeQuestions.push(que);
          }
          else if (que.ENABLED == false) {
            this.inactiveQuestions.push(que);
          }
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addRatingQuestion() {
    const dialogRef = this.dialog.open(AddRatingComponent, {
      width: '40%',
      height: 'auto',
      data: { name: "add_rating" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.loading = true;
        this.getRatingQuestions();
      }
    })
  }

  editRatingQuestion(id) {
    const dialogRef = this.dialog.open(AddRatingComponent, {
      width: '40%',
      height: 'auto',
      data: { ID: id, name: "update_rating" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.loading = true;
        this.getRatingQuestions();
      }
    })
  }

  enableRatingQuestion(element) {
    this.loading = true;
    let obj = {
      "ID": element.ID,
      "SERVICE_INFO": element.SERVICE_INFO,
      "ENABLED": !element.ENABLED
    }
    this.adminService.updateRating(obj).subscribe({
      next: (data) => {
        this.getRatingQuestions();
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  deleteRatingQuestion(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this rating question ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.adminService.updateRating(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Rating question deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getRatingQuestions();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}