<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Edit Shift':'Add New Shift'}}</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addShiftForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="41px">
                <div>
                    <div class="box-name">
                        Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Enter Shift Name">
                    </div>
                    <mat-error class="error"
                        *ngIf="addShiftForm.controls['name'].touched && addShiftForm.get('name').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div>
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea type="text" class="text-area" formControlName="description"
                            placeholder="Enter Description"></textarea>
                    </div>
                    <mat-error class="error"
                        *ngIf="addShiftForm.controls['description'].touched && addShiftForm.get('description').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div fxLayout="row" fxLayoutGap="24px">
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Shift From
                        </div>
                        <div>
                            <mat-select formControlName="from_time" class="select" placeholder="Choose Shift From"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let slot of slots" [value]="slot.ID">{{slot.TIME}}</mat-option>
                            </mat-select>
                            <mat-error class="error"
                                *ngIf="addShiftForm.controls['from_time'].touched && addShiftForm.get('from_time').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="47%">
                        <div class="box-name">
                            Shift To
                        </div>
                        <div>
                            <mat-select formControlName="to_time" class="select" placeholder="Choose Shift To"
                                style="font-size: 13px;width: 97% !important;" appearance="fill">
                                <mat-option *ngFor="let slot of slots" [value]="slot.ID">{{slot.TIME}}</mat-option>
                            </mat-select>
                            <mat-error class="error"
                                *ngIf="addShiftForm.controls['to_time'].touched && addShiftForm.get('to_time').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button mat-stroked-button class="button" (click)="cancel()">
                    Cancel
                </button>
                <button mat-stroked-button [ngClass]="this.addShiftForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addShiftForm.invalid" (click)="addShift()">
                    Save
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</div>