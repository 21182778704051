import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) { }

  ngOnInit(): void {}

  onSubmit(): void {
    // Close the dialog, return true
    this.dialogRef.close({ 'state': true });
  }

  onClose(): void {
    // Close the dialog, return false
    this.dialogRef.close({ 'state': false });
  }

}