<div class="spa-categories" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="category-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span class="header">{{notCreate ? 'Update about category':'Create new about category'}}</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button mat-dialog-close class="close-btn">
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="aboutCategoryForm">
        <div mat-dialog-content class="content-box" fxLayout="column" fxLayoutGap="22px">
            <div class="spa-name-box" fxLayout="column" fxLayoutGap="9px">
                <div class="box-name">
                    About
                </div>
                <div>
                    <input type="text" class="text-box" formControlName="categoryName" placeholder="Enter name">
                </div>
            </div>
            <div class="upload-img" fxLayout="column" fxLayoutGap="12px">
                <span class="box-name">Upload Image</span>
                <div class="upload-box" fxLayout="column" fxLayoutAlign="center center" *ngIf="uploadedCategories.length == 0 || data.name == 'update_category'"
                    appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                    <div>
                        <input type="file" class="doc_file" id="doc_file" style="display: none"
                            #fileInput hidden accept=".jpg,.jpeg,.png" (change)="onFileSelected($event.target.files)" />
                        <mat-icon (click)="fileInput.click()" style="cursor:pointer">upload</mat-icon>
                    </div>
                    <div fxFlex="45%" class="upload-text" (click)="fileInput.click()">
                        <span class="upload-text1">Click to upload </span>
                        <span class="upload-text2">or drag and drop jpeg,jpg,png</span>
                    </div>
                </div>
                <div *ngIf="uploadedCategories.length != 0" fxLayout="row" fxLayoutGap="16px">
                    <img src="{{bucketUrl + img.SOURCE}}" *ngFor="let img of uploadedCategories; let i = index" class="img_preview" (click)="preview(img.SOURCE)">
                    <span id="delete" *ngIf="data.name == 'add_category'" (click)="deleteCategory()" style="cursor: pointer;"><span class="material-icons-outlined" style="color:#9F9393"> delete_outline</span></span>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button
                    [ngClass]="this.aboutCategoryForm.invalid || source_url == null || source_url == '' ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.aboutCategoryForm.invalid || source_url == null || source_url == ''" (click)="saveCategories()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>