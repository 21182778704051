import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { colorObj } from 'src/app/shared/color-object';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulk-inventory',
  templateUrl: './bulk-inventory.component.html',
  styleUrls: ['./bulk-inventory.component.scss']
})
export class BulkInventoryComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  bulkInventoryForm: FormGroup;
  currentDate = new Date();
  minToDate: Date;
  roomCategories = [];
  roomTypesList = [];
  selectedRoomTypes = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  responses = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.bulkInventoryForm = this.fb.group({
      bulkUpdateFrom: ['', Validators.required],
      bulkUpdateTo: ['', Validators.required],
      roomCategory: ['', Validators.required],
      roomType: [[], Validators.required],
      inventoryUpdateArray: fb.array([]),
      overBookingLimit: ['', Validators.required],
      overBookingsArray: fb.array([])
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getCategories();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.responses = res['RESPONSE'];  //update from master data
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  selectDate() {
    this.minToDate = new Date(this.bulkInventoryForm.value.bulkUpdateFrom);
    this.bulkInventoryForm.controls['bulkUpdateTo'].reset();
  }

  getCategories() {
    this.hotelService.getRoomCategory().subscribe({
      next: (data) => {
        this.roomCategories = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectRoomCategory(category) {
    this.getRoomTypes(category);
  }

  getRoomTypes(categoryId) {
    this.hotelService.getRoomTypeList(categoryId).then((data) => {
      if (data) {
        this.roomTypesList = data.object;
      }
    }).catch((err) => {
      this.notify.showNotification(
        err.error.message,
        "top",
        (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
        err.error.status
      )
    });
  }

  selectRoomTypes() {
    this.selectedRoomTypes = [];
    (this.bulkInventoryForm.controls['inventoryUpdateArray'] as FormArray).clear();
    this.bulkInventoryForm.controls['roomType'].value.forEach(element => {
      this.roomTypesList.forEach(type => {
        if (element == type.ID) {
          this.selectedRoomTypes.push(type);
        }
      })
    });
    this.selectedRoomTypes.forEach(type => {
      this.addInventoryUpdate(type);
    });
    if (!!this.bulkInventoryForm.controls['overBookingLimit'].value && this.bulkInventoryForm.controls['overBookingLimit'].value == 1) {
      this.bulkInventoryForm.controls["overBookingLimit"].setValue(2);
      (this.bulkInventoryForm.controls['overBookingsArray'] as FormArray).clear();
    }
  }

  addInventoryUpdate(type?: any) {
    let index = (<FormArray>this.bulkInventoryForm.get('inventoryUpdateArray')).length;
    let fg = this.fb.group({
      roomTypeId: type.ID,
      roomTypeName: type.NAME,
      availableRooms: type.ROOMS.length,
      updateInventory: this.fb.control('', Validators.required),
      inventoryStatus: this.fb.control('', Validators.required)
    });
    (<FormArray>this.bulkInventoryForm.get('inventoryUpdateArray')).push(fg);
  }

  toggle(event, fieldName) {
    if (fieldName == 'overBookingLimit') {
      if (event.value == 1) {
        this.selectedRoomTypes = [];
        this.bulkInventoryForm.controls['roomType'].value.forEach(element => {
          this.roomTypesList.forEach(type => {
            if (element == type.ID) {
              this.selectedRoomTypes.push(type);
            }
          })
        });
        this.selectedRoomTypes.forEach(type => {
          this.addOverBookingLimit(type);
        });
      }
      else if (event.value == 2) {
        this.selectedRoomTypes = [];
        (this.bulkInventoryForm.controls['overBookingsArray'] as FormArray).clear();
      }
    }
  }

  addOverBookingLimit(type?: any) {
    let index = (<FormArray>this.bulkInventoryForm.get('overBookingsArray')).length;
    let fg = this.fb.group({
      roomTypeId: type.ID,
      roomTypeName: type.NAME,
      overBookingCapacity: this.fb.control('', Validators.required)
    });
    (<FormArray>this.bulkInventoryForm.get('overBookingsArray')).push(fg);
  }

  updateBulkInventory() {
    this.loading = true;
    const formattedDateFrom = this.datePipe.transform(this.bulkInventoryForm.value.bulkUpdateFrom, 'dd-MM-yyyy');
    const formattedDateTo = this.datePipe.transform(this.bulkInventoryForm.value.bulkUpdateTo, 'dd-MM-yyyy');
    let formData: any;
    formData = this.bulkInventoryForm.value;
    let typeArray = formData['inventoryUpdateArray'];
    let inventoryObj = {};
    for (let type of typeArray) {
      Object.assign(inventoryObj, {
        [type.roomTypeId]: {
          "AVAILABLE": type.inventoryStatus,
          "INVENTORY": type.updateInventory
        }
      });
    }
    let overBookArray = formData['overBookingsArray'];
    let overBookingObj = {};
    for (let book of overBookArray) {
      Object.assign(overBookingObj, {
        [book.roomTypeId]: {
          "LIMIT": book.overBookingCapacity
        }
      });
    }
    let savedTypeFiles = {
      "DATE_FROM": formattedDateFrom,
      "DATE_TO": formattedDateTo,
      "CATEGORY_ID": this.bulkInventoryForm.value.roomCategory,
      "TYPE_ID": this.bulkInventoryForm.value.roomType,
      "INVENTORY_DATA": inventoryObj,
      "OVERBOOKING_ID": this.bulkInventoryForm.value.overBookingLimit,
      "OVERBOOKING": overBookingObj
    }
    this.hotelService.bulkUpdateRoomType(savedTypeFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.cancelUpdate();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.cancelUpdate();
      }
    })
  }

  cancelUpdate() {
    this.bulkInventoryForm.reset();
    this.selectedRoomTypes = [];
    (this.bulkInventoryForm.controls['inventoryUpdateArray'] as FormArray).clear();
    (this.bulkInventoryForm.controls['overBookingsArray'] as FormArray).clear();
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}