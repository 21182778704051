// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_BASE_URL: 'https://admin-service.gofriday.ai',
  BUCKET_URL: 'https://admin-assets.gofriday.ai/',
  APP_URL: 'https://app.gofriday.ai',
  EMAIL_PATTERN: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$',
  PASSWORD_PATTERN: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  SLOT_DIFFERENCE:15,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
