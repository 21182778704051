import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comfirm-logout',
  templateUrl: './comfirm-logout.component.html',
  styleUrls: ['./comfirm-logout.component.scss']
})

export class ComfirmLogoutComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ComfirmLogoutComponent>
  ) { }

  closeDialog(action) {
    window.localStorage.clear();
    this.router.navigate(['auth/login']);
    // this.dialogRef.close(true);
  }

}