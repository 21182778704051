import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { NotificationService } from 'src/app/services/notification.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { Select } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { colorObj } from 'src/app/shared/color-object';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { BookServiceComponent } from './book-service/book-service.component';
import { RequestConfirmedPopupComponent } from './request-confirmed-popup/request-confirmed-popup.component';
import { BookDiningComponent } from './book-dining/book-dining.component';
import { UpdateGuestBookingComponent } from './update-guest-booking/update-guest-booking.component';

@Component({
  selector: 'app-guest-requests',
  templateUrl: './guest-requests.component.html',
  styleUrls: ['./guest-requests.component.scss']
})
export class GuestRequestsComponent {

  loading: boolean;
  userInfo: any;
  userName: any;
  accessType: any;
  currencyInfo: any;
  currency: any;
  searchVal = new FormControl('');
  reqFromDate = new FormControl('');
  service = new FormControl('');
  reqToDate = new FormControl('');
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  status = [];
  escalatedBookings = [];
  liveBookings = [];
  completedBookings = [];
  staffBookingStatus = new FormControl('');
  displayedColumns: string[] = ['date', 'room', 'category', 'priority', 'assignee', 'status', 'button'];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  toBookingDate: string = '';
  fromBookingDate: string = '';
  services: any;
  permittedServices: any;
  allowedServices: any = [];
  selectedAssignee = [];
  assigneeInfo = new FormControl('');
  reservationId: any;
  isLoading: boolean = false;
  bookingsInfo = [];
  selectedTab;
  isNewRequest: boolean = false;
  addRequestForm: FormGroup;
  bookingIdList = [];
  filterBookingIds = [];
  roomsList = [];
  serviceCategories = [];
  housekeepingServices = [];
  currentActive = 0;
  currentCategoryID: Number;
  selectedService;
  serviceItems = [];
  serviceInstruction = new FormControl('');
  booking: FormControl = new FormControl();
  reservationID;
  reservation;
  private _onDestroy = new Subject<void>();
  selectedHouseKeepingServices = [];
  requiredServices: any = [];
  orderInfo = {};
  canOrder: boolean = false;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('categoryContent', {}) public categoryContent;
  public bucketUrl = `${environment.BUCKET_URL}`;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private notify: NotificationService,
    private hotelService: HotelServicesService,
    private fb: FormBuilder
  ) {
    this.addRequestForm = this.fb.group({
      bookingId: ['', Validators.required],
      roomNo: ['', Validators.required],
      service: ['', Validators.required],
      guestName: ['', Validators.required],
      createdBy: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.status = res['STATUS'];
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.permittedServices = window.localStorage.getItem('hotel_services');
        for (let i = 0; i < this.permittedServices.length; i++) {
          if (this.permittedServices[i] != ',') {
            let index = this.services?.findIndex(x => x.ID == this.permittedServices[i]);
            if (this.services[index].CONFIGURABLE == true) {
              if (this.userInfo.roleId != 4 && this.userInfo.roleId != 3) {
                this.allowedServices.push(this.services[index]);
              } else {
                this.userInfo.serviceId.forEach(element => {
                  if (this.services[index].ID == element) {
                    this.allowedServices.push(this.services[index]);
                  }
                });
              }
            }
          }
        }
      }
    })
    this.selectedTab = 0;
    this.getEscalatedBookings();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.booking.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBookingId();
      });
  }

  onTabChanged(event) {
    this.selectedTab = event.index;
    this.service.setValue('');
    this.reqFromDate.setValue('');
    this.fromBookingDate = '';
    this.reqToDate.setValue('');
    this.toBookingDate = '';
    this.searchVal.setValue('');
    if (this.selectedTab == 0) {
      this.getEscalatedBookings();
    }
    else if (this.selectedTab == 1) {
      this.getLiveBookings();
    }
    else if (this.selectedTab == 2) {
      this.getCompletedBookings();
    }
  }

  refresh() {
    this.service.setValue('');
    this.reqFromDate.setValue('');
    this.fromBookingDate = '';
    this.reqToDate.setValue('');
    this.toBookingDate = '';
    this.searchVal.setValue('');
    if (this.selectedTab == 0) {
      this.getEscalatedBookings();
    }
    else if (this.selectedTab == 1) {
      this.getLiveBookings();
    }
    else if (this.selectedTab == 2) {
      this.getCompletedBookings();
    }
  }

  getEscalatedBookings() {
    this.loading = true;
    this.hotelService.getGuestEscalatedBookings().subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource = data.object;
        this.escalatedBookings = data.object;
        this.escalatedBookings.map(item => {
          item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getLiveBookings() {
    this.loading = true;
    this.hotelService.getGuestLiveBookings().subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource = data.object;
        this.liveBookings = data.object;
        this.liveBookings.map(item => {
          item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getCompletedBookings() {
    this.loading = true;
    this.hotelService.getGuestCompletedBookings().subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource = data.object;
        this.completedBookings = data.object;
        this.completedBookings.map(item => {
          item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateBooking(element, type) {
    const dialogRef = this.dialog.open(UpdateGuestBookingComponent, {
      width: '70%',
      height: '500px',
      data: { request: element, requestType: type },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'escalated') {
        this.selectedTab = 0;
        this.getEscalatedBookings();
      }
      else if (result === 'live') {
        this.selectedTab = 1;
        this.getLiveBookings();
      }
    })
  }

  save(reservation_id, service_id) {
    this.trigger.closeMenu();
    this.loading = true;
    let obj = {
      "SERVICE_ID": service_id,
      "RESERVATION_ID": reservation_id,
      "STATUS": this.staffBookingStatus.value
    }
    this.update(obj);
    this.staffBookingStatus.setValue('');
  }

  update(obj) {
    this.hotelService.updateGuestBooking(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
        if (this.selectedTab == 0) {
          this.getEscalatedBookings();
        }
        else if (this.selectedTab == 1) {
          this.getLiveBookings();
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  search() {
    if (this.selectedTab == 0) {
      this.escalatedBookings = [];
      if (this.reqFromDate.value) {
        this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      }
      if (this.reqToDate.value) {
        this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      }
      this.loading = true;
      this.hotelService.getGuestEscalatedBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
        next: (data) => {
          this.loading = false;
          this.dataSource = data.object;
          this.escalatedBookings = data.object;
          this.escalatedBookings.map(item => {
            item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
          })
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 1) {
      this.liveBookings = [];
      if (this.reqFromDate.value) {
        this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      }
      if (this.reqToDate.value) {
        this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      }
      this.loading = true;
      this.hotelService.getGuestLiveBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
        next: (data) => {
          this.loading = false;
          this.dataSource = data.object;
          this.liveBookings = data.object;
          this.liveBookings.map(item => {
            item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
          })
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (this.selectedTab == 2) {
      this.completedBookings = [];
      if (this.reqFromDate.value) {
        this.fromBookingDate = moment(this.reqFromDate.value).format('DD-MM-YYYY');
      }
      if (this.reqToDate.value) {
        this.toBookingDate = moment(this.reqToDate.value).format('DD-MM-YYYY');
      }
      this.loading = true;
      this.hotelService.getGuestCompletedBookingsByKey(this.searchVal.value, this.fromBookingDate, this.toBookingDate, this.service.value).subscribe({
        next: (data) => {
          this.loading = false;
          this.dataSource = data.object;
          this.completedBookings = data.object;
          this.completedBookings.map(item => {
            item.DATE = moment(item.DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
          })
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  createRequest() {
    this.isNewRequest = true;
    this.getBookingId();
    this.addRequestForm.controls["createdBy"].setValue(this.userInfo.firstName + ' ' + this.userInfo.lastName);
    this.canOrder = false;
  }

  backToRequests() {
    this.isNewRequest = false;
    this.addRequestForm.reset();
    this.serviceCategories = [];
    this.selectedHouseKeepingServices = [];
    this.requiredServices = [];
    // this.selectedTab = 0;
    // this.getEscalatedBookings();
  }

  getBookingId() {
    this.hotelService.getBookingIdList().subscribe({
      next: (data) => {
        this.bookingIdList = data.object;
        this.filterBookingIds = this.bookingIdList;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changeBookingId(id) {
    for (let reserveId of this.bookingIdList) {
      if (reserveId.ID == id) {
        this.reservation = reserveId.RESERVATION;
        this.reservationID = reserveId.ID;
        this.addRequestForm.controls["guestName"].setValue(reserveId.HOST.FIRST_NAME + ' ' + reserveId.HOST.LAST_NAME);
        this.addRequestForm.controls["roomNo"].setValue('');
      }
    }
    this.addRequestForm.controls["bookingId"].addValidators([Validators.required]);
    this.addRequestForm.controls["bookingId"].updateValueAndValidity();
    this.getUserRooms(this.reservationID);
  }

  filterBookingId() {
    let search = this.booking.value;
    this.filterBookingIds = this.bookingIdList.filter(bookId =>
      bookId.RESERVATION.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  getUserRooms(id) {
    this.hotelService.getRoomWithBookingId(id).subscribe({
      next: (data) => {
        this.roomsList = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectRequestService(service) {
    this.selectedService = service;
    this.getCategories(service);
  }

  getCategories(serviceId) {
    this.loading = true;
    if (serviceId == 1) {
      this.hotelService.getSpaCategory().subscribe({
        next: (data) => {
          this.serviceCategories = data.object;
          for (let i = 0; i < this.serviceCategories.length; i++) {
            this.currentActive = 0;
            this.currentCategoryID = this.serviceCategories[0].ID;
          }
          this.loading = false;
          if (this.serviceCategories.length != 0) {
            this.getSpaTypes(this.currentCategoryID);
          }
          else {
            this.serviceItems = [];
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (serviceId == 2) {
      this.getHousekeepingServices();
    }
    else if (serviceId == 3) {
      this.hotelService.getTransportCategory().subscribe({
        next: (data) => {
          this.serviceCategories = data.object;
          for (let i = 0; i < this.serviceCategories.length; i++) {
            this.currentActive = 0;
            this.currentCategoryID = this.serviceCategories[0].ID;
          }
          this.loading = false;
          if (this.serviceCategories.length != 0) {
            this.getTransportTypes(this.currentCategoryID);
          }
          else {
            this.serviceItems = [];
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (serviceId == 4) {
      this.hotelService.getConferenceCategory().subscribe({
        next: (data) => {
          this.serviceCategories = data.object;
          for (let i = 0; i < this.serviceCategories.length; i++) {
            this.currentActive = 0;
            this.currentCategoryID = this.serviceCategories[0].ID;
          }
          this.loading = false;
          if (this.serviceCategories.length != 0) {
            this.getConferenceTypes(this.currentCategoryID);
          }
          else {
            this.serviceItems = [];
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (serviceId == 5) {
      this.hotelService.getPackageCategory().subscribe({
        next: (data) => {
          this.serviceCategories = data.object;
          for (let i = 0; i < this.serviceCategories.length; i++) {
            this.currentActive = 0;
            this.currentCategoryID = this.serviceCategories[0].ID;
          }
          this.loading = false;
          if (this.serviceCategories.length != 0) {
            this.getPackageTypes(this.currentCategoryID);
          }
          else {
            this.serviceItems = [];
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else if (serviceId == 6) {
      this.hotelService.getDiningCategory().subscribe({
        next: (data) => {
          this.serviceCategories = data.object;
          for (let i = 0; i < this.serviceCategories.length; i++) {
            this.currentActive = 0;
            this.currentCategoryID = this.serviceCategories[0].ID;
          }
          this.loading = false;
          if (this.serviceCategories.length != 0) {
            this.getDiningTypes(this.currentCategoryID);
          }
          else {
            this.serviceItems = [];
          }
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  getSpaTypes(categoryId) {
    this.loading = true;
    this.hotelService.getSpaType(categoryId).subscribe({
      next: (data) => {
        this.loading = false;
        this.serviceItems = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getHousekeepingServices() {
    this.selectedHouseKeepingServices = [];
    this.hotelService.getHousekeepingServices().subscribe({
      next: (data) => {
        this.loading = false;
        this.housekeepingServices = [...data.object];
        this.housekeepingServices.forEach(element => {
          let obj = {
            "ID": element['ID'],
            "REQUIRED": false
          }
          this.selectedHouseKeepingServices.push(obj);
        });
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getTransportTypes(categoryId) {
    this.loading = true;
    this.hotelService.getTransportType(categoryId).subscribe({
      next: (data) => {
        this.loading = false;
        this.serviceItems = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getConferenceTypes(categoryId) {
    this.loading = true;
    this.hotelService.getConferenceType(categoryId).subscribe({
      next: (data) => {
        this.loading = false;
        this.serviceItems = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getPackageTypes(categoryId) {
    this.loading = true;
    this.hotelService.getPackageType(categoryId).subscribe({
      next: (data) => {
        this.loading = false;
        this.serviceItems = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getDiningTypes(categoryId) {
    this.loading = true;
    this.hotelService.getDiningType(categoryId).subscribe({
      next: (data) => {
        this.loading = false;
        this.serviceItems = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  displayDetails(index, id) {
    this.currentActive = index;
    this.currentCategoryID = id;
    if (this.selectedService == 1) {
      this.getSpaTypes(this.currentCategoryID);
    }
    else if (this.selectedService == 3) {
      this.getTransportTypes(this.currentCategoryID);
    }
    else if (this.selectedService == 4) {
      this.getConferenceTypes(this.currentCategoryID);
    }
    else if (this.selectedService == 5) {
      this.getPackageTypes(this.currentCategoryID);
    }
    else if (this.selectedService == 6) {
      this.getDiningTypes(this.currentCategoryID);
    }
  }

  public categoryScrollRight(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public categoryScrollLeft(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  bookService(id) {
    const dialogRef = this.dialog.open(BookServiceComponent, {
      width: '70%',
      height: '500px',
      data: { bookingId: this.reservationID, serviceId: this.selectedService, categoryId: this.currentCategoryID, typeId: id, roomId: this.addRequestForm.value.roomNo },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'refresh') {
        this.addRequestForm.reset();
        this.serviceCategories = [];
        this.isNewRequest = false;
        this.selectedTab = 1;
        this.getLiveBookings();
      }
    })
  }

  selectHouseKeepingServices(event, id) {
    let order = {
      "ID": id,
      "REQUIRED": event.checked
    }
    let index = this.selectedHouseKeepingServices.findIndex(x => x.ID == id);
    if (index == -1) {
      this.selectedHouseKeepingServices.push(order);
    } else {
      this.selectedHouseKeepingServices[index].REQUIRED = event.checked;
    }
    let requiredIndex = this.requiredServices.findIndex(x => x.ID == id);
    if (requiredIndex == -1 && event.checked == true) {
      this.requiredServices.push(order);
    } else if (requiredIndex != -1 && event.checked == false) {
      this.requiredServices.splice(requiredIndex, 1);
    }
  }

  submitHousekeepingRequest() {
    this.loading = true;
    let obj = {
      "BOOKING_ID": this.reservationID,
      "ROOM_ID": this.addRequestForm.value.roomNo,
      "INSTRUCTION": this.serviceInstruction.value,
      "ORDER": this.selectedHouseKeepingServices
    }
    this.hotelService.bookHouseKeepingService(obj).subscribe({
      next: (data) => {
        this.loading = false;
        const dialogRef = this.dialog.open(RequestConfirmedPopupComponent, {
          width: '500px',
          height: 'auto',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'refresh') {
            this.addRequestForm.reset();
            this.housekeepingServices = [];
            this.selectedHouseKeepingServices = [];
            this.requiredServices = [];
            this.isNewRequest = false;
            this.selectedTab = 1;
            this.getLiveBookings();
          }
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  isIdPresent(id) {
    if (!this.orderInfo[id]) {
      return true;
    } else {
      return false;
    }
  }

  addItem(i, diningInfo) {
    if (!this.orderInfo[diningInfo]) {
      this.orderInfo[diningInfo.ID] = {
        "data": diningInfo,
        "quantity": 1
      }
    }
    if (Object.keys(this.orderInfo).length != 0) {
      this.canOrder = true;
    }
    else {
      this.canOrder = false;
    }
  }

  getQuantity(id) {
    if (this.orderInfo[id]) {
      return this.orderInfo[id].quantity;
    } else {
      return '';
    }
  }

  addQuantity(id) {
    if (this.orderInfo[id]) {
      this.orderInfo[id].quantity = this.orderInfo[id].quantity + 1;
    }
    if (Object.keys(this.orderInfo).length != 0) {
      this.canOrder = true;
    }
    else {
      this.canOrder = false;
    }
  }

  reduceQuantity(id) {
    if (this.orderInfo[id]) {
      if (this.orderInfo[id].quantity - 1 >= 1) {
        this.orderInfo[id].quantity = this.orderInfo[id].quantity - 1;
      }
      else {
        delete this.orderInfo[id];
      }
    }
    if (Object.keys(this.orderInfo).length != 0) {
      this.canOrder = true;
    }
    else {
      this.canOrder = false;
    }
  }

  bookDining() {
    const dialogRef = this.dialog.open(BookDiningComponent, {
      width: '65%',
      height: '500px',
      data: { bookingId: this.reservationID, serviceId: this.selectedService, roomId: this.addRequestForm.value.roomNo, order: this.orderInfo },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'refresh') {
        this.addRequestForm.reset();
        this.serviceCategories = [];
        this.orderInfo = {};
        this.isNewRequest = false;
        this.selectedTab = 1;
        this.getLiveBookings();
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}