import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-confirmed-popup',
  templateUrl: './request-confirmed-popup.component.html',
  styleUrls: ['./request-confirmed-popup.component.scss']
})
export class RequestConfirmedPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<RequestConfirmedPopupComponent>
  ) { }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close('refresh');
  }

}