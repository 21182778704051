import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { GetMasterData } from "../actions/user-info.actions";
import { AdminService } from "src/app/services/admin.service";

// User State Model
export class MasterDataStateModel {
    masterData: any[];
    masterDataLoaded: boolean;
}

//User state
@State<MasterDataStateModel>({
    name: 'master',
    defaults: {
        masterData: [],
        masterDataLoaded: false
    }
})

@Injectable()
export class MasterDataState {
    constructor(private adminService: AdminService) { }

    @Selector()
    static getMasterData(state: MasterDataStateModel) {
        return state.masterData
    }

    @Selector()
    static isMasterDataLoaded(state: MasterDataStateModel) {
        return state.masterDataLoaded
    }

    @Action(GetMasterData)
    getHotelInfo({ getState, setState }: StateContext<MasterDataStateModel>) {
        return this.adminService.getMasterData().pipe(tap(res => {
            const state = getState();
            res.object.forEach(item => {
                for (let val in item) {
                    item[val].shift();
                }
            })
            setState({
                ...state,
                masterData: res.object[0],
                masterDataLoaded: true
            })
        }))
    }
}