import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})
export class RevenueComponent implements AfterViewInit {
  selected: string;
  periods = [];

  @ViewChild('chart') chartContainer: ElementRef;

  ngOnInit() {
    this.initializePeriods();
  }

  initializePeriods() {
    this.periods = [
      { label: 'Today', value: 'today' },
      { label: 'One Month', value: '1_month' },
      { label: 'Six Months', value: '6_months' },
      { label: 'One Year', value: '1_year' }
    ];
    this.selected = this.periods[0].value; // Default selection
  }

  
  ngAfterViewInit() {
    this.createLollipopChart();
  }

  createLollipopChart() {
    const data = [18, 12, 6, 9, 12, 3, 9, 14, 5, 7, 11, 4, 8, 6, 10];
    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  
    const element = this.chartContainer.nativeElement;
    const margin = { top: 10, right: 10, bottom: 30, left: 10 };
    const width = 900 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
  
    const svg = d3.select(element)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  
    const x = d3.scaleBand()
      .domain(labels)
      .range([0, width])
      .padding(0.1);
  
    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .range([height, 0]);
  
    // Tooltip inside the chart container
    const tooltip = d3.select(element)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background', '#333')
      .style('color', '#fff')
      .style('padding', '5px 10px')
      .style('border-radius', '4px')
      .style('display', 'none')
      .style('font-size', '12px')
      .style('pointer-events', 'none');
  
    // Lollipop lines (sticks)
    svg.selectAll('.stick')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'stick')
      .attr('x1', (d, i) => x(labels[i]) + x.bandwidth() / 2)
      .attr('x2', (d, i) => x(labels[i]) + x.bandwidth() / 2)
      .attr('y1', height)
      .attr('y2', d => y(d))
      .attr('stroke', 'gray')
      .attr('stroke-width', 2);
  
    // Lollipop circles with mouseover effects
   // Lollipop lines (sticks) with mouseover effects
const sticks = svg.selectAll('.stick')
.data(data)
.enter()
.append('line')
.attr('class', 'stick')
.attr('x1', (d, i) => x(labels[i]) + x.bandwidth() / 2)
.attr('x2', (d, i) => x(labels[i]) + x.bandwidth() / 2)
.attr('y1', height)
.attr('y2', d => y(d))
.attr('stroke', 'gray')
.attr('stroke-width', 2)
.on('mouseover', function (event, d) {
  d3.select(this)
    .attr('stroke', '#D95F02')  // Highlight color on the stick
    .attr('stroke-width', 4);   // Increase line width

  // Show tooltip
  tooltip.style('display', 'block')
    .html(` ${d}`)
    .style('font-size', '14px')  // Make the tooltip text bigger
    .style('left', `${event.pageX - element.getBoundingClientRect().left + 10}px`)
    .style('top', `${event.pageY - element.getBoundingClientRect().top - 25}px`);
})
.on('mousemove', function (event) {
  // Update tooltip position
  tooltip.style('left', `${event.pageX - element.getBoundingClientRect().left + 10}px`)
    .style('top', `${event.pageY - element.getBoundingClientRect().top - 25}px`);
})
.on('mouseout', function () {
  d3.select(this)
    .attr('stroke', 'gray')  // Reset color
    .attr('stroke-width', 2);  // Reset line width

  // Hide tooltip
  tooltip.style('display', 'none');
});

// Lollipop circles with mouseover effects
const lollipops = svg.selectAll('.lollipop')
.data(data)
.enter()
.append('circle')
.attr('class', 'lollipop')
.attr('cx', (d, i) => x(labels[i]) + x.bandwidth() / 2)
.attr('cy', d => y(d))
.attr('r', 6)
.attr('fill', '#EF9E00')
.on('mouseover', function (event, d) {
  d3.select(this)
    .transition()
    .duration(200)
    .attr('fill', '#D95F02')  // Highlight color
    .attr('r', 20);           // Increase size

  // Show tooltip
  tooltip.style('display', 'block')
    .html(` ${d}`)
    .style('font-size', '14px')  // Make the tooltip text bigger
    .style('left', `${event.pageX - element.getBoundingClientRect().left + 10}px`)
    .style('top', `${event.pageY - element.getBoundingClientRect().top - 25}px`);
})
.on('mousemove', function (event) {
  // Update tooltip position
  tooltip.style('left', `${event.pageX - element.getBoundingClientRect().left + 10}px`)
    .style('top', `${event.pageY - element.getBoundingClientRect().top - 25}px`);
})
.on('mouseout', function () {
  d3.select(this)
    .transition()
    .duration(200)
    .attr('fill', '#EF9E00')  // Reset color
    .attr('r', 6);            // Reset size

  // Hide tooltip
  tooltip.style('display', 'none');
});

  
    // X-axis with button-style labels
    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`);
  
    xAxis.selectAll('.tick')
      .data(labels)
      .enter()
      .append('g')
      .attr('class', 'tick')
      .attr('transform', (d) => `translate(${x(d) + x.bandwidth() / 2},0)`)
      .each(function (d) {
        d3.select(this)
          .append('circle')
          .attr('r', 16)
          .attr('fill', '#E0E0E0');
  
        d3.select(this)
          .append('text')
          .attr('dy', 5)
          .attr('text-anchor', 'middle')
          .attr('fill', 'black')
          .text(d);
      });
  
    // Remove original x-axis line
    xAxis.selectAll('path').style('stroke', 'none');
  }
  
}
