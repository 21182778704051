import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userInfo: any;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      const token = this.userInfo.access_token;
      if (route.data.role && route.data.role.includes(this.userInfo.roleId) == true) {
        if (token) {
          // logged in so return true
          return true;
        }
      }
      else {
        if (this.userInfo.roleId == 1) {
          this.router.navigateByUrl('dashboard/clients');
        }
        else if (this.userInfo.roleId == 2 || this.userInfo.roleId == 3) {
          this.router.navigateByUrl('dashboard/members');
        }
        else if (this.userInfo.roleId == 4) {
          this.router.navigateByUrl('dashboard/notification');
        }
      }
    }
    // not logged in so redirect to login page with the return url
    localStorage.clear();
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}