<div fxLayout="column">
    <div mat-dialog-content class="content">
        {{message.title}}
    </div>
    <mat-dialog-actions align="end" class="actions">
        <button mat-stroked-button class="save-button" mat-dialog-close (click)="onSubmit()">
            Yes
        </button>
        <button mat-stroked-button class="button" mat-dialog-close (click)="onClose()">
            No
        </button>
    </mat-dialog-actions>
</div>