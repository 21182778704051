import { Component, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetMasterData } from 'src/app/session/shared/store/actions/user-info.actions';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { Router } from '@angular/router';
import { AddEditDiningfilterComponent } from './add-edit-diningfilter/add-edit-diningfilter.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent {

  userInfo: any;
  userName: any;
  showServices: boolean = true;
  showCurrency: boolean = false;
  showDiningFilters: boolean = false;
  client = new FormControl('');
  currencyType: string = '';
  selectedClientId;
  services = [];
  clients = [];
  countries = [];
  availableServices: any;
  servicesByClient = [];
  currencyByClient = [];
  serviceColumns: string[] = ['sr.no', 'name', 'action'];
  currencyColumns: string[] = ['country', 'currency', 'action'];
  diningFilterColumns: string[] = ['sr.no', 'dining_type', 'action'];
  loading: boolean = true;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public adminService: AdminService,
    private notify: NotificationService,
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.countries = res['COUNTRY'];
      }
    })
    this.getClients();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  showService() {
    this.showServices = true;
    this.showCurrency = false;
    this.showDiningFilters = false;
    this.dataSource.data = [];
    this.getClients();
  }

  manageCurrency() {
    this.showServices = false;
    this.showCurrency = true;
    this.showDiningFilters = false;
    this.dataSource.data = [];
    this.getClients();
  }

  diningFilter() {
    this.showServices = false;
    this.showCurrency = false;
    this.showDiningFilters = true;
    this.dataSource.data = [];
    this.getDiningFilters();
  }

  getClients() {
    this.adminService.getClientsList().subscribe({
      next: (data) => {
        this.loading = false;
        this.clients = data.object;
        this.selectedClientId = this.clients[0]?.ID;
        this.client.setValue(this.selectedClientId);
        if (this.clients.length != 0) {
          if (this.showServices == true) {
            this.getServicesByClient();
          }
          else if (this.showCurrency == true) {
            this.getCurrency();
          }
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changeClient(clientID) {
    this.selectedClientId = clientID;
    if (this.showServices == true) {
      this.getServicesByClient();
    }
    else if (this.showCurrency == true) {
      this.getCurrency();
    }
  }

  getServicesByClient() {
    this.loading = true;
    this.servicesByClient = [];
    this.adminService.getServiceByClientId(this.selectedClientId).subscribe({
      next: (data) => {
        this.availableServices = data.object.SERVICES;
        for (let service of this.services) {
          if (this.availableServices.indexOf(service.ID) != -1) {
            let obj = {
              "ID": service.ID,
              "NAME": service.NAME,
              "ENABLED": true
            }
            this.servicesByClient.push(obj);
          }
          else if (this.availableServices.indexOf(service.ID) == -1) {
            if (service.CONFIGURABLE == false) {
              let obj = {
                "ID": service.ID,
                "NAME": service.NAME,
                "ENABLED": true
              }
              this.servicesByClient.push(obj);
            }
            else {
              let obj = {
                "ID": service.ID,
                "NAME": service.NAME,
                "ENABLED": false
              }
              this.servicesByClient.push(obj);
            }
          }
        }
        this.loading = false;
        this.dataSource.data = this.servicesByClient;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  enable(isChecked, id) {
    this.loading = true;
    let updatedService = [];
    for (let service of this.servicesByClient) {
      if (service.ID != id) {
        let changedService = {
          "S_ID": service.ID,
          "ENABLED": service.ENABLED
        }
        updatedService.push(changedService);
      }
      else if (service.ID == id) {
        let changedService = {
          "S_ID": service.ID,
          "ENABLED": isChecked
        }
        updatedService.push(changedService);
      }
    }
    let obj = {
      "C_ID": this.selectedClientId,
      "SERVICE": updatedService,
    }
    this.adminService.updateClientService(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.getServicesByClient();
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getCurrency() {
    this.loading = true;
    this.currencyType = '';
    this.currencyByClient = [];
    this.adminService.getCurrencyByClientId(this.selectedClientId).subscribe({
      next: (data) => {
        if (!!data.object.CURRENCY) {
          this.currencyByClient.push(data.object);
          this.dataSource.data = this.currencyByClient;
          this.currencyType = this.currencyByClient[0].CURRENCY.COUNTRY.CURRENCY == null || undefined || '' ? '' : this.currencyByClient[0].CURRENCY.COUNTRY.CURRENCY;
        }
        else if (!data.object.CURRENCY) {
          this.currencyByClient.push({
            "CURRENCY": {
              "ID": 0,
              "DELETED": false,
              "COUNTRY": {
                "ID": 0,
                "NAME": null,
                "CODE": null,
                "CURRENCY": null,
                "ISO_CODE": null,
                "SYMBOL": null,
                "CURRENCY_CODE": null
              }
            }
          });
          this.dataSource.data = this.currencyByClient;
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changeCurrency(country) {
    for (let coun of this.countries) {
      if (coun.ID == country) {
        this.currencyType = coun.CURRENCY == null || undefined || '' ? '' : coun.CURRENCY;
      }
    }
  }

  updateCurrency(countryId) {
    this.loading = true;
    let obj = {
      "C_ID": this.selectedClientId,
      "COUNTRY_ID": countryId,
    }
    this.adminService.updateClientCurrency(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.getCurrency();
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getDiningFilters() {
    this.loading = true;
    this.adminService.getDiningFilter().subscribe({
      next: (data) => {
        this.dataSource.data = data.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addDiningFilter() {
    const dialogRef = this.dialog.open(AddEditDiningfilterComponent, {
      width: '558px',
      height: 'auto',
      data: { name: "add_filter" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getDiningFilters();
      }
    });
  }

  editDiningFilter(id) {
    const dialogRef = this.dialog.open(AddEditDiningfilterComponent, {
      width: '558px',
      height: 'auto',
      data: { ID: id, name: "update_filter" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getDiningFilters();
      }
    });
  }

  deleteDiningFilter(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this filter ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        let deleteFilterBody = {}
        deleteFilterBody = {
          "ID": id,
          "DELETED": true
        }
        this.adminService.updateDiningFilter(deleteFilterBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Dining filter deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.getDiningFilters();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}