<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div class="main-header">User Management</div>
            <div class="input-container">
                <mat-card class="card">
                    <div fxLayout="row" style="height:inherit">
                        <div fxLayout="column" class="services-buttons" fxFlex="20%">
                            <button mat-button class="service-button" (click)="showUploadedUsers()"
                                [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Upload Users</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="showAppUsers()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">App Users</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column" *ngIf="showSheets" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="space-between">
                                <div class="header">
                                    Uploaded Sheets History
                                </div>
                                <div class="template-link" (click)="downloadTemplate()">
                                    Download Sample Template
                                </div>
                                <div>
                                    <button class="add-btn" (click)="uploadUsers()" [disabled]="accessType == 2">
                                        <span><iconify-icon icon="material-symbols:upload" style="font-size: 23px; margin-bottom: -7px"></iconify-icon></span>
                                        Upload Users
                                    </button>
                                </div>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource">
                                    <ng-container matColumnDef="file_name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 40%;"> File
                                            Name </th>
                                        <td mat-cell *matCellDef="let row">{{row.FILE_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="uploaded_by">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 35%;"> Uploaded
                                            By
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.UPLOADED_BY.FIRST_NAME}}
                                            {{row.UPLOADED_BY.LAST_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="uploaded_date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Date
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.CREATED_DATE | date:'dd/MM/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="uploadColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: uploadColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showUsers" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="space-between">
                                <div class="header">
                                    Guests
                                </div>
                                <div>
                                    <button class="add-btn" (click)="addAppUsers()" [disabled]="accessType == 2">
                                        + Add Guest
                                    </button>
                                </div>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource1">
                                    <ng-container matColumnDef="booking_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="14%"> Booking Id </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="14%">{{row.BOOKING_ID}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="15%"> Name </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="15%">{{row.HOST.FIRST_NAME}} {{row.HOST.LAST_NAME | slice: 0:7}}{{row.HOST.LAST_NAME.length> 7 ? '..':''}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="communication">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="20%"> Communication </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="20%">
                                            <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="3px">
                                                <span matTooltip="{{row.HOST.EMAIL_ID}}">{{row.HOST.EMAIL_ID | slice: 0:15}}{{row.HOST.EMAIL_ID.length> 15 ? '..':''}}</span>
                                                <span>{{row.HOST.MOBILE}}</span>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="15%"> Date </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="16%">
                                            <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="3px">
                                                <span>{{row.FROM_DATE}}</span>
                                                <span>{{row.TO_DATE}}</span>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="room">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="11%"> Room No </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="11%">{{row.HOST.ROOM}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="totalGuests">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="13%"> Total Guests </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="11%">{{row.NO_OF_GUESTS}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" fxFlex="12%"> Action
                                        </th>
                                        <td mat-cell *matCellDef="let row" fxFlex="12%">
                                            <div fxLayout="row" fxLayoutAlign="center">
                                                <button mat-icon-button (click)="editAppUser(row.HOST.ID)" [disabled]="accessType == 2 || (row.toDate < currentDate)">
                                                    <span class="material-icons-outlined">edit</span>
                                                </button>
                                                <button mat-icon-button (click)="deleteAppUser(row.HOST.ID)" [disabled]="accessType == 2">
                                                    <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                                                </button>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="usersColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>