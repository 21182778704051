import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/app/shared/color-object';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { LogoutConfirmationComponent } from 'src/app/home/dashboard/logout-confirmation/logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ValidatorFn, ValidationErrors } from '@angular/forms';
import { ComfirmLogoutComponent } from '../../auth/comfirm-logout/comfirm-logout.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  profileform: FormGroup;
  passwordform: FormGroup;
  isMyProfile: boolean = true;
  isChangePassword: boolean = false;
  usernamepattern = /^[a-z A-Z]+$/;
  public emailPattern = `${environment.EMAIL_PATTERN}`;
  public passwordPattern = `${environment.PASSWORD_PATTERN}`;
  loading: boolean = false;
  isDisabled = false;
  services: [] = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  userInfo: any;
  userName: any;
  userPhoneNo: any;
  userEmailId: any;
  mobile: boolean;
  roleId: any;
  allocatedRoom = [];
  hotelServices = [];
  profileOptionsView: boolean = true;
  editProfileView: boolean = false;
  changePasswordView: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private fb: FormBuilder,
    public adminService: AdminService,
    public notify: NotificationService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.profileform = this.fb.group({
      fname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), Validators.pattern(this.usernamepattern)]],
      lname: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(this.usernamepattern)]],
      emailId: [''],
      role: [''],
      phoneNo: [''],
      service: [''],
      allocatedRoom: [[]]
    })
    this.passwordform = this.fb.group({
      old_pswd: ['', [Validators.required, Validators.pattern(this.passwordPattern), Validators.minLength(8), Validators.maxLength(15)]],
      new_pswd: ['', [Validators.required, Validators.pattern(this.passwordPattern), Validators.minLength(8), Validators.maxLength(15)]],
      confirm_pswd: ['', [Validators.required, Validators.pattern(this.passwordPattern), Validators.minLength(8), Validators.maxLength(15)]],
    }, {
      validators: [this.oldNewPasswordValidator(), this.newConfirmPasswordValidator()]
    });
  }

  oldNewPasswordValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const oldPassword = formGroup.controls['old_pswd'].value;
      const newPassword = formGroup.controls['new_pswd'].value;
      if (oldPassword === newPassword) {
        return { 'oldNewMismatch': true };
      }
      return null;
    };
  }

  newConfirmPasswordValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const newPassword = formGroup.controls['new_pswd'].value;
      const confirmPassword = formGroup.controls['confirm_pswd'].value;
      if (newPassword !== confirmPassword) {
        return { 'newConfirmMismatch': true };
      }
      return null;
    };
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.roleId = this.userInfo.roleId;
    this.userName = this.userInfo.firstName;
    this.getProfileInfo();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  userProfile() {
    this.isMyProfile = true;
    this.isChangePassword = false;
  }

  password() {
    this.isMyProfile = false;
    this.isChangePassword = true;
  }

  editStaffProfile() {
    this.profileOptionsView = false;
    this.editProfileView = true;
    this.changePasswordView = false;
    this.getProfileInfo();
  }

  changeStaffPassword() {
    this.profileOptionsView = false;
    this.editProfileView = false;
    this.changePasswordView = true;
  }

  backToRequests() {
    this.router.navigateByUrl('guest-request');
  }

  backToProfileOptions() {
    this.profileOptionsView = true;
    this.editProfileView = false;
    this.changePasswordView = false;
    this.profileform.reset();
    this.allocatedRoom = [];
    this.hotelServices = [];
    this.passwordform.reset();
  }

  getProfileInfo() {
    this.allocatedRoom = [];
    this.hotelServices = [];
    this.loading = true;
    this.adminService.getMyProfileInfo().subscribe({
      next: (data) => {
        this.loading = false;
        this.userPhoneNo = data.object.MOBILE;
        this.userEmailId = data.object.EMAIL_ID;
        this.profileform.patchValue({
          fname: data.object.FIRST_NAME,
          lname: data.object.LAST_NAME,
          emailId: data.object.EMAIL_ID,
          role: data.object.ROLE.NAME,
          phoneNo: data.object.MOBILE
        })
        data.object.ROOM_ALLOCATION?.forEach(val => {
          this.allocatedRoom.push(val.NAME);
          this.allocatedRoom.join(',');
        })
        data.object.SERVICE.forEach(val => {
          this.hotelServices.push(val.NAME);
          this.hotelServices.join(',');
        })
        this.profileform.get("service").patchValue(this.hotelServices);
        this.profileform.get("allocatedRoom").patchValue(this.allocatedRoom);
      },
      error: (e) => {
        this.loading = false;
      }
    })
  }

  savePolicy() {
    let body = {
      'FIRST_NAME': this.profileform.value.fname,
      'LAST_NAME': this.profileform.value.lname,
      // 'SERVICE_ID': this.profileform.value.service
    }
    this.adminService.updateMyProfile(body)
      .subscribe({
        next: (data) => {
          this.loading = true;
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
          this.getProfileInfo();
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          );
        }
      })
  }

  changePassword() {
    this.loading = true;
    let body = {
      'OLD_PASSWORD': this.passwordform.value.old_pswd,
      'NEW_PASSWORD': this.passwordform.value.new_pswd
    }
    this.adminService.updatePassword(body)
      .subscribe({
        next: (data) => {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
          this.loading = false;
          this.openDialog();
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    this.passwordform.reset();
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ComfirmLogoutComponent, {
      width: '400px',
      height: '250px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}