<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update FAQ':'Create FAQs'}}</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addForm">
        <div mat-dialog-content class="control-container">
            <div style="margin-bottom: 30px;">
                <div class="heading">Service</div>
                <div>
                    <mat-select formControlName="category" class="category" placeholder="Select service"
                        style="font-size: 13px;width: 97% !important;" appearance="fill">
                        <mat-option *ngFor="let category of permittedServices"
                            [value]="category.ID">{{category.NAME}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div style="margin-bottom: 30px;">
                <span class="heading">Question</span><span style="font-size: 10px; color: #474747;">(max 500
                    characters)</span>
                <div>
                    <textarea type="text" class="form" formControlName="question" placeholder="Enter Question"
                        maxlength="500"></textarea>
                </div>
            </div>
            <div>
                <span class="heading">Answer</span><span style="font-size: 10px; color: #474747;">(max 1000
                    characters)</span>
                <div>
                    <textarea type="text" class="form" formControlName="answer" placeholder="Enter Answer"
                        maxlength="1000"></textarea>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button [ngClass]="this.addForm.invalid ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.addForm.invalid" (click)="save()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>