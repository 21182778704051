import { Component, ViewEncapsulation, Inject, ViewChild, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { MatChip } from '@angular/material/chips';
import { Observable, findIndex, map, startWith } from 'rxjs';

@Component({
  selector: 'app-add-edit-room-allocation',
  templateUrl: './add-edit-room-allocation.component.html',
  styleUrls: ['./add-edit-room-allocation.component.scss']
})
export class AddEditRoomAllocationComponent {
  loading: boolean = false;
  areas = [];
  users = [];
  rooms = [];
  roomData = [];
  roomIds = [];
  currentArray = [];
  allRooms = [];
  addAllocationForm: FormGroup;
  areaId: any;
  selectedRooms = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditRoomAllocationComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addAllocationForm = this.fb.group({
      user: ['', Validators.required],
      area: ['', Validators.required],
      rooms: [[], Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.loading = true;
    if (this.data) {
      this.addAllocationForm.get('area').clearValidators();
      this.getAllocatedRoomsByUserId(this.data.id)
    } else {
      this.addAllocationForm.get('area').setValidators(Validators.required)
    }
    this.getAllInternalMembers();
    this.getAreas();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getAllInternalMembers() {
    this.adminService.getAllInternalMembers().subscribe({
      next: (data) => {
        this.users = data.object
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getAreas() {
    this.cs.getAreas().subscribe({
      next: (data) => {
        this.areas = data.object
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  removeRooms(roomName: string) {
    this.roomData = this.addAllocationForm.value.rooms as string[];
    const index = this.roomData.indexOf(roomName);
    // const roomIndex = this.rooms.findIndex(x => x.NAME === roomName);
    // const selectedIndex = this.selectedRooms.findIndex(x => x.NAME === roomName)
    // if (roomIndex !== -1) {
    //   this.rooms.splice(roomIndex, 1);
    // }
    // if (selectedIndex !== -1) {
    //   this.selectedRooms.splice(selectedIndex, 1);
    // }
    if (index !== -1) {
      this.roomData.splice(index, 1);
    }
    this.addAllocationForm.get('rooms').setValue(this.roomData);
    if (this.addAllocationForm.value.area != '') {
      this.getRoomsByAreaId();
    }
  }

  getAllocatedRoomsByUserId(val) {
    this.loading = true;
    this.rooms = [];
    this.selectedRooms = [];
    this.addAllocationForm.get("area").setValue('')
    this.addAllocationForm.get('rooms').setValue([]);
    this.addAllocationForm.get("user").setValue(val);
    this.roomData = [];
    this.cs.getRoomAllocationByMemberId(val).subscribe({
      next: (data) => {
        this.loading = false;
        this.roomData = this.addAllocationForm.value.rooms as string[];
        if (data.object.ROOMS) {
          data.object.ROOMS.forEach((roomInfo) => {
            this.selectedRooms.push(roomInfo)
            this.roomData.push(roomInfo.NAME);
          });
          this.addAllocationForm.get('rooms').setValue(this.roomData);
          data.object.ROOMS.forEach((val) => {
            if (this.rooms.indexOf(val) == -1) {
              this.rooms.push(val)
            }
          });
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getRoomsByAreaId() {
    this.loading = true;
    //Updating Selected Rooms array by pushing
    this.addAllocationForm.value.rooms.forEach((value, index, array) => {
      Object.keys(this.allRooms).forEach((key) => {
        if (value === this.allRooms[key].NAME) {
          const index = this.selectedRooms.findIndex(x => x.ID === this.allRooms[key].ID);
          if (index == -1) {
            this.selectedRooms.push(this.allRooms[key])
          }
        }
      })
    });
    //Updating Selected Rooms array by slicing
    this.selectedRooms.forEach((val, index) => {
      if (this.addAllocationForm.value.rooms.includes(val.NAME) == false) {
        this.selectedRooms.splice(index, 1);
      }
    })
    let currentRoomsByArea = []
    this.cs.getRoomsByAreaId(this.addAllocationForm.value.area).subscribe({
      next: (data) => {
        if(data.object.length == 0){
          this.rooms = [...this.selectedRooms]
        }
        this.areaId = this.addAllocationForm.value.area;
        data.object.forEach((val) => {
          currentRoomsByArea.push(val)
          this.selectedRooms.forEach(val => {
            const index = currentRoomsByArea.findIndex(x => x.ID === val.ID);
            if (index !== -1) {
              currentRoomsByArea.splice(index, 1);
            }
          })
          this.allRooms.push(val);
          //rooms array contains the combination of, rooms by area and selected rooms
          this.rooms = [...new Set([...currentRoomsByArea, ...this.selectedRooms])]
        });
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addAllocation() {
    this.rooms.forEach(val => {
      if (this.addAllocationForm.value.rooms.indexOf(val.NAME) != -1) {
        this.roomIds.push(val.ID)
      }
    })
    this.loading = true;
    let obj = {
      "MEMBER_ID": this.addAllocationForm.value.user,
      "ROOM_ID": this.roomIds,
    }
    this.cs.updateRoomAllocation(obj).subscribe({
      next: (data) => {
        this.roomIds = [];
        this.roomData = [];
        this.loading = false;
        this.dialogRef.close(data.object);
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
      },
      error: (e) => {
        this.roomIds = [];
        this.roomData = [];
        this.loading = false;
        this.dialogRef.close();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

}