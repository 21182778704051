<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                <div class="back-arrow" (click)="backToReservations()">
                    <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                </div>
                <div class="bulk-head">
                    Payment Details
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="tabheader">
                            Order Details
                        </div>
                    </div>
                    <table mat-table [dataSource]="serviceDataSource">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="12%"> Date </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{row.DATE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="service">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="21%"> Service </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">
                                <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                    {{row.SERVICE_ID.NAME}} - {{row.CATEGORY_ID.NAME}} - {{row.CATEGORY_TYPE_ID.NAME}}
                                </ng-container>
                                <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                    <ng-container *ngFor="let dsVal of row.ITEMS">
                                        <li>{{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_ID?.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}</li>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="row.SERVICE_ID.ID === 2 && row.SERVICE_ID.ID !== 6">
                                    <li *ngFor="let dsVal of row.ITEMS">
                                        {{row.SERVICE_ID.NAME}} - {{dsVal.CATEGORY_TYPE_ID?.NAME}}
                                    </li>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Quantity </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">
                                <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                    {{row.QUANTITY}}
                                </ng-container>
                                <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                    <li *ngFor="let dsVal of row.ITEMS">
                                        {{dsVal.QUANTITY}}
                                    </li>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="12%"> Amount </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">
                                <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                    {{currency}} {{row.CATEGORY_TYPE_ID?.PRICE}}
                                </ng-container>
                                <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                    <li *ngFor="let dsVal of row.ITEMS">
                                        {{currency}} {{dsVal.QUANTITY * dsVal.CATEGORY_TYPE_ID?.PRICE}}
                                    </li>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="discount">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Discount </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">
                                <ng-container *ngIf="row.SERVICE_ID.ID !== 2">
                                    {{row.OFFER_ID?.DISCOUNT || 0}}% - {{currency}} {{row.OFFER_PRICE || 0}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tax">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="17%"> Tax </th>
                            <td mat-cell *matCellDef="let row" class="table-detail" style="text-align: start !important;">
                                <ng-container *ngIf="row.SERVICE_ID.ID !== 6 && row.SERVICE_ID.ID !== 2">
                                    <ng-container *ngIf="row.CATEGORY_TYPE_ID?.SERVICETAX.length != 0"> 
                                        <li *ngFor="let tax of row.CATEGORY_TYPE_ID?.SERVICETAX">
                                            {{tax.TAX_ID?.PERCENTAGE}}% - 
                                            <span *ngIf="row.OFFER_ID !== null">{{currency}} {{((row.CATEGORY_TYPE_ID?.PRICE - row.OFFER_PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                            <span *ngIf="row.OFFER_ID === null">{{currency}} {{((row.CATEGORY_TYPE_ID?.PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                        </li>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="row.SERVICE_ID.ID === 6 && row.SERVICE_ID.ID !== 2">
                                    <ng-container *ngFor="let dsVal of row.ITEMS">
                                        <ng-container *ngIf="dsVal.CATEGORY_TYPE_ID?.SERVICETAX.length != 0">
                                            <li *ngFor="let tax of dsVal.CATEGORY_TYPE_ID?.SERVICETAX">
                                                {{tax.TAX_ID?.PERCENTAGE}}% - 
                                                <span *ngIf="row.OFFER_ID !== null">{{currency}} {{(((dsVal.QUANTITY *
                                                    dsVal.CATEGORY_TYPE_ID?.PRICE) - ((row.OFFER_ID?.DISCOUNT / 100) * (dsVal?.CATEGORY_TYPE_ID?.PRICE *
                                                    dsVal?.QUANTITY))) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                                <span *ngIf="row.OFFER_ID === null">{{currency}} {{((dsVal.QUANTITY * dsVal.CATEGORY_TYPE_ID?.PRICE) * (tax.TAX_ID?.PERCENTAGE / 100)).toFixed(2)}}</span>
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="13%"> Total </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{currency}} {{row.TOTAL_AMOUNT + (row.TAXAMOUNT || 0)}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedServiceColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedServiceColumns;"></tr>
                    </table>
                    <div class="box-name" style="text-align: center;" *ngIf="serviceDataSource.data.length == 0">
                        <span>
                            <br>No orders...<br>
                        </span>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="tabheader">
                            Room Details
                        </div>
                    </div>
                    <table mat-table [dataSource]="roomDataSource">
                        <ng-container matColumnDef="roomType">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="30%"> Room Type </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">
                                {{row.ROOM_CATEGORY_TYPE_ID?.CATEGORY_ID.NAME}} - {{row.ROOM_CATEGORY_TYPE_ID?.NAME}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="checkInDate">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-In Date </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{reservationDetails.ROOM_PAYMENT?.BOOKING?.FROM_DATE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="checkOutDate">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Check-Out Date </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{reservationDetails.ROOM_PAYMENT?.BOOKING?.TO_DATE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Amount </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{currency}}</td>
                        </ng-container>
                        <ng-container matColumnDef="discount">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Discount </th>
                            <td mat-cell *matCellDef="let row" class="table-detail"></td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef class="table-header" width="15%"> Total </th>
                            <td mat-cell *matCellDef="let row" class="table-detail">{{currency}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedRoomColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedRoomColumns;"></tr>
                    </table>
                    <div class="box-name" style="text-align: center;" *ngIf="roomDataSource.data.length == 0">
                        <span>
                            <br>No rooms...<br>
                        </span>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card fxFlex="70%" class="reserve-card" fxLayout="row" fxLayoutGap="15px">
                        <div fxFlex="60%" fxLayout="column" fxLayoutGap="15px">
                            <div class="reserve-detail">Guest ID<span>{{reservationDetails.ROOM_PAYMENT?.BOOKING?.RESERVATION}}</span></div>
                            <div class="reserve-detail">Address<span fxFlex="78%">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id turpis nulla. Sed</span></div>
                        </div>
                        <ng-container *ngFor="let user of reservationDetails.ROOM_PAYMENT?.USER">
                            <div fxFlex="40%" fxLayout="column" fxLayoutGap="15px" *ngIf="user.USER_TYPE.ID == 1 && reservationDetails.ROOM_PAYMENT?.USER?.length != 0">
                                <div class="reserve-detail">First Name<span>{{user?.FIRST_NAME}}</span></div>
                                <div class="reserve-detail">Last Name<span>{{user?.LAST_NAME}}</span></div>
                                <div class="reserve-detail">Number of guests<span>{{reservationDetails.ROOM_PAYMENT?.USER?.length}}</span></div>
                                <div class="reserve-detail">Duration<span>{{reservationDetails.ROOM_PAYMENT?.BOOKING?.FROM_DATE}} -
                                    {{reservationDetails.ROOM_PAYMENT?.BOOKING?.TO_DATE}}</span></div>
                            </div>
                        </ng-container>
                    </mat-card>
                    <div fxFlex="25%" fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="price-category">Total</div>
                            <div class="price-amount">{{currency}} -</div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="price-category">Amount</div>
                            <div class="price-amount">{{currency}} -</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="price-category">Total</div>
                            <div class="price-amount">{{currency}} -</div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="price-category">Paid</div>
                            <div class="price-amount">{{currency}} -</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="price-category">Balance</div>
                            <div class="price-amount">{{currency}} -</div>
                        </div>
                    </div>
                </div>
                <!-- <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="tabheader">
                            Payment Details
                        </div>
                    </div>
                    <mat-card class="reserve-card">
                        <form [formGroup]="paymentForm" fxLayout="column" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutGap="40px">
                                <div fxLayout="column" fxFlex="25%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Payment Mode
                                    </div>
                                    <div>
                                        <mat-select class="text-box" appearance="fill" formControlName="paymentMode"
                                            placeholder="Select">
                                            <mat-option [value]="1">Cash</mat-option>
                                            <mat-option [value]="2">Online</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="25%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Amount
                                    </div>
                                    <div>
                                        <input type="number" class="text-box" formControlName="paymentAmount">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card>
                </div> -->
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <button mat-button class="save-btn" (click)="updateReservation()">Check-Out</button>
                    <!--[disabled]="paymentForm.invalid"-->
                    <button mat-button class="cancel-btn" (click)="backToReservations()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>