<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" class="content">
            <div class="main-header">Website Management</div>
            <div class="input-container">
                <mat-card class="card">
                    <div fxLayout="row" style="height:inherit">
                        <div fxLayout="column" class="services-buttons" fxFlex="20%">
                            <button mat-button class="service-button" (click)="showContactUs()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Contact Us</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="showFreeTrial()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Free Trial</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="showSubscription()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Subscriber</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="showConnectUs()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Connect With Us</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-button class="service-button" (click)="showJoinUs()" [disabled]="loading">
                                <div fxLayout="row" fxFlex="173px" fxLayoutAlign="space-between center">
                                    <span class="button-label">Join Us</span>
                                    <div>
                                        <mat-icon class="arrow-icon">chevron_right</mat-icon>
                                    </div>
                                </div>
                            </button>
                            <mat-divider></mat-divider>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column" *ngIf="showContacts" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                <div class="header">
                                    Contact Us
                                </div>
                                <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">
                                    refresh
                                </span></button>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource1">
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%;"> Name </th>
                                        <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="email_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Email ID </th>
                                        <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="message">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 35%;"> Message </th>
                                        <td mat-cell *matCellDef="let row">{{row.MESSAGE}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%;"> Date </th>
                                        <td mat-cell *matCellDef="let row">{{row.CREATED_DATE | date:'dd/MM/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="contactColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: contactColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showTrials" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                <div class="header">
                                    Free Trial
                                </div>
                                <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">
                                    refresh
                                </span></button>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource2">
                                    <ng-container matColumnDef="hotel_name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Hotel Name </th>
                                        <td mat-cell *matCellDef="let row">{{row.HOTEL_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="email_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 30%;"> Email ID </th>
                                        <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="mobile">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Mobile </th>
                                        <td mat-cell *matCellDef="let row" matTooltip="{{row.PHONE_NUMBER}}">
                                            <ng-container *ngFor="let country of countries">
                                                <ng-container *ngIf="country.ID == row.COUNTRY && !!row.PHONE_NUMBER">
                                                    {{country.CODE}} {{row.PHONE_NUMBER | slice:0: 10}}{{row.PHONE_NUMBER.length>10? '..':''}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%;"> Date </th>
                                        <td mat-cell *matCellDef="let row">{{row.CREATED_DATE | date:'dd/MM/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="trialColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: trialColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showSubscribers" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                <div class="header">
                                    Subscriber
                                </div>
                                <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">
                                    refresh
                                </span></button>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource3">
                                    <ng-container matColumnDef="email_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 50%;"> Email ID </th>
                                        <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 50%;"> Date </th>
                                        <td mat-cell *matCellDef="let row">{{row.CREATED_DATE | date:'dd/MM/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="subscriberColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: subscriberColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showConnects" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                <div class="header">
                                    Connect With Us
                                </div>
                                <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">
                                    refresh
                                </span></button>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource4">
                                    <ng-container matColumnDef="email_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 50%;"> Email ID </th>
                                        <td mat-cell *matCellDef="let row">{{row.EMAIL_ID}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 50%;"> Date </th>
                                        <td mat-cell *matCellDef="let row">{{row.CREATED_DATE | date:'dd/MM/yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="connectColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: connectColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                        <div fxLayout="column" *ngIf="showJoins" class="container" fxFlex="78%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                <div class="header">
                                    Join Us
                                </div>
                                <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()" style="font-size: 19px;">
                                    refresh
                                </span></button>
                            </div>
                            <div class="table-scroll">
                                <table mat-table [dataSource]="dataSource5">
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Name </th>
                                        <td mat-cell *matCellDef="let row">{{row.FIRST_NAME}} {{row.LAST_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="hotel_name">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%;"> Hotel Name </th>
                                        <td mat-cell *matCellDef="let row">{{row.HOTEL_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="email_id">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 30%;"> Email ID </th>
                                        <td mat-cell *matCellDef="let row">{{row.EMAIL}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="mobile">
                                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%;"> Mobile </th>
                                        <td mat-cell *matCellDef="let row" matTooltip="{{row.MOBILE}}">
                                            <ng-container *ngFor="let country of countries">
                                                <ng-container *ngIf="country.ID == row.COUNTRY_ID && !!row.MOBILE">
                                                    {{country.CODE}} {{row.MOBILE | slice:0: 10}}{{row.MOBILE.length>10? '..':''}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="joinColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: joinColumns;"></tr>
                                </table>
                                <mat-paginator class="paginator" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                    [hidePageSize]="true" (page)="changePage($event)"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>