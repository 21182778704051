import { Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { colorObj } from 'src/app/shared/color-object';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-website-management',
  templateUrl: './website-management.component.html',
  styleUrls: ['./website-management.component.scss']
})
export class WebsiteManagementComponent {

  contactColumns: string[] = ['name', 'email_id', 'message', 'date'];
  trialColumns: string[] = ['hotel_name', 'email_id', 'mobile', 'date'];
  subscriberColumns: string[] = ['email_id', 'date'];
  connectColumns: string[] = ['email_id', 'date'];
  joinColumns: string[] = ['name', 'hotel_name', 'email_id', 'mobile'];
  dataSource1 = new MatTableDataSource<any>();
  dataSource2 = new MatTableDataSource<any>();
  dataSource3 = new MatTableDataSource<any>();
  dataSource4 = new MatTableDataSource<any>();
  dataSource5 = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  userInfo: any;
  userName: any;
  loading: boolean;
  showContacts: boolean = true;
  showTrials: boolean = false;
  showSubscribers: boolean = false;
  showConnects: boolean = false;
  showJoins: boolean = false;
  limit = 10;
  pageSize = 5;
  pageIndex = 0;
  contactPageNumber: number;
  trialPageNumber: number;
  subscriberPageNumber: number;
  connectPageNumber: number;
  joinPageNumber: number;
  contactResArray: Array<any> = [];
  trialResArray: Array<any> = [];
  subscriberResArray: Array<any> = [];
  connectResArray: Array<any> = [];
  joinResArray: Array<any> = [];
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('paginatorRef') paginatorRef: MatPaginator;
  private _contactData = new BehaviorSubject<any[]>([]);
  private contactDataStore: { $contactData: any[] } = { $contactData: [] };
  readonly $contactData = this._contactData.asObservable();
  private _trialData = new BehaviorSubject<any[]>([]);
  private trialDataStore: { $trialData: any[] } = { $trialData: [] };
  readonly $trialData = this._trialData.asObservable();
  private _subscribeData = new BehaviorSubject<any[]>([]);
  private subscribeDataStore: { $subscribeData: any[] } = { $subscribeData: [] };
  readonly $subscribeData = this._subscribeData.asObservable();
  private _connectData = new BehaviorSubject<any[]>([]);
  private connectDataStore: { $connectData: any[] } = { $connectData: [] };
  readonly $connectData = this._connectData.asObservable();
  private _joinData = new BehaviorSubject<any[]>([]);
  private joinDataStore: { $joinData: any[] } = { $joinData: [] };
  readonly $joinData = this._joinData.asObservable();
  currentPageIndex: any;
  previousPageIndex: any;
  visitedIndex: Array<any> = [0];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  countries = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public adminService: AdminService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.countries = res['COUNTRY'];
      }
    })
    this.showContactUs();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  showContactUs() {
    this.showContacts = true;
    this.showTrials = false;
    this.showSubscribers = false;
    this.showConnects = false;
    this.showJoins = false;
    this.contactPageNumber = 0;
    this.dataSource1.data = [];
    this.contactResArray = [];
    this.getContactUs(0);
  }

  showFreeTrial() {
    this.showContacts = false;
    this.showTrials = true;
    this.showSubscribers = false;
    this.showConnects = false;
    this.showJoins = false;
    this.trialPageNumber = 0;
    this.dataSource2.data = [];
    this.trialResArray = [];
    this.getFreeTrial(0);
  }

  showSubscription() {
    this.showContacts = false;
    this.showTrials = false;
    this.showSubscribers = true;
    this.showConnects = false;
    this.showJoins = false;
    this.subscriberPageNumber = 0;
    this.dataSource3.data = [];
    this.subscriberResArray = [];
    this.getSubscribers(0);
  }

  showConnectUs() {
    this.showContacts = false;
    this.showTrials = false;
    this.showSubscribers = false;
    this.showConnects = true;
    this.showJoins = false;
    this.connectPageNumber = 0;
    this.dataSource4.data = [];
    this.connectResArray = [];
    this.getConnectUs(0);
  }

  showJoinUs() {
    this.showContacts = false;
    this.showTrials = false;
    this.showSubscribers = false;
    this.showConnects = false;
    this.showJoins = true;
    this.joinPageNumber = 0;
    this.dataSource5.data = [];
    this.joinResArray = [];
    this.getJoinUs(0);
  }

  getContactUs(pageNo) {
    this.loading = true;
    this.adminService.getWebsiteContactUs(pageNo, this.limit).subscribe({
      next: (data) => {
        this.contactResArray = this.contactResArray.concat(data.object);
        this.contactDataStore.$contactData = this.contactResArray;
        this.dataSource1.data = this.contactDataStore.$contactData;
        this.dataSource1 = new MatTableDataSource<any>(this.dataSource1.data);
        this._contactData.next(Object.assign({}, this.contactDataStore).$contactData);
        this.dataSource1.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getFreeTrial(pageNo) {
    this.loading = true;
    this.adminService.getWebsiteFreeTrial(pageNo, this.limit).subscribe({
      next: (data) => {
        this.trialResArray = this.trialResArray.concat(data.object);
        this.trialDataStore.$trialData = this.trialResArray;
        this.dataSource2.data = this.trialDataStore.$trialData;
        this.dataSource2 = new MatTableDataSource<any>(this.dataSource2.data);
        this._trialData.next(Object.assign({}, this.trialDataStore).$trialData);
        this.dataSource2.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getSubscribers(pageNo) {
    this.loading = true;
    this.adminService.getWebsiteSubscriber(pageNo, this.limit).subscribe({
      next: (data) => {
        this.subscriberResArray = this.subscriberResArray.concat(data.object);
        this.subscribeDataStore.$subscribeData = this.subscriberResArray;
        this.dataSource3.data = this.subscribeDataStore.$subscribeData;
        this.dataSource3 = new MatTableDataSource<any>(this.dataSource3.data);
        this._subscribeData.next(Object.assign({}, this.subscribeDataStore).$subscribeData);
        this.dataSource3.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getConnectUs(pageNo) {
    this.loading = true;
    this.adminService.getWebsiteConnectUs(pageNo, this.limit).subscribe({
      next: (data) => {
        this.connectResArray = this.connectResArray.concat(data.object);
        this.connectDataStore.$connectData = this.connectResArray;
        this.dataSource4.data = this.connectDataStore.$connectData;
        this.dataSource4 = new MatTableDataSource<any>(this.dataSource4.data);
        this._connectData.next(Object.assign({}, this.connectDataStore).$connectData);
        this.dataSource4.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getJoinUs(pageNo) {
    this.loading = true;
    this.adminService.getWebsiteJoinUs(pageNo, this.limit).subscribe({
      next: (data) => {
        this.joinResArray = this.joinResArray.concat(data.object);
        this.joinDataStore.$joinData = this.joinResArray;
        this.dataSource5.data = this.joinDataStore.$joinData;
        this.dataSource5 = new MatTableDataSource<any>(this.dataSource5.data);
        this._joinData.next(Object.assign({}, this.joinDataStore).$joinData);
        this.dataSource5.paginator = this.paginator.toArray()[0];
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changePage(event) {
    if (this.visitedIndex.indexOf(event.pageIndex) == -1) {
      this.visitedIndex.push(event.pageIndex);
    }
    this.currentPageIndex = event.pageIndex;
    this.previousPageIndex = event.previousPageIndex;
    if (this.currentPageIndex > this.previousPageIndex) {
      if (this.visitedIndex.indexOf(this.currentPageIndex + 1)) {
        this.loading = true;
        if (this.showContacts == true) {
          this.contactPageNumber++;
          this.getContactUs(this.contactPageNumber);
        }
        else if (this.showTrials == true) {
          this.trialPageNumber++;
          this.getFreeTrial(this.trialPageNumber);
        }
        else if (this.showSubscribers == true) {
          this.subscriberPageNumber++;
          this.getSubscribers(this.subscriberPageNumber);
        }
        else if (this.showConnects == true) {
          this.connectPageNumber++;
          this.getConnectUs(this.connectPageNumber);
        }
        else if (this.showJoins == true) {
          this.joinPageNumber++;
          this.getJoinUs(this.joinPageNumber);
        }
      }
    }
  }

  refresh() {
    if (this.showContacts) {
      this.showContactUs();
    } else if (this.showTrials) {
      this.showFreeTrial();
    } else if (this.showSubscribers) {
      this.showSubscription();
    } else if (this.showConnects) {
      this.showConnectUs();
    } else if (this.showJoins) {
      this.showJoinUs();
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}