<div fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">Calendar</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button mat-dialog-close class="close-btn">
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="generateSlotsForm">
        <div mat-dialog-content fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                <div class="calendar" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-button (click)="changeDates('backward')" [disabled]="disableBackArrow" style="place-content: end;">
                        <mat-icon>arrow_back_ios</mat-icon>
                    </button>
                    <div class="date-picker" fxLayoutAlign="center center">
                        <span class="date">
                            {{currentDate | date:'dd MMMM yyyy'}}
                        </span>
                        <input matInput [matDatepicker]="picker1" (dateChange)="selectDate($event)" formControlName="slotDate" [min]="minDate" class="select-date-field" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </div>
                    <button mat-button (click)="changeDates('forward')" style="place-content: center;">
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                </div>
                <div>
                    <div class="slot-header">Select Date Range</div>
                    <mat-form-field appearance="outline" class="inputfield">
                        <mat-date-range-input class="date-range-field" [formGroup]="generateSlotsForm" [rangePicker]="picker" [min]="currentDate">
                            <input matStartDate formControlName="startDate" placeholder="Start date" readonly>
                            <input matEndDate formControlName="endDate" placeholder="End date" readonly>
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker>
                            <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutGap="25px">
                    <div fxFlex="27%">
                        <span class="slot-header">Time Starts with</span>
                    </div>
                    <div fxFlex="27%">
                        <span class="slot-header">Time Ends with</span>
                    </div>
                    <div fxFlex="27%">
                        <span class="slot-header">Time Duration</span>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="25px">
                    <mat-select formControlName="startTime" class="date-field" appearance="fill"
                        (selectionChange)="updateValues()">
                        <mat-option *ngFor="let slot of calendarTime" [value]="slot.ID">{{slot.TIME}}</mat-option>
                    </mat-select>
                    <mat-select formControlName="endTime" class="date-field" appearance="fill"
                        (selectionChange)="updateValues()">
                        <mat-option *ngFor="let slot of calendarTime" [value]="slot.ID">{{slot.TIME}}</mat-option>
                    </mat-select>
                    <div>
                        <button class="value-button" [disabled]="generateSlotsForm.controls['startTime'].disabled"
                            (click)="decreaseDuration()">-</button>
                        <input matInput readonly class="date-field" formControlName="duration"
                            style="width:45% !important">
                        <button class="value-button" [disabled]="generateSlotsForm.controls['startTime'].disabled"
                            (click)="increaseDuration()">+</button>
                    </div>
                </div>
            </div>
            <div class="slots" *ngIf="isGenerate == false">
                <mat-checkbox class="checkbox-area" *ngFor="let slot of slots;" [checked]="slot.ENABLED"
                    (change)="toggle($event, slot.ID)" [disabled]="slot.ENABLED">
                    <span class="slot">
                        {{slot.SLOT_FROM.TIME}}-{{slot.SLOT_TO.TIME}}
                    </span>
                </mat-checkbox>
            </div>
            <div class="slots" *ngIf="isGenerate == true">
                <mat-checkbox class="checkbox-area" *ngFor="let slot of createdSlots;"
                    (change)="toggleforGenerateSlots($event, slot)">
                    <span class="slot">
                        {{slot.FROM}}-{{slot.TO}}
                    </span>
                </mat-checkbox>
            </div>
            <div class="generate-area">
                <button mat-button [ngClass]="this.generateSlotsForm.invalid || disableGenearte ? 'button' : 'generate-button'"
                    [disabled]="this.generateSlotsForm.invalid || disableGenearte" [hidden]="slots.length != 0 || createdSlots.length != 0"
                    (click)="generateSlots()">
                    Generate
                </button>
            </div>
        </div>
    </form>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center">
            <button mat-stroked-button class="delete-btn" (click)="deleteSlots()" [hidden]="slots.length == 0">Delete Slots</button>
        </div>
        <div fxLayoutAlign="end center">
            <button mat-stroked-button [ngClass]="updatedSlots.length == 0 && createdSlots.length == 0? 'action-btn' : 'selected-action-btn'"
                [disabled]="updatedSlots.length == 0 && createdSlots.length == 0" (click)="updateorGenerateSlots()">Save</button>
            <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
        </div>
    </mat-dialog-actions>
</div>