import { Component, ViewEncapsulation, Inject, ViewChild, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-add-edit-area',
  templateUrl: './add-edit-area.component.html',
  styleUrls: ['./add-edit-area.component.scss']
})
export class AddEditAreaComponent {

  loading: boolean = false;
  addAreaForm: FormGroup;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditAreaComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addAreaForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    if (this.data) {
      this.loading = true;
      this.cs.getAreaById(this.data.id).subscribe({
        next: (res) => {
          this.loading = false;
          this.addAreaForm.get("name").setValue(res.object.NAME);
          this.addAreaForm.get("description").setValue(res.object.DESCRIPTION);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addArea() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.addAreaForm.value.name,
        "DESCRIPTION": this.addAreaForm.value.description
      }
      this.cs.updateArea(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.addAreaForm.value.name,
        "DESCRIPTION": this.addAreaForm.value.description
      }
      this.cs.addArea(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}