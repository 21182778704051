<div mat-dialog-title>
  Confirm Delete
  <mat-icon mat-dialog-close class="clear-icon">clear</mat-icon>
</div>
<div mat-dialog-content class="content">
  Would you like to delete the Policy?
</div>
<div mat-dialog-actions class="bottom-buttons">
  <button mat-stroked-button mat-dialog-close class="cancle-button">Cancel</button>
  <button mat-stroked-button cdkFocusInitial class="delete-button" (click)="deletePrivacyPolicy()">Delete</button>
</div>
