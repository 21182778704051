import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { SlotCheckPopupComponent } from '../slot-check-popup/slot-check-popup.component';
import { RequestConfirmedPopupComponent } from '../request-confirmed-popup/request-confirmed-popup.component';

@Component({
  selector: 'app-book-service',
  templateUrl: './book-service.component.html',
  styleUrls: ['./book-service.component.scss']
})
export class BookServiceComponent {

  loading: boolean = false;
  currencyInfo: any;
  currencySymbol: any;
  bookingIdList = [];
  from_date;
  to_date;
  bookingID;
  serviceId;
  categoryId;
  typeId;
  roomId;
  serviceDate = new FormControl('');
  slots = [];
  slotDifference = environment.SLOT_DIFFERENCE;
  disableIdRange: number;
  currentSlotInfo: any = [];
  currentSlotIndex: any;
  todaysDate: string;
  selectedDate: string;
  taxArray = [];
  totalTaxPercentage: number = 0;
  totalTaxAmount: number = 0;
  totalAmountPayable: number = 0;
  public bucketUrl = `${environment.BUCKET_URL}`;
  availableOffers = [];
  offerPrice: number = 0;
  priceAfterOffers: number = 0;
  selectedIndex: any;
  appliedOfferId: any;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<BookServiceComponent>,
    private hotelService: HotelServicesService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currencySymbol = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.bookingID = this.data.bookingId;
    this.serviceId = this.data.serviceId;
    this.categoryId = this.data.categoryId;
    this.typeId = this.data.typeId;
    this.roomId = this.data.roomId;
    this.getBookingId();
    this.getOffersByService();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getBookingId() {
    this.hotelService.getBookingIdList().subscribe({
      next: (data) => {
        this.bookingIdList = data.object;
        this.bookingIdList.forEach((val) => {
          if (val.ID == this.bookingID) {
            this.from_date = val.FROM_DATE;
            this.to_date = val.TO_DATE;
          }
        });
        this.getDates();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getDates() {
    var startDate = moment(this.from_date, 'DD-MM-YYYY').format('MM-DD-YYYY');
    var endDate = moment(this.to_date, 'DD-MM-YYYY').format('MM-DD-YYYY');
    let fromDate = new Date(startDate);
    let toDate = new Date(endDate);
    var currentDate = new Date();
    if (currentDate <= toDate) {
      if (currentDate <= fromDate) {
        this.from_date = new Date(startDate);
      }
      else {
        this.from_date = new Date();
      }
      this.to_date = new Date(endDate);
    }
    this.serviceDate.setValue(this.datePipe.transform(this.from_date, 'yyyy-MM-dd'));
    this.getServiceTimingsByDate(this.from_date);
  }

  getServiceTimingsByDate(date) {
    let minutesinhours = 60;
    let currentDate = new Date();
    this.disableIdRange = (minutesinhours / this.slotDifference) * (currentDate.getHours() + 1);
    this.currentSlotIndex = -1;
    this.currentSlotInfo = [];
    this.loading = true;
    this.todaysDate = moment(currentDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.selectedDate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    if (this.serviceId == 1) {
      this.hotelService.getSpaSlotsByDate(this.selectedDate, this.typeId, this.categoryId).subscribe({
        next: (data) => {
          this.loading = false;
          this.slots = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else if (this.serviceId == 3) {
      this.hotelService.getTransportSlotsByDate(this.selectedDate, this.typeId, this.categoryId).subscribe({
        next: (data) => {
          this.loading = false;
          this.slots = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else if (this.serviceId == 4) {
      this.hotelService.getConferenceRoomSlotsByDate(this.selectedDate, this.typeId, this.categoryId).subscribe({
        next: (data) => {
          this.loading = false;
          this.slots = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else if (this.serviceId == 5) {
      this.hotelService.getPackageSlotsByDate(this.selectedDate, this.typeId, this.categoryId).subscribe({
        next: (data) => {
          this.loading = false;
          this.slots = data.object;
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  formatDate(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }

  getSlot(slotInfo, index) {
    this.currentSlotInfo = slotInfo;
    this.totalTaxPercentage = 0;
    this.totalTaxAmount = 0;
    this.taxArray = [];
    for (let tax of this.currentSlotInfo.TYPE_ID?.SERVICETAX) {
      let obj = {
        NAME: tax?.TAX_ID.NAME,
        PERCENTAGE: tax?.TAX_ID.PERCENTAGE,
        AMOUNT: (this.currentSlotInfo.TYPE_ID.PRICE * (tax?.TAX_ID.PERCENTAGE / 100)).toFixed(2)
      }
      this.taxArray.push(obj);
      this.totalTaxPercentage += tax.TAX_ID.PERCENTAGE;
    }
    let totalTax = ((this.currentSlotInfo.TYPE_ID.PRICE * (this.totalTaxPercentage / 100))).toFixed(1);
    this.totalTaxAmount = Number(totalTax);
    this.totalAmountPayable = Number(this.currentSlotInfo.TYPE_ID.PRICE) + this.totalTaxAmount;
    this.currentSlotIndex = index;
  }

  getOffersByService() {
    var currentDate = new Date();
    this.hotelService.getOffersByServiceId(this.serviceId).subscribe({
      next: (data) => {
        data.object.forEach(element => {
          var endDate = moment(element.END_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
          let toDate = new Date(endDate);
          if (currentDate <= toDate) {
            this.availableOffers.push(element);
          }
        });
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  applyOffer(discount, index, offerID, price) {
    this.offerPrice = Math.round(((discount / 100) * price) * 100) / 100;
    this.priceAfterOffers = Math.round((price - this.offerPrice) * 100) / 100;
    this.totalTaxAmount = 0;
    this.taxArray = [];
    for (let tax of this.currentSlotInfo.TYPE_ID?.SERVICETAX) {
      let obj = {
        NAME: tax?.TAX_ID.NAME,
        PERCENTAGE: tax?.TAX_ID.PERCENTAGE,
        AMOUNT: (this.priceAfterOffers * (tax?.TAX_ID.PERCENTAGE / 100)).toFixed(2)
      }
      this.taxArray.push(obj);
    }
    let totalTax = ((this.priceAfterOffers * (this.totalTaxPercentage / 100))).toFixed(1);
    this.totalTaxAmount = Number(totalTax);
    if (this.totalTaxAmount != 0) {
      this.priceAfterOffers = Math.round((this.priceAfterOffers + this.totalTaxAmount) * 100) / 100;
    }
    this.selectedIndex = index;
    this.appliedOfferId = offerID;
  }

  proceed(slotId) {
    this.loading = true;
    this.hotelService.checkBookedSlots(this.serviceId, this.selectedDate, this.categoryId, this.typeId, this.bookingID).subscribe({
      next: (data) => {
        if (data.object.BOOKING == true) {
          const dialogRef = this.dialog.open(SlotCheckPopupComponent, {
            width: '50%',
            height: 'auto',
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult && dialogResult.state) {
              this.book(slotId);
            }
            else {
              this.cancel();
            }
          })
          this.loading = false;
        }
        else if (data.object.BOOKING == false) {
          this.book(slotId);
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.cancel();
      }
    })
  }

  book(slotId) {
    this.loading = true;
    let obj = {
      "BOOKING_ID": this.bookingID,
      "CATEGORY_ID": this.categoryId,
      "TYPE_ID": this.typeId,
      "SLOT_ID": slotId,
      "DATE": this.selectedDate,
      "OFFER_ID": this.appliedOfferId,
      "ROOM_ID": this.roomId
    }
    this.hotelService.bookService(this.serviceId, obj).subscribe({
      next: (data) => {
        this.loading = false;
        const dialogRef = this.dialog.open(RequestConfirmedPopupComponent, {
          width: '500px',
          height: 'auto',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'refresh') {
            this.dialogRef.close('refresh');
          }
        })
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.dialogRef.close();
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}