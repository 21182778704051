import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-room-dining-categories',
  templateUrl: './room-dining-categories.component.html',
  styleUrls: ['./room-dining-categories.component.scss']
})
export class RoomDiningCategoriesComponent {

  diningCategoryForm: FormGroup;
  savedCategoryFiles = {};
  uploadedCategories = [];
  categoryById: any = [];
  source_url;
  loading: boolean = false;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  notCreate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<RoomDiningCategoriesComponent>,
    private fb: FormBuilder,
    public notify: NotificationService,
    private uploadService: UploadService,
    public hotelService: HotelServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.diningCategoryForm = this.fb.group({
      categoryName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    if (this.data.name == "update_category") {
      this.notCreate = true;
      this.loading = true;
      this.hotelService.getDiningCategoryById(this.data.ID).subscribe({
        next: (res) => {
          this.loading = false;
          this.categoryById = res.object;
          this.source_url = res.object.ATTACHMENT_SOURCE;
          this.diningCategoryForm.get("categoryName").setValue(res.object.NAME);
          this.uploadedCategories.push({
            "ID": this.data.ID,
            "NAME": this.diningCategoryForm.value.categoryName,
            "POSITION": this.categoryById.POSITION,
            "SOURCE": this.source_url
          })
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  async onFileSelected(files) {
    for (let i = 0; i <= files.length - 1; i++) {
      this.loading = true;
      var selectedFile = files[i];
      let tempObjforGetsigned = {
        SERVICE_ID: "6",
        SECTION: 'category',
        FILE_NAME: selectedFile['name']
      }
      await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
        if (res && res['SIGNED_URL']) {
          let saveBody = {
            "POSITION": 1111, //ToDo: temp value
            "SOURCE": res['PATH'],
          }
          this.source_url = res['PATH'];
          let tempObjForUpload = {
            url: res['SIGNED_URL'],
            file: selectedFile
          }
          await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
            if (data) {
              this.loading = false;
              this.notify.showNotification(
                'File uploaded successfully',
                "top",
                (!!colorObj[200] ? colorObj[200] : "success"),
                200
              );
              this.uploadedCategories = [];
              this.uploadedCategories.push(saveBody);
            }
          }).catch((err) => {
            this.handleError(err);
          });
        }
      })
        .catch((err) => {
          this.handleError(err);
        });
    }
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  deleteCategory() {
    this.uploadedCategories = [];
    this.source_url = '';
  }

  saveCategories() {
    let name = this.diningCategoryForm.value.categoryName.trim();
    if (name.length == 0) {
      this.notify.showMessage(
        "Name should not be empty",
        "top",
        "warning"
      )
      this.diningCategoryForm.get("categoryName").setValue('');
    }
    else {
      this.loading = true;
      if (this.data.name == "add_category") {
        this.savedCategoryFiles = {
          "NAME": this.diningCategoryForm.value.categoryName.trim(),
          "POSITION": 1111, //ToDo: temp value
          "SOURCE": this.source_url
        }
        this.hotelService.addDiningCategory(this.savedCategoryFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
      else if (this.data.name == "update_category") {
        this.savedCategoryFiles = {
          "ID": this.data.ID,
          "NAME": this.diningCategoryForm.value.categoryName.trim(),
          "POSITION": 1111, //ToDo: this.categoryById.POSITION,
          "SOURCE": this.source_url
        }
        this.hotelService.updateDiningCategory(this.savedCategoryFiles).subscribe({
          next: (data) => {
            this.loading = false;
            this.dialogRef.close('refresh');
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
            this.dialogRef.close();
          }
        })
      }
    }
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

}