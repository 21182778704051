import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { colorObj } from 'src/app/shared/color-object';
import { NotificationService } from 'src/app/services/notification.service';
import { Select, Store } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { GetMasterData } from 'src/app/session/shared/store/actions/user-info.actions';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent {

  userInfo: any;
  services = [];
  percentages: number[] = Array(99).fill(0).map((x, i) => i + 1);
  serviceId: any;
  slots = [];
  updateData = [];
  isEnabled: any;
  loading: boolean = false;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  currentDate: Date = new Date();
  hotelServices: any = [];
  permittedServices: any = [];

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddComponent>,
    public adminService: AdminService,
    private notify: NotificationService,
    private store: Store,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  addForm: FormGroup = this.fb.group({
    service: ['', Validators.required],
    percentage: ['', Validators.required],
    deal: ['', Validators.required],
    description: ['', Validators.required],
    start: ['', Validators.required],
    end: ['', Validators.required]
  });

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (this.data) {
      this.loading = true;
      this.adminService.getOfferById(this.data.id).subscribe(res => {
        this.loading = false;
        this.isEnabled = res.object[0].ENABLED;
        this.addForm.get("service").setValue(res.object[0].SERVICE.ID);
        this.addForm.get("deal").setValue(res.object[0].NAME);
        this.addForm.get("description").setValue(res.object[0].DESCRIPTION);
        this.addForm.get("percentage").setValue(res.object[0].DISCOUNT);
        const startDateParts = res.object[0].START_DATE.split('-').map(part => parseInt(part, 10));
        const endDateParts = res.object[0].END_DATE.split('-').map(part => parseInt(part, 10));
        const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]);
        const endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]);
        this.addForm.get("start").setValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
        this.addForm.get("end").setValue(this.datePipe.transform(endDate, 'yyyy-MM-dd'));
        // this.addForm.get("start").setValue(res.object[0].START_DATE);
        // this.addForm.get("end").setValue(res.object[0].END_DATE);
      });
    }
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      if (this.userInfo.roleId == 2) {
        for (let service of this.services) {
          if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
      else if (this.userInfo.roleId != 2) {
        for (let service of this.services) {
          if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.permittedServices.push(service);
          }
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  save() {
    this.loading = true;
    const formattedStartDate = this.datePipe.transform(this.addForm.value.start, 'dd-MM-yyyy');
    const formattedEndDate = this.datePipe.transform(this.addForm.value.end, 'dd-MM-yyyy');
    //To update offer
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.addForm.value.deal,
        "SERVICE_ID": this.addForm.value.service,
        "DESCRIPTION": this.addForm.value.description,
        "DISCOUNT": this.addForm.value.percentage,
        "START_DATE": formattedStartDate,
        "END_DATE": formattedEndDate,
        "ENABLED": this.isEnabled
      }
      this.adminService.updateOffer(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    //To add offer
    else {
      let obj = {
        "NAME": this.addForm.value.deal,
        "SERVICE_ID": this.addForm.value.service,
        "DESCRIPTION": this.addForm.value.description,
        "DISCOUNT": this.addForm.value.percentage,
        "START_DATE": formattedStartDate,
        "END_DATE": formattedEndDate,
        "ENABLED": true
      }
      this.adminService.addOffer(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}