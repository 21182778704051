import { Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { colorObj } from 'src/app/shared/color-object';
import { NotificationService } from 'src/app/services/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent {

  loading: boolean = true;
  userInfo: any;
  userName: string;
  accessType: number;
  limit = 10;
  pageSize = 5;
  pageIndex = 0;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['reservationId', 'guestName', 'checkInDate', 'checkOutDate', 'action'];
  pageNumber = 0;
  resArray: Array<any> = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('paginatorRef') paginatorRef: MatPaginator;
  private _userData = new BehaviorSubject<any[]>([]);
  private userDataStore: { $userData: any[] } = { $userData: [] };
  readonly $userData = this._userData.asObservable();
  currentPageIndex: any;
  previousPageIndex: any;
  visitedIndex: Array<any> = [0];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private hotelService: HotelServicesService,
    public notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getCheckedInList(this.pageNumber);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCheckedInList(pageNo) {
    this.hotelService.getReservationList('2', pageNo, this.limit).subscribe({
      next: (data) => {
        this.loading = false;
        this.resArray = this.resArray.concat(data.object);
        this.userDataStore.$userData = this.resArray;
        this.dataSource.data = this.userDataStore.$userData;
        this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
        this.dataSource.paginator = this.paginator.toArray()[0];
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changePage(event) {
    if (this.visitedIndex.indexOf(event.pageIndex) == -1) {
      this.visitedIndex.push(event.pageIndex);
    }
    this.currentPageIndex = event.pageIndex;
    this.previousPageIndex = event.previousPageIndex;
    if (this.currentPageIndex > this.previousPageIndex) {
      if (this.visitedIndex.indexOf(this.currentPageIndex + 1)) {
        this.pageNumber++;
        this.loading = true;
        this.getCheckedInList(this.pageNumber);
      }
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}