<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div *ngIf="isSlaMngmt">
            <div fxLayout="column" fxLayoutGap="25px">
                <div fxLayout="column" fxLayoutGap="20px">
                    <div class="sla-type">Active</div>
                    <div fxLayout="row wrap" fxLayoutGap="20px" class="questions-row">
                        <mat-card class="ques-card" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center">
                            <div class="card-head">Create Your Own SLA</div>
                            <button mat-stroked-button class="add-sla-btn" (click)="createSLA()" [disabled]="accessType == 2">
                                Add
                            </button>
                        </mat-card>
                        <div *ngFor="let sla of slaList; let i=index;">
                            <mat-card class="ques-card" fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxLayout="column" fxFlex="65%" fxLayoutGap="15px">
                                    <div class="sla-head">{{sla.NAME}}</div>
                                    <div fxLayout="row" fxLayoutGap="15px">
                                        <button mat-stroked-button class="disable-btn"
                                            (click)="enableSLA(sla.ID, sla.ENABLED, sla.ENABLED ? 'disable' : 'enable')"
                                            [disabled]="accessType == 2">
                                            {{sla.ENABLED ? 'Disable' : 'Enable'}}
                                        </button>
                                        <button mat-stroked-button class="add-sla-btn" (click)="editSLA(sla.ID)" [disabled]="accessType == 2">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                                <img src="{{bucketUrl + sla.SERVICE_ID.SLA_IMAGE_SOURCE}}"
                                    onerror="this.src='assets/images/question_mark.svg';" class="sla-img">
                            </mat-card>
                        </div>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="column" fxLayoutGap="5px">
                        <div class="sla-type">Inactive</div>
                        <div class="sla-type-head" style="padding-bottom: 20px;">Choose our diverse range of SLA</div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="20px" class="questions-row">
                        <div *ngFor="let service of permittedServices; let i=index;">
                            <mat-card class="ques-card" fxLayout="column" fxLayoutGap="15px"
                                fxLayoutAlign="center center" style="cursor: pointer;"
                                (click)="listServiceSLAs(service.ID)">
                                <div class="card-head">SLA for {{service.NAME}}</div>
                                <img src="{{bucketUrl + service.SLA_IMAGE_SOURCE}}"
                                    onerror="this.src='assets/images/question_mark.svg';" class="sla-img">
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="createSla">
            <mat-card fxLayout="column" fxLayoutGap="15px" class="sla-card">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxFlex="50%" fxLayoutAlign="start center" fxLayoutGap="2%">
                        <div class="back-arrow" (click)="backToSLAs()">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="request-head">
                            {{updateSla ? 'Edit SLA' : 'Create SLA'}}
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="50%" fxLayoutAlign="end center" fxLayoutGap="3%">
                        <button mat-button class="cancel-btn" (click)="backToSLAs()">Cancel</button>
                        <button mat-button class="save-btn" *ngIf="!updateSla" (click)="saveSLA()"
                            [disabled]="slaForm.invalid">Save</button>
                        <button mat-button class="save-btn" *ngIf="updateSla" (click)="updateSLA()"
                            [disabled]="slaForm.invalid">Update</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <form [formGroup]="slaForm">
                    <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    SLA Name
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="slaName"
                                        placeholder="Enter SLA Name" maxlength="200">
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Service
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="slaService"
                                        placeholder="Select Service">
                                        <mat-option *ngFor="let service of permittedServices" [value]="service.ID" [disabled]="updateSla">
                                            {{service.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex="100%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Description
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="slaDescription"
                                        placeholder="Enter SLA Description" maxlength="250">
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="30px">
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Start Condition
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="startCondition"
                                        placeholder="Select Condition"
                                        (selectionChange)="selectStartCondition($event.value)">
                                        <mat-select-trigger>When it is in {{startStatus}} status</mat-select-trigger>
                                        <mat-option *ngFor="let status of requestStatus" [value]="status.ID">
                                            {{status.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Stop Condition
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="stopCondition"
                                        placeholder="Select Condition"
                                        (selectionChange)="selectStopCondition($event.value)">
                                        <mat-select-trigger>When it is in {{stopStatus}} status</mat-select-trigger>
                                        <mat-option *ngFor="let status of requestStatus" [value]="status.ID"
                                            [disabled]="status.ID <= slaForm.controls['startCondition'].value">
                                            {{status.NAME}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Duration (In Minutes)
                                </div>
                                <div>
                                    <input type="number" min="0" oninput="validity.valid||(value='');" class="text-box"
                                        formControlName="slaDuration" placeholder="Enter Duration">
                                </div>
                            </div>
                        </div>
                        <div class="request-head" style="padding: 15px 0;" *ngIf="!!stopId">Configure TAT</div>
                        <div formArrayName="TATArray">
                            <div fxLayout="column" fxLayoutGap="30px"
                                *ngFor="let guestControl of getTAT(); let in = index;" formGroupName="{{in}}">
                                <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center"
                                    style="padding-bottom: 10px;">
                                    <div fxLayout="row" fxFlex="40%" fxLayoutGap="2%" fxLayoutAlign=" center">
                                        <div fxFlex="40%">
                                            <mat-select class="text-box" appearance="fill" formControlName="statusFrom">
                                                <mat-option *ngFor="let status of requestStatus" [value]="status.ID"
                                                    disabled>
                                                    {{status.NAME}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div fxFlex="14%" fxLayoutAlign="center center" class="tat-text"
                                            style="padding-left: 15px;">To</div>
                                        <div fxFlex="40%">
                                            <mat-select class="text-box" appearance="fill" formControlName="statusTo">
                                                <mat-option *ngFor="let status of requestStatus" [value]="status.ID"
                                                    disabled>
                                                    {{status.NAME}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxFlex="60%" fxLayoutGap="30px" class="duration"
                                        fxLayoutAlign="space-between center">
                                        <div fxFlex="7%">
                                            <input type="number" min="0" oninput="validity.valid||(value='');"
                                                class="text-box" formControlName="days">
                                        </div>
                                        <div class="tat-text">In Days</div>
                                        <div fxFlex="7%">
                                            <input type="number" min="0" oninput="validity.valid||(value='');"
                                                class="text-box" formControlName="hours">
                                        </div>
                                        <div class="tat-text">In Hours</div>
                                        <div fxFlex="7%">
                                            <input type="number" min="0" oninput="validity.valid||(value='');"
                                                class="text-box" formControlName="minutes">
                                        </div>
                                        <div class="tat-text">Minutes</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
        <div *ngIf="serviceSla" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                <div class="back-arrow" (click)="backToSLAs()">
                    <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                </div>
                <div class="request-head">
                    {{selectedService}}
                </div>
            </div>
            <mat-card class="service-sla-card" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
                *ngIf="!noSLA">
                <div fxLayout="column" fxFlex="70%" fxLayoutGap="10px">
                    <div class="sla-head">{{serviceSlaList.NAME}}</div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <button mat-stroked-button class="add-sla-btn" (click)="viewSLA(serviceSlaList.ID)">
                            View
                        </button>
                        <button mat-stroked-button class="disable-btn"
                            (click)="enableSLA(serviceSlaList.ID, serviceSlaList.ENABLED, serviceSlaList.ENABLED ? 'disable' : 'enable')"
                            [disabled]="accessType == 2">
                            {{serviceSlaList.ENABLED ? 'Disable' : 'Enable'}}
                        </button>
                        <button mat-icon-button (click)="editSLA(serviceSlaList.ID)" [disabled]="accessType == 2">
                            <iconify-icon icon="material-symbols:edit-outline" class="edit-icon"></iconify-icon>
                        </button>
                    </div>
                </div>
                <img src="{{bucketUrl + serviceSlaList.SERVICE_ID?.SLA_IMAGE_SOURCE}}"
                    onerror="this.src='assets/images/question_mark.svg';" class="sla-img">
            </mat-card>
            <div *ngIf="noSLA" class="no-records">
                No records found
            </div>
        </div>
        <div *ngIf="viewSla" fxLayout="row" fxLayoutGap="20px">
            <mat-card fxFlex="75%" fxLayout="column" class="detail-card" fxLayoutGap="2%">
                <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
                    <div fxLayout="row" fxFlex="70%" fxLayoutGap="3%" fxLayoutAlign="start center">
                        <div class="back-arrow" (click)="listServiceSLAs(slaDetails.SERVICE_ID?.ID)">
                            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                        </div>
                        <div class="request-head">
                            {{slaDetails?.NAME}}
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="30%" fxLayoutGap="10px" fxLayoutAlign="end center">
                        <button mat-stroked-button class="add-sla-btn"
                            (click)="enableSLA(slaDetails.ID, slaDetails.ENABLED, slaDetails.ENABLED ? 'disable' : 'enable')"
                            [disabled]="accessType == 2">
                            {{slaDetails.ENABLED ? 'Disable' : 'Enable'}}
                        </button>
                        <button mat-stroked-button class="disable-btn" (click)="editSLA(slaDetails.ID)"
                            [disabled]="accessType == 2">Edit
                        </button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutGap="20px" style="padding-top: 15px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="50%" fxLayoutGap="10px">
                            <div class="detail-head">SLA Name</div>
                            <div class="detail-value">{{slaDetails?.NAME}}</div>
                        </div>
                        <div fxLayout="column" fxFlex="50%" fxLayoutGap="10px">
                            <div class="detail-head">Service</div>
                            <div class="detail-value">{{slaDetails.SERVICE_ID?.NAME}}</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="50%" fxLayoutGap="10px">
                            <div class="detail-head">Start Condition</div>
                            <div class="detail-value">Start when it is in <span
                                style="color: rgba(239, 158, 0, 1);">{{slaDetails.START_STATUS?.NAME}}</span> status
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="50%" fxLayoutGap="10px">
                            <div class="detail-head">Stop Condition</div>
                            <div class="detail-value">Stop when it is in <span
                                style="color: rgba(239, 158, 0, 1);">{{slaDetails.END_STATUS?.NAME}}</span> status
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="50%" fxLayoutGap="10px">
                            <div class="detail-head">Duration</div>
                            <div class="detail-value">{{slaDetails.DURATION}} Minutes</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="100%" fxLayoutGap="10px">
                            <div class="detail-head">Description</div>
                            <div class="detail-value">{{slaDetails.DESCRIPTION}}</div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutGap="20px" style="padding-top: 15px;">
                    <div class="request-head">Configure TAT</div>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="status_from">
                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:30%"> Status Change From </th>
                            <td mat-cell *matCellDef="let element">{{element.START_STATUS.NAME}}</td>
                        </ng-container>
                        <ng-container matColumnDef="status_to">
                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:30%"> Status Change To </th>
                            <td mat-cell *matCellDef="let element">{{element.END_STATUS.NAME}}</td>
                        </ng-container>
                        <ng-container matColumnDef="duration">
                            <th mat-header-cell *matHeaderCellDef class="table-header" style="width:40%"> Duration </th>
                            <td mat-cell *matCellDef="let element">{{element.DAYS}} Days, {{element.HOURS}} Hours, {{element.MINUTES}} Minutes</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-card>
            <mat-card fxFlex="25%" fxLayout="column" class="detail-card" fxLayoutGap="5%">
                <div class="request-head">Activity Log</div>
                <mat-divider></mat-divider>
            </mat-card>
        </div>
    </div>
</div>