import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-internal-booking',
  templateUrl: './update-internal-booking.component.html',
  styleUrls: ['./update-internal-booking.component.scss']
})
export class UpdateInternalBookingComponent {

  userInfo: any;
  loading: boolean = false;
  updateBookingForm: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  statusList = [];
  priorityList = [];
  requestDetails = {};
  isLoading: boolean = false;
  selectedAssignee = [];
  requestId;
  serviceId;
  imageName: any;
  source_url;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<UpdateInternalBookingComponent>,
    private fb: FormBuilder,
    private hotelService: HotelServicesService,
    private adminService: AdminService,
    private uploadService: UploadService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.updateBookingForm = this.fb.group({
      service: [''],
      createdBy: [''],
      assignee: [''],
      priority: ['', Validators.required],
      status: ['', Validators.required],
      description: ['']
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.requestDetails = this.data.request;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.statusList = res['STATUS'];
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.priorityList = res['PRIORITY'];
      }
    })
    let image = !!this.requestDetails['ATTACHMENT_SOURCE'] ? this.requestDetails['ATTACHMENT_SOURCE'] : "";
    let selectedImage = image.split("/");
    this.imageName = selectedImage[selectedImage.length - 1];
    this.source_url = this.requestDetails['ATTACHMENT_SOURCE'];
    this.updateBookingForm.get("createdBy").setValue(this.userInfo.firstName + ' ' + this.userInfo.lastName);
    this.updateBookingForm.get("service").setValue(this.requestDetails['SERVICE_ID']?.NAME);
    this.updateBookingForm.get("assignee").setValue(this.requestDetails['ASSIGNEE']?.ID);
    this.updateBookingForm.get("priority").setValue(this.requestDetails['PRIORITY']?.ID);
    this.updateBookingForm.get("status").setValue(this.requestDetails['REQUEST_STATUS']?.ID);
    this.updateBookingForm.get("description").setValue(this.requestDetails['DESCRIPTION']);
    this.requestId = this.requestDetails['ID'];
    this.serviceId = this.requestDetails['SERVICE_ID']?.ID;
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  openAssigneeSelect() {
    this.isLoading = true;
    this.getAllInternalMembersByServiceID();
  }

  getAllInternalMembersByServiceID() {
    this.adminService.getInternalMembersByServiceId(this.serviceId).subscribe({
      next: (data) => {
        this.selectedAssignee = data.object;
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  updateBooking() {
    this.loading = true;
    let obj = {
      "ID": this.requestId,
      "SERVICE_ID": this.serviceId,
      "ASSIGNEE": this.updateBookingForm.value.assignee,
      "PRIORITY": this.updateBookingForm.value.priority,
      "STATUS": this.updateBookingForm.value.status,
      "ATTACHMENT_SOURCE": this.source_url,
      "DESCRIPTION": this.updateBookingForm.value.description
    }
    this.hotelService.updateInternalBooking(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.dialogRef.close(this.data.requestType);
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status] : "success"),
          data.status
        )
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.dialogRef.close();
      }
    })
  }

  async onFileSelected(files) {
    var selectedFile = files[0];
    let tempObjforGetsigned = {
      SERVICE_ID: this.serviceId,
      SECTION: 'internal-request',
      FILE_NAME: selectedFile['name']
    }
    await this.uploadService.getPreSignedUrl(tempObjforGetsigned).then(async (res) => {
      if (res && res['SIGNED_URL']) {
        this.loading = true;
        this.source_url = res['PATH'];
        let tempObjForUpload = {
          url: res['SIGNED_URL'],
          file: selectedFile
        }
        await this.uploadService.uploadFilethroughSignedUrl(tempObjForUpload).then((data) => {
          if (data) {
            this.loading = false;
            this.notify.showNotification(
              'File uploaded successfully',
              "top",
              (!!colorObj[200] ? colorObj[200] : "success"),
              200
            );
            this.imageName = files[0].name;
          }
        }).catch((err) => {
          this.handleError(err);
        });
      }
    })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    this.loading = false;
    this.notify.showNotification(
      err.error.message,
      "top",
      (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
      err.error.status
    );
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}