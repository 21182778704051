<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div class="spa-services" fxLayout="column">
            <div class="spa-header" fxLayout="row" fxFlex="3pc">
                <div>
                    <span class="spa-header1">
                        Create Services /
                    </span>
                    <span class="spa-header2">
                        In-Room Dining
                    </span>
                </div>
                <div *ngFor="let time of serviceTiming">
                    <span *ngIf="time.SERVICE.ID == 6 && time.ENABLED == true" class="timing">
                        <u style="font-family: 'Montserrat-SemiBold';">Service Available</u> : <u>From</u>:
                        {{time.FROM.TIME}} - <u>To</u>: {{time.TO.TIME}}
                    </span>
                    <span *ngIf="time.SERVICE.ID == 6 && time.ENABLED == false" class="timing">
                        <u style="font-family: 'Montserrat-SemiBold';">Service Available</u> : 24/7
                    </span>
                </div>
            </div>
            <div class="spa-banners" fxLayout="column" fxFlex="10pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Banners
                    </div>
                    <div>
                        <button class="add-btn" (click)="addBanners()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 25px">
                    <div style="align-self: center;" *ngIf="diningBanners.length != 0">
                        <mat-button (click)="bannerScrollLeft()" class="scroll-btn">
                            <img src="assets/images/arrow_back.svg">
                        </mat-button>
                    </div>
                    <div #bannerContent fxLayout="row" fxLayoutGap="30px" class="banners"
                        *ngIf="diningBanners.length != 0">
                        <div *ngFor="let banner of diningBanners; let i=index;">
                            <div id="banner-images" fxLayout="row">
                                <img src="{{bucketUrl + banner.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="img_preview"
                                    (click)="preview(banner.ATTACHMENT_SOURCE)">
                                <div id="swap" style="color: #000000; font-weight: 600; cursor: pointer;"
                                    [matMenuTriggerFor]="bannerOptions">
                                    <mat-icon>more_vert</mat-icon>
                                </div>
                                <mat-menu #bannerOptions="matMenu">
                                    <div class="option-container">
                                        <button mat-menu-item (click)="updateBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -7px;">edit</mat-icon></span>
                                            <span class="option-text">Edit</span>
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button mat-menu-item (click)="deleteBanner(banner.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -9px;">delete_outline</mat-icon></span>
                                            <span class="option-text">Delete</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div style="align-self: center;" *ngIf="diningBanners.length != 0">
                        <mat-button (click)="bannerScrollRight()" class="scroll-btn">
                            <img src="assets/images/arrow_forward.svg">
                        </mat-button>
                    </div>
                    <div class="text-message" *ngIf="diningBanners.length == 0">
                        <span>
                            <br>
                            No banners available ....
                            <br>
                        </span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="spa-categories" fxLayout="column" fxFlex="11pc">
                <div fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 15px;">
                    <div class="box-headers">
                        Category
                    </div>
                    <div>
                        <button class="add-btn" (click)="addCategories()" [disabled]="accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 25px">
                    <div style="align-self: center;" *ngIf="diningCategories.length != 0">
                        <mat-button (click)="categoryScrollLeft()" class="scroll-btn">
                            <img src="assets/images/arrow_back.svg">
                        </mat-button>
                    </div>
                    <div #categoryContent fxLayout="row" fxLayoutGap="30px" class="banners"
                        *ngIf="diningCategories.length != 0">
                        <div *ngFor="let category of diningCategories; let i=index;"
                            [ngStyle]="{'border-bottom': currentActive === i ? '7px solid #000;' : '1px solid #E8E8E8' }"
                            fxLayout="column" fxLayoutAlign="center center"
                            (click)="displayDetails(i, category.NAME, category.ID)">
                            <div fxLayout="row" id="category-images">
                                <img src="{{bucketUrl + category.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';" class="img_preview">
                                <div id="category-swap"><mat-icon
                                        style="color: #000000; font-weight: 600; cursor: pointer;"
                                        [matMenuTriggerFor]="categoryOptions">more_vert</mat-icon></div>
                                <mat-menu #categoryOptions="matMenu">
                                    <div class="option-container">
                                        <button mat-menu-item (click)="updateCategory(category.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -7px;">edit</mat-icon></span>
                                            <span class="option-text">Edit</span>
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button mat-menu-item (click)="deleteCategory(category.ID)" [disabled]="accessType == 2">
                                            <span class="option-text"><mat-icon
                                                    style="margin-bottom: -9px;">delete_outline</mat-icon></span>
                                            <span class="option-text">Delete</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                            <div style="padding:4px" class="category-name">{{category.NAME}}</div>
                        </div>
                    </div>
                    <div style="align-self: center;" *ngIf="diningCategories.length != 0">
                        <mat-button (click)="categoryScrollRight()" class="scroll-btn">
                            <img src="assets/images/arrow_forward.svg">
                        </mat-button>
                    </div>
                    <div class="text-message" *ngIf="diningCategories.length == 0">
                        <span>
                            <br>
                            No categories available ....
                            <br>
                        </span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>

            <div class="spa-details" fxLayout="column" fxLayoutGap="15px" fxFlex="23%" style="margin-top: 20px;">
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div class="box-headers">
                        Type
                    </div>
                    <div>
                        <button class="add-btn" (click)="addTypes()" [disabled]="diningCategories.length == 0 || accessType == 2">
                            + Add
                        </button>
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="sr.no">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:5%"> No. </th>
                        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> Image </th>
                        <td mat-cell *matCellDef="let element">
                            <img *ngIf="!!element.ATTACHMENT_SOURCE" src="{{bucketUrl + element.ATTACHMENT_SOURCE}}" onerror="this.src='assets/images/question_mark.svg';"
                            (click)="preview(element.ATTACHMENT_SOURCE)" class="img_preview">
                            <img *ngIf="!element.ATTACHMENT_SOURCE"
                            src="../../../../assets/images/dining-food.png" class="img_preview1">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> Name </th>
                        <td mat-cell *matCellDef="let element">{{element.NAME}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tags">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:15%"> Tags</th>
                        <td mat-cell *matCellDef="let element">{{element.TAG}} </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%"> Price </th>
                        <td mat-cell *matCellDef="let element"> {{currency}} {{element.PRICE}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:20%"> Description </th>
                        <td mat-cell *matCellDef="let element" matTooltip="{{element.DESCRIPTION}}">
                            {{element.DESCRIPTION | slice:0: 50}}{{element.DESCRIPTION.length>51? '..':''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="available">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%"> Available </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch">
                                <input #checkbox type="checkbox" [checked]="element.AVAILABLE" disabled>
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="table-header" style="width:10%"> Action </th>
                        <td mat-cell *matCellDef="let element" style="height:109px !important">
                            <div fxLayout="row" fxLayoutAlign="center">
                                <button mat-icon-button (click)="editTypes(element.ID)" [disabled]="accessType == 2">
                                    <span class="material-icons-outlined">edit</span>
                                </button>
                                <button mat-icon-button (click)="deleteTypes(element.ID, element.CATEGORY.ID)" [disabled]="accessType == 2">
                                    <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                </table>
                <div class="text-message" *ngIf="dataSource.data.length == 0">
                    <span>
                        <br>
                        No types available ....
                        <br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>