<div class="spa-details" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="main-header">
        <div>
            <span class="header">{{notCreate ? 'Update about type':'Create about type'}}</span>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-button mat-dialog-close class="close-btn">
                    <mat-icon class="close-icon">
                        close
                    </mat-icon>
                </button>
            </mat-dialog-actions>
        </div>
    </div>
    <form [formGroup]="aboutDetailForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutGap="25px">
                <div fxLayout="column" fxFlex="50%" fxLayoutGap="7px">
                    <div class="box-name">
                        Upload Image
                    </div>
                    <div class="text-box" fxLayout="row" style="height:auto" appFileDragDrop (filesChangeEmiter)="onFileSelected($event)">
                        <div>
                            <input type="file" formControlName="image" class="doc_file" id="doc_file"
                                style="display: none" #fileInput hidden accept=".jpg,.jpeg,.png"
                                (change)="onFileSelected($event.target.files)" />
                            <mat-icon (click)="fileInput.click()" style="cursor: pointer;">upload</mat-icon>
                        </div>
                        <div (click)="fileInput.click()">
                            <span class="upload-text1">Click to upload </span><span class="upload-text2">
                                or drag and drop jpeg,jpg,png
                            </span>
                        </div>
                    </div>
                    <div class="file-name" (click)="preview(source_url)" style="cursor: pointer;">
                        {{imageName}}
                    </div>
                </div>
                <div fxLayout="column" fxFlex="50%" fxLayoutGap="7px">
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="description" placeholder="Enter description"></textarea>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="25px">
                <div fxLayout="column" fxFlex="50%" fxLayoutGap="7px">
                    <div class="box-name">
                        About
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="aboutName" placeholder="Enter name">
                    </div>
                </div>
                <div fxLayout="column" fxFlex="50%" fxLayoutGap="7px">
                    <div class="box-name">
                        Tag
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="tag" placeholder="Enter tag">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl">
                <button mat-stroked-button [ngClass]="this.aboutDetailForm.invalid || source_url == null || source_url == '' ? 'action-btn' : 'selected-action-btn'"
                    [disabled]="this.aboutDetailForm.invalid || source_url == null || source_url == ''" (click)="saveTypes()">Save</button>
                <button mat-stroked-button class="action-btns" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>